import React, { ReactNode, createContext, useContext, useState } from 'react';

export interface PageHeaderContextValue {
  height: number;
  setHeight: (height: number) => void;
  hasScrollState: boolean;
  setHasScrollState: (hasScrollState: boolean) => void;
}

const PageHeaderContext = createContext({
  height: 0,
  setHeight: (height: number) => {},
  hasScrollState: false,
  setHasScrollState: (hasScrollState: boolean) => {}
} as PageHeaderContextValue);

export const usePageHeaderContext = () => useContext(PageHeaderContext);

export const PageHeaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [height, setHeight] = useState(0);
  const [hasScrollState, setHasScrollState] = useState(false);

  return (
    <PageHeaderContext.Provider value={{ 
      height,
      setHeight,
      hasScrollState,
      setHasScrollState
    }}>
      {children}
    </PageHeaderContext.Provider>
  );
};
