import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getProjectPermissionList } from '~/api/projects';
import { getPortfolioPermissionList } from '~/api/portfolios';
import { usePageType } from '~/hooks';
import { getPermissions } from '~/api/user';

export const permissionsKey = (type: string, id: string) => ['permissions', type, id];

export const usePermissions = () => {
  const { id } = useParams<{ id: string }>();
  const pageType = usePageType();

  const useQueryResult = useQuery({
    queryKey: permissionsKey(pageType, id),
    async queryFn() {
      let result: { [key: string]: boolean };
      if (pageType === 'project' && id) {
        result = await getProjectPermissionList(id) as { [key: string]: boolean };
      } else if (pageType === 'portfolio' && id) {
        result = await getPortfolioPermissionList(id) as { [key: string]: boolean };
      } else {
        result = await getPermissions() as { [key: string]: boolean };
      }

      return result;
    },
    staleTime: Infinity, // Never refetch
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false
  });

  return { ...useQueryResult, permissions: useQueryResult.data ?? {} };

};