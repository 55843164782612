import { WizardField } from '../../Field';
import WizardForm from '../../WizardForm';
import { LongTermOwnerDropdownOptions, PricingTypeDropdownOptions } from '~/constants';
import { PartnerTypeRadioOptions } from '~/constants/partner-type';
import { EpcCompanyTypeDropdownValues } from '~/constants';
import { useDerivedProjectSetupFields } from '~/features/projectWizard/derived/useDerivedProjectSetupFields';
import { useDerivedRECFields } from '~/features/projectWizard/derived';

export default function ProjectRoles() {
  // Update certain values automatically based on field changes
  useDerivedProjectSetupFields();
  useDerivedRECFields();

  return (
    <WizardForm>
      <WizardField 
        type="radio"
        schemaKey="longTermOwner"
        fieldLabel="Who will own and operate the system once it's built?"
        values={LongTermOwnerDropdownOptions}
      />
      <WizardField
        type="radio"
        schemaKey="epcCompanyType"
        fieldLabel="Who will install the system?"
        values={EpcCompanyTypeDropdownValues}
      />
      <WizardField
        type="text"
        schemaKey="epcCompanyName"
        fieldLabel="EPC name"
        placeholder="Enter EPC name"
      />
      <WizardField
        type="radio"
        schemaKey="partnerType"
        fieldLabel="What type of partner are you seeking?"
        values={PartnerTypeRadioOptions}
      />
      <WizardField
        type="radio"
        schemaKey="pricingType"
        fieldLabel="What type of bids are you seeking?"
        values={PricingTypeDropdownOptions}
      />
    </WizardForm>
  );
}