import { useFadeInStyles } from '~/hooks';

export default function SpecialRECsDescriptionText() {
  return (
    <p style={useFadeInStyles()}>
      You have selected a state that has special renewable energy credits. 
      For pricing to be accurate, we need to collect more detailed information from you before we can provide an estimate - please 
      create a full project, fill in the required fields, and estimate pricing on the Summary tab of that project.
    </p>
  );
}