import { Button, NoAutocompleteDropdown } from '~/components';
import { FormSchemaAwareWidget } from '~/components/form';
import { ProjectStatus } from '~/constants';
import { stringFieldSorter } from '~/utils';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { useForm } from '~/hooks';
import { useEffect } from 'react';
import { Project } from '~/types/project';

const StyledSubmitButton = styled(Button)`
  width: 80%;
`;

//! getAvailableProjectOptions() is used in other files, the rest of this file does not appear to be in use
//! possible replaced with files in this folder client\src\components\PortfolioPage\AddProjectsDropdown

export function getAvailableProjectOptions(projects: any, investorId?: string, mapToOptions = true) {
  if (!projects) {
    return [];
  }
  const availableProjects = projects
    .filter((project: Project) => {
      return !project.isArchived
        && !project.portfolioId
        && (!project.investorId || project.investorId === investorId)
        && (
          project.status === ProjectStatus.DRAFT
          // TODO change this back when underlying issues with IN_REVIEW and MATCHING projects are fixed with marketplace
          // || project.status === ProjectStatus.IN_REVIEW
          // || project.status === ProjectStatus.MATCHING
        );
    })
    .sort(stringFieldSorter('name'));
  
  if (mapToOptions) {
    return availableProjects.map((project: Project) => ({key: project.id, text: project.name, value: project.id}));
  }

  return availableProjects;
}

export function NoAvailableProjectsModalParts({setOpen}: any) {
  return (
    <>
      <Modal.Description style={{padding: '80px 0'}}>
        No suitable projects found.  Try creating a project first.
      </Modal.Description>
      <Modal.Actions style={{padding: '20px'}}>
        <StyledSubmitButton
          onClick={() => setOpen(false)}
          size="small"
          style={{margin: 'auto', fontSize: '18px', border: '0'}}
        >
          Cancel
        </StyledSubmitButton>
      </Modal.Actions>
    </>
  );
}

const ProjectSelectionDropdown = styled(NoAutocompleteDropdown)`
  &.ui.dropdown .menu .selected.item {
    background: var(--color-pure-white);
    color: var(--color-charcoal);
    position: relative;
    padding-right: calc(var(--small) * 2) !important; // Have to use !important to override semantic styles
    &::after {
      content: "\f00c";
      font-family: 'Icons';
      position: absolute;
      right: var(--small);
      top: 50%;
      margin-top: calc(var(--small) / 2 * -1);
      width: var(--small);
      height: var(--small);
      border-radius: 100%;
      background: var(--color-success);
      color: var(--color-pure-white);
      font-size: var(--x-small);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.ui.dropdown .menu > .item:hover {
    background: var(--color-off-white);
    color: var(--color-charcoal);
  }
  &.ui.selection.visible.dropdown:not(.has-value) > .text:not(.default),
  &.ui.dropdown:not(.has-value) .text {
    color: var(--color-charcoal);
  }
`;


export default function AddProjectsDropdown({
  projectOptions,
  schemaKey = 'projectIds',
  placeholder = 'Select projects...',
  style = {width: '100%'},
  selection = true,
  status,
  noResultsMessage = 'No matching projects found.',
  ...props
}: any) {
  const { getFormValue, formState, updateFormValue } = useForm() as any;

  useEffect(() => {
    updateFormValue(schemaKey, []);
  }, []);

  return (
    <FormSchemaAwareWidget as={ProjectSelectionDropdown}
      {...{schemaKey, placeholder, selection, noResultsMessage}}
      fieldLabel="Project selection"
      loading={status === 'pending' || status === 'idle' || status === 'error'}
      disabled={status === 'pending' || status === 'idle' || status === 'error'}
      style={{...style}}
      options={projectOptions.map((option: any) => ({
        ...option,
        selected: getFormValue(schemaKey)?.includes(option.value)
      }))}
      selectOnNavigation={false}
      closeOnChange={false}
      selectOnBlur={false}
      onChange={(schemaKey: any, value: any): any => {
        // Add value to the list if it's not already there. Otherwise remove
        // it from the list.
        const currentValue = formState[schemaKey];
        const newValue = currentValue?.includes(value)
          ? currentValue.filter((v: any) => v !== value)
          : [...currentValue, value];
        updateFormValue(schemaKey, newValue);
      }}
      className={formState[schemaKey]?.length ? 'has-value' : ''}
      text={formState[schemaKey]?.length ? `${formState[schemaKey].length} project${formState[schemaKey].length > 1 ? 's' : ''} selected` : 'Select projects'}
      {...props}
    />
  );
}
