import { addProjectTeamMember, getProjectTeam, inviteExternalProjectTeamMember, updateProjectTeamMember } from '~/api/projects';
import { usePortfolioContext } from './providers/PortfolioContextProvider';
import { useProjectContext } from './providers/ProjectContextProvider';
import usePageType from './usePageType';
import { addPortfolioTeamMember, getPortfolioTeam, inviteExternalPortfolioTeamMember, updatePortfolioTeamMember } from '~/api/portfolios';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { TeamMember } from '~/types/teamManagement';
import toast from 'react-hot-toast';

export default function useProjectTeamApiEndpoints() {
  let pageType = usePageType();
  const { project } = useProjectContext();
  const { portfolio } = usePortfolioContext();
  const queryClient = useQueryClient();

  let id: string;
  if (pageType === 'project' && project?.portfolioId) {
    id = project?.portfolioId;
    pageType = 'portfolio'; // Override page type so we fetch portfolio instead
  } else if (pageType === 'project') {
    id = project?.id;
  } else {
    id = portfolio?.id;
  }

  return useMemo(() => {
    const queryKey = ['projectTeam', pageType, id];

    const updateGetTeamQueryData = (newMember: TeamMember) => {
      const team = queryClient.getQueryData<TeamMember[]>(queryKey);
      let needsAdded = true;
      const newTeam = team?.map((member) => {
        if (member.id === newMember.id || (member.userId && newMember.userId && member.userId === newMember.userId)) {
          needsAdded = false;
          return newMember;
        } else {
          return member;
        }
      });
      if (needsAdded && newTeam) {
        newTeam?.push(newMember);
      }
      queryClient.setQueryData(queryKey, newTeam);
    };

    return {
      getTeam() {
        return pageType === 'project' ? getProjectTeam(id) : getPortfolioTeam(id);
      },
      getTeamQueryKey() {
        return queryKey;
      },
      async addUserToTeam(userId: string) {
        const newMember = await (
          pageType === 'project' 
            ? addProjectTeamMember(id, userId) 
            : addPortfolioTeamMember(id, userId)
        );
        updateGetTeamQueryData(newMember); // Update react-query cache with new data
        return newMember;
      },
      async updateTeamMember(params: { 
        memberId: string; 
        role?: 'LEAD' | 'MEMBER'; 
        isActive?: boolean 
      }) {
        const response = await (
          pageType === 'project' 
            ? updateProjectTeamMember({ id, ...params }) 
            : updatePortfolioTeamMember({ id, ...params })
        );

        if (response.memberDeleted) {
          // Remove member from react-query cache. Do this in a setTimeout to give a delay to the row being deleted
          setTimeout(() => {
            const team = queryClient.getQueryData<TeamMember[]>(queryKey);
            const newTeam = team?.filter((member) => member.id !== params.memberId);
            queryClient.setQueryData(queryKey, newTeam);
            toast.success('External team member removed.', { duration: 4000 });
          }, 500);
        } else {
          // Update react-query cache with new data
          updateGetTeamQueryData(response);
        }

        return response;
      },
      async inviteExternalTeamMember(email: string) {
        const newMember = await (
          pageType === 'project' 
            ? inviteExternalProjectTeamMember(id, email) 
            : inviteExternalPortfolioTeamMember(id, email)
        );
        updateGetTeamQueryData(newMember); // Update react-query cache with new data
        return newMember;
      }
    };
  }, [pageType, id]);
}