import { useUserContext } from '~/hooks';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

export const AvatarCircle = styled.span`
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background: var(--color-gray);
  color: var(--color-pure-white);
  align-items: center;
  justify-content: center;
  font-weight: 600;
  flex-shrink: 0;
`;

export default function Avatar() {
  const { loggedInUser } = useUserContext() as any;

  let avatarContent = <Icon name="user" />;

  const nameParts = loggedInUser?.name?.split(' ');
  if (nameParts?.length > 1) {
    const firstInitial = nameParts[0][0];
    const lastInitial = nameParts[nameParts.length - 1][0];
    avatarContent = <><span>{firstInitial}</span><span>{lastInitial}</span></>;
  }

  return (
      <AvatarCircle>
        {avatarContent}
      </AvatarCircle>
  );
}