import { ProductionUnit } from '~/constants';
import { nonEmptyString, range, validFloat, validationChain } from '../validators';

const validation = {
  epcCost: validFloat,
  name: nonEmptyString,
  addressState: nonEmptyString,
  contractEscalator: validFloat,
  contractTerm: validFloat,
  arrayType: nonEmptyString,
  sizeKwdc: validationChain(validFloat, range(0, 40000)),
  upfrontIncentives: validFloat,
  yearOneSavings: validFloat,
  yearOneProduction: (value: number, data: any, index: number) => {
    const isValidFloat = validFloat(value);
    const unit = data?.yearOneProductionUnit;
    if (isValidFloat) {
      return true;
    } else if (unit === ProductionUnit.KWH && value < 5000) {
      return 'Must be at least 5,000 kWh.';
    } else if (unit === ProductionUnit.KWHKW) {
      return range(500, 3000)(value);
    }
    return false;
  }
};

export default validation;