import * as S from './styles';
import NumberFormat from 'react-number-format';
import { Icon, Input } from 'semantic-ui-react';
import { InputType } from '~/schema/InputType';
import { useFormGeneric, useAutoSaveContext } from '~/hooks';
import { FormButtonSelectGroup, AutoSaveButtonSelectGroup } from '../index';
import FieldErrorMessage from './FieldErrorMessage';
import { AutoSaveRadioSelectGroup, FormRadioSelectGroup } from './RadioSelectGroup';
import FieldLabel from './FieldLabel';
import { useEffect, useState } from 'react';

export type NumberInputProps = {
  autosaving?: boolean
  as?: any
  disabled?: boolean
  schemaKey: string
  datepicker?: boolean
  options?: any
  label?: string
  fieldStyle?: any
  fieldLabel?: string
  fieldLabelStyle?: any
  helpText?: string
  optional?: boolean
  inline?: boolean
  onChange?: Function
  style?: any
  placeholder?: string
  context?: any
  buttonSelectGroup?: any
  numberType?: string
  decimals?: number
  allowFlexibleDecimalInput?: boolean
  unitField?: string
  units?: {
    value: string
    label: string
    decimals?: number
  }[]
  className?: string
  defaultUnit?: string
  fieldId?: string
  buttonStyle?: { [key: string]: string }
  unitsStyle?: { [key: string]: string }
  labelStyle?: { [key: string]: string }
  inputStyle?: { [key: string]: string }
  defaultValue?: number
}


function NumberInputImpl({
  autosaving,
  context,
  buttonSelectGroup: ButtonSelectGroup,
  schemaKey,
  placeholder,
  numberType = InputType.NONE,
  label,
  decimals,
  unitField,
  units,
  defaultUnit,
  fieldStyle,
  fieldLabel,
  fieldLabelStyle,
  optional,
  onChange,
  buttonStyle,
  unitsStyle,
  labelStyle,
  inputStyle,
  defaultValue,
  helpText,
  disabled,
  allowFlexibleDecimalInput,
  ...props
}: NumberInputProps) {
  let {updateValue, getValue, errors} = context;
  if (onChange) {
    updateValue = onChange;
  }

  const storedValue = getValue(schemaKey);
  const [displayValue, setDisplayValue] = useState(storedValue);
  const schemaPath = schemaKey.split('.');
  const numberField = schemaPath.pop();
  if (unitField) {
    schemaPath.push(unitField);
  }
  const unitsSchemaKey = unitField ? schemaPath.join('.') : null;

  useEffect(() => {
    if (!allowFlexibleDecimalInput) {
      setDisplayValue(storedValue === null || storedValue === undefined ? '' : storedValue);
    }
  }, [storedValue]);

  // TODO reimplement this. The decimals prop should act as a default and unit-scoped decimals override that default.
  // Instead. The decimals prop overrides everything.
  if (decimals == null && units?.length) {
    const unit = units.find(unit => unit.value === getValue(unitsSchemaKey)) ?? units[0];
    decimals = unit?.decimals;
  }

  if (!placeholder && decimals === 0) {
    placeholder = '0';
  } else if (!placeholder && decimals && decimals > 0) {
    placeholder = '0.';
    for (let i = 0; i < decimals; i++) {
      placeholder += '0';
    }
  } else if (!placeholder) {
    placeholder = 'Enter value here';
  }

  const error = errors[schemaKey];

  const UnitRadioSelectGroup = autosaving ? AutoSaveRadioSelectGroup : FormRadioSelectGroup;

  return (
    <S.SchemaAwareWidgetField
      error={!!error}
      style={{ position: 'relative', ...fieldStyle}}
    >
      <FieldLabel
        text={fieldLabel as string}
        htmlFor={schemaKey}
        style={fieldLabelStyle}
        optional={optional}
        helpText={helpText}
      />
      <Input 
        icon={numberType === InputType.PERCENT || numberType === InputType.DOLLARS || numberType === InputType.DEGREES}
        iconPosition={numberType === InputType.DOLLARS ? 'left' : undefined} // "right" is the default
        labelPosition={label || numberType === InputType.DEGREES ? 'right' : undefined}
        disabled={disabled}
      >
        {numberType === InputType.DOLLARS ? <Icon name="dollar sign"/> : ''}
        <NumberFormat
          name={numberField}
          thousandSeparator={true}
          decimalScale={allowFlexibleDecimalInput ? undefined : decimals}
          fixedDecimalScale={true}
          allowNegative={numberType === InputType.DEGREES}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={displayValue}
          style={units ? {maxWidth: '16.375rem', ...inputStyle} : {...inputStyle}}
          onBlur={() => {
            const storedValue = getValue(schemaKey);
            if (displayValue !== storedValue) {
              setDisplayValue(storedValue);
            }
          }}
          onValueChange={e => {
            let value = e.floatValue;
            setDisplayValue(value);
            if (allowFlexibleDecimalInput && decimals !== undefined && value && !isNaN(value)) {
              // Manually round to the number decimals amount
              value = parseFloat(value.toFixed(decimals));
            }
            updateValue(schemaKey, value);
          }}
        />
        {numberType === InputType.PERCENT ? <Icon name="percent"/> : ''}
        {units && units.length && (
          <UnitRadioSelectGroup
            schemaKey={unitsSchemaKey}
            style={{...unitsStyle}}
            buttonStyle={buttonStyle}
            values={units}
            defaultValue={defaultUnit}
            inline
          />
        )}
        {label && (
          <div className="ui label label">
           {label}
          </div>
        )}
        {numberType === InputType.DEGREES && !label && (
          <div className="ui label label">degrees</div>
        )}
      </Input>
      <FieldErrorMessage content={error} />
    </S.SchemaAwareWidgetField>
  );
}

// this could be much more concise by relying entirely on ...props, but doing this facilitates IDE autocompletion
export function FormNumberInput({
  schemaKey, placeholder, numberType, label, decimals, unitField, units, fieldStyle, fieldId, fieldLabel, fieldLabelStyle,
  optional, onChange, buttonStyle, unitsStyle, labelStyle, inputStyle, defaultValue, ...props
}: NumberInputProps) {
  return (
    <NumberInputImpl
      context={useFormGeneric()}
      buttonSelectGroup={FormButtonSelectGroup}
      {...{
        schemaKey, placeholder, numberType: numberType, label, decimals, unitField, units, fieldStyle, fieldId, fieldLabel, fieldLabelStyle,
        optional, onChange, buttonStyle, unitsStyle, labelStyle, inputStyle, defaultValue
      }}
      {...props}
    />
  );
}

// this could be much more concise by relying entirely on ...props, but doing this facilitates IDE autocompletion
export function AutoSaveNumberInput({
  schemaKey, placeholder, numberType, label, decimals, unitField, units, defaultUnit, fieldStyle, fieldLabel, fieldLabelStyle,
  optional, onChange, buttonStyle, unitsStyle, labelStyle, inputStyle, defaultValue, ...props
}: NumberInputProps) {
  return (
    <NumberInputImpl
      context={useAutoSaveContext()}
      autosaving
      buttonSelectGroup={AutoSaveButtonSelectGroup}
      {...{
        schemaKey, placeholder, numberType: numberType, label, decimals, unitField, units, defaultUnit, fieldStyle, fieldLabel, fieldLabelStyle,
        optional, onChange, buttonStyle, unitsStyle, labelStyle, inputStyle, defaultValue
      }}
      {...props}
    />
  );
}
