import React, { useEffect, useState } from 'react';
import { AsyncLoading, LightGreySegment } from '~/components';
import { FormSchemaAwareWidget } from '~/components/form';
import { Button, Checkbox, Form, Header } from 'semantic-ui-react';
import { usePromiseWatcher, useForm } from '~/hooks';
import { fetchRoles, signup } from '~/api/user';
import { colors } from '~/theme';
import { ErrorTexts, UserRole } from '~/constants';
import styled from 'styled-components';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

const SignupPane = styled(LightGreySegment)`
  &&& {
    width: 50%;
    min-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 525px) {
      width: 95%;
      min-width: 95%;
      h2.ui.header {
        font-size: 1.25em;
      }
      .ui.checkbox input+label {
        font-size: 14px !important;
      }
      .ui.button {
        padding-left: 1em;
        padding-right: 1em;
        font-size: 18px;
      }
    }
    @media (max-height: 700px) {
      .ui.form>.field,
      .ui.header {
        margin-bottom: .5em;
      }
      .ui.form>p {
        margin: .5em 0;
      }
      .ui.form>.ui.checkbox {
        padding: 10px 0 !important;
      }
      .ui.form .field .ui.input input {
        padding-bottom: .5em;
        padding-top: .5em;
      }
      .ui.button {
        padding-top: .5em;
        padding-bottom: .5em;
      }
    }
  }
`;

interface UserSignupProps {
  userType: any;
  inviteToken: any;
  presetEmail: string;
  defaults?: any;
  externalCompanyId?: string;
}

const UserSignupImpl = ({ userType, inviteToken, presetEmail, externalCompanyId }: UserSignupProps) => {
  const [error, setError] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [ready, setReady] = useState(false);
  const {formState, updateFormValue} = useForm() as any;

  const needsCompanySignup = !inviteToken || (inviteToken && userType === UserRole.EXTERNAL && !externalCompanyId); // TODO add check here to look for external company role code + null externalCompanyId

  const {execute, status} = usePromiseWatcher(
    () => {
      return signup({ 
        ...formState, 
        inviteToken 
      })
      .then(() => {
        window.location.href = '/login';
      });
    }, {
      messageStub: 'signing you up',
      error: (e: any) => {
        if (e.message === ErrorTexts.USER_ALREADY_EXISTS) {
          return (
            <span>
              There is an existing account associated with this e-mail address.{' '}
              Please enter another e-mail, or if you believe this to be in error, contact us via email at{' '}
              <a href="mailto:support@conductor.solar">support@conductor.solar</a>
            </span>
          );
        }
        return undefined;
      }
    },
    [formState, inviteToken],
    {toastOpts: {id: 'signup'}} as any
  );

  if (presetEmail && presetEmail !== formState.email) {
    updateFormValue('email', presetEmail);
  }

  const { execute: getRoles } = usePromiseWatcher(
    async () => {
      const roles = await fetchRoles();
      const role = Object.values(roles).find((role: any) => role.code === userType) as any;
      updateFormValue('roleId', role.id);
    },
    'fetching your role id',
    [userType]
  );
  useEffect(() => {
    if (needsCompanySignup) {
      getRoles();
    }
  }, []);

  useEffect(() => {
    let newError;
    let newReady;

    if (
      !agreed ||
      (formState.company.length === 0 &&
        formState.name.length === 0 &&
        formState.email.length === 0 &&
        formState.password.length === 0)
    ) {
      newError = '';
      newReady = false;
    } else if (!inviteToken && formState.company.length === 0) {
      newError = 'Please enter all fields';
      newReady = false;
    } else if (formState.name.length === 0 || formState.email.length === 0) {
      newError = 'Please enter all fields';
      newReady = false;
    } else if (!emailRegex.test(formState.email)) {
      newError = 'Please enter a valid email';
      newReady = false;
    } else if (formState.password.length === 0) {
      newError = 'Please enter all fields';
      newReady = false;
    } else if ((formState.passwordConfirm && formState.passwordConfirm !== formState.password) || formState.password.length < 8) {
      newError = ''; // no error message at the top because it is handled elsewhere...
      newReady = false; // ... but we still want to disable the submit button
    } else {
      newError = '';
      newReady = true;
    }

    setError(newError);
    setReady(newReady);
  }, [...Object.values(formState), agreed]); // this handles additions to formState
  return (
    <>
      <AsyncLoading status={status} displayStatuses={['pending']}/>
      <SignupPane>
        <Header as="h2">
          New User Signup
          {{[UserRole.INSTALLER]: ' — Developer / EPC', [UserRole.INVESTOR]: ' — Investor'}[userType]}
        </Header>
        {error && <p style={{color: colors.red}}>{error}</p>}
        <Form>
          <FormSchemaAwareWidget
            schemaKey="name"
            fluid
            placeholder="Full name"
          />
          {needsCompanySignup && (
            <FormSchemaAwareWidget
              schemaKey="company"
              fluid
              placeholder="Company name"
            />
          )}
          <FormSchemaAwareWidget
            schemaKey="email"
            fluid
            placeholder="Email"
            autoComplete="username"
            type="email"
            readOnly={!!presetEmail}
          />
          <FormSchemaAwareWidget
            schemaKey="password"
            fluid
            placeholder="Password"
            autoComplete="new-password"
            type="password"
          />
          <FormSchemaAwareWidget
            schemaKey="passwordConfirm"
            fluid
            placeholder="Repeat Password"
            autoComplete="new-password"
            type="password"
          />
          {formState.password && formState.password.length < 8 && (
            <p style={{color: colors.red, fontSize: 16, fontWeight: 900}}>
              Password must be at least 8 characters
            </p>
          )}
          {formState.passwordConfirm && formState.passwordConfirm !== formState.password && (
            <p style={{color: colors.red, fontSize: 16, fontWeight: 900}}>Passwords must match</p>
          )}
          <Checkbox
            style={{padding: '20px 0'}}
            onChange={() => setAgreed(!agreed)}
            checked={agreed}
            label={
              <label style={{fontSize: '16px', fontWeight: '900'}}>
                By checking this box, you agree to the{' '}
                <a style={{color: colors.orange}} href="https://conductor.solar/terms-of-service">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a style={{color: colors.orange}} href="https://conductor.solar/privacy-policy">
                  Privacy Policy
                </a>
                .
              </label>
            }
          />
          <Button
            disabled={!ready}
            style={{backgroundColor: colors.orange, color: colors.pureWhite}}
            fluid
            size="massive"
            onClick={execute}
          >
            Create Account
          </Button>
        </Form>
      </SignupPane>
    </>
  );
};

const UserSignup = ({userType, inviteToken, presetEmail, externalCompanyId, defaults = {
  name: '',
  company: '',
  email: presetEmail ?? '',
  password: '',
  passwordConfirm: '',
  roleName: userType,
  roleId: ''
}}: UserSignupProps) => {
  return (
    <FormContextProvider defaults={defaults}>
      <UserSignupImpl  {...{ userType, inviteToken, presetEmail, externalCompanyId }}/>
    </FormContextProvider>
  );
};

export default UserSignup;
