import { Header } from 'semantic-ui-react';
import { DealStates } from '~/constants';
import { usePortfolioContext } from '~/hooks';
import DealSummaryTable from './DealSummaryTable';
import styled from 'styled-components';

const DealSummaryContainer = styled.div`
  * {
    padding: 0;
    margin: 0;
  }
`;

export default function DealSummary() {
  const portfolioContext = usePortfolioContext();
  
  const { portfolioState, portfolioDisplayValues } = portfolioContext ?? {};
  const isBidding = portfolioState?.earliestState >= DealStates.AWAITING_BID;
  const status = portfolioDisplayValues?.status;

  return (
    <DealSummaryContainer>
      <Header as="h2">
        Deal Summary{status && ` — ${status}`}
      </Header>
      {!isBidding && 
        <p style={{ margin: 'var(--small) 0' }}>
          Your project is being matched with investors. When they bid on this project, it will appear here.
        </p>}
      {isBidding && (
        <>
          <p style={{ margin: 'var(--small) 0' }}>
            Your investors are putting together their bids. As they are received, they will appear below.
          </p>
          <DealSummaryTable />
        </>
      )}
    </DealSummaryContainer>
  );
}