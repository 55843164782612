import Modal from '~/components/Modal';
import { Button } from '~/components';
import { useModalContext } from '~/hooks/providers/ModalProvider';

export default function PortfolioPriceCheckModal() {
  const { closeModal } = useModalContext();

  return (
    <Modal allowClose closeModal={closeModal}>
      <Modal.Title>
        Action required: bid type mismatch
      </Modal.Title>
      <Modal.Content>
        <p>
          When submitting multiple projects within a portfolio, all projects must be set up to request the same bid type from investors. 
        </p>
        <p style={{ fontWeight: '700' }}>
          Please review your projects and re-submit the portfolio with a single bid type across all projects.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary 
          onClick={closeModal}
        >
          OK
        </Button>
      </Modal.Footer>
  </Modal>
  );
};