import axios from '~/utils/axiosInstance';

export async function fetchTeamMembers() {
  const response = await axios.get('/team/users');
  return response.data;
}

export async function fetchTeam() {
  const response = await axios.get('/team');
  return response.data;
}

export async function updateTeamCompany(data: any) {
  const response = await axios.put('/team', data);
  return response.data;
}

export async function inviteTeammate(email: string) {
  const response = await axios.post('team/invite', {email});
  return response.data;
}

export async function verifyToken(token: string) {
  try {
    const response = await axios.get(`team/invite/${token}`);
    return response.data;
  } catch (e: any) {
    if (e?.request?.response && typeof e.request.response === 'string') {
      return Promise.reject({...JSON.parse(e?.request?.response), status: e.response.status});
    }
    throw e;
  }
}

export async function inviteSignup(data: any) {
  try {
    delete data.roleName;
    delete data.roleId;
    delete data.company;
    const response = await axios.post('team/signup', data);
    return response.data;
  } catch (e: any) {
    if (e?.request?.response && typeof e.request.response === 'string') {
      return Promise.reject({...JSON.parse(e?.request?.response), status: e.response.status});
    }
    throw e;
  }
}

export async function getPriorInvestors(installerId: string) {
  const response = await axios.get(`team/priorInvestors/${installerId}`);
  return response.data;
}

export async function getExternalUsers() {
  const response = await axios.get('/team/external-users');
  return response.data;
}

export interface UpdateUserCompanySettingsParams {
  userId?: string;
  inviteId?: string;
  companyRole?: string;
  isDefaultProjectLead?: boolean;
}

export async function updateUserCompanySettings (params: UpdateUserCompanySettingsParams) {
  const response = await axios.put('/team/user/settings', params);
  return response.data;
}

export async function removeInvite(inviteId: string) {
  const response = await axios.delete(`team/invite/${inviteId}`);
  return response.data;
}