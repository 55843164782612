import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

export const SuccessCheckmark = styled(Icon)`
  &.icon {
    color: var(--color-pure-white);
    border-radius: 100%;
    background: var(--color-success);
    font-size: 0.6rem;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    margin: 0;
    width: 1rem;
    height: 1rem;
  }
`;
SuccessCheckmark.defaultProps = {
  name: 'check'
};

export const TabContent = styled.div`
  display: flex;
  align-items: center;
  padding-right: var(--small);
  .ui.circular.label {
    margin-left: auto;
    + .ui.circular.label {
      margin-left: var(--x-small);
    }
  }
  .complete-checkmark {
    margin-left: auto;
  }
`;

export const ProgressBarContainer = styled.div`  
  padding: var(--medium) var(--medium) var(--x-large) 0;
`;