import styled from 'styled-components';

export const EditDocTypesActions = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: calc(var(--x-small) + 0.75rem);
  gap: var(--medium);
  .ui.pointing.scrolling.dropdown {
    .menu {
      margin-top: 0;
      width: 20rem !important;
      margin-right: calc(-1 * var(--small));
      &:after {
        // position the arrow
        left: unset;
        right: calc(var(--small) - 1px);
      }
      > .item {
        max-width: 22.5rem;
        white-space: normal;
      }
    }
  }
`;

export const UploadDocsActions = styled.div<{ fullWidth?: boolean }>`
  width: 100%;
  margin-bottom: calc(var(--x-small) + 0.75rem);
  display: flex;
  justify-content: ${props => props.fullWidth ? 'space-between' : 'flex-start'};
  gap: var(--x-small);
`;