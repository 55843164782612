import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { updateProject } from '~/api/projects';

export const useUpdateProject = (params = {}) => {
  return useMutation({
    mutationFn: async (params: { id: string; data: any; additionalParams?: any, refetchFunction?: () => Promise<any> }) => {
      try {
        await updateProject(params.id, params.data, params.additionalParams);
        if (params.refetchFunction) {
          await params.refetchFunction();
        }
      } catch (err) {
        console.error('Error occurred updating project with ID ' + params?.id);
        console.error(err);
        throw err;
      }
    },
    onError() {
      toast.error('Error occurred updating project. Please try again.', { duration: 5000 });
    },
    ...params
  });
};