import Link from '~/components/Link';
import * as S from './styles';
import { usePortfolioContext } from '~/hooks';

export default function ProjectBids() {
  const { portfolioDisplayValues, displayBlobs } = usePortfolioContext();
  const investors = portfolioDisplayValues?.dealSummary?.investors;
  const investorProjectBidMap = portfolioDisplayValues?.dealSummary?.investorProjectBidMap;

  return displayBlobs.map((projectBlob: any) => {
    const project = projectBlob?.project;
    return (
      <>
        <S.DealSummaryLabel key={project?.id}>
          <Link to={`/project/${project?.id}`} primary underline bold>
            {project?.name}
          </Link>
        </S.DealSummaryLabel>
        
        {investors?.map((investor: any) => {
          const bid = investorProjectBidMap?.[investor?.id]?.[project?.id] ?? {
            text: 'Conversation Ended',
            color: 'var(--color-red)'
          };
          return (
            <S.DealSummaryCell key={investor?.id} style={bid.color ? { color: bid.color } : {}}>
              {bid?.text}
            </S.DealSummaryCell>
          );
        })}
      </>
    );
  });
}