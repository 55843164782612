import { Form, TextArea as _TextArea } from 'semantic-ui-react';
import { Button } from '~/components';
import { FormSchemaAwareWidget } from '~/components/form';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import styled from 'styled-components';
import { useMessagingContext } from '../providers/MessagingProvider';
import { withPromiseToaster } from '~/utils';
import { useConversationContext } from '../providers/ConversationProvider';
import { useAddCommentMutation } from '~/mutations/conversations';

const TextArea = styled(_TextArea)`
  width: 100%;
  resize: none;
  height: 5.875rem;
`;

const FileUploadBox = styled.div`
  position: absolute;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: calc(100% - 1.5em);
  border: 1px dashed var(--color-charcoal);
  background: var(--color-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  color: var(--color-charcoal);
  > p {
    font-size: 1.25rem;
    margin: 0;
  }
`;

function NewCommentImplForm({
  fileDragging,
  fileUploading,
  getInputProps
}: {
  fileDragging?: boolean;
  fileUploading?: boolean;
  getInputProps: () => {};
}) {
  const { formState, updateFormValue } = useForm() as any;
  const { selectedConversation } = useConversationContext();
  const { addNewMessage } = useMessagingContext();

  const { mutateAsync: addComment, status: addCommentStatus } = useAddCommentMutation();
  const saveComment = () => withPromiseToaster(
    (async () => {
      const newMessage = await addComment({
        conversationId: selectedConversation?.id as string,
        comment: formState.comment
      });
      updateFormValue('comment', '');
      addNewMessage(newMessage);
    })(),
    {
      messageStub: 'sending your message',
      loading: null,
      success: null
    }
  );

  const isSavingComment = addCommentStatus === 'loading';

  return (
    <Form>
      <div style={{ position: 'relative' }}>
        <FormSchemaAwareWidget as={TextArea}
          schemaKey="comment"
          fieldStyle={{ marginBottom: 'var(--small)', paddingTop: 'var(--medium)' }}
          placeholder="Click here to enter a comment, or drag-and-drop a file here to share with your partner"
        />
        <FileUploadBox style={{ zIndex: fileDragging || fileUploading ? 2 : -1 }}>
          <p>Drop file here to upload</p>
          <input {...getInputProps()} />
        </FileUploadBox>
      </div>
      <Button
        primary
        size='medium'
        onClick={saveComment}
        disabled={!selectedConversation?.isActive || isSavingComment || !formState?.comment || fileUploading}
        loading={isSavingComment || fileUploading}
      >
        Send Message
      </Button>
    </Form>
  );
}

const NewCommentForm = ({ defaults = {comment: ''}, getInputProps, fileDragging, fileUploading }: any) => {
  return (
    <FormContextProvider defaults={defaults}>
      <NewCommentImplForm getInputProps={getInputProps} fileDragging={fileDragging} fileUploading={fileUploading} />
    </FormContextProvider>
  );
};

export default NewCommentForm;