import { useEffect, useState } from 'react';
import formConditions, { FormConditionsParams } from '~/utils/formConditions';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useFormConditions = function({ data, updateData, config, getTransformedData }: FormConditionsParams) {
  const [conditions, setConditions] = useState(formConditions({
    data,
    config,
    updateData,
    getTransformedData
  }));

  useEffect(() => {
    setConditions(formConditions({
      data,
      config,
      updateData,
      getTransformedData
    }));
  }, [data, updateData, config, getTransformedData]);

  return conditions;
};