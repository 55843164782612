import { Project } from '~/types/project';
import { ProjectStorage } from '~/types/project';

export default function storage (project: Project) {
  const wattage = project?.storage?.reduce((acc: any, storage: ProjectStorage) => acc + (storage?.wattage ?? 0), 0);
  const discharge = project?.storage?.reduce((acc: any, storage: ProjectStorage) => acc + (storage?.discharge ?? 0), 0);
  
  return {
    make: null,
    model: null,
    wattage,
    discharge,
  };
}