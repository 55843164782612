import { useEffect, useRef, useState } from 'react';
import * as S from './styles';
import styled from 'styled-components';
import SidebarTabs from '~/components/SidebarTabs';
import { Conversation } from '~/types/conversations/Conversation';
import { useConversationContext } from '../providers/ConversationProvider';
import { useRequiredResponseCountsQuery } from '~/requests/projects/useRequiredResponseCounts';
import { Label } from 'semantic-ui-react';

interface ConversationDropdownListProps {
  show: boolean;
  conversations: Conversation[];
}

const DropdownContainer = styled.div<{ show: boolean, containerHeight: string }>`
  transition: 0.2s ease-in-out;
  opacity: 0;
  margin-top: 0;
  max-height: 0;
  overflow: hidden;
  ${props => props.show && `
    opacity: 1;
    margin-top: 0.75rem;
    max-height: ${props.containerHeight};
  `}
`;

export default function ConversationDropdownList({ show, conversations }: ConversationDropdownListProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState('0');
  const { selectedConversation, setConversation } = useConversationContext();
  const { data: requiredResponseCountsData } = useRequiredResponseCountsQuery();

  useEffect(() => {
    if (show) {
      const childrenHeight = Array.from(ref.current?.children ?? []).reduce((acc, child) => {
        acc += child.clientHeight;
        return acc;
      }, 0);
      setHeight(`${childrenHeight / 16}rem`);
    } else {
      setHeight('0');
    }
  }, [show]);
  
  return (
    <DropdownContainer ref={ref} show={show} containerHeight={height}>
      {conversations.map(conversation => {
        const requiredResponseCount = requiredResponseCountsData?.find((response: any) => response.id === conversation?.id)?.count ?? 0;

        return (
          <SidebarTabs.Tab
            key={conversation.id}
            active={conversation.id === selectedConversation?.id}
            onSelect={() => setConversation(conversation) }

          >
            <S.TabContent>
              <div style={{color: 'var(--color-charcoal)'}}>{conversation.name}</div>
              {requiredResponseCount ? (
                <Label circular color='red' style={{ flexShrink: '0' }}>
                  {requiredResponseCount}
                </Label>
              ): null}
            </S.TabContent>
          </SidebarTabs.Tab>
        );
      })}
    </DropdownContainer>
  );
}