import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '~/components';
import { Form } from 'semantic-ui-react';
import { createProject } from '~/api/projects';
import Modal from '~/components/Modal';
import { FormSchemaAwareWidget } from '~/components/form';
import { usePromiseWatcher } from '~/hooks';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import { defaultValues } from '~/schema/project';
import { STATES } from '~/utils';

const CreateProjectModal = () => {
  // Call hooks and parent contexts
  const { formState } = useForm() as any;
  const { closeModal, modalProps } = useModalContext();
  const portfolioId = modalProps?.portfolioId;

  const history = useHistory();

  // Form is not valid if the name or addressState is empty
  const hasError = !formState.name || !formState.addressState;

  const { execute, status, value } = usePromiseWatcher(
    () => createProject({...defaultValues, ...formState, portfolioId}),
    'creating the project' + (portfolioId ? ' and adding it to the portfolio' : ''),
    [formState]
  );

  // Handle the form submission
  const handleSubmit = () => {
    if (!hasError) {
      execute();
    }
  };

  useEffect(() => {
    const id = (value as any)?.id;
    if (id) {
      history.push(`/project/${id}/wizard`);
      closeModal();
    }
  }, [value, history]);

  return (
    <Modal>
      <Modal.Title>
        Create new project
      </Modal.Title>
      <Modal.Content>
        <p>
          Start with the basics so we can customize your project information based on location.
        </p>
        <Form onSubmit={handleSubmit}>
          <FormSchemaAwareWidget
            fieldLabel="Project name"
            placeholder="Enter value"
            schemaKey="name"
          />
          <FormSchemaAwareWidget
            fieldLabel="State"
            placeholder="Enter value"
            schemaKey="addressState"
            search
            options={STATES}
          />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Button primary outlined onClick={closeModal}>Cancel</Button>
        <Button
          primary
          disabled={hasError || status === 'pending'}
          loading={status === 'pending'}
          onClick={handleSubmit}
        >
          Create project
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function() {
  const defaults = {
    name: '',
    addressState: ''
  };
  return (
    <FormContextProvider defaults={defaults}>
      <CreateProjectModal />
    </FormContextProvider>
  );
}