import { usePortfolioList } from '~/requests/projects/usePortfolioList';
import PopupMenu from './PopupMenu';
import * as S from '../styles';
import { Link } from 'react-router-dom';
import { PopupMenuProps } from './types';
import { Portfolio } from '~/types/portfolio';

export default function PortfoliosPopupMenu({ trigger, onClose }: PopupMenuProps) {
    const { data: portfolios, status } = usePortfolioList();

    return (
      <PopupMenu trigger={trigger} onClose={onClose} isLoading={status === 'loading'}>
        {portfolios?.length === 0 ? 'No portfolios to display' 
          : portfolios?.map((portfolio: Portfolio) => (
            <S.MenuItem
              key={portfolio.id}
              as={Link}
              to={`/portfolio/${portfolio.id}`}
              onClick={() => onClose()}
            >
              {portfolio.name}
            </S.MenuItem>
          ))}
      </PopupMenu>
    );
  };
