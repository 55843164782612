export type ProjectRejectionReason = 'NOT_LARGE_ENOUGH' | 'BAD_ESTIMATE' | 'ADDITIONAL_INFO_NEEDED' | 'ECONOMICS_NOT_FEASIBLE' | 'ADDITIONAL_DEVELOPMENT_NEEDED' | 'LOCATION_NOT_ALLOWED' | 'OTHER';

export const ProjectRejectionReasons = {
  NOT_LARGE_ENOUGH: 'This project size may not be large enough for an individual transaction',
  BAD_ESTIMATE: 'Production estimate does not match expectations based on the project details submitted',
  ADDITIONAL_INFO_NEEDED: 'We need additional information on the offtake customer\'s credit',
  ECONOMICS_NOT_FEASIBLE:
    'The project economics do not look feasible for all parties (Offtaker, EPC/Developer, and/or Investor)',
  ADDITIONAL_DEVELOPMENT_NEEDED: 'Additional development work is needed prior to matching with an Investor',
  LOCATION_NOT_ALLOWED: 'This type of project is not allowed in this location',
  OTHER: 'Other (see below for details)',
} as any;