import React, { createContext, useContext, ReactNode, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { fetchProjectEvents } from '~/api/projectEvents';
import { useUserContext } from '~/hooks';
import { withPromiseToaster } from '~/utils';
import { omit } from 'lodash';
import { ProjectEvent } from '../types';
import { useConversationContext } from './ConversationProvider';
import { fetchConversation } from '~/api/conversations';
import { Conversation } from '~/types/conversations/Conversation';
import { CommentMessage, Message } from '~/types/conversations/Message';
import { useProjectTeamQuery } from '~/requests/team';

// Generate a random number to use for the messaging canvas between 1 million and 10 million
const randomNumber = 1000000 + Math.floor(Math.random() * (9999999 - 1000000 + 1));
const SCROLLABLE_BODY_DOM_ID = `message-canvas-${randomNumber}`;
interface MessagesContextProps {
  companyId: string;
  addNewMessage: (newEvent: any) => void;
  onMessageUpdate: (editedEvent: any) => void;
  commentEdit: any;
  setCommentEdit: (event: any) => void;
  initCommentReply(event: any): void;
  closeCommentReply(event: any): void;
  newCommentReplies: { [key: string]: any };
  scrollableBodyDomId: string;
  isLoading: boolean;
  hasError: boolean;
  messageList: (Message | ProjectEvent)[];
}

const MessagesContext = createContext<MessagesContextProps>({
  companyId: '',
  addNewMessage: () => {},
  commentEdit: null,
  setCommentEdit: () => {},
  onMessageUpdate: () => {},
  initCommentReply: () => {},
  closeCommentReply: () => {},
  newCommentReplies: {},
  scrollableBodyDomId: SCROLLABLE_BODY_DOM_ID,
  isLoading: false,
  hasError: false,
  messageList: []
});
export const useMessagingContext = () => useContext(MessagesContext);

export const MessagingProvider: React.FC<{ pageType: 'portfolio' | 'project', children: ReactNode }> = ({ pageType, children }) => {
  const { loggedInUser } = useUserContext() as any;

  // Get selected conversation from conversation context
  const { selectedConversation, supportConversations } = useConversationContext();
  const isSupportConversation = supportConversations?.find(conversation => conversation?.id === selectedConversation?.id);

  // Set up the edit comment index state
  const [commentEdit, setCommentEdit] = useState(null);

  // Map object for new comment replies. This is intentionally a number and has a functional reason -- do not change to boolean.
  // We want to be able to iterate the number on re-click of "Reply", which allows us to
  // detect the changing value and re-focus on + scroll to the comment reply input
  const [newCommentReplies, setNewCommentReplies] = useState<{ [key: string]: number }>({});

  // Setter for comment reply map
  const initCommentReply = useCallback((event: any) => {
    const id = event?.replyThreadId ?? event?.id;

    // Iterate the number instead of using true/false. This allows us to have a useEffect
    // that detects the changing value and re-focus on + scroll to the comment reply input
    const value = newCommentReplies[id] ? newCommentReplies[id] + 1 : 1;
    setNewCommentReplies({
      ...newCommentReplies,
      [id]: value
    });
  }, [newCommentReplies]);

  // Setter for cancelling comment reply
  const closeCommentReply = useCallback((event: any) => {
    const id = event?.replyThreadId ?? event?.id;
    setNewCommentReplies(omit(newCommentReplies, id));
  }, [newCommentReplies]);

  // Get the correct companyId from the project team response
  const { data: team } = useProjectTeamQuery(); // This data is already expected to have been loaded by the parent component
  const companyId = team?.[0]?.installer?.companyId ?? team?.[0]?.investor?.companyId ?? loggedInUser?.installer?.companyId ?? loggedInUser?.investor?.companyId ?? '';

  // Fetch messages for the conversation
  const { data: messageList, status: fetchMessagesStatus } = useQuery({
    queryKey: ['messages', selectedConversation?.id],
    queryFn() {
      if (selectedConversation) {
        return withPromiseToaster(
          Promise.all([
            fetchConversation(selectedConversation.id), 
            isSupportConversation 
              ? Promise.resolve([]) // Don't fetch events for support conversations
              : fetchProjectEvents(selectedConversation.projectId as string, selectedConversation.investorId)
          ]), {
            messageStub: 'fetching messages',
            loading: null,
            success: null
          }, {
            catchErrors: false
          }
        ).then(([conversation, events]: [Conversation, ProjectEvent[]]) => {
          const messages = (conversation?.messages ?? []).map((message, i) => {
            return {
              ...message,

              // Set flag denoting whether this is a counterparty
              isCounterParty: Boolean(companyId !== (message?.companyId ?? '') || (!companyId && message?.companyId) || (companyId && !message?.companyId))
            };
          }) as Message[];

          let parsedMessages: Message[] = [];
          if (messages?.length) {
            parsedMessages = messages.filter((event: any) => !event?.replyThreadId);
            const parentArrayIndexMap = parsedMessages.reduce((acc: { [key: string]: number }, event: any, i: number) => {
              acc[event?.id] = i;
              return acc;
            }, {} as { [key: string]: number });
            const replyMessages = messages.filter((event: any) => event?.replyThreadId);

            replyMessages.filter(message => message.type === 'COMMENT').forEach((msg: Message) => {
              const parentIndex = parentArrayIndexMap[msg?.replyThreadId as string];
              if (parentIndex !== undefined && parsedMessages[parentIndex]?.type === 'COMMENT') {
                const message = parsedMessages[parentIndex] as CommentMessage;
                if (!message?.replies) {
                  message.replies = [];
                }
                message.replies.push(msg as CommentMessage);
              }
            });
          }

          // Return the messages + events, sorted by createdAt
          return [...parsedMessages, ...events].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        });
      } else {
        return [] as (Message | ProjectEvent)[];
      }
    },
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false
  });

  // Handle adding a new message
  // by updating the queryClient cache
  const queryClient = useQueryClient();
  const addNewMessage = useCallback((newMessage: Message) => {
    queryClient.setQueryData(['messages', selectedConversation?.id], (messages: Message[] | undefined) => {
      messages = messages ?? [] as Message[];
      const newMessages = [...messages as Message[]];

      if (newMessage?.replyThreadId) {
        const parentIndex = messages.findIndex(message => message?.id === newMessage?.replyThreadId);
        if (parentIndex !== -1) {
          const parentComment = newMessages[parentIndex] as CommentMessage;
          if (!parentComment.replies) {
            parentComment.replies = [];
          }
          parentComment.replies.push(newMessage as CommentMessage);
        }

        // For non-admin users all events with the same replyThreadId and update the requiresResponse flag and replyReceived flag as needed
        // The flags need updates only if the reply event is from the other party
        if (!(loggedInUser?.isAdmin && !isSupportConversation)) {
          const replyThreadParentComment = messages.find(message => message?.id === newMessage?.replyThreadId) as CommentMessage;
          const replyThreadComments = replyThreadParentComment ? [replyThreadParentComment, ...(replyThreadParentComment?.replies ?? [])] : [];
          replyThreadComments.filter(message => 
            message.isCounterParty && (message?.replyThreadId === newMessage?.replyThreadId || message?.id === newMessage?.replyThreadId) 
          ).forEach(message => {
            if (message.requiresResponse) {
              message.requiresResponse = false;
              message.replyReceived = true;
            } 
          });
        }
      } else {
        newMessages.push(newMessage);
      }

      return newMessages;
    });
  }, [selectedConversation?.id]);

  // Handle updates to an event in the events list
  // by updating the queryClient cache
  const onMessageUpdate = useCallback((editedMessage: Message) => {
    queryClient.setQueryData(['messages', selectedConversation?.id], (messages: Message[] | undefined) => {
      const updatedMessages = [...messages as Message[]];

      if (editedMessage?.replyThreadId) {
        const parentIndex = messages?.findIndex((message: Message) => message?.id === editedMessage?.replyThreadId) ?? -1;
        if (parentIndex !== -1) {
          const parentComment = updatedMessages[parentIndex] as CommentMessage;
          if (!parentComment?.replies) {
            parentComment.replies = [];
          }
          const replyIndex = parentComment.replies.findIndex((message: Message) => message?.id === editedMessage?.id);
          if (replyIndex !== -1) {
            parentComment.replies[replyIndex] = editedMessage as CommentMessage;
          }
        }
      } else {
        const index = messages?.findIndex((message: Message) => message?.id === editedMessage?.id) ?? -1;
        if (index !== -1) {
          updatedMessages[index] = { 
            ...editedMessage, 
            replies: (updatedMessages[index] as CommentMessage)?.replies 
          } as CommentMessage;
        }
      }

      if (editedMessage?.requiresResponse) {
        const replyId = editedMessage?.replyThreadId ?? editedMessage?.id;
        const message = updatedMessages.find((message: Message) => message?.id === replyId) as CommentMessage;
        if (message) {
          [message, ...(message.replies ?? [])].forEach((event: any) => {
            if (event.id !== editedMessage.id) {
              event.requiresResponse = false;
            }
          });
        }
      }

      return updatedMessages;
    });
  }, [selectedConversation?.id]);

  return (
    <MessagesContext.Provider value={{
      companyId,
      isLoading: fetchMessagesStatus === 'loading',
      hasError: fetchMessagesStatus === 'error',
      addNewMessage,
      commentEdit,
      setCommentEdit,
      onMessageUpdate,
      initCommentReply,
      closeCommentReply,
      newCommentReplies,
      scrollableBodyDomId: SCROLLABLE_BODY_DOM_ID,
      messageList: messageList as (Message | ProjectEvent)[],
    }}>
      {children}
    </MessagesContext.Provider>
  );
};
