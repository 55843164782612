import { useEffect } from 'react';
import { Form, TextArea, Checkbox } from 'semantic-ui-react';
import { FormSchemaAwareWidget } from '~/components/form';
import Button from '~/components/Button';
import styled from 'styled-components';
import Modal from '~/components/Modal';
import { usePortfolioContext, usePromiseWatcher } from '~/hooks';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import { getPriorInvestors } from '~/api/team';
import { submitPortfolioProjects } from '~/api/portfolioProjects';
import { useHistory } from 'react-router-dom';
import { usePermissions } from '~/requests/permissions/usePermissions';

const FormText = styled.p`
  &&& {
    color: var(--color-charcoal);
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 0;
  }
`;

interface PortfolioModalProps {
  closeModal: () => void;
  isStandaloneProject: boolean;
}

function PortfolioSubmissionFormImpl( {
  closeModal,
  isStandaloneProject
}: PortfolioModalProps) {
  const { id, portfolio, updatePortfolioValue } = usePortfolioContext();
  const { formState } = useForm() as any;
  const { permissions } = usePermissions();
  const history = useHistory();

  const {execute: submit, status: submitStatus} = usePromiseWatcher(
   async () => {
      const projects = await submitPortfolioProjects(id, formState);
      if (projects.revertStandaloneProject) {
        // Redirect to standalone project
        history.push(`/project/${projects.revertStandaloneProject.id}`);
      }
      else {
        updatePortfolioValue('', {...formState, projects}, {doSave: false, patch: true});
      }
    },
    {messageStub: 'submitting your portfolio'},
    [id, formState]
  );

  const {execute: executePriorInvestors, value: priorInvestors} = usePromiseWatcher(
    async () => {
      if (!portfolio?.installer?.id) {
        return [];
      }
      const investors = await getPriorInvestors(portfolio?.installer?.id);
      return investors.map((investor: any) => ({key: investor.id, value: investor, text: investor.name}));
    },
    'fetching the Investors you\'ve matched with previously',
    [portfolio?.installer?.id]
  );

  useEffect(executePriorInvestors, [portfolio?.installer?.id]);

  return (
    isStandaloneProject ? (
      <Modal allowClose closeModal={submitStatus !== 'pending' ? closeModal : undefined}>
        <Modal.Title>Project submission</Modal.Title>
        <Modal.Content>
          <p>This portfolio contains a single project.</p>
          <p style={{ fontWeight: '700' }}>
            By clicking the confirm button below, you acknowledge that the portfolio will be removed and the project will be submitted on its own.
          </p>
        </Modal.Content>
        <Modal.Footer>
          <Button 
            primary
            onClick={() => {
              submit();
              closeModal();
            }}
            loading={submitStatus === 'pending'}
          >
            Confirm
          </Button>
          <Button 
            primary
            outlined 
            onClick={closeModal}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal> 
    ) : (  
    <Modal allowClose closeModal={submitStatus !== 'pending' ? closeModal : undefined}>
      <Modal.Title>
        Confirm portfolio submission
      </Modal.Title>
      <Modal.Content>
        <Form>
          <FormText>Conductor&apos;s algorithm will match you with the best investor options for your portfolio. If you&apos;d like to ensure certain investors do (or don&apos;t) see your portfolio, indicate that below.</FormText>
          <FormSchemaAwareWidget
            schemaKey="preferredInvestors"
            placeholder="Previously matched investors"
            multiple
            options={priorInvestors}
            maxSelections={5}
          />  
          <FormSchemaAwareWidget as={TextArea}
            schemaKey="preferredInvestorDetails"
            placeholder="Use this space to provide any additional context, including other preferred investors who are not on the above list or investors with whom you do not want to be matched."
          />
         <FormSchemaAwareWidget as={Checkbox}
            schemaKey="autoGrantDocAccess"
            label='Share my uploaded docs with investors'
            fieldLabelStyle={{fontSize: '0.875rem'}}
          />
          <Modal.Footer>
            <Button
              primary
              outlined
              size="medium"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button 
              primary  
              onClick={() => {
                submit();
                closeModal();
              }}
              loading={submitStatus === 'pending'}
              disabled={!permissions.isDeveloper || formState.preferredInvestors.length > 5 || submitStatus === 'pending'}
            >
              Submit portfolio 
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Content>
    </Modal>
    ));
  }

export const PortfolioSubmissionModal = ( {
  closeModal,
  isStandaloneProject
}: PortfolioModalProps) => {
  return (
    <FormContextProvider defaults={{
      autoGrantDocAccess: true,
      preferredInvestors: [],
      preferredInvestorDetails: '',
    }}>
      <PortfolioSubmissionFormImpl 
        closeModal={closeModal} 
        isStandaloneProject={isStandaloneProject}
      />
    </FormContextProvider>
  );
};