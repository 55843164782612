import { ReactNode, useState } from 'react';
import { Message } from 'semantic-ui-react';
import { DealStates, ProjectRejectionReason, ProjectRejectionReasons } from '~/constants';
import { useProjectContext } from '~/hooks';

export default function ErrorDialog() {
  const { project, projectState } = useProjectContext();
  const [isVisible, setIsVisible] = useState(true);
  const dealState = projectState?.dealState ?? 0;

  const error = {
    title: '',
    message: '' as ReactNode
  };

  if (projectState.isRejected && dealState < DealStates.IN_REVIEW) {
    error.title = 'Project returned to draft status';

    const reason = ProjectRejectionReasons[project?.rejectionDetail?.reason as ProjectRejectionReason];
    const messageParts = project?.rejectionDetail?.message?.split('\n');

    if (reason || messageParts?.length) {
      error.message = (
        <>
          {reason ? <p>{reason}</p> : null}
          {messageParts?.length ? (
            messageParts.map((part: string, i: number) => (
              <p key={i}>{part}</p>
            ))
          ) : null}
        </>
      );
    }
  }

  return isVisible && error.title && error.message
    ? (
      <div style={{ width: '100%', maxWidth: '28.25rem', margin: '0 auto var(--medium) auto' }}>
        <Message error onDismiss={() => setIsVisible(false)}>
          <Message.Header>{error.title}</Message.Header>
          <Message.Content>{error.message}</Message.Content>
        </Message>
      </div>
    ) 
    : null;
}