import styled from 'styled-components';

export const FormListActions = styled.div`
  margin-top: var(--medium);
  display: flex;
  align-items: center;
  > * {
    display: inline-block;
  }
  > * + * {
    margin-left: var(--medium);
  }
`;