import { fetchPortfolioList } from '~/api/portfolios';
import { useQuery } from 'react-query';
import { Portfolio } from '~/types/portfolio';

export const getListPortfolioKey = () => {
  return 'portfolios/list';
};

export const usePortfolioList = (params = {}, queryParams = {}) => {
  return useQuery({
    queryKey: getListPortfolioKey(),
    queryFn() {
      // Query for portfolios, automatically convert updatedAt and createdAt to Date objects
      return fetchPortfolioList(params).then((portfolios: any) => {
        return portfolios.map((portfolio: Portfolio) => ({
          ...portfolio,
          updatedAt: portfolio.updatedAt ? new Date(portfolio.updatedAt) : null,
          createdAt: portfolio.createdAt ? new Date(portfolio.createdAt) : null
        }));
      });
    },

    // Turn of cache... for now
    staleTime: 0,

    // No retries
    retry: false,

    enabled: true,

    // Turn off refetch on window focus
    refetchOnWindowFocus: false,

    // Allow overriding default react-query props
    ...queryParams
  });
};