import { ContractType, ExpensesUnit, Unit } from '~/constants';

const defaultValues = {
  budgetCosts: {
    INTERCONNECTION: {amount: 0, unit: Unit.WDC},
    OTHER_ITC: {amount: 0, unit: Unit.WDC},
    OTHER_NON_ITC: {amount: 0, unit: Unit.WDC},
  },
  epcCostUnit: Unit.WDC,
  contractType: ContractType.PPA,
  siteLeaseCostUnit: ExpensesUnit.KWDC,
  propertyTaxType: null,
  propertyTaxUnit: ExpensesUnit.YEAR,
  omPriceUnit: ExpensesUnit.KWDC,
  omProposeFlag: false,
  siteLeaseExtensions: 0,
  upfrontIncentiveUnit: Unit.WDC,
  stateSpecificUpfrontIncentiveUnit: Unit.WDC,
  installerAcknowledgmentFlag: false,
  additionalOperatingExpenses: [],
  additionalRevenues: [],
  projectArrays: [],
  preferredInvestors: [],
};

export default defaultValues;
