import ProjectWizardHeader from './components/ProjectWizardHeader';
import Worksheet from './components/Worksheet';
import ProjectWizardProvider from './providers/ProjectWizardProvider';

export default function ProjectWizard() {
  return (
    <ProjectWizardProvider>
      <div>
        <ProjectWizardHeader />
        <Worksheet />
      </div>
    </ProjectWizardProvider>
  );
}