import React, { useState } from 'react';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import { DealStates, InvestorBidRate } from '~/constants';
import EditInvestorRateForm from '~/components/investorInvitations/EditInvestorRateForm';
import InvestorButtonOptions from '~/components/investorInvitations/InvestorButtonOptions';
import Link from '../Link';
import { Button } from '~/components/index';

const InvestorGridContainer = styled.div`
  max-width: 100%;
  width: 50rem;
  margin-bottom: var(--large);
`;

const InvestorDisplayGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  padding: var(--x-small) 0;
`;

const InvestorGridCell = styled.div`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
`;

interface InvestorDisplayProps {
  label: string;
  investorQueue: any;
  updateInvitation: any;
  unassignInvestor: any;
  dealState: any;
}

const InvestorDisplay: React.FC<InvestorDisplayProps> = ({
  updateInvitation, 
  investorQueue, 
  label, 
  unassignInvestor, 
  dealState
}) => {
  const sortInvestorQueue = (a: any, b: any) => {
    if (a.order > b.order) return 1;
    else if (a.order < b.order) return -1;
    else return 0;
  };
  const [openEdit, setOpenEdit] = useState({}) as any;

  const setCurrentEditState = (editIndex: any, isOpen: any) => {
    const editMap = {...openEdit, [editIndex]: isOpen};
    setOpenEdit(editMap);
  };


  return (
    <InvestorGridContainer>
      <Header as="h2" style={{ margin: 'var(--small) 0'}}>Investor Invitations</Header>
        {investorQueue?.sort(sortInvestorQueue).map((queueItem: any) => (
          
          <InvestorDisplayGrid key={queueItem.investor.id}> 
            
            <InvestorGridCell style={{ fontWeight: '700', color: 'var(--color-charcoal)'}}>
              {queueItem.investor?.company?.name}
            </InvestorGridCell>
            <InvestorGridCell style={{ justifyContent: 'space-between' }}>
              {openEdit[queueItem.investor.id] ? (
                <EditInvestorRateForm
                  defaults={{
                    investorId: queueItem.investor.id,
                    nrsFee: queueItem.nrsFee,
                    nrsFeeUnit: queueItem.nrsFeeUnit,
                  }}
                  setOpenEdit={(isOpen: any) => setCurrentEditState(queueItem.investor.id, isOpen)}
                  updateInvitation={updateInvitation}
                />
              ) : (
                <>
                  <div>
                    {queueItem.nrsFeeUnit === InvestorBidRate.DOLLARS_PER_WDC && '$'}
                    {queueItem.nrsFee}
                    {queueItem.nrsFeeUnit === InvestorBidRate.DOLLARS_PER_WDC
                      ? ' per WDC'
                      : '%'}
                  </div>
                  {dealState < DealStates.AWAITING_DATAROOM && (
                    <Link 
                      underline
                      as={Button} 
                      onClick={() => setCurrentEditState(queueItem.investor.id, true)}>
                      adjust fee
                    </Link>
                   )}
                </>
              )}
            </InvestorGridCell>
            <InvestorGridCell style={{ justifyContent: 'flex-end'}}>
              <InvestorButtonOptions
                {...{queueItem, updateInvitation, label, unassignInvestor}}
              />
            </InvestorGridCell>
          </InvestorDisplayGrid>
        ))}
    </InvestorGridContainer>
  );
};  

export default InvestorDisplay;
