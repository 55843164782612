import { useEffect, useRef } from 'react';
import { useProjectContext } from '~/hooks';
import { ContractType, LongTermOwner, PricingType, ProjectInterconnectionDropdownValues, ProjectInterconnectionTypeCodes, RecOwnership, RevenueSource } from '~/constants';
import { PartnerTypes } from '~/constants/partner-type';
import { Project } from '~/types/project';
import { DeploymentTypes } from '~/constants/deployment-type';
import { DevelopmentStages } from '~/constants/development-stage';

export const useDerivedProjectSetupFields = () => {
  const { project, updateProjectValue } = useProjectContext();

  // Update certain values automatically based on field changes
  const deploymentTypeRef = useRef(project?.deploymentType);
  const longTermOwnerRef = useRef(project?.longTermOwner);
  useEffect(() => {
    const { pricingType, deploymentType, longTermOwner, partnerType } = project ?? {};

    const updates = {} as any;

    const queueUpdate = (key: keyof Project, value: any) => {
      if (project[key] !== value) {
        updates[key] = value;
      }
    };

    // Update interconnectionType based on deploymentType
    if (deploymentType === DeploymentTypes.CI) {
      queueUpdate('interconnectionType', ProjectInterconnectionTypeCodes.BEHIND_METER);
    } else if (deploymentType && [DeploymentTypes.COMMUNITY_SOLAR, DeploymentTypes.UTILITY].includes(deploymentType)) {
      queueUpdate('interconnectionType', ProjectInterconnectionTypeCodes.FRONT_OF_METER);
    }

    // Update revenueSource based on deploymentType and longTermOwner
    if (deploymentType === DeploymentTypes.COMMUNITY_SOLAR) {
      queueUpdate('revenueSource', RevenueSource.COMMUNITY_SOLAR_SUBS);
      queueUpdate('recOwnership', RecOwnership.INVESTOR);
    } else if (deploymentType && [DeploymentTypes.CI, DeploymentTypes.UTILITY].includes(deploymentType) && longTermOwner === LongTermOwner.END_USER) {
      queueUpdate('revenueSource', RevenueSource.N_A);
    } else if (deploymentType && longTermOwner && [DeploymentTypes.CI, DeploymentTypes.UTILITY].includes(deploymentType) && [LongTermOwner.DEVELOPER, LongTermOwner.THIRD_PARTY].includes(longTermOwner)) {
      queueUpdate('revenueSource', RevenueSource.PPA_LEASE_ESA);
    }

    // Update partnerType based on various fields
    if ((
      [DeploymentTypes.CI, DeploymentTypes.UTILITY].includes(deploymentType as string) &&
      [DevelopmentStages.PROSPECTING, DevelopmentStages.DEVELOPMENT, DevelopmentStages.CONSTRUCTION].includes(project?.developmentStage as string) &&
      [LongTermOwner.DEVELOPER, LongTermOwner.END_USER].includes(longTermOwner as string)
    )) {
      queueUpdate('pricingType', PricingType.ITC);
    }

    // Update pricingType based on developmentStage and longTermOwner
    if (project.developmentStage === DevelopmentStages.OPERATING) {
      queueUpdate('longTermOwner', LongTermOwner.DEVELOPER);
      queueUpdate('partnerType', PartnerTypes.OWN);
      queueUpdate('pricingType', PricingType.ACQUISITION);
    } else if (partnerType === PartnerTypes.TAX_CREDITS) {
      queueUpdate('pricingType', PricingType.ITC);
    } else if (project.deploymentType === DeploymentTypes.COMMUNITY_SOLAR && partnerType === PartnerTypes.OWN) {
      queueUpdate('pricingType', PricingType.ACQUISITION);
    } else if (pricingType === PricingType.ITC && partnerType !== PartnerTypes.TAX_CREDITS) {
      queueUpdate('pricingType', null);
    }

    // Unset "End user" for certain conditions
    if ((
      deploymentType === DeploymentTypes.COMMUNITY_SOLAR &&
      [DevelopmentStages.PROSPECTING, DevelopmentStages.DEVELOPMENT, DevelopmentStages.CONSTRUCTION].includes(project?.developmentStage as string) &&
      longTermOwner === LongTermOwner.END_USER
    )) {
      queueUpdate('longTermOwner', null);
    }

    // Set partnerType based on deploymentType and longTermOwner
    if (
      (deploymentType === DeploymentTypes.COMMUNITY_SOLAR || deploymentType === DeploymentTypes.CI) && 
      longTermOwner === LongTermOwner.THIRD_PARTY
    ) {
      queueUpdate('partnerType', PartnerTypes.OWN);
    }

    // If deployment type or long term owner changes, check if partnerType needs cleared
    if (
      ![LongTermOwner.DEVELOPER, LongTermOwner.END_USER].includes(longTermOwnerRef.current as string) &&
      [LongTermOwner.DEVELOPER, LongTermOwner.END_USER].includes(longTermOwner as string)
    ) {
      queueUpdate('partnerType', null);
      queueUpdate('pricingType', null);
    }
    
    // Call updateProjectValue only if something changed
    if (Object.keys(updates).length) {
      updateProjectValue('', updates, { patch: true });
    }

    deploymentTypeRef.current = deploymentType;
    longTermOwnerRef.current = longTermOwner;
  }, [project?.developmentStage, project?.deploymentType, project?.partnerType, project?.longTermOwner]);

  useEffect(() => {
    if (project?.revenueSource && project?.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS && !project?.customerType?.toLowerCase()?.includes('community solar')) {
      updateProjectValue('customerType', 'Community Solar');
    } else if (project?.revenueSource && project?.revenueSource !== RevenueSource.COMMUNITY_SOLAR_SUBS && project?.customerType?.toLowerCase()?.includes('community solar')) {
      updateProjectValue('customerType', null);
    }
    
    if (project?.contractType !== ContractType.PPA && project?.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS) {
      updateProjectValue('contractType', ContractType.PPA);
    }
  }, [project?.revenueSource]);

  // Clear out deprecated interconnection status on interconnectionType change
  const interconnectionTypeRef = useRef(project?.interconnectionType);
  useEffect(() => {
    if (
      project?.interconnectionType !== interconnectionTypeRef.current && 
      project?.interconnectionStatus &&
      ProjectInterconnectionDropdownValues.find((dropdownValue) => {
        return dropdownValue.value === project?.interconnectionStatus && dropdownValue.deprecated;
      })) {
      updateProjectValue('interconnectionStatus', null);
    }
  }, [project?.interconnectionType]);
};