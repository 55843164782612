import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Table } from '~/components';
import { useUpdateUserCompanySettings } from '~/mutations/team/useUpdateUserCompanySettings';
import { getListTeamMembersKey } from '~/requests/team';

const DefaultProjectLeadCheckbox = ({ member }: { member: { id: string; name: string; email?: string; isDefaultProjectLead: boolean; isTeamInvite?: boolean, isUser?: boolean }}) => {
  const [checkedState, setCheckedState] = useState(member.isDefaultProjectLead);
  const queryClient = useQueryClient();

  const { mutateAsync, status } = useUpdateUserCompanySettings();

  const toggleCheckedState = async () => {
    const newCheckedState = !checkedState;
    const payload = member.isTeamInvite ? { inviteId: member.id, isDefaultProjectLead: newCheckedState } : { userId: member.id, isDefaultProjectLead: newCheckedState };
    try {
      setCheckedState(newCheckedState);
      await mutateAsync(payload);
      queryClient.invalidateQueries(getListTeamMembersKey());
    } catch (e: any) {
      console.error(e);
      setCheckedState(!newCheckedState);
    }
  };

  return (
    <Table.CellCheckbox
      ariaLabel={`Default project lead checkbox for ${member.name ?? member.email ?? 'unknown user'}`}
      checked={checkedState}
      height={48}
      onChange={toggleCheckedState}
      disabled={status === 'loading'}
    />
  );
};

export default DefaultProjectLeadCheckbox;