import { useEffect, useState } from 'react';

// Internal component that renders a three dot animation, used to indicate that the system is loading
export default function LoadingStateDots() {
  const [numDots, setNumDots] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      if (numDots === 1) setNumDots(2);
      else if (numDots === 2) setNumDots(3);
      else setNumDots(1);
    }, 1000);
  }, [numDots]);

  return (
    <>
      <span>.</span>
      <span style={{ opacity: Number(numDots >= 2) }}>.</span>
      <span style={{ opacity: Number(numDots === 3) }}>.</span>
    </>
  );
};