import styled from 'styled-components';

export const TabContent = styled.div`
  &&& {
    display: flex;
    align-items: center;
    padding-right: var(--small);
    .ui.circular.label {
      margin-left: auto;
      + .ui.circular.label {
        margin-left: var(--x-small);
      }
    }
  }
`;

export const ProjectDropdownTrigger = styled.span`
  color: var(--color-secondary);
  font-weight: bold;
  + i.icon {
    color: var(--color-secondary);
    + .menu {
      transform: translateX(1rem);
    }
  }
`;

export const ConversationDropdownButton = styled.button<{ active: boolean; allowToggle: boolean }>`
  all: unset;
  color: var(--color-charcoal);
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  &:hover { 
    cursor: pointer;
    color: var(--color-pure-black);
  }
  .icon {
    transition: 0.2s ease-in-out;
  }
  ${props => props.active && `
    color: var(--color-pure-black);
    font-weight: bold;
    .icon {
      transform: rotate(90deg);
      margin-top: 4px;
    }
  `}
  ${props => !props.allowToggle && `
    pointer-events: none;
    .icon {
      opacity: 0;
    }
  `}
`;

export const ConversationDropdown = styled.div`
    & + & {
      margin-top: var(--medium);
    }
`;