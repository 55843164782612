export const SiteControlStatus = {
  SECURED_VIA_SIGNED_LEASE_OR_EASEMENT: 'SECURED_VIA_SIGNED_LEASE_OR_EASEMENT',
  SECURED_VIA_OPTION_TO_LEASE_AGREEMENT: 'SECURED_VIA_OPTION_TO_LEASE_AGREEMENT',
  SECURED_VIA_LICENSE: 'SECURED_VIA_LICENSE',
  NOT_SECURED_OFFTAKER_OWNS: 'NOT_SECURED_OFFTAKER_OWNS',
  NOT_SECURED_OFFTAKER_DOES_NOT_OWN: 'NOT_SECURED_OFFTAKER_DOES_NOT_OWN',
};

export const SiteControlStatusDisplay = {
  [SiteControlStatus.SECURED_VIA_SIGNED_LEASE_OR_EASEMENT]: 'Secured via a lease or easement',
  [SiteControlStatus.SECURED_VIA_OPTION_TO_LEASE_AGREEMENT]: 'Secured via an option to lease agreement',
  [SiteControlStatus.SECURED_VIA_LICENSE]: 'Secured via a site license',
  [SiteControlStatus.NOT_SECURED_OFFTAKER_OWNS]: 'Not yet secured. Offtaker owns the premises',
  [SiteControlStatus.NOT_SECURED_OFFTAKER_DOES_NOT_OWN]: 'Not yet secured. Offtaker does not own the premises',
};

export const SiteControlStatusDropdownOptions = [
  { value: SiteControlStatus.SECURED_VIA_SIGNED_LEASE_OR_EASEMENT, text: SiteControlStatusDisplay[SiteControlStatus.SECURED_VIA_SIGNED_LEASE_OR_EASEMENT] },
  { value: SiteControlStatus.SECURED_VIA_OPTION_TO_LEASE_AGREEMENT, text: SiteControlStatusDisplay[SiteControlStatus.SECURED_VIA_OPTION_TO_LEASE_AGREEMENT]},
  { value: SiteControlStatus.SECURED_VIA_LICENSE, text: SiteControlStatusDisplay[SiteControlStatus.SECURED_VIA_LICENSE] },
  { value: SiteControlStatus.NOT_SECURED_OFFTAKER_OWNS, text: SiteControlStatusDisplay[SiteControlStatus.NOT_SECURED_OFFTAKER_OWNS] },
  { value: SiteControlStatus.NOT_SECURED_OFFTAKER_DOES_NOT_OWN, text: SiteControlStatusDisplay[SiteControlStatus.NOT_SECURED_OFFTAKER_DOES_NOT_OWN] }
];
