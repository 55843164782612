export const RevenueSource = {
  PPA_LEASE_ESA: 'PPA_LEASE_ESA',
  N_A: 'N_A',
  COMMUNITY_SOLAR_SUBS: 'COMMUNITY_SOLAR_SUBS',
  MERCHANT_POWER_SALES: 'MERCHANT_POWER_SALES'
};

export const RevenueSourceDisplayValues = {
  [RevenueSource.PPA_LEASE_ESA]: 'PPA / Lease / Energy services agreement',
  [RevenueSource.N_A]: 'N/A — The power user will also own the system',
  [RevenueSource.COMMUNITY_SOLAR_SUBS]: 'Community solar subscriptions',
  // [RevenueSource.MERCHANT_POWER_SALES]: 'Merchant power sales'
};