export const formatNumber = (val: any, dec = 0, {default0, prefix = '', suffix = ''}: any = {}) => {
  let num = Number(val);
  const defaultVal = default0 ?? 0;
  if (!num || isNaN(num) || num === Infinity) {
    if (typeof defaultVal === 'number') {
      num = defaultVal;
    } else {
      return defaultVal;
    }
  }
  return prefix + num.toLocaleString('en-US', {minimumFractionDigits: dec, maximumFractionDigits: dec}) + suffix;
};

export const formatDollars = (
  val: any, 
  { dec = 0, default0 = '—', prefix = '$', suffix = '' }: { dec?: number, default0?: string | null, prefix?: string, suffix?: string } = {}
) => {
  return formatNumber(val, dec, { default0, prefix, suffix });
};