import { fetchPortfolioDataroom, fetchProjectDataroom } from 'api/dataroom';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseQueryOptions, UseQueryResult, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { usePageType, useProjectContext } from '~/hooks';
import { Dataroom } from '~/types/dataroom';

export const getDataroomQueryKey = (pageType: string, id: string) => {
  return `${pageType}/${id}/dataroom`;
};

const handleError = (err: any) => {
  console.error(err);
  toast.error('Error occurred fetching project dataroom. Please refresh the page and try again.', { duration: 6000 });
};

export const useDataroomQuery = (queryParams = {} as UseQueryOptions) => {
  const pageType = usePageType(); 
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: getDataroomQueryKey(pageType, id),
    queryFn() {
      if (pageType === 'project' && id) {
        return fetchProjectDataroom(id).catch((err: any) => {
          (queryParams?.onError ?? handleError)(err);
          return {} as Dataroom;
        });
      } else if (pageType === 'portfolio' && id) {
        return fetchPortfolioDataroom(id).catch((err: any) => {
          (queryParams?.onError ?? handleError)(err);
          return {} as Dataroom;
        });
      } else {
        return null;
      }
    },
    staleTime: Infinity, // Cache until invalidated
    retry: false,
    ...queryParams
  });

  // Refetch when interconnection type changes on a project since this automatically updates dataroom data
  const { project } = useProjectContext();
  useEffect(() => {
    if (pageType === 'project') {
      query.refetch();
    }
  }, [project?.interconnectionType, pageType]);

  return {
    ...query,
    updateDataroomCache: (data: Dataroom) => queryClient.setQueryData(getDataroomQueryKey(pageType, id), data),
    clearDataroomCache: () => queryClient.invalidateQueries(getDataroomQueryKey(pageType, id)),
  } as UseQueryResult<Dataroom> & { 
    updateDataroomCache: (data: Dataroom) => void,
    clearDataroomCache: () => void
  };
};