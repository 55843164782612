import Link from '~/components/Link';
import { DealStates } from '~/constants';
import { usePermissions } from '~/requests/permissions/usePermissions';

export default function PortfolioSummaryTableStatus({ projectBlob }: any) {
  const { permissions } = usePermissions();
  const { project, projectState, projectDisplayValues } = projectBlob;

  const isInvestor = !permissions.isAdmin && permissions.hasPortfolioInvestorAccess;
  const dealState = projectState?.dealState as number;
  const needsReview = dealState === DealStates.PROJECT_HAS_ERRORS;
  const readyToSubmit = dealState > DealStates.PROJECT_HAS_ERRORS && dealState <= DealStates.READY_TO_SUBMIT;

  if (isInvestor && dealState === DealStates.AWAITING_BID) {
    return (
      <Link to={`/project/${project.id}/review`} primary bold underline>
        Click here to submit bid
      </Link>
    );
  } else if (isInvestor && dealState === DealStates.PENDING_BID) {
    return 'Bid submitted';
  }

  if (readyToSubmit) {
    return 'Ready to submit';
  }

  if (needsReview) {
    return 'Needs review';
  }

  return projectDisplayValues?.status ?? null;

}