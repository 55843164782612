import WizardForm from '~/features/projectWizard/components/WizardForm';
import { WizardField } from '../../Field';
import { ProjectInterconnectionDropdownValues, SiteControlStatusDropdownOptions } from '~/constants';

// TODO update all status dropdowns to use constants similar to how the siteControlStatus dropdown is set up

export default function KeyDatesAndStatuses () {
  return (
    <WizardForm>
      <WizardField.Row>
        <WizardField
          schemaKey='expectedProceedDate'
          datepicker
          optional
          fieldLabel='Expected notice to proceed date'
          helpText='Estimates are ok here.'
          placeholder="Select date"
        />
        <WizardField
          schemaKey='expectedCommercialOperationDate'
          datepicker
          fieldLabel='Expected commercial operation date'
          helpText='Estimates are ok here.'
          placeholder="Select date"
        />
      </WizardField.Row>
      <WizardField 
        schemaKey="customerPricingStatus"
        type='dropdown'
        selection
        placeholder="Select status"
        options={[
          {text: 'Pricing agreed via a signed document', value: 'PRICING_AGREED_VIA_SIGNED_DOCUMENT'},
          {text: 'Pricing agreed verbally or via email', value: 'PRICING_AGREED_VERBALLY'},
          {text: 'Pricing target known due to avoided costs', value: 'PRICING_TARGET_KNOWN_DUE_TO_AVOIDED_COSTS'},
          {text: 'Pricing target largely unknown', value: 'PRICING_TARGET_LARGELY_UKNOWN'},
        ]}
        fieldLabel='Project status - Customer pricing'
      />
      <WizardField 
        type='dropdown'
        schemaKey="offtakeContractStatus"
        selection
        placeholder="Select status"
        options={[
          {text: 'Offtake contract signed', value: 'OFFTAKE_CONTRACT_SIGNED'},
          {text: 'Offtake contract in review', value: 'OFFTAKE_CONTRACT_IN_REVIEW'},
          {text: 'Offtake term sheet signed', value: 'OFFTAKE_TERM_SIGNED'},
          {text: 'Offtake term sheet in review', value: 'OFFTAKE_TERM_IN_REVIEW'},
          {text: 'No offtake-related docs sent to customer', value: 'NO_OFFTAKE_DOCS_SENT'},
        ]}
        fieldLabel='Project status - Offtake contract'
      />
      <WizardField 
        type='dropdown'
        schemaKey="permitsStatus"
        selection
        placeholder="Select status"
        options={[
          {text: 'In-hand, ready for NTP', value: 'IN_HAND_NTP_READY'},
          {text: 'Only ministerial permits needed', value: 'MINISTERIAL_PERMITS_NEEDED'},
          {text: 'Expected within next 3 months', value: 'EXPECTED_WITHIN_THREE_MONTHS'},
          {text: 'Not expected until >3 months out', value: 'NOT_EXPECTED_UNTIL_THREE_MONTHS_OUT'},
        ]}
        fieldLabel='Project status - Permits'
      />
      <WizardField 
        type='dropdown'
        schemaKey="siteControlStatus"
        selection
        placeholder="Select status"
        options={SiteControlStatusDropdownOptions}
        fieldLabel='Project status - Site control'
      />
      <WizardField 
        type='dropdown'
        schemaKey="interconnectionStatus"
        selection
        placeholder="Select status"
        options={ProjectInterconnectionDropdownValues}
        fieldLabel='Project status - Interconnection'
      />
    </WizardForm>
  );
}