import { useEffect, useRef } from 'react';
import { FormRadioSelectGroup } from '~/components/form';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import { Form } from 'semantic-ui-react';
import { useTeamQuery } from '~/requests/team';
import { updateTeamCompany } from '~/api/team';
import { useForm, useUserContext } from '~/hooks';
import { isEqual } from 'lodash';
import { useMutation } from 'react-query';
import { withPromiseToaster } from '~/utils';

const GlobalCompanySettingsFormImpl = () => {
  const { refetch: refetchTeam } = useTeamQuery(); 
  const { formState } = useForm() as any;

  const { mutate: updateCompany } = useMutation(
    async () => {
      await withPromiseToaster(
      updateTeamCompany(formState)
      .then(() => {
        refetchTeam();
      })
      .catch((err: any) => {
        console.error(err);
        throw err;
      }),
      { messageStub: 'updating global company settings' },
      [formState] as any
      );
    });

  const formStateRef = useRef(formState);
  const autoSaveCompanySettingsTimeoutRef = useRef();
  useEffect(() => {
    if (formState.projectVisibility && !isEqual(formState, formStateRef.current)) {
      if (autoSaveCompanySettingsTimeoutRef.current) {
        clearTimeout(autoSaveCompanySettingsTimeoutRef.current);
      }
      autoSaveCompanySettingsTimeoutRef.current = setTimeout(updateCompany, 3000) as any;
      formStateRef.current = formState;
    }
  }, [formState]);

  // Admins do not use this setting. Just return null
  const { loggedInUser } = useUserContext() as any;
  if (loggedInUser.isAdmin) {
    return null;
  }
  
  return (
    <Form>
      <FormRadioSelectGroup
        schemaKey="projectVisibility"
        fieldLabel="Global company settings"
        fieldStyle={{ 
          marginRight: '6rem'
        }}
        helpText="Standard users should be able to see..."
        inline
        values={[
          { value: 'ALL', label: 'All projects', disabled: false },
          { value: 'INVITE_ONLY', label: 'Only projects to which they have been invited', disabled: false },
        ]}
      >
      </FormRadioSelectGroup>
    </Form>
  );
};

const GlobalCompanySettingsForm = () => {
  const { data: team } = useTeamQuery();
  const defaults = {
    projectVisibility: team.projectVisibility 
  };
  
  return (
    <FormContextProvider defaults={defaults}>
      <GlobalCompanySettingsFormImpl />
    </FormContextProvider>
  );
};

export default GlobalCompanySettingsForm;