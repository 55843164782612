import { useQuery } from 'react-query';
import { fetchNotificationPreferences } from '~/api/user';

export const notificationPreferencesQueryKey = () => 'notification-preferences/list';

export const useNotificationPreferencesQuery = () => {
  return useQuery({
    queryKey: notificationPreferencesQueryKey(),
    queryFn: fetchNotificationPreferences,

    // Cache forever until the cache is cleared manually
    staleTime: Infinity,

    // No retries
    retry: false,

    // No auto refetching
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
};