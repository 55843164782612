const knownErrors = { 
  overview: {
    projectFoundations: [
      'deploymentType',
      'developmentStage',
    ],
    projectRoles: [
      'longTermOwner',
      'epcCompanyName',
      'epcCompanyType',
      'partnerType',
      'pricingType',
    ],
    location: [
      'name',
      'addressCity',
      'addressZip',
      'addressState',
      'addressStreet',
      'utilityZone',
    ],
    keyDatesAndStatuses: [
      'expectedCommercialOperationDate',
      'interconnectionStatus',
      'permitsStatus',
      'customerPricingStatus',
      'offtakeContractStatus',
      'siteControlStatus',
    ],
  },
  system: {
    pv: [
      'projectArrays',
      'projectArrays[].type',
      'projectArrays[].sizeKwdc',
      'projectArrays[].sizeKwac',
      'projectArrays[].yearOneProduction',
      'projectArrays[].moduleTiltDegrees',
      'projectArrays[].azimuthDegrees',
      'projectArrays[].roofCondition',
    ],
    storage: [
      'storage[].make',
      'storage[].model',
      'storage[].wattage',
      'storage[].discharge'
    ],
    productionValidation: []
  },
  budget: {
    upfrontInventives: [],
    budgetItems: [
      'epcCost',
      'budgetCosts.INTERCONNECTION.amount',
      'budgetCosts.OTHER_ITC.amount',
      'budgetCosts.OTHER_NON_ITC.amount'
    ],
 },
  revenue: {
    customerInformation: [
      'customerCreditRating',
      'customerName',
      'customerType',
    ],
    customerContract: [
      'contractTerm',
      'contractEscalator',
      'contractRate',
      'communitySolarDiscountPercentage'
    ],  
    renewableEnergyCredits: [
      'recOwnership',
      'recsData.Illinois.isShinesProgram',
      'recsData.Illinois.recAllocation',
      'recsData.Illinois.recProjectCategory',
      'recsData.Illinois.recUtilityGroup',
      'recsData.Illinois.recProgramYear',
      'recsData.New Jersey.recAllocation',
      'recsData.New Jersey.recValue',
      'recsData.New Jersey.recContractTerm'
    ],
    otherRevenueStreams: [
      'additionalRevenues[].name',
      'additionalRevenues[].amount',
      'additionalRevenues[].escalator',
      'additionalRevenues[].expectedTerm',
    ],
  },
  expenses: {
    siteExpenses: [
      'siteLeaseCost',
      'siteLeaseEscalator',
      'siteLeaseTerm',
      'siteLeaseAcreage',
      'propertyTaxType',
      'propertyTaxAmount',
      'propertyTaxEscalator',
    ],
    operationAndMaintenance: [
      'storageExpenseAmount',
      'storageExpenseEscalator',
      'storageExpenseExpectedTerm',
      'storageExpenseNotes',
    ],
    subscriptionManagement: [
      'subscriptionExpenseAmount',
      'subscriptionExpenseEscalator',
      'subscriptionExpenseExpectedTerm',
      'subscriptionExpenseNotes'
    ],
    operatingExpenses: [
      'additionalOperatingExpenses[].name',
      'additionalOperatingExpenses[].amount',
      'additionalOperatingExpenses[].escalator',
      'additionalOperatingExpenses[].expectedTerm',
    ],
  }
};

export default knownErrors;
