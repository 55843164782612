import { ProjectArraysShortDisplay } from '~/constants';
import { formatNumber } from '~/utils/formatNumber';
// import { Project } from '~/types/project';

export default function technologies(portfolioProjects: any = []) {
  const projectArrayMap = portfolioProjects.reduce((acc: any, project: any) => {
    const projectArraysArray = Object.values(project?.projectArrays || {});
    projectArraysArray?.forEach((projectArray: any) => {
      if (projectArray.sizeKwdc && projectArray.type) {
        acc[projectArray.type] = acc[projectArray.type] || 0;
        acc[projectArray.type] += projectArray.sizeKwdc;
      }
    });

    return acc;
  }, {} as { [key: string]: number }) ?? {};

  const storage = portfolioProjects?.reduce((acc: { discharge?: number, wattage?: number }, project: any) => {
    if (project?.hasStorage) {
      if (project?.storage?.wattage) { //TODO project typing
        acc.wattage = (acc.wattage ?? 0) + project.storage.wattage;
      }
      if (project?.storage?.discharge) {
        acc.discharge = (acc.discharge ?? 0) + project.storage.discharge;
      }
    }
    return acc;
  }, { discharge: undefined, wattage: undefined });

  let technologiesString = '';
  for (const [key, value] of Object.entries(projectArrayMap)) {
    if (technologiesString) {
      technologiesString += '; ';
    }
    technologiesString += `${formatNumber(value)} kWdc, ${ProjectArraysShortDisplay[key]}`;
  }

  if (storage?.wattage !== undefined || storage?.discharge !== undefined) {
    if (technologiesString) {
      technologiesString += '; ';
    }
    technologiesString += storage?.wattage ? `${formatNumber(storage.wattage)} kW` : 'N/A';
    technologiesString += ' / ';
    technologiesString += storage?.discharge ? `${formatNumber(storage.discharge)} kWh` : 'N/A';
    technologiesString += ', Storage';
  }

  return technologiesString;
}