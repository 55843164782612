import ProjectReview from '~/features/projectReview';
import { Route, Switch, useParams } from 'react-router-dom';
import { ProjectContextProvider } from '~/hooks/providers/ProjectContextProvider';
import ProjectWizard from '~/features/projectWizard';
import Dataroom from '~/features/dataroom';
import ProjectPage from '~/components/ProjectPage';
import { DataroomProvider } from '~/features/dataroom/providers/DataroomProvider';
import RedirectRoute from './RedirectRoute';
import Messaging from '~/features/messaging';
import { MessagingProvider } from '~/features/messaging/providers/MessagingProvider';
import { ConversationProvider } from '~/features/messaging/providers/ConversationProvider';
import TeamManagement from '~/components/TeamManagement';

function Project() {
  const {id} = useParams() as any;

  return (
    <ProjectContextProvider id={id}>
      <ProjectPage>
        <Switch>
          <Route path="/project/:id/review">
            <ProjectReview />
          </Route>
          <Route path="/project/:id/wizard">
            <ProjectWizard />
          </Route>
          <Route exact path="/project/:id/dataroom">
            <Route exact path="/project/:id/dataroom">
              <DataroomProvider>
                <Dataroom />
              </DataroomProvider>
            </Route>
          </Route>
          <Route exact path="/project/:id">
            <RedirectRoute to={`/project/${id}/review`} />
          </Route>
          <Route exact path="/project/:id/messages">
            <ConversationProvider>
              <MessagingProvider pageType="project">
                <Messaging />
              </MessagingProvider>
            </ConversationProvider>
          </Route>
          <Route exact path="/project/:id/team">
            <TeamManagement />
          </Route>
        </Switch>
      </ProjectPage>
    </ProjectContextProvider>
  );
}

export default Project;
