import { UseQueryOptions} from 'react-query';
import { useDataroomQuery } from './useDataroomQuery';

export const useProjectDoclist = (queryParams = {} as UseQueryOptions) => {
  const dataroomQuery = useDataroomQuery(queryParams);

  return {
    ...dataroomQuery,
    data: dataroomQuery.data?.documents
  };
};