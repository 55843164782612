import { useMutation, useQueryClient } from 'react-query';
import { completeTutorial } from '~/api/tutorial';
import { getTutorialListKey } from '~/requests/tutorials/useTutorialList';

export const useCompleteTutorialMutation = (params = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ code }: { code: string }) => {
      try {
        await completeTutorial(code);
        queryClient.invalidateQueries(getTutorialListKey());
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    ...params
  });
};