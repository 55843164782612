export const ProjectTeamRoles = {
  LEAD: 'LEAD',
  MEMBER: 'MEMBER',
};

export const ProjectTeamRolesDisplay = {
  [ProjectTeamRoles.LEAD]: 'Project lead',
  [ProjectTeamRoles.MEMBER]: 'Team member',
};

export const ProjectTeamRolesDropdownOptions = [
  { 
    key: ProjectTeamRoles.LEAD,
    text: ProjectTeamRolesDisplay[ProjectTeamRoles.LEAD],
    value: ProjectTeamRoles.LEAD 
  },
  { 
    key: ProjectTeamRoles.MEMBER,
    text: ProjectTeamRolesDisplay[ProjectTeamRoles.MEMBER],
    value: ProjectTeamRoles.MEMBER 
  }
];


