export const PropertyTaxType = {
  EXEMPT: 'EXEMPT',
  PILOT: 'PILOT',
  ANNUAL: 'ANNUAL',
};

export const PropertyTaxTypeDisplayValues = {
  [PropertyTaxType.EXEMPT]: 'Exempt',
  [PropertyTaxType.PILOT]: 'PILOT',
  [PropertyTaxType.ANNUAL]: 'Assessed Annually'
};

export const PropertyTaxTypeRadioOptions = [
  { value: PropertyTaxType.EXEMPT, label: PropertyTaxTypeDisplayValues[PropertyTaxType.EXEMPT] },
  { value: PropertyTaxType.PILOT, label: PropertyTaxTypeDisplayValues[PropertyTaxType.PILOT] },
  { value: PropertyTaxType.ANNUAL, label: PropertyTaxTypeDisplayValues[PropertyTaxType.ANNUAL] }
];