export type ProjectFilterType = '' | 'DRAFT' | 'IN_REVIEW' | 'BIDDING' | 'BID_REVIEW' | 'IN_DILIGENCE' | 'CLOSED' | 'OPEN_QUESTIONS';

export const ProjectFilters = {
  ALL: '',
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  BID_REVIEW: 'BID REVIEW',
  BIDDING: 'BIDDING',
  IN_DILIGENCE: 'IN_DILIGENCE',
  CLOSED: 'CLOSED',
  OPEN_QUESTIONS: 'OPEN_QUESTIONS'
};

export const ProjectFiltersList = [
  { value: ProjectFilters.ALL, label: 'All projects' },
  { value: ProjectFilters.DRAFT, label: 'Draft', hideForInvestors: true },
  { value: ProjectFilters.IN_REVIEW, label: 'In review', hideForInvestors: true },
  { value: ProjectFilters.BIDDING, label: 'Bidding' },
  { value: ProjectFilters.BID_REVIEW, label: 'Bid Review' },
  { value: ProjectFilters.IN_DILIGENCE, label: 'In diligence' },
  { value: ProjectFilters.CLOSED, label: 'Archived' },
  { value: ProjectFilters.OPEN_QUESTIONS, label: 'Open Questions', hideForInvestors: true, hideForDevelopers: true }
];