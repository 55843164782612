import React from 'react';

import * as S from './styles';
import { useUserContext } from '~/hooks';
import { useHistory } from 'react-router-dom';
import Buttons from './Buttons';
import Typography from './Typography';
import TableExample from './TableExample';

function ClientLibrary() {
  const history = useHistory();
  const userContext: any = useUserContext();

  if (!userContext.loggedInUser.isAdmin) {
    history.replace('/');
    return null;
  }

  return (
    <S.ClientLibraryContainer>
      <h1>Client Library</h1>
      <Typography />
      <Buttons />
      <TableExample />
    </S.ClientLibraryContainer>
  );
}

export default ClientLibrary;