import { Loader } from 'semantic-ui-react';

const SettingsPageLoader = () => {
  return (
    <div style={{ 
      position: 'absolute',
      height: 'calc(100vh - 9.125rem)',
      width: '100%',
      inset: 0,
      zIndex: 1,
      background: 'var(--color-off-white)'
    }}>
      <Loader active size="massive" />
    </div>
  );
};

export default SettingsPageLoader;