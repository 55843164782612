import axios from '~/utils/axiosInstance';

export async function fetchTutorials() {
  const response = await axios.get('/team/tutorials');
  return response.data;
}

export async function completeTutorial(code: string) {
  const response = await axios.post(`/team/tutorials/complete?code=${code}`);
  return response.data;
}
