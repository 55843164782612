import styled from 'styled-components';
import { Tab as _Tab } from 'semantic-ui-react';

const Tab = styled(_Tab)`
  gap: var(--large);
  .ui.segment.tab {
    background: transparent;
    border: none;
    padding: 0;
  }
  .ui.secondary.pointing.menu .item {
    display: flex;
    height: 3rem;
    padding: 1rem 0px;
    align-items: center;
    &:not(:first-child) {
      margin-left: var(--large);
    }
  }
`;

// Make tabs be the secondary menu by default
Tab.defaultProps = {
  menu: {
    secondary: true,
    pointing: true
  }
};

export default Tab;