import axios from '~/utils/axiosInstance';

interface AssignToInvestorParams {
  investorId: string;
  nrsFee: number;
  nrsFeeUnit: string;
}

interface UpdateInvestorQueueParams {
  investorId: string;
  nrsFee: number;
  status: string;
  hasDocAccess?: boolean;
}

export const assignProjectToInvestor = async function (id: string, {investorId, nrsFee, nrsFeeUnit}: AssignToInvestorParams) {
  const response = await axios.post(`/admin/projects/${id}/invitations/assign`, {investorId, nrsFee, nrsFeeUnit});
  return response.data;
};

export const unassignProjectFromInvestor = async function (id: string, {investorId}: {investorId: string}) {
  const response = await axios.post(`/admin/projects/${id}/invitations/unassign`, {investorId});
  return response.data;
};

export const updateInvestorInProjectQueue = async function (id: string, {investorId, nrsFee, status}: UpdateInvestorQueueParams) { 
  const response = await axios.patch(`/admin/projects/${id}/invitations/update`, {investorId, nrsFee, status});
  return response.data;
};

export const assignPortfolioToInvestor = async function (id: string, {investorId, nrsFee, nrsFeeUnit}: AssignToInvestorParams) {
  const response = await axios.post(`/admin/portfolios/${id}/invitations/assign`, {investorId, nrsFee, nrsFeeUnit});
  return response.data;
};

export const unassignPortfolioFromInvestor = async function (id: string, {investorId}: {investorId: string}) {
  const response = await axios.post(`/admin/portfolios/${id}/invitations/unassign`, {investorId});
  return response.data;
};

export const updateInvestorInPortfolioQueue = async function (id: string, {investorId, nrsFee, status}: UpdateInvestorQueueParams) { 
  const response = await axios.patch(`/admin/portfolios/${id}/invitations/update`, {investorId, nrsFee, status});
  return response.data;
};
