import { Dropdown } from 'semantic-ui-react';
import { Project } from '~/types/project';
import { Link, useLocation } from 'react-router-dom';
import { usePortfolioContext, useProjectContext } from '~/hooks';
import { usePermissions } from '~/requests/permissions/usePermissions';

export default function PortfolioProjectSwitcher() {
  const { permissions } = usePermissions();
  let { portfolio } = usePortfolioContext();
  const { project } = useProjectContext();
  const location = useLocation();

  portfolio = project?.portfolio ?? portfolio;
  if (!portfolio?.projects?.length) {
    return null;
  }

  const [,,, ...urlParts] = location.pathname.split('/');
  const urlPath = urlParts?.join('/') + location.search;

  const projectList = portfolio?.projects
    ?.filter((p: Project) => {
      if (permissions.hasProjectDeveloperAccess) {
        return !p.parentId || p.id === p.parentId || p.childSelected;
      } else {
        return true;
      }
    })
    ?.sort((a: Project, b: Project) => a?.name?.localeCompare(b?.name));

  return (
    <Dropdown
      trigger={'Change project/portfolio'}
      style={{ 
        fontSize: '0.875rem',
        color: 'var(--color-primary)',
        fontWeight: '700',
        margin: 'var(--small) var(--small) var(--small) 0'
      }}
    > 
    <Dropdown.Menu style={{ width: '15.5rem', minWidth: 'auto' }}>
      <Dropdown.Item
        as={Link}
        to={`/portfolio/${portfolio?.id}/${urlPath}`}
        key={`portfolio-${portfolio?.id}`}
        style={{ whiteSpace: 'normal' }}
      >
        Portfolio-level documents
      </Dropdown.Item>
      {projectList.map((project: Project) => (
        <Dropdown.Item
          as={Link}
          to={`/project/${project?.id}/${urlPath}`}
          key={`project-${project.id}`}
          style={{ whiteSpace: 'normal' }}
        >
          {project?.name}
        </Dropdown.Item>
      ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}