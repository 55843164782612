import styled from 'styled-components';
import WizardForm from '~/features/projectWizard/components/WizardForm';
import { WizardField } from '~/features/projectWizard/components/Field';
import { RecOwnershipRadioOptions } from '~/constants';
import { useProjectContext } from '~/hooks';
import { useDerivedRECFields } from '~/features/projectWizard/derived';
import StateSpecificRECForm from './StateSpecificRECForm';

const RecContainer = styled.div`
  &&& {
    .fields.inline {
      label {
        font-size: 0.875rem;
      }
   }
   .field {
    margin-bottom: var(--x-small);
   }
  }
`;

export default function RenewableEnergyCredits () {
  const { project } = useProjectContext();

  // Update REC data based on field changes
  useDerivedRECFields();

  return (
    <RecContainer>
      <WizardForm>
        <WizardField
          type="radio"
          fieldLabel="Renewable energy certificates ownership"
          helpText={
            <>
              Which party has the rights to sell any RECs generated by the project? This may affect pricing.<br /><br /> 
              Please also note that Conductor is making its own assumptions on the values of RECs in {project.addressState}.
            </>
          }
          schemaKey="recOwnership"
          values={RecOwnershipRadioOptions}
        />
        <StateSpecificRECForm />
        <WizardField
          type="textarea"
          fieldLabel='REC Details'
          schemaKey="recDetails"
          placeholder="If needed, please describe any REC nuances that may be pertinent to pricing."
          optional
          fieldStyle={{ marginTop: project.addressState === 'Illinois' ? 'var(--x-large)' : undefined }}
        />
      </WizardForm>
    </RecContainer>
  );
}

