import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import styled from 'styled-components';

const Label = styled.label<{ isDisabled?: boolean, padding?: string }>`
  padding-top: ${props => props.padding ?? '0.75rem'};
  padding-bottom: ${props => props.padding ?? '0.75rem'};
  display: block;
  ${({ isDisabled }) => isDisabled 
    ? `
      cursor: not-allowed;
    `: `
      &:hover {
        background: var(--color-grey);
        cursor: pointer;
      }
    `
  }
`;

const TableCellCheckbox = (props: CheckboxProps & { height?: number, ariaLabel: string }) => {
  // height is a plain number in pixels. This component calculates what padding should be based on the desired height of the cell.
  const padding = props.height ? (((props.height - 16) / 2) / 16) + 'rem' : undefined;

  return (
    <Label padding={padding} isDisabled={props.disabled}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Checkbox
          aria-label={props.ariaLabel}
          type="checkbox"
          {...props}
        />
      </div>
    </Label>
  );
};

export default TableCellCheckbox;