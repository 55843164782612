import { Button } from '~/components';
import Modal from '~/components/Modal';
import { useMutation } from 'react-query';
import { withPromiseToaster } from '~/utils';
import { deactivateUser } from '~/api/user';
import { removeInvite } from '~/api/team';
import { getListTeamMembersKey } from '~/requests/team';
import { useQueryClient } from 'react-query';

export default function RemoveUserModal({ user, closeModal }: { user: any, closeModal: () => void }) {
  const queryClient = useQueryClient();

  const { mutate, status } = useMutation(
    async () => {
      // Check if removing active user or team invite
      const removalFunction = user.isTeamInvite ? removeInvite : deactivateUser;

      await withPromiseToaster(
         removalFunction(user.id).then(() => closeModal()),
         {
            success: `${user.name ?? user.email} has been successfully removed.`,
            error: 'Conductor ran into an error while removing the user. Please refresh and try again.'
         }
      );
    }, {
      onSuccess: () => {
         queryClient.invalidateQueries(getListTeamMembersKey());
      }
    } 
  );

  return (
    <Modal closeModal={closeModal}>
      <Modal.Title>
         Confirm user deactivation
      </Modal.Title>
      <Modal.Content>
        <p>
        Are you sure you want to remove <strong>{user.name ?? user.email}</strong>?
        </p>
        <p>
        By deactivating this user, they will be removed from your company in Conductor Solar and will no longer be able to log in. 
        Their historical bids, messages, and document activity will still be accessible to users.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button primary disabled={status === 'loading'} loading={status === 'loading'} onClick={() => mutate()}>Confirm deactivation</Button>
        <Button outlined disabled={status === 'loading'} primary onClick={closeModal}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}