import { UnitDecimals } from '~/constants';
import { AutopricingResultDataItem } from '~/types/project';
import { useFadeInStyles, useForm } from '~/hooks';
import { formatDollars } from '~/utils';
import { PricingTypes } from '~/types/project';

export default function PriceEstimate() {
  const { formState } = useForm() as any;
  const project = formState.project;
  const pricingType = project.pricingType;

  const autopricingKwh = project?.autopricingResult 
    ? project?.autopricingResult[pricingType as PricingTypes].find((row: AutopricingResultDataItem) => row.unit === 'KWH')
    : null;

  return (
    <p style={{ textAlign: 'center', color: 'var(--color-pure-black)', marginBottom: '-1rem', ...useFadeInStyles() }}>
      <strong>
        Your estimated PPA pricing:{' '}
        <em>
          {formatDollars(autopricingKwh.low, { dec: UnitDecimals.KWH })}{' '}
          - {formatDollars(autopricingKwh.high, { dec: UnitDecimals.KWH })} / kWh
        </em>
      </strong>
    </p>
  );
}