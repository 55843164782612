import { useLocation } from 'react-router-dom';

// This hook assumes you are within a /portfolio or /project route.
// If you are not, it will return 'unknown'

export default function usePageType() {
  const { pathname } = useLocation();

  if (pathname.includes('/portfolio/')) {
    return 'portfolio';
  } else if (pathname.includes('/project/')) {
    return 'project';
  } else {
    return 'general';
  }
}
