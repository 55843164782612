import React from 'react';
import { toast } from 'react-hot-toast';
import { declinePortfolioBids } from 'api/portfolioBids';
import { usePortfolioContext, usePromiseWatcher } from 'hooks';
import Modal from '~/components/Modal';
import { Button } from '~/components';

interface DeclinePortfolioBidModalProps {
  investorId: string;
  closeModal: () => void;
}

const DeclinePortfolioBidModal: React.FC<DeclinePortfolioBidModalProps> = ({ closeModal, investorId }) => {
  const { portfolio, fetchPortfolio } = usePortfolioContext();

  const {execute: declineBid, status} = usePromiseWatcher(
    () => {
      return declinePortfolioBids(portfolio?.id, { investorId })
        .then(() => {
          toast.success('The conversation has been ended.');
          fetchPortfolio();
          closeModal();
        });
    },
    'declining the portfolio',
    []
  );
    
  const decliningBid = status === 'pending';
    
  return (
    <Modal closeModal={closeModal} allowClose={!decliningBid}>
      <Modal.Title>Decline project bid(s)?</Modal.Title>
      <Modal.Content>
        <p>
          Are you sure you want to decline project bid(s) for this portfolio? This cannot be undone without contacting an administrator.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button primary outlined disabled={decliningBid} onClick={closeModal}>Go back</Button>
        <Button primary disabled={decliningBid} loading={decliningBid} onClick={declineBid}>Continue</Button>
      </Modal.Footer>
    </Modal>
  );    
};

export default DeclinePortfolioBidModal;