import { sumBy } from 'lodash';
import { ProductionUnit } from '~/constants';
import { Project, ProjectArray } from '~/types/project';

const normalizeToProjectArrayList = (projectOrProjectArrays: Project | ProjectArray | ProjectArray[] = []) => {
  return ((projectOrProjectArrays as Project)?.projectArrays
  ? (projectOrProjectArrays as Project).projectArrays
  : projectOrProjectArrays && !Array.isArray(projectOrProjectArrays)
  ? [projectOrProjectArrays]
  : projectOrProjectArrays) as ProjectArray[];
};

const sizeKwdc = (projectOrProjectArrays: Project | ProjectArray | ProjectArray[] = []) => {
  // Normalize to ProjectArray[] format
  const projectArrays = normalizeToProjectArrayList(projectOrProjectArrays);
  if (!projectArrays?.length) {
    return 0;
  }
  
  return sumBy(projectArrays.filter(projectArray => projectArray.sizeKwdc && !isNaN(projectArray.sizeKwdc)), (projectArray: any) => Number(projectArray.sizeKwdc));
};
const sizeKwac = (projectOrProjectArrays: Project | ProjectArray | ProjectArray[] = []) => {
  const projectArrays = normalizeToProjectArrayList(projectOrProjectArrays);
  if (!projectArrays?.length) {
    return 0;
  }

  return sumBy(projectArrays.filter(projectArray => projectArray.sizeKwac && !isNaN(projectArray.sizeKwac)), (projectArray: any) => Number(projectArray.sizeKwac));
};

const yearOneProduction = (projectOrProjectArrays: Project | ProjectArray | ProjectArray[] = []) => {
  let projectArrays = normalizeToProjectArrayList(projectOrProjectArrays);
  if (!projectArrays?.length) {
    return 0;
  }

  projectArrays = projectArrays?.filter(projectArray => projectArray.yearOneProduction && !isNaN(projectArray.yearOneProduction));
  const production = sumBy(
    projectArrays.filter((mount) => mount.yearOneProductionUnit === ProductionUnit.KWH),
    (mount: any) => Number(mount.yearOneProduction)
  );
  const productionkW = sumBy(
    projectArrays.filter((mount) => mount.yearOneProductionUnit === ProductionUnit.KWHKW),
    (mount: any) => Number(mount.yearOneProduction) * Number(mount.sizeKwdc)
  );
  
  return Number(production) + Number(productionkW);
};

const productionFactor = (projectOrProjectArrays: Project | ProjectArray | ProjectArray[] = []) => {
  const projectArrays = normalizeToProjectArrayList(projectOrProjectArrays);
  const size = sizeKwdc(projectArrays);
  const production = yearOneProduction(projectArrays);
  return size && production && !isNaN(size) && !isNaN(production) ? production / size : undefined;
};


export { sizeKwdc, sizeKwac, yearOneProduction, productionFactor };
