import { InputType } from '~/schema';
import { WizardField } from '../../../Field';
import { Unit, UnitDecimals } from '~/constants';

export default function EpcCosts() {
  return (
    <>
      <WizardField
        type="number"
        fieldLabel="EPC cost + dev fee"
        schemaKey="epcCost"
        numberType={InputType.DOLLARS}
        unitField="epcCostUnit"
        units={[
          {value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC},
          {value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL},
        ]}
      />
    </>
  );
}