// Module Type
export const ModuleType = {
  mono: 'mono',
  poly: 'poly',
  ctde: 'ctde',
};

export const ModuleTypeDisplay = {
  [ModuleType.mono]: 'Monocrystalline',
  [ModuleType.poly]: 'Polycrystalline',
  [ModuleType.ctde]: 'CDTE',
};

export const ModuleTypeDropdownOptions = [
  { value: ModuleType.mono, key: ModuleType.mono, text: ModuleTypeDisplay[ModuleType.mono] },
  { value: ModuleType.poly, key: ModuleType.poly, text: ModuleTypeDisplay[ModuleType.poly] },
  { value: ModuleType.ctde, key: ModuleType.ctde, text: ModuleTypeDisplay[ModuleType.ctde] },
];

// Land Type
export const LandType = {
  'urban_lite': 'urban_lite',
  'urban_heavy': 'urban_heavy',
  'ag_lite': 'ag_lite',
  'ag_heavy': 'ag_heavy',
  'desert': 'desert'
};

export const LandTypeDisplay = {
  [LandType.urban_lite]: 'Urban - lightly used',
  [LandType.urban_heavy]: 'Urban - heavily used',
  [LandType.ag_lite]: 'Agriculture - lightly used',
  [LandType.ag_heavy]: 'Agriculture - heavily used',
  [LandType.desert]: 'Desert',
};

export const LandTypeDropdownOptions = [
  { key: LandType.urban_lite, text: LandTypeDisplay[LandType.urban_lite], value: LandType.urban_lite},
  { key: LandType.urban_heavy, text: LandTypeDisplay[LandType.urban_heavy], value: LandType.urban_heavy},
  { key: LandType.ag_lite, text: LandTypeDisplay[LandType.ag_lite], value: LandType.ag_lite},
  { key: LandType.ag_heavy, text: LandTypeDisplay[LandType.ag_heavy], value: LandType.ag_heavy},
  { key: LandType.desert, text: LandTypeDisplay[LandType.desert], value: LandType.desert},
];

// Panel Washing
export const PanelWashing = {
  none: 'none',
  once: 'once',
  twice: 'twice'
};

export const PanelWashingDisplay = {
  [PanelWashing.none]: 'None',
  [PanelWashing.once]: 'Once per year',
  [PanelWashing.twice]: 'Twice per year',
};

export const PanelWashingDropdownOptions = [
  { key: PanelWashing.none, text: PanelWashingDisplay[PanelWashing.none], value: PanelWashing.none},
  { key: PanelWashing.once, text: PanelWashingDisplay[PanelWashing.once], value: PanelWashing.once},
  { key: PanelWashing.twice, text: PanelWashingDisplay[PanelWashing.twice], value: PanelWashing.twice},
];

// Inverter Type
export const InverterType = {
  'micro': 'micro',
  'string': 'string',
  'central': 'central'
};

export const InverterTypeDisplay = {
  [InverterType.micro]: 'Micro',
  [InverterType.string]: 'String',
  [InverterType.central]: 'Central',
};

export const InverterTypeDropdownOptions = [
  { key: InverterType.micro, text: InverterTypeDisplay[InverterType.micro], value: InverterType.micro},
  { key: InverterType.string, text: InverterTypeDisplay[InverterType.string], value: InverterType.string},
  { key: InverterType.central, text: InverterTypeDisplay[InverterType.central], value: InverterType.central},
];

// Module orientation
export const ModuleOrientation = {
  portrait: 'portrait',
  landscape: 'landscape',
};

export const ModuleOrientationDisplay = {
  [ModuleOrientation.portrait]: 'Portrait',
  [ModuleOrientation.landscape]: 'Landscape',
};

export const ModuleOrientationDropdownOptions = [
  { key: ModuleOrientation.portrait, text: ModuleOrientationDisplay[ModuleOrientation.portrait], value: ModuleOrientation.portrait},
  { key: ModuleOrientation.landscape, text: ModuleOrientationDisplay[ModuleOrientation.landscape], value: ModuleOrientation.landscape},
];