import * as S from '../styles';
import React from 'react';

interface AuthFormProps {
  header: string;
  children: React.ReactNode;
}

export default function AuthForm ({ header, children }: AuthFormProps){
  return (
    <S.Container>
      <S.HeaderLink href='http://conductor.solar'>
        <img src='/logo-longform.png' alt='Conductor Solar Home' />
      </S.HeaderLink>
      <S.Box>
        <S.Header as='h2'>
          {header}
        </S.Header>
        <S.Form>
          {children}
        </S.Form>
      </S.Box>
    </S.Container>    
  );
}


