import { Dimmer, Loader } from 'semantic-ui-react';
import { colors } from '~/theme';
import React from 'react';

interface AsyncLoadingProps {
  id?: string;
  status?: any;
  statuses?: any;
  size?: 'mini' | 'tiny' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'massive';
  isLoading?: boolean;
  displayStatuses?: any;
  color?: string;
  children?: React.ReactNode;
  noLoader?: boolean;
}

const AsyncLoading = function ({
  id,
  status,
  statuses,
  size = 'large',
  isLoading,
  displayStatuses = ['pending', 'error'],
  color,
  children,
  noLoader
}: AsyncLoadingProps) {
  // Override react-query's status of loading and set it to pending instead
  if (status === 'loading') status = 'pending';

  let display = false;
  if (isLoading || displayStatuses.includes(status)) {
    display = true;
  }
  if (!display && statuses) {
    for (let stat of statuses) {
      if (displayStatuses.includes(stat)) {
        display = true;
        break;
      }
    }
  }
  return display && (
    <Dimmer id={id} active inverted style={{backgroundColor: `${color || colors.pureWhite}A5`}}>
      { noLoader ? (
        children
      ) : (
        <Loader size={size}>
          {children}
        </Loader>
      )}
    </Dimmer>
  );
};

export default AsyncLoading;
