export const LongTermOwner = {
  DEVELOPER: 'DEVELOPER',
  THIRD_PARTY: 'THIRD_PARTY',
  END_USER: 'END_USER'
};

export const LongTermOwnerDisplayValues = {
  [LongTermOwner.DEVELOPER]: 'Developer',
  [LongTermOwner.THIRD_PARTY]: 'Third-party investor',
  [LongTermOwner.END_USER]: 'End user'
};

export const LongTermOwnerDescriptions = {
  [LongTermOwner.DEVELOPER]: '(e.g. your company)',
  [LongTermOwner.THIRD_PARTY]: '(e.g. a PPA provider)',
  [LongTermOwner.END_USER]: '(e.g. customer or utility)'
};

export const LongTermOwnerDropdownOptions = [
  {
    value: LongTermOwner.THIRD_PARTY,
    label: `${LongTermOwnerDisplayValues[LongTermOwner.THIRD_PARTY]} ${LongTermOwnerDescriptions[LongTermOwner.THIRD_PARTY]}`
  },
  {
    value: LongTermOwner.DEVELOPER,
    label: `${LongTermOwnerDisplayValues[LongTermOwner.DEVELOPER]} ${LongTermOwnerDescriptions[LongTermOwner.DEVELOPER]}`
  },
  {
    value: LongTermOwner.END_USER,
    label: `${LongTermOwnerDisplayValues[LongTermOwner.END_USER]} ${LongTermOwnerDescriptions[LongTermOwner.END_USER]}`
  },
];