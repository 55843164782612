import useResizeObserver from '@react-hook/resize-observer';
import { MutableRefObject, useLayoutEffect } from 'react';

export default function useHeightCalculation(ref: MutableRefObject<any>, callback: (height: number) => void) {
  useLayoutEffect(() => {
    callback(ref.current?.clientHeight);
  }, [ref]);

  useResizeObserver(ref, (entry) => {
    callback(entry?.borderBoxSize?.[0].blockSize ?? 0);
  });
}