import * as S from './styles';
import BidForm from './BidForm';
import BidAreaHeader from './BidAreaHeader';
import BidTable from './BidTable';

export default function InvestorBidArea() {

  return (
    <S.Container>
      <BidAreaHeader />
      <BidTable />
      <BidForm />
    </S.Container>
  );
}