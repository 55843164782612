import { TutorialCodes } from '~/constants';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import TutorialModal from '../components/TutorialModal';
import { useHistory } from 'react-router-dom';
import { Project } from '~/types/project';

export default function FirstDevProjectSubmission() {
  const { modalProps } = useModalContext();
  const history = useHistory();

  return (
    <TutorialModal
      title='Submitting your project'
      content={
        <>
          <p>
            This project looks ready to submit - great work! To do so, click the button on the top right side of the screen and the Conductor team will review the details and follow up with any questions. Our goal here is to find you the best investors for this opportunity.
          </p>
        </>
      }
      hasCancelButton
      cancelText='OK'
      submitButtonText='Go to project summary'
      tutorialCode={TutorialCodes.PROJECT_SUBMISSION_READY}
      onSubmit={() => {
        const id = modalProps.projects?.find((p: Project) => p.worksheetValid)?.id;
        history.push(`/project/${id}/review`);
      }}
    />  
  );
};

