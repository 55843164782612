export interface DataroomCounts {
  approved: number;
  uploaded: number;
  total: number;
  categorizedCounts: {
    [key: string]: {
      approved: number;
      uploaded: number;
      total: number;
    }
  }
}

export const getDataroomCounts = (dataroom: any): DataroomCounts => {
  let approved = 0,
    uploaded = 0,
    total = 0;

  if (!dataroom) {
    return {
      approved,
      uploaded,
      total,
      categorizedCounts: {}
    };
  }

  if (dataroom.length === undefined) {
    // passed in a single category
    dataroom = [dataroom];
  }

  const categorizedCounts: { 
    [key: string]: {
      approved: number;
      uploaded: number;
      total: number;
    } 
  } = {};
  for (let category of dataroom) {
    categorizedCounts[category.id] = categorizedCounts[category.id] || {
      approved: 0,
      uploaded: 0,
      total: 0
    };
    for (let type of category.documentTypes) {
      if (!type.isSelected) {
        continue;
      }
      total++;
      categorizedCounts[category.id].total++;
      if (!type.document) {
        continue;
      }
      uploaded++;
      categorizedCounts[category.id].uploaded++;
      if (!type.document.investorApproved) {
        continue;
      }
      approved++;
      categorizedCounts[category.id].approved++;
    }
  }

  return {
    approved,
    uploaded,
    total,
    categorizedCounts
  };
};
