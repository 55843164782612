import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

// TODO remove this by updating components that use it

const LightGreySegment = styled(Segment)`
  &&& {
    margin: 0 0 10px 0;
    background-color: ${props => props.backgroundColor ?? '#F7F7F7'};
    border: ${props => props.border ?? 'none'};
    padding: ${props => props.padding ?? '20px 40px'};
    border-radius: ${props => props.borderRadius ?? '12px'};
    box-shadow: ${props => props.boxShadow ?? 'none'};
  }
`;

export default LightGreySegment;
