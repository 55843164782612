import { ReactNode, useEffect } from 'react';
import { useBreadcrumbs } from '~/hooks';
import { storage } from '~/utils';

export default function AppTitle({ children }: { children: ReactNode }) {
  
  const breadcrumbs = useBreadcrumbs();

  useEffect(
    () => {
      let title = 'Conductor';
      if (storage.getSpoofedEmail()) {
        title = `${storage.getSpoofedEmail()} # ${title}`;
      }
      if (breadcrumbs.project) {
        title += ` - ${breadcrumbs.project.label}`;
      } else if (breadcrumbs.portfolio) {
        title += ` - ${breadcrumbs.portfolio.label}`;
      }
      document.title = title;
    },
    [breadcrumbs]
  );

  return null;
  
}