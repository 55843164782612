import ProductionValidation from './ProductionValidation';
import PV from './PV';
import Storage from './Storage';

const System = {
  ProductionValidation,
  PV,
  Storage
};

export default System;