import { formatDollars } from '~/utils/formatNumber';
import { display } from '../../common';
import { UnitDecimals } from '~/constants';

export default function otherCosts(type: 'interconnection' | 'otherItc' | 'otherNonItc', projectCosts: any) {
  const perWdc = display(projectCosts?.perWdc?.[type] && formatDollars(projectCosts.perWdc[type], { dec: UnitDecimals.WDC, default0: '' }));
  const total = display(projectCosts?.overall?.[type] && formatDollars(projectCosts.overall[type], { dec: UnitDecimals.TOTAL, default0: '' }));

  return {
    perWdc,
    total
  };
}