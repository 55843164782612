import * as S from './styles';
import { Form, Radio } from 'semantic-ui-react';
import { useFormGeneric, useAutoSaveContext } from '~/hooks';
import React, { ReactNode, useEffect, CSSProperties } from 'react';
import FieldErrorMessage from './FieldErrorMessage';
import FieldLabel from './FieldLabel';

export interface RadioSelectGroupProps {
  schemaKey: string
  hideErrors?: boolean
  fieldLabel?: string
  values?: {
    label: string
    value: boolean | number | string
    disabled?: boolean
  }[]
  defaultValue?: number | string
  inline?: boolean
  stretched?: boolean
  radioStyle?: {
    [key: string]: string
  }
  fieldStyle?: {
    [key: string]: string
  }
  helpText?: string | ReactNode
  helpTextStyle?: CSSProperties
  onChange?: Function
  context?: any
  children?: ReactNode
  optional?: boolean
}

function RadioSelectGroupImpl({
  context,
  hideErrors,
  schemaKey,
  onChange,
  values,
  fieldLabel,
  radioStyle = {},
  fieldStyle = {},
  inline = false,
  stretched = false,
  helpText,
  children,
  defaultValue,
  optional,
  ...props
}: RadioSelectGroupProps) {
  let {updateValue, getValue, errors} = context;
  const error = errors[schemaKey as string];

  if (onChange) {
    updateValue = onChange;
  }

  const value = getValue(schemaKey);
  useEffect(() => {
    if (!value && defaultValue) {
      updateValue(schemaKey, defaultValue, { doSave: false });
    }
  }, [value]);

  return (
    <S.RadioContainer className="radio-container" stretched={stretched}>
      {fieldLabel && (
        <Form.Field className="label" error={!!error}>
          <FieldLabel helpText={helpText} text={fieldLabel} optional={optional} />
        </Form.Field>
      )}
      <Form.Group {...props} id={schemaKey} error={!!error} inline={inline}>
      {values?.map((button) => {
        return (
          <Form.Field
            style={{...fieldStyle}}
            key={button.value as string}
          >
            <Radio
              id={`${schemaKey}.${button.value}`}
              style={{...radioStyle}}
              onClick={() => {
                updateValue(schemaKey, button.value);
              }}
              checked={value === button.value}
              label={button.label}
              value={button.value as string}
              disabled={button.disabled}
            />
          </Form.Field>
        );
      })}
      {children}
      <FieldErrorMessage content={error} />
    </Form.Group>
    </S.RadioContainer>
  );
}

// this could be much more concise by relying entirely on ...props, but doing this facilitates IDE autocompletion
export function FormRadioSelectGroup({
  schemaKey, onChange, values, radioStyle, fieldStyle, ...props
}: any) {
  return (
    <RadioSelectGroupImpl
      context={useFormGeneric()}
      {...{schemaKey, onChange, values, radioStyle, fieldStyle}}
      {...props}
    />
  );
}

// this could be much more concise by relying entirely on ...props, but doing this facilitates IDE autocompletion
export function AutoSaveRadioSelectGroup({
  schemaKey, onChange, values, radioStyle, fieldStyle, ...props
}: any) {
  return (
    <RadioSelectGroupImpl
      context={useAutoSaveContext()}
      {...{schemaKey, onChange, values, radioStyle, fieldStyle}}
      {...props}
    />
  );
}
