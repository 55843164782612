import React, { useRef } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { autopricing, generateInstantQuote, updateProject } from '~/api/projects';
import AsyncRequestButton from '~/components/AsyncRequestButton';
import { useForm } from '~/hooks';

export default function GeneratePPAButton({ onClick, onFinish, disabled }: { onClick?: () => void, onFinish?: () => void, disabled?: boolean }) {
  const { formState, errors, updateFormValue } = useForm() as any;
  const { project, ...data } = formState ?? {};
  const hasErrors = Boolean(Object.keys(errors)?.length);
  const estimatedTimeTimeoutRef = useRef<NodeJS.Timeout>();

  const { mutateAsync: generateQuote, status } = useMutation(async () => {
    try {
      onClick?.();
      if (formState?.project?.id) {
        // Use the project from the form state if it exists,
        // and just call the existing autopricing endpoint.
        const { arrayType, sizeKwdc, yearOneProduction, yearOneProductionUnit, project, ...data } = formState;

        const result = await updateProject(project.id, {
          ...data,
          projectArrays: [{
            type: arrayType,
            sizeKwdc,
            yearOneProduction,
            yearOneProductionUnit
          }]
        }).then((project) => autopricing(project.id, false))
          .then((autorpicingResult: any) => {
            return {
              ...project,
              ...autorpicingResult
            };
          });
        toast.success('Successfully refreshed initial market quote', { duration: 5000 });
        Object.assign(formState.project, result);
        updateFormValue('project', { ...formState.project });
      } else {
        // Create a new quote
        const projectResult = await generateInstantQuote(data);
        toast.success('Successfully generated initial market quote', { duration: 5000 });
        updateFormValue('project', projectResult);
      }
    } catch (err) {
      console.error(err);
      toast.error('An error occurred generating your initial market quote. Please refresh the page and try again', { duration: 5000 });
    } finally {
      clearTimeout(estimatedTimeTimeoutRef.current);
      onFinish?.();
    }
  });

  const loading = status === 'loading';
  const hasGeneratedPPA = !!project;

  return (
    <AsyncRequestButton
      text={hasGeneratedPPA ? 'Update quote' : 'Generate my PPA rate'}
      loadingText={hasGeneratedPPA ? 'Refreshing your estimated PPA pricing' : 'Generating your estimated PPA pricing'}
      disabled={loading || hasErrors || disabled}
      onClick={generateQuote}
    />
  );
}