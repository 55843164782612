import { useProjectTeamQuery } from '~/requests/team';
import { Table } from '~/components';
import { usePageType, useProjectContext } from '~/hooks';
import ProjectMemberTableCell from './ProjectMemberTableCell';
import RoleDropdownTableCell from './RoleDropdownTableCell';
import { useTeamTableSortReducer } from './reducers/useTeamTableSortReducer';

const TeamManagementTable = () => {
  const pageType = usePageType();
  const { project } = useProjectContext();
  const label = pageType === 'project' && !project?.portfolioId ? 'Project' : 'Portfolio';

  const { team: _team } = useProjectTeamQuery();
  const { data: team, getSort, updateSort } = useTeamTableSortReducer(_team);

  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={getSort('name')}
            onClick={() => updateSort('name')}  
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={getSort('email')}
            onClick={() => updateSort('email')}
          >
            Email
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            {label} member?
          </Table.HeaderCell>
          <Table.HeaderCell 
            textAlign="center"
            sorted={getSort('role')}
            onClick={() => updateSort('role')}
          >
            {label} role
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {team.map((member) => (
          <Table.Row key={member.id ? `member-${member.id}` : `user-${member.userId}`}>
            <Table.Cell>{member.name}{member.name && member.isExternal ? ' - External' : ''}</Table.Cell>
            <Table.Cell>{member.email}</Table.Cell>
            <ProjectMemberTableCell member={member} />
            <RoleDropdownTableCell member={member} />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default TeamManagementTable;