import React, { useState } from 'react';
import { ProjectEvent } from '../types';
import * as S from '../styles';
import { Button } from '~/components';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { useMessagingContext } from '../providers/MessagingProvider';
import { useUserContext } from '~/hooks';
import Modal from '~/components/Modal';
import { useMutation } from 'react-query';
import { withPromiseToaster } from '~/utils';
import { deleteCommentMessage } from '~/api/conversations';
import { FileMessage } from '~/types/conversations/Message';
import { useReplaceMessageFileMutation } from '~/mutations/conversations/useReplaceMessageFileMutation';
import { useConversationContext } from '../providers/ConversationProvider';

const FileMessageActions: React.FC<{ message: FileMessage }> = ({ message }) => {
  const { loggedInUser } = useUserContext() as any;
  const { onMessageUpdate } = useMessagingContext();
  const { selectedConversation } = useConversationContext(); 
  const { mutateAsync: replaceFile, status: replaceFileStatus } = useReplaceMessageFileMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  
  const { mutateAsync: deleteEvent, status: deleteStatus } = useMutation(() => {
    return withPromiseToaster(
      deleteCommentMessage(selectedConversation?.id as string, message.id), 
      {
        messageStub: 'deleting your document',
        loading: null
      }
    ).then((updatedEvent: ProjectEvent) => {
      onMessageUpdate(updatedEvent);
      setIsDeleteModalOpen(false);
    });
  });
 
  const isLoading = replaceFileStatus === 'loading' || deleteStatus === 'loading';

  const { open } = useDropzone({
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    async onDrop(acceptedFiles) {
      if (acceptedFiles?.length) {
        const uploadedFile = acceptedFiles?.[0];
        const updatedEvent = await replaceFile({
          conversationId: selectedConversation?.id as string,
          messageId: message.id,
          file: uploadedFile
        } as any);
        onMessageUpdate(updatedEvent);
        toast.success('Document replaced successfully.');
      } else {
        toast.error('Only one file can be uploaded at a time.');
      }
    }
  });

  if (message?.user?.id !== loggedInUser?.id || message?.data?.commentDeleted) {
    return null;
  }

  return (
    <>
      <S.CommentActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={open} disabled={isLoading}>Replace</Button>
        <Button disabled={isLoading} onClick={() => setIsDeleteModalOpen(true)}>Delete</Button>
      </S.CommentActions>

      {isDeleteModalOpen && (
        <Modal
          title="Delete File"
          isOpen={isDeleteModalOpen}
          allowClose={!isLoading}
          closeModal={() => setIsDeleteModalOpen(false)}
        >
          <Modal.Title>Confirm deletion</Modal.Title>
          <Modal.Content>
            <p>Deleting this file will remove it for all parties in this conversation.</p>
          </Modal.Content>
          <Modal.Footer>
            <Button 
              primary 
              onClick={() => deleteEvent()}
              disabled={isLoading}
              loading={isLoading}
            >
              Delete document
            </Button>
            <Button 
              primary 
              outlined 
              onClick={() => setIsDeleteModalOpen(false)}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default FileMessageActions;