import { useQuery } from 'react-query';
import { fetchTutorials } from '~/api/tutorial';

export const getTutorialListKey = () => {
  return 'tutorials/list';
};
  
export const useTutorialList = (queryParams = {}) => {
  return useQuery({
    queryKey: getTutorialListKey(),
    queryFn: fetchTutorials,

    // Turn of cache... for now
    staleTime: Infinity,

    // No retries
    retry: false,

    // Turn off refetch on window focus
    refetchOnWindowFocus: false,

    // Allow overriding default react-query props
    ...queryParams
  });
};