/**
 * This file is used to fetch the team for a project OR a portfolio.
 * It checks the page type and fetches the team accordingly, by either calling
 * GET /api/projects/{id}/team or GET /api/portfolios/{id}/team based on page type.
 */

import { useQuery } from 'react-query';
import { TeamManagementResponse } from '~/types/teamManagement';
import { useProjectTeamQueryApi } from '~/hooks';

export const projectTeamKey = (type: string, id: string) => ['projectTeam', type, id];

export const useProjectTeamQuery = () => {
  const { getTeam, getTeamQueryKey } = useProjectTeamQueryApi();

  const useQueryResult = useQuery({
    queryKey: getTeamQueryKey(),
    queryFn: getTeam,
    staleTime: 300000, // Refetch every 5 minutes
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false
  });

  return { ...useQueryResult, team: useQueryResult.data as TeamManagementResponse ?? {} };

};