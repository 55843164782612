import { TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  FormSchemaAwareWidgetProps,
  AutoSaveSchemaAwareWidget,
  AutoSaveRadioSelectGroup,
  RadioSelectGroupProps,
  NumberInputProps,
  AutoSaveNumberInput
} from '~/components/form';
import { useProjectContext } from '~/hooks';

type WizardFieldWidths = 'full' | 'half' | 'third' | 'quarter';

const WidthDivisorMap = {
  full: 1,
  half: 2,
  third: 3,
  quarter: 4
};

interface WizardFieldTextInputProps extends FormSchemaAwareWidgetProps {
  type?: 'dropdown' | 'text' | 'textarea'
  width?: WizardFieldWidths
}

interface WizardFieldRadioInputProps extends RadioSelectGroupProps {
  type: 'radio'
  fieldLabel: string
}

interface WizardFieldNumberInputProps extends NumberInputProps {
  type: 'number'
  width?: WizardFieldWidths
}

type WizardFieldProps = WizardFieldTextInputProps | WizardFieldRadioInputProps | WizardFieldNumberInputProps;

export const WizardField = ({ type = 'text', ...props }: WizardFieldProps) => {

  const width = (props as WizardFieldTextInputProps | WizardFieldNumberInputProps)?.width;
  const maxFieldWidth = width && width !== 'full' ? `calc(42rem / ${WidthDivisorMap[width]} - (var(--medium) / 2))` : '100%';

  const {
    conditions: {
      isFieldDisplaying,
      getValidRadioValues,
      getFieldProps
    }
  } = useProjectContext();

  const displayStateProps = getFieldProps(props.schemaKey) ?? {};

  props = {
    ...props,
    ...displayStateProps
  };

  if (!isFieldDisplaying(props.schemaKey)) {
    return null;
  } else if (type === 'number') {
    // Format number input props
    const numberInputProps = props as WizardFieldNumberInputProps;
    return (
      <div style={{ maxWidth: maxFieldWidth ?? '100%', width: '100%' }}>
        <AutoSaveNumberInput {...numberInputProps} />
      </div>
    );
  } else if (type === 'radio') {
    const { values, ...radioProps } = props as WizardFieldRadioInputProps;
    return <AutoSaveRadioSelectGroup values={getValidRadioValues(props.schemaKey, values ?? [])} {...radioProps} />;
  } else {
    // Format text input props
    const textInputProps = props as WizardFieldTextInputProps;
    
    // Set custom widget type if needed
    const Widget = type === 'textarea' ? TextArea : undefined;
    
    return (
      <div style={{ maxWidth: maxFieldWidth ?? '100%', width: '100%' }}>
        <AutoSaveSchemaAwareWidget as={Widget} {...textInputProps} />
      </div>
    );
  }
};

WizardField.Row = styled.div`
  &&& {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: var(--medium);
    > * {
      width: 100%;
    }
    @media(min-width: 1061px) {
      .field {
        margin-bottom: 0;
      }
    }
    @media(max-width: 1060px) {
      flex-direction: column;
      gap: 0;
      > * + * {
        margin-top: var(--medium);
      }
    }
  }
`;