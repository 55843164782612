import styled from 'styled-components';

export const PORTFOLIO_PRIMARY_COLOR = 'var(--color-secondary)';

const PortfolioTheme = styled.div<{ active?: boolean }>`
  &&& {
    ${props => props.active !== false && `
      // Define specific elements to change primary to turquoise
      button,
      .ui.button.primary,
      .ui.button.primary.outlined:not(:hover):not(:active):not(:focus) {
        --color-primary: ${PORTFOLIO_PRIMARY_COLOR};
      }  

      .ui.button.primary {
        background-color: ${PORTFOLIO_PRIMARY_COLOR};
        color: var(--color-pure-white);
        &:hover {
          filter: brightness(95%);
        }
        &:active {
          filter: brightness(90%);
        }
      }
      .ui.button.primary.outlined:not(:hover):not(:active):not(:focus) {
        background-color: var(--color-pure-white);
        color: ${PORTFOLIO_PRIMARY_COLOR};
        box-shadow: 0px 0px 0px 1px ${PORTFOLIO_PRIMARY_COLOR} inset !important;
        &:hover {
          background-color: ${PORTFOLIO_PRIMARY_COLOR};
          color: var(--color-pure-white);
        }
      }

      .ui.loading.button.primary.outlined:not(:hover):not(:active):not(:focus):after, 
      .ui.loading.button.primary.outlined.disabled:after {
        border-color: ${PORTFOLIO_PRIMARY_COLOR} transparent transparent;
      }
    `}
  }
`;

export default PortfolioTheme;