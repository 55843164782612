import { fetchProjectConfig } from '~/api/projects';
import { useQuery } from 'react-query';

export const getProjectConfigKey = () => {
  return 'projects/config';
};

export const useProjectConfig = (queryParams = {}) => {
  return useQuery({
    queryKey: getProjectConfigKey(),
    queryFn() {
      return fetchProjectConfig();
    },

    // Cache for entire user session. This should very rarely change
    staleTime: Infinity, 

    // No retries
    retry: false,

    // Allow overriding default react-query props
    ...queryParams
  });
};