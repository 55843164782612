import { useEffect } from 'react';
import { Button } from '~/components';
import { resetPassword, verifyPasswordResetToken } from '~/api/auth';
import { Link, useParams } from 'react-router-dom';
import { FormSchemaAwareWidget } from '~/components/form';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import AuthForm from '../components/AuthForm';
import { usePromiseWatcher, useForm } from '~/hooks';
import toast from 'react-hot-toast';

const ResetPasswordFormImpl = () => {
  const {token} = useParams() as any;
  const {formState} = useForm() as any;
  
  const submitResetPassword = () => {
    if (formState.password !== formState.confirmPassword) {
      toast.error('Passwords do not match - unable to save');
    } else {
      savePassword();
    }
  };

  // When the page loads, fetch password reset token present in URL to determine validity
  const {execute: runTokenValidation, status: tokenValidationStatus} = usePromiseWatcher(
    () => verifyPasswordResetToken(token),
    null,
    [token]
  );
  useEffect(() => {
    runTokenValidation();
  }, []);

  // Save the new password
  const {execute: savePassword, status: savePasswordStatus} = usePromiseWatcher(
    () => resetPassword({password: formState.password, passwordResetToken: token}),
    'resetting your password',
    [formState, token]
  );

  return (
    <>
      { tokenValidationStatus === 'error' && (
          <AuthForm header = 'Password reset link invalid, click below for new link'>
            <Button
              as={Link}
              to="/forgot-password"
              fluid
              secondary
              size="large"
            >
              Forgot Password Form
            </Button>
          </AuthForm>
      )}
      { tokenValidationStatus === 'success' && (
        <>
          { savePasswordStatus === 'success'
            ? (
              <AuthForm header="Password Reset Successful">
                <Button 
                  as={Link} 
                  to="/login"
                  fluid 
                  secondary
                  size="large"
                >
                  Return to Login Page
                </Button>
               </AuthForm> 
              ) : (
              <AuthForm header="Enter your new password">
                <FormSchemaAwareWidget
                  schemaKey="password"
                  fluid
                  type="password"
                  placeholder="Password"
                  autoComplete="new-password"
                />
                <FormSchemaAwareWidget
                  schemaKey="confirmPassword"
                  fluid
                  type="password"
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                />
                <Button
                  secondary
                  fluid
                  size="large"
                  disabled={!formState.password.length || !formState.confirmPassword.length}
                  loading={savePasswordStatus === 'pending' || savePasswordStatus === 'success'} 
                  onClick={submitResetPassword}
                >
                  Save New Password
                </Button>   
              </AuthForm>
            )
          }
        </>
      )}
    </>
  );
};

const ResetPasswordForm = ({defaults = {
  email: '',
  password: '',
  confirmPassword: '',
}}) => {
  return (
    <FormContextProvider defaults={defaults}>
      <ResetPasswordFormImpl/>
    </FormContextProvider>
  );
};

export default ResetPasswordForm;

