import { useAutoSaveContext } from '~/hooks/providers/AutoSaveContextProvider';
import { useSetBreadcrumbs } from '~/hooks/providers/BreadcrumbsContextProvider';
import { usePortfolioCalculatedValues } from '~/hooks/usePortfolioCalculatedValues';
import { createContext, useContext, useMemo } from 'react';
import portfolioDisplayValues from '~/utils/displayValues/portfolio';
import formConditions from '~/utils/formConditions';
import config from '~/schema/project/config';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { Project } from '~/types/project';

const PortfolioContext = createContext({} as any);

export const usePortfolioContext = () => useContext(PortfolioContext);

const PortfolioBreadcrumbs = ({ children }: any) => {
  const { portfolio } = usePortfolioContext();
  useSetBreadcrumbs(
    {
      portfolio: {
        label: portfolio.name,
        destination: `/portfolio/${portfolio.id}`
      }
    },
    [portfolio.name, portfolio.id] as any
  );
  return (<>
    {children}
  </>);
};

export const PortfolioContextProvider = ({ children }: any) => {
  const { permissions } = usePermissions();
  const {state, updateValue, errors, handleOnChangeEvent, getValue, fetchFreshData, fetchStatus, id} = useAutoSaveContext() as any;
  const portfolio = state;

  // Transform the portfolio's project data by filtering it through
  // the visibility engine. This makes it so fields that are hidden
  // by the validation engine do not show up in the UI or excel download.
  const portfolioProjects = useMemo(() => {
    if (portfolio.projects) {
      const projects = portfolio.projects.map((project: Project) => {
        const conditions = formConditions({
          data: project,
          config,
          getTransformedData: true
        });
        return conditions?.transformedData ?? project;
      });
      return projects;
    }
  }, [portfolio?.projects]);

  const {
    projectBlobs,
    projectBidCountMap,
    portfolioState,
    totalBid,
    locations,
    portfolioTotals,
    displayBlobs,
    projectCount,
    parentProjects,
    childProjects,
    parentProjectChildProjectMap
  } = usePortfolioCalculatedValues(portfolio, portfolioProjects);

  const displayValues = useMemo(
    () => {
      return portfolioDisplayValues(state, portfolioState, permissions, projectBlobs);
    },
    [state, portfolioState, permissions, projectBlobs]
  );

  if (!portfolio) return null;

  return (
    <PortfolioContext.Provider value={{
      portfolio,
      updatePortfolioValue: updateValue,
      portfolioErrors: errors,
      handleOnChangeEvent,
      getPortfolioValue: getValue,
      fetchPortfolio: fetchFreshData,
      fetchStatus,
      id,
      projectBlobs,
      projectBidCountMap,
      portfolioState,
      totalBid,
      locations,
      portfolioTotals,
      displayBlobs,
      projectCount,
      portfolioDisplayValues: displayValues,
      isITC: !!projectBlobs.find((blob: any) => blob?.projectState?.isITC),
      parentProjects,
      childProjects,
      parentProjectChildProjectMap
    }}>
      <PortfolioBreadcrumbs>
        {children}
      </PortfolioBreadcrumbs>
    </PortfolioContext.Provider>
  );
};
