export const DealStates = {
  PROJECT_HAS_ERRORS: 0,
  RETURNED_TO_DRAFT: 1,
  READY_TO_SUBMIT: 2,
  IN_REVIEW: 3,
  MATCHING: 4,
  AWAITING_BID: 5,
  PENDING_BID: 6,
  DECLINED: 7,
  AWAITING_DATAROOM: 8,
  DATAROOM: 9,
};