import { useProjectList } from '~/requests/projects/useProjectList';
import PopupMenu from './PopupMenu';
import * as S from '../styles';
import { Link } from 'react-router-dom';
import { PopupMenuProps } from './types';
import { Project } from '~/types/project';

export default function ProjectsPopupMenu ({ trigger, onClose }: PopupMenuProps) {
  const { data: projects, status } = useProjectList();
  const activeProjects = projects?.filter((project: Project) => !project?.isArchived && !project?.portfolioIsArchived);

  return (
    <PopupMenu trigger={trigger} onClose={onClose} isLoading={status === 'loading'}>
      {activeProjects?.length === 0 ? 'No projects to display' 
        : activeProjects?.map((project: Project) => (
          <S.MenuItem
            key={project.id}
            as={Link}
            to={`/project/${project.id}`}
            onClick={() => onClose()}
          >
            {project.name}
          </S.MenuItem>
      ))}
    </PopupMenu>
  );
}