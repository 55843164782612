import DocumentTypeSidebar from './components/DocumentTypeSidebar';
import DocumentList from './components/DocumentList';
import styled from 'styled-components';
import { useDataroomContext } from './providers/DataroomProvider';
import { PageHeaderContextValue, usePageHeaderContext } from '~/components/PageHeader/PageHeaderProvider';
import MainContentContainer from './components/MainContentContainer';
import PageLoader from '~/components/PageLoader';
import { Header } from 'semantic-ui-react';
import AdditionalDocumentsUploadButton from './components/AdditionalDocumentsUploadButton';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { usePageType } from '~/hooks';
import PortfolioProjectSwitcher from '~/components/PortfolioProjectSwitcher';

const PAGE_PADDING_TOP = '1.25rem';
const PAGE_PADDING_BOTTOM = 'var(--x-large)';

const Container = styled.div<{ pageHeaderContext: PageHeaderContextValue }>`
  padding-top: ${PAGE_PADDING_TOP};
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: ${PAGE_PADDING_TOP};
  .dataroom-sidebar {
    > * {
      transition: 0.2s ease-in-out;
      height: unset;
      min-height: calc(
        100vh 
        - 1.25rem 
        - ${PAGE_PADDING_BOTTOM} 
        - ${props => `${props.pageHeaderContext.height / 16}rem`}
      );
    }
    width: 16.5rem;
    position: sticky;
    top: calc(${PAGE_PADDING_TOP} + ${props => `${props.pageHeaderContext.height / 16}rem`});
    height: 100%;
    transition: top 0.2s ease-in-out;
    max-height: calc(100vh - ${PAGE_PADDING_TOP} - ${props => `${props.pageHeaderContext.height / 16}rem`});
    overflow-y: hidden;
    overflow-x: hidden;
    flex-shrink: 0;
    overflow-y: auto;
  }

  .main-content {
    max-width: 47rem;
    width: 100%;
    padding-bottom: calc(${PAGE_PADDING_BOTTOM} + 1rem);
    margin-left: var(--medium);
    position: relative;
  }

`;

const DataroomPageContent = function () {
  const pageHeaderContext = usePageHeaderContext();
  const { isSimplifiedUI } = useDataroomContext();
  const { permissions } = usePermissions();
  const pageType = usePageType();

  return (
    <Container pageHeaderContext={pageHeaderContext} style={isSimplifiedUI && !permissions.canUpdateDataroom ? { paddingTop: 'var(--x-large)' } : {}}>
      <DocumentTypeSidebar />
      <MainContentContainer>
        {isSimplifiedUI && !permissions.canUpdateDataroom && <PortfolioProjectSwitcher />}
        {isSimplifiedUI && permissions.canUpdateDataroom && (
          <>
            <Header as="h2" style={{ marginTop: 'calc(var(--x-large) - 1.25rem)' }}>Share your {pageType} documents</Header>
            <PortfolioProjectSwitcher />
            <p style={{ marginBottom: 'var(--medium)' }}>
              Based on the type of {pageType} you’re building, Conductor Solar recommends sharing the following documents with potential partners. 
              You can drag-and-drop the files directly onto each row or click to upload files. If you have other documents you’d like to share, 
              simply click the ‘add more documents’ button at the bottom, 
              or drag-and-drop as many files as you like directly onto the ‘additional files’ section. 
            </p>
          </>
        )}
        <DocumentList />
        {isSimplifiedUI && (
          <div style={{ marginTop: 'calc(var(--medium) - var(--2x-small))' }}>
            <AdditionalDocumentsUploadButton />
          </div>
        )}
      </MainContentContainer>
    </Container>
  );
};

export default function Dataroom() {
  const pageHeaderContext = usePageHeaderContext();
  const { isLoading, hasError } = useDataroomContext();

  if (isLoading) {
    return (
      <div style={{ position: 'relative', width: '100%', height: pageHeaderContext.height ? `calc(100vh - ${pageHeaderContext.height/16}rem)` : '100%' }}>
        <PageLoader />
      </div>
    );
  } else if (hasError) {
    return null;
  }

  return <DataroomPageContent />;
}

