// This component acts as a wrapper of semantic's Button component, with some custom options for our app.
// It heavily restricts the usage of Semantic UI's button to only allow for states that our design supports.
//
// If there is ever a need for a different state needed that this component does not support, we should:
// 1. Discuss with the team as to why the different state is needed and confirm it isn't a mistake, and
// 2. Update this component to support the new state if it is deemed necessary.

import { Location, LocationDescriptor, LocationState } from 'history';
import React from 'react';
import { Button as SemanticUIButton, ButtonProps as SemanticUIButtonProps } from 'semantic-ui-react';

export interface ButtonProps {
  outlined?: boolean;                  // Our outlined state. Defaults to false.
  size?: 'small' | 'medium' | 'large'; // Our size states. Defaults to 'medium'.
  className?: string;                  // Optional class name to add to the button.
  primary?: boolean;                   // Our primary state (Orange button). Defaults to false.
  secondary?: boolean;                 // Our secondary state (Blue button). Defaults to false.
  positive?: boolean;                  // Our positive state (Green button). Defaults to false.
  negative?: boolean;                  // Our negative state (Red button). Defaults to false.
  disabled?: boolean;                  // Our disabled state. Defaults to false.
  loading?: boolean;                   // Our loading state. Defaults to false.
  variant?: 'button' | 'link';         // Option for rendering a button as a link. Defaults to 'button'.
  fluid?: boolean;                     // Option for rendering a button as width: 100%. Defaults to false.
  style?: React.CSSProperties;         // Allow for custom CSS styles
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>, data: SemanticUIButtonProps) => void

  // Allows for simple 'as="a"' or 'as={Link}' usage 
  // so we can render links with button styles
  as?: any;
  href?: string;
  target?: string;
  to?: LocationDescriptor<LocationState> | ((location: Location<LocationState>) => LocationDescriptor<LocationState>)
}

export default function Button({
  outlined = false,
  variant = 'button',
  className = '',
  ...props
}: ButtonProps = {}) {
  if (outlined) {
    className += ' outlined ';
  }
  if (variant) {
    className += ` variant--${variant} `;
  }

  return (
    <SemanticUIButton className={className} {...props} />
  );
}
