import { CommentIconAndLabel } from './CommentElements';
import { ResponseIcon } from './CommentElements';
import CommentResponseButton from './CommentResponseButton';

interface UserNeedsResponseProps {
  isOwnComment: boolean;
  requiresResponse?: boolean | undefined;
  isAdmin?: boolean | undefined;
};

export default function UserNeedsResponse ({ 
  isOwnComment,
  requiresResponse,
  isAdmin,
}: UserNeedsResponseProps) {
  const isResponse = requiresResponse && !isOwnComment && !isAdmin;

  if (!isResponse) {
    return null;
  }

  return (
    <CommentResponseButton isDiv>
      <CommentIconAndLabel icon={ResponseIcon} label="Needs a response" />
    </CommentResponseButton>
  );
};