import { useRef } from 'react';
import Tab from '~/components/Tab';
import { ProjectFiltersList } from '~/constants';
import { useQueryParamState } from '~/hooks/useQueryParamState';
import StickyContainer from './StickyContainer';
import { Grid } from 'semantic-ui-react';
import useHeightCalculation from '~/hooks/useHeightCalculation';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { DASHBOARD_QUERY_PARAM_STATE } from '../constants';

interface DashboardNavProps {
  onHeightCalculation: (height: number) => void;
}

export default function NavRow({ onHeightCalculation }: DashboardNavProps) {
  const ref = useRef(null);
  const { permissions } = usePermissions();

  const projectFiltersList = ProjectFiltersList.filter(({ hideForInvestors, hideForDevelopers }) => {
    return !(permissions.hasInvestorRole && !permissions.hasDeveloperRole && hideForInvestors) && !(permissions.hasDeveloperRole && hideForDevelopers);
  });
  const [dashboardState, setDashboardState] = useQueryParamState(DASHBOARD_QUERY_PARAM_STATE);

  // Report the height of this component to the parent
  useHeightCalculation(ref, onHeightCalculation);

  // Get the current filter from the url query param
  const currentFilter = dashboardState?.filter ?? projectFiltersList[0].value;

  // Get the active tab index based on the current filter
  const activeTabIndex = projectFiltersList.findIndex(({ value }) => value === currentFilter);

  // This tab menu is used only for updating the url query param.
  // This is why there is just an onTabChange handler and no render function
  return (
    <StickyContainer style={{ paddingTop: 'var(--large)' }} ref={ref}>
      <Grid.Row>
        <Grid.Column style={{ position: 'relative' }}>
          <Tab
            panes={projectFiltersList
              .map(({ label }) => {
                return {
                  menuItem: label
                };
              })
            }
            activeIndex={activeTabIndex}
            onTabChange={(_ignored: any, { activeIndex }: { activeIndex: number }) => {
              const filter = projectFiltersList[activeIndex].value;
              const newDashboardState = { ...dashboardState, filter };
              if (!filter) {
                delete newDashboardState.filter;
              }
              if (!Object.keys(newDashboardState).length) {
                setDashboardState(undefined);
              } else {
                setDashboardState(newDashboardState);
              }
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </StickyContainer>
  );
}