import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';
import { capitalizeFirst, getByPath, withPromiseToaster, updateByPath } from '~/utils';
import { validate } from '~/schema';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import { useQuery, useQueryClient } from 'react-query';
import { FormConfig } from '~/schema/types';
import formConditions from '~/utils/formConditions';
import { usePermissions } from '~/requests/permissions/usePermissions';

const AutoSaveContext = createContext({});

export type AutoSaveContextParams = {
  id: string, 
  testValue?: any 
  validation?: any 
  label: string 
  children: ReactNode   
  config?: FormConfig
  api: { 
    update: Function 
    fetch: Function
  }
}

export const AutoSaveContextProvider = ({ 
  id, 
  testValue, 
  label, 
  api, 
  validation, 
  children,
  config
}: AutoSaveContextParams) => {
  const [errors, setErrors] = useState({});
  const numErrors = Object.keys(errors).length;
  const [pendingChange, setPendingChange] = useState(false);
  const { permissions } = usePermissions();

  const queryClient = useQueryClient();
  const setState = (newState: any) => {
    queryClient.setQueryData(`${label}/${id}`, newState);
  };

  const { data: state, refetch: fetchFreshData, status: fetchStatus } = useQuery(
    `${label}/${id}`,
    () => {
      return withPromiseToaster(
        (async () => {
          let fetchedData;
          if (testValue) {
            fetchedData = testValue;
          } else if (!id) {
            fetchedData = {};
          } else {
            fetchedData = await api.fetch(id);
          }

          if (pendingChangeRef.current) {
            console.error(`Found pending changes after fetching ${label} data`);
            return;
          }

          return fetchedData;
        })(),
        { messageStub: `loading ${label} data`, success: null, loading: null }
      );
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  );

  const stateRef = useRef(state);
  stateRef.current = state;
  
  const pendingChangeRef = useRef(pendingChange);
  pendingChangeRef.current = pendingChange;

  const history = useHistory();
  const location = useLocation();
  const canSave = permissions?.hasProjectDeveloperAccess;

  const save = useMemo(
    () => debounce(
      (value, entityId, numErrors) => {
        if (testValue || !entityId) {
          return;
        }
        
        if (!canSave) {
          toast.error('Read-only mode. Changes won\'t be saved');
          setPendingChange(false);
          return;
        }

        withPromiseToaster(
          api.update(entityId, value, { isValid: !numErrors })
            .then((updatedValue: any) => {
              const updatedState = updateByPath(stateRef.current, 'updatedAt', updatedValue.updatedAt);
              setState(updatedState);
              setPendingChange(false);
            }),
          {
            loading: 'Saving...',
            success: {message: `${capitalizeFirst(label)} saved!`, duration: 2000},
            error: (
              <div>
                <div style={{ marginBottom: '0.3125rem', fontWeight: '700' }}>Conductor ran into an error while saving your {label}. Please refresh the page and validate the data you entered.</div>
                <div style={{ marginBottom: '0.625rem' }}>If the problem persists, please contact us at{' '}
                  <a href="mailto:support@conductor.solar">support@conductor.solar</a>
                </div>
              </div>
            )
          }
        );
      },
      3000,
      {trailing: true},
    ),
    // even though id isn't used internally above, it is important that we DO NOT REMOVE IT FROM THE DEPENDENCY LIST BELOW.  If we remove it, then
    // subsequent calls to save on a DIFFERENT ID would debounce (forget) the final calls that were made on the prior id (if there were pending
    // changes during the id change).  By including id below, we generate a new debounced save function for the new id, and let the old version
    // happily expire and save on its own.
    // Actually, the above may not be true any longer, now that the history.block() stuff below has been added, but better safe than sorry.
    [id, testValue, canSave]
  );

  useEffect(
    () => {
      if (!pendingChange) {
        return;
      }
      const unblock = history.block(
        (location, action) => {
          if (pendingChangeRef.current) {
            save.flush();
          }
          unblock();
          if (action === 'POP') {
            history.goBack();
          }
          history.replace(location);
        }
      );
      return unblock;
    },
    [save, location, pendingChange]
  );

  useEffect(
    () => {
      if (id && !testValue) {
        fetchFreshData();
      }
    },
    [id, testValue]
  );

  const handleBeforeUnload = useCallback(
    () => {
      if (pendingChangeRef.current) {
        save.flush();  // save on their way out
      }
    },
    [],
  );
  useEffect(
    () => {
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    },
    [handleBeforeUnload]
  );

  const conditions = useMemo(() => {
    return formConditions({
      data: state,
      config,
      getTransformedData: false
    });
  }, [state, config]);

  const updateValue = useCallback(
    (schemaKey: string | null | undefined, value: any, { doSave = true, patch = false, deleteKeys = false }: { doSave?: boolean, patch?: boolean, deleteKeys?: boolean } = {}) => {
      if (typeof schemaKey !== 'string') {
        throw new Error(`Bad schemaKey value received: ${schemaKey}`);
      }
      const updatedState = updateByPath(stateRef.current, schemaKey, value, {patch, deleteKeys});
      if (fastDeepEqual(updatedState, stateRef.current)) {
        doSave = false;
      }
      const errors = validate(updatedState, validation, {}, '', state, state, config, conditions);
      setErrors(errors);
      if (doSave) {
        setPendingChange(true);
      }
      setState(updatedState);
      if (doSave) {
        save(updatedState, id, Object.keys(errors).length);
      }
    },
    [save, id, numErrors],
  );

  const handleOnChangeEvent = useCallback(
    ({target: {value, name}}: any, schemaKey: string) => {
      updateValue(schemaKey || name, value);
    },
    [updateValue]
  );

  const getValue = useCallback(
    (schemaKey: string) => {
      return getByPath(stateRef.current, schemaKey);
    },
    []
  );

  useEffect(() => {
    if (state) {
      setErrors(validate(state, validation, {}, '', state, state, config, conditions));
    }
  }, [state, conditions?.displayStates]);

  return (
    <>
      {/* @ts-ignore */}
      <AutoSaveContext.Provider value={{
        state: state ?? {},
        errors,
        getValue,
        updateValue,
        handleOnChangeEvent,
        fetchFreshData,
        fetchStatus,
        id
      }}>
        {children}
      </AutoSaveContext.Provider>
    </>
  );
};

export const useAutoSaveContext = () => useContext(AutoSaveContext);
