import { createContext, useContext, useState } from 'react';
import { UserRole } from '~/constants';

const UserContext = createContext({});

const UserContextProvider = ({children, user}: any) => {
  const [loggedInUser, setLoggedInUser] = useState(user);

  switch (loggedInUser?.role?.code) {
    case UserRole.ADMIN:
      loggedInUser.isAdmin = true;
      break;
    case UserRole.INVESTOR:
      loggedInUser.isInvestor = true;
      break;
    case UserRole.INSTALLER:
      loggedInUser.isInstaller = true;
      break;
    case UserRole.EXTERNAL:
      loggedInUser.isExternalCompany = true;
      break;
  }

  return (
    <UserContext.Provider
      value={{
        loggedInUser,
        setLoggedInUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

export const useUserContext = () => useContext(UserContext);
