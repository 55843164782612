import WizardForm from '~/features/projectWizard/components/WizardForm';
import { WizardField } from '~/features/projectWizard/components/Field';
import { ContractType, ContractTypeDropdownOptions, RevenueSource, UnitDecimals } from '~/constants';
import { InputType } from '~/schema';
import { useProjectContext } from '~/hooks';
import FieldLabel from '~/components/form/FieldLabel';
import { Form } from 'semantic-ui-react';
import { formatNumber } from '~/utils';
import RateText from './RateText';

export default function Contract () {
  const { project } = useProjectContext();

  let communitySolarRevenueRate: number | undefined = undefined;
  if (project?.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS && project?.contractRate && project?.communitySolarDiscountPercentage) {
    communitySolarRevenueRate = project.contractRate * (1 - project.communitySolarDiscountPercentage / 100);
  }

  return (
    <WizardForm>
      <WizardField
        type="radio"
        fieldLabel="Do you have recommended revenue assumptions?"
        schemaKey="revenueAssumptionsFlag"
        values={[
          { label: 'Yes, I want to recommend revenue assumptions', value: true },
          { label: 'No, I want the investor to make their own revenue assumptions', value: false },
        ]}
        inline
        stretched
      />
      <WizardField
        type="radio"
        schemaKey="contractType"
        fieldLabel="Contract Type"
        values={ContractTypeDropdownOptions}
        inline
        stretched
      />
      <WizardField
        type="number"
        fieldLabel="Contract term"
        schemaKey="contractTerm"
        placeholder="Enter contract term"
        label="years"
        decimals={0}
      />
      <WizardField
        type="number"
        fieldLabel="Offtake rate"
        placeholder="Enter offtake rate"
        schemaKey="contractRate"
        label={project.contractType === ContractType.PPA ? 'per kWh' : 'per year'}
        numberType={InputType.DOLLARS}
        decimals={project.contractType === ContractType.PPA ? UnitDecimals.KWH : UnitDecimals.YEAR}
      />
      <RateText />
      <WizardField
        type="number"
        fieldLabel="Discount percentage"
        placeholder="Enter discount percentage"
        schemaKey="communitySolarDiscountPercentage"
        numberType={InputType.PERCENT}
        decimals={UnitDecimals.PERCENT}
        helpText="Blended discount to the base power rate provided"
      />
      <WizardField
        type="number"
        fieldLabel="Annual contract escalator"
        placeholder="Enter contract escalator"
        helpText="1-2% is most common."
        schemaKey="contractEscalator"
        numberType={InputType.PERCENT}
        decimals={UnitDecimals.PERCENT}
      />
      {project.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS && project.revenueAssumptionsFlag ? (
        <Form.Field>
        <FieldLabel 
            text={
              `Community solar revenue rate — ${
                communitySolarRevenueRate 
                ? `$${formatNumber(communitySolarRevenueRate, UnitDecimals.KWH)} per kWh` 
                : 'N/A: more information needed'
              }`
            }
          />
          <p style={{ marginTop: 'var(--small)' }}>
            This is the expected $/kWh rate which will accrue to the project owner.
          </p>
        </Form.Field> 
      ) : null}
      
      <WizardField
        type="textarea"
        fieldLabel="Community solar revenue details"
        schemaKey="communitySolarRevenueDetails"
        placeholder="Add any revenue assumption details or perspectives here."
        optional
      />
    </WizardForm>
  );
}