import { ReactNode } from 'react';
import * as S from './styles';

interface TabProps {
  active?: boolean;
  onSelect?: () => void;
  children: ReactNode;
}

export default function Tab({
  active,
  onSelect = () => {},
  children
}: TabProps) {
  return <S.Tab className={active ? 'active' : ''} onClick={onSelect}>{children}</S.Tab>;
}