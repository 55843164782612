import styled from 'styled-components';

export const ActionCell = styled.div`
  &&& {
    border: none !important;
    height: 3.5rem;
    font-size: 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
  }
`;

export const DealSummaryLabel = styled.div`
  border: none !important;
  background: var(--color-grey);
  font-weight: bold;
  padding: 0.75rem var(--small) !important;
  line-height: 1rem;
  font-size: 0.75rem;
  display: block !important;
`;

export const DealSummaryHeader = styled.div`
  border-left: none !important;
  border-right: none !important;
`;

export const DealSummaryCell = styled.div`
  border-right: 1px solid var(--color-grey);
  border-bottom: none !important;
`;

export const DealSummaryTable = styled.div<{ dealSummary: any }>`
  display: grid;
  text-align: center;
  ${props => `grid-template-columns: 7.5rem repeat(${props?.dealSummary?.investors?.length ?? 0}, 1fr);`}

  > div, > label {
    padding: 0 var(--small);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 3rem;
    border-bottom: 1px solid var(--color-grey);
    &.cell {
      border-left: 1px solid var(--color-grey);
      &:last-child {
        border-right: 1px solid var(--color-grey);
      }
    }
    > strong {
      font-size: 0.75rem;
      line-height: 1rem;
      + div {
        font-size: 0.6875rem;
        line-height: 1rem;
      }
    }
  }
  > label:hover {
    cursor: pointer;
  }
  + p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--color-charcoal);
    a {
      color: var(--color-primary);
    }
  }
`;