import React from 'react';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';

/**
 * This function wraps a component with a form context provider.
 * It lets us do away with the boilerplate of wrapping a component with a FormContextProvider.
 */
export const withForm = ({ defaults, validation }: any = {}, Component: React.ComponentType<any>) => {
  return (props: any) => {
    return (
      <FormContextProvider defaults={defaults} validation={validation}>
        <Component {...props} />
      </FormContextProvider>
    );
  };
};