import styled from 'styled-components';
import { Form as _Form, Message as _Message, Divider as _Divider } from 'semantic-ui-react';

export const UserInfoFormContainer = styled(_Form)`
  .radio-container {
    max-width: 550px;
    padding-top: .5rem;
  }
  width: 100%;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--x-large);
`;  

export const Message = styled(_Message)`
    &&& {
    font-size: 0.875rem;
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: none;
    }
`;

export const Divider = styled(_Divider)`
  &&& {
    color: #E4E4E4;
    margin: 0;
  }  
`;  

export const TableWrapper = styled.div<{ loading?: boolean }>`
  position: relative;
  width: 100%;
  overflow-x: auto;
  ${props => props.loading && `
    tbody {
      height: 12rem;
    }
    .table-loader-overlay {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background-color: var(--color-pure-white);
      z-index: 1;
    }
    .ui.loader.active {
      margin-top: 1rem;
      z-index: 2;
    }
  `}
`;