import Modal from '~/components/Modal';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import { Button } from '~/components';

export default function DeclineToBidModal() {
  const { modalProps, closeModal } = useModalContext();
  const executeDecline = modalProps?.executeDecline;
  const declineStatus = modalProps?.declineStatus;

  return (
    <Modal closeModal={declineStatus === 'pending' ? undefined : () => closeModal()}>
      <Modal.Title>Are you sure you want to decline to bid?</Modal.Title>
      <Modal.Content>
        <p>Declining to bid will end the conversation on this project.</p>
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary
          onClick={() => {
            executeDecline();
            closeModal();
          }}
          disabled={declineStatus === 'pending'}
          loading={declineStatus === 'pending'}
        >
          Confirm
        </Button>
        <Button
          outlined
          primary
          onClick={() => closeModal()}
          disabled={declineStatus === 'pending'}
        >
          Cancel
        </Button>
      </Modal.Footer>
  </Modal> 
  );
}