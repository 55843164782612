import * as S from './styles';
import Link from '~/components/Link';
import { FormNumberInput, FormSchemaAwareWidget } from '~/components/form';
import { ContractType, PricingType, Unit, UnitDecimals } from '~/constants';
import { useProjectContext, usePromiseWatcher } from '~/hooks';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import { InputType } from '~/schema';
import { Button } from '~/components';
import { declineProjectBid, postProjectBid } from '~/api/projectBids';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import BidConfirmationModal from './modals/BidConfirmationModal';
import DeclineToBidModal from './modals/DeclineToBidModal';
import budget from '~/utils/displayValues/project/budget';
import { calculateProjectCosts } from '~/utils/calculators';
import { usePermissions } from '~/requests/permissions/usePermissions';

const BidForm = () => {
  const { formState, updateFormValue } = useForm() as any;
  const { permissions } = usePermissions();
  const { project, id, fetchProject } = useProjectContext();
  const history = useHistory();
  const [projectPricingType, setProjectPricingType] = useState(project?.pricingType);
  const { openModal } = useModalContext();

  const { execute: submitBid, status: submitBidStatus } = usePromiseWatcher(
    async () => {
      await postProjectBid(formState, id);
      fetchProject();
      if (project.portfolioId) {
        history.push(`/portfolio/${project.portfolioId}`);
      }
      updateFormValue('amount', '');
      updateFormValue('investorAcknowledgmentsFlag', false);
      updateFormValue('unit', project?.pricingType === PricingType.OFFTAKE ? (project?.contractType === ContractType.PPA ? Unit.KWH : Unit.YEAR) : Unit.WDC);
      setProjectPricingType(project?.pricingType);
    },
    {messageStub: 'submitting your bid'},
    [id, formState]
  );

  const portfolioId = project?.portfolioId;
  const {execute: executeDecline, status: declineStatus} = usePromiseWatcher(
    async () => {
      const { finalProject } = await declineProjectBid(id);
      if (permissions.hasProjectDeveloperAccess) {
        await fetchProject();
      } else if (portfolioId && !finalProject) {
        history.push(`/portfolio/${portfolioId}`);
      } else {
        history.push('/dashboard');
      }
    },
    {
      loading: null,
      success: 'You have ended conversation on the project.',
      messageStub: 'ending conversation on the project.',
    },
    [id, permissions, history, portfolioId]
  );

  const projectCosts =  calculateProjectCosts(project, {
    perWdc: true,
    overall: true,
    nrsFee: true,
    bid: formState
  });

  const budgetDisplayValues = budget(project, projectCosts, permissions);

  return (
    <>
      <S.BidForm>
        <FormNumberInput
          schemaKey="amount"
          fieldLabel='My bid'
          decimals={project?.pricingType === PricingType.OFFTAKE ? (project?.contractType === ContractType.PPA ? 4 : 0) : undefined}
          label={project?.pricingType === PricingType.OFFTAKE ? (project?.contractType === ContractType.PPA ? 'per kWh' : 'per year') : undefined}
          numberType={InputType.DOLLARS}
          unitField="unit"
          units={project?.pricingType === PricingType.ACQUISITION ? [
          {value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC},
          {value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL},
          ] : undefined}
        />

        <S.BidFormCheckbox>
          <FormSchemaAwareWidget schemaKey="investorAcknowledgmentsFlag" />
          <label htmlFor="investorAcknowledgmentsFlag" >
            I’ve reviewed the <Link to="/acknowledgements" primary underline bold>Investor’s Acknowledgment</Link>
          </label>
        </S.BidFormCheckbox>

        <S.BidFormActions>
          <Button 
            primary 
            onClick={() => {
              openModal(BidConfirmationModal, { 
              submitBid,
              submitBidStatus,
              budgetDisplayValues,
              projectPricingType,
              formState
              });
            }}
            disabled={!formState.investorAcknowledgmentsFlag}
          >
            Submit bid
          </Button>
          <Button 
            primary 
            outlined
            onClick={() => openModal(DeclineToBidModal, {
              executeDecline,
              declineStatus
            })}
          >
            Decline to bid
          </Button>
        </S.BidFormActions>
      </S.BidForm>
    </>
  );
};

export default function() {
  const { project } = useProjectContext();

  const defaults = {
    amount: null,
    unit: project?.pricingType === PricingType.OFFTAKE
    ? (project?.contractType === ContractType.PPA ? Unit.KWH : Unit.YEAR)
    : Unit.WDC,
    investorAcknowledgmentsFlag: false
  };

  return (
    <FormContextProvider defaults={defaults}>
      <BidForm />
    </FormContextProvider>
  );
}