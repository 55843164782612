import { nrsFeeWithUnit } from '~/utils/calculators';
import { display } from '../../common';
import { formatNumber } from '~/utils/formatNumber';
import { PricingType, UnitDecimals } from '~/constants';
import { Project } from '~/types/project';

export default function conductorFee(project: Project, projectCosts: any) {
  const feePercent = nrsFeeWithUnit(project)?.fee;

  if (!projectCosts?.bid && project?.pricingType === PricingType.ACQUISITION) {
    return `${feePercent}% of budget - To be calculated based on the sum of your bid, Interconnection costs, and Other ITC-eligible costs in the project budget`;
  } else if (feePercent && !projectCosts?.overall?.nrsFee) {
    return `${feePercent}% of budget - Amount TBD based on bid`;
  } else if (feePercent) {
    return `${feePercent}% of budget — $${formatNumber(projectCosts?.overall?.nrsFee, UnitDecimals.TOTAL)}`;
  } else {
    return display(undefined);
  }
}