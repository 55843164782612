import { FormConfig } from './types';

export const defaultErrorMessage = 'Required';

const validate = (object: any, schema: any, errors = {} as any, parents = '', parentObject = object, rootObject = object, config?: FormConfig, conditions?: any) => {
  Object.keys(schema).forEach((key: string) => {
    if (object && schema[key]) {
      // Check config/conditions to see if the field is displaying
      const fieldConfig = config?.mappedFields?.[key];
      if (
        fieldConfig && 
        conditions && (
          !conditions.isFieldDisplaying(key) ||
          !conditions.isSectionDisplaying(fieldConfig.sectionId ?? '') ||
          !conditions.isSubsectionDisplaying(fieldConfig.subsectionId ?? '')
        )
      ) {
        return ''; // Don't validate if the field is not displaying
      }

      if (typeof schema[key] === 'function') {
        const validator = schema[key];
        if (key.startsWith('@')) {
          key = key.substring(1);
        }
        let index: number | undefined = Number(parents?.split('.')?.pop());
        if (isNaN(index)) {
          index = undefined;
        }
        let error = validator(object[key], rootObject, index);
        if (error) {
          if (typeof error === 'boolean') {
            error = defaultErrorMessage;
          }
          errors[`${parents}.${key}`.slice(1)] = error;
        }
      } else if (Array.isArray(schema[key])) {
        if (!Array.isArray(object[key])) {
          return false;
        }
        if (schema[key].length > 1) {
          throw new Error('Misconfigured schema: array length must be 1. Provided schema: ' + JSON.stringify(schema[key]));
        }
        return object[key].map((_: any, index: number) => {
          if (object[key] && object[key][index]) {
            return validate(object[key][index], schema[key][0], errors, `${parents}.${key}.${index}`, object, rootObject, config, conditions);
          }
          return '';
        });
      } else {
        return validate(object[key], schema[key], errors, `${parents}.${key}`, object, rootObject, config, conditions);
      }
    }
  });
  return errors;
};

export default validate;
