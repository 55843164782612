import { ProductionUnit, LongTermOwner, ProjectArrays } from '~/constants';
import { areRecStateFieldsEnabled, nonEmptyString, range, validationChain, validFloat, isIllinoisShinesProgram, max } from '~/schema/validators';
import { Project } from '~/types/project';

const budgetCostSchema = {
  amount: validFloat,
};

const additionalLineItemSchema = {
  name: (value: any, project: Project) => project.longTermOwner !== LongTermOwner.END_USER && nonEmptyString(value),
  amount: (value: any, project: Project) => project.longTermOwner !== LongTermOwner.END_USER && validFloat(value),
  escalator: (value: any, project: Project) => project.longTermOwner !== LongTermOwner.END_USER && validFloat(value),
  expectedTerm: (value: any, project: Project) => {
    if (project.longTermOwner === LongTermOwner.END_USER) {
      return false;
    }

    const isValidFloat = validFloat(value);
    if (isValidFloat) {
      return true;
    } else if (value < 0 || value > 30) {
      return 'Must be between 0 and 30';
    }
    return false;
  }
};

const validation = {
  epcCost: validFloat,
  epcCompanyName: nonEmptyString,
  epcCompanyType: nonEmptyString,
  siteLeaseAcreage: validFloat,
  budgetCosts: {
    INTERCONNECTION: {
      amount: (value: any, project: Project) => project?.longTermOwner !== LongTermOwner.END_USER && validFloat(value)
    },
    OTHER_ITC: budgetCostSchema,
    OTHER_NON_ITC: budgetCostSchema,
  },
  deploymentType: nonEmptyString,
  developmentStage: nonEmptyString,
  partnerType: nonEmptyString,
  customerName: nonEmptyString,
  customerType: nonEmptyString,
  customerCreditRating: nonEmptyString,
  contractTerm: validFloat,
  contractRate: validFloat,
  contractEscalator: validFloat,
  communitySolarDiscountPercentage: validFloat,
  recOwnership: nonEmptyString,
  recsData: {
    'Illinois': {
      isShinesProgram: areRecStateFieldsEnabled('Illinois', nonEmptyString),
      recAllocation: isIllinoisShinesProgram(nonEmptyString),
      recProjectCategory: isIllinoisShinesProgram(nonEmptyString),
      recUtilityGroup: isIllinoisShinesProgram(nonEmptyString),
      recProgramYear: isIllinoisShinesProgram(nonEmptyString)
    },
    'New Jersey': {
      recAllocation: areRecStateFieldsEnabled('New Jersey', nonEmptyString),
      recValue: areRecStateFieldsEnabled('New Jersey', validationChain(validFloat, range(1, 500))),
      recContractTerm: areRecStateFieldsEnabled('New Jersey', validFloat)
    }
  },
  name: nonEmptyString,
  addressCity: nonEmptyString,
  addressState: nonEmptyString,
  addressZip: nonEmptyString,
  addressStreet: nonEmptyString,
  expectedCommercialOperationDate: nonEmptyString,
  siteLeaseCost: validFloat,
  siteLeaseEscalator: validFloat,
  siteLeaseTerm: nonEmptyString,
  propertyTaxType: nonEmptyString,
  propertyTaxAmount: validFloat,
  propertyTaxEscalator: validFloat,
  customerPricingStatus: nonEmptyString,
  offtakeContractStatus: nonEmptyString,
  permitsStatus: nonEmptyString,
  siteControlStatus: nonEmptyString,
  interconnectionStatus: nonEmptyString,
  utilityZone: nonEmptyString,
  interconnectionType: nonEmptyString,
  pricingType: nonEmptyString,
  longTermOwner: nonEmptyString,
  revenueSource: nonEmptyString,
  projectArrays: [{
    type: nonEmptyString,
    sizeKwdc: validationChain(validFloat, range(0, 40000)),
    sizeKwac: validationChain(isIllinoisShinesProgram(validFloat), range(0, 40000)),
    roofCondition: (value: number, project: Project, index: number) => {
      if (project?.projectArrays?.[index]?.type === ProjectArrays.ROOFTOP) {
        return nonEmptyString(value);
      }
      return false;
    },
    yearOneProduction: (value: number, project: Project, index: number) => {
      const isValidFloat = validFloat(value);
      const unit = project?.projectArrays?.[index]?.yearOneProductionUnit;
      if (isValidFloat) {
        return true;
      } else if (unit === ProductionUnit.KWH && value < 5000) {
        return 'Must be at least 5,000 kWh.';
      } else if (unit === ProductionUnit.KWHKW) {
        return range(500, 3000)(value);
      }
      return false;
    },
    moduleTiltDegrees: range(0, 90),
    azimuthDegrees: range(0, 359),
    derateFactor: max(9),
  }],
  storage: [{
    make: nonEmptyString,
    model: nonEmptyString,
    wattage: validFloat,
    discharge: validFloat
  }],
  additionalRevenues: [additionalLineItemSchema],
  additionalOperatingExpenses: [additionalLineItemSchema],
  subscriptionExpenseAmount: validFloat,
  subscriptionExpenseEscalator: validFloat,
  subscriptionExpenseExpectedTerm: validFloat,
  storageExpenseAmount: validFloat,
  storageExpenseEscalator: validFloat,
  storageExpenseExpectedTerm: nonEmptyString,
  storageExpenseNotes: nonEmptyString
};

export default validation;
