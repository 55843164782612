import dealSummary from './dealSummary';
import status from './status';
import summary from './summary';
import { Portfolio } from '~/types/portfolio';

export default function portfolioDisplayValues(portfolio: Portfolio, portfolioState: any, permissions: any = {}, projectBlobs: any) {
  const result = {
    // The summary table at the top of the portfolio review page
    summary: summary(portfolio),

    // The status of the portfolio
    status: status(portfolio, portfolioState, permissions),

    // Deal summary for portfolio
    dealSummary: dealSummary(portfolio, projectBlobs)
  };

  return result;
}