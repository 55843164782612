import WizardForm from '~/features/projectWizard/components/WizardForm';
import ItcAddersForm from './ItcAddersForm';
import ExpectedITCPercentage from './ExpectedITCPercentage';
import UpfrontIncentives from './UpfrontIncentives';

export default function SourceOfFunds () {
  return (
    <WizardForm>
      <ItcAddersForm />
      <ExpectedITCPercentage />
      <UpfrontIncentives />
    </WizardForm>
  );
}