import { sumBy } from 'lodash';
import { InvestorBidRate } from '~/constants';
import getBudgetCostAmount from './getBudgetCostAmount';

const nrsFee = (project: any, epcCost: any) => {
  const {fee, unit} = nrsFeeWithUnit(project) ?? {};
  if (fee === undefined) {
    return null;
  }

  if (unit === InvestorBidRate.DOLLARS_PER_WDC) {
    const sizeKwdc = project.projectArrays ? sumBy(Object.values(project.projectArrays), (mount: any) => Number(mount.sizeKwdc)) : 0;
    return fee * sizeKwdc * 1000;
  } else {
    if (isNaN(epcCost)) {
      return null;
    }
    const otherItcAmount = getBudgetCostAmount(project, 'OTHER_ITC');
    const interconnectionAmount = getBudgetCostAmount(project, 'INTERCONNECTION');

    const nrsFee = ((epcCost + otherItcAmount + interconnectionAmount) / 100) * fee;

    return nrsFee;
  }
};


const nrsFeeWithUnit = (project: any) => {
  if (!project) {
    return null;
  }

  if ((!project.investorQueue || project.investorQueue?.length === 0) && !project.activeInvestorQueueRow) {
    return null;
  }

  let fee;
  let unit;
  if (project.activeInvestorQueueRow) {
    fee = project.activeInvestorQueueRow?.nrsFee;
    unit = project.activeInvestorQueueRow?.nrsFeeUnit;
  } else {
    fee = project.investorQueue[0]?.nrsFee; //TODO Project typing issue
    unit = project.investorQueue[0]?.nrsFeeUnit;
  }

  return {fee, unit};
};

export { nrsFee, nrsFeeWithUnit };
