import { Header, Popup } from 'semantic-ui-react';
import { ReactComponent as DNVLogo } from 'assets/dnv.svg';
import Link from '~/components/Link';

export default function ProductionValidationInfo() {
  return (
    <>
      <div style={{ display: 'flex', marginTop: 'var(--medium)' }}>
        <Header>Enhanced Production Analysis in Partnership with DNV</Header>
        <Popup
          inverted
          trigger={
            <a 
              style={{ 
                display: 'inline-block', 
                width: '3.625rem', 
                marginLeft: 'auto' 
              }}
              target="_blank"
              rel="noreferrer"
              href="https://www.dnv.com/power-renewables/generation/solar-energy.html"
            >
              <DNVLogo />
            </a>
          }
          content="Conductor's production analysis is powered by DNV, a global leader in solar engineering and provider of independent engineering for commercial and community solar projects."
          position="top center"
          on={['hover', 'focus']}
          style={{ maxWidth: '22.5rem', fontSize: '0.875rem', lineHeight: '1.25rem' }}
        />
      </div>
      <p style={{ marginBottom: '3rem' }}>
        {'Use the inputs below and the optional fields on the PV tab to refine production estimates and build investor confidence. DNV offers IE services with preferred pricing through Conductor - contact '}
        <Link
          as='a'
          href='mailto:support@conductor.solar'
          linkText='support@conductor.solar'
          underline
          rel="noopener noreferrer"
        />
        {' to learn more.'}
      </p>
    </>
  );
}