export const ProjectCustomerPricing = {
  PRICING_AGREED_VIA_SIGNED_DOCUMENT: 'Pricing agreed via a signed document',
  PRICING_AGREED_VERBALLY: 'Pricing agreed verbally or via email',
  PRICING_TARGET_KNOWN_DUE_TO_AVOIDED_COSTS: 'Pricing target known due to avoided costs',
  PRICING_TARGET_LARGELY_UNKNOWN: 'Pricing target largely unknown',
} as any;

export const ProjectOfftakeContract = {
  OFFTAKE_CONTRACT_SIGNED: 'Offtake contract signed',
  OFFTAKE_CONTRACT_IN_REVIEW: 'Offtake contract in review',
  OFFTAKE_TERM_SIGNED: 'Offtake term sheet signed',
  OFFTAKE_TERM_IN_REVIEW: 'Offtake term sheet in review',
  NO_OFFTAKE_DOCS_SENT: 'No offtake-related docs sent to customer',
} as any;

export const ProjectPermits = {
  IN_HAND_NTP_READY: 'In-hand, ready for NTP',
  MINISTERIAL_PERMITS_NEEDED: 'Only ministerial permits needed',
  EXPECTED_WITHIN_THREE_MONTHS: 'Expected within next 3 months',
  NOT_EXPECTED_UNTIL_THREE_MONTHS_OUT: 'Not expected until >3 months out',
} as any;

export const ProjectInterconnectionStatus = {
  EXECUTED_BY_UTILITY: 'EXECUTED_BY_UTILITY',
  STUDY_COMPLETED_NO_AGREEMENT: 'STUDY_COMPLETED_NO_AGREEMENT',
  CONSIDERED_FORMALITY: 'CONSIDERED_FORMALITY',
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  APPLICATION_NOT_YET_SUBMITTED: 'APPLICATION_NOT_YET_SUBMITTED',
  APPLICATION_SUBMITTED_NOT_YET_RETURNED: 'APPLICATION_SUBMITTED_NOT_YET_RETURNED',
  AGREEMENT_FINALIZED_NOT_YET_EXECUTED: 'AGREEMENT_FINALIZED_NOT_YET_EXECUTED',
  AGREEMENT_EXECUTED: 'AGREEMENT_EXECUTED',
  PRE_APPLICATION_IN_PROCESS: 'PRE_APPLICATION_IN_PROCESS',
  PRE_APPLICATION_COMPLETED_BY_UTILITY: 'PRE_APPLICATION_COMPLETED_BY_UTILITY',
  IMPACT_STUDY_REQUIRED_BUT_NOT_YET_COMPLETED: 'IMPACT_STUDY_REQUIRED_BUT_NOT_YET_COMPLETED',
  IMPACT_STUDY_COMPLETED_OR_NOT_REQUIRED_NO_AGREEMENT_EXECUTED: 'IMPACT_STUDY_COMPLETED_OR_NOT_REQUIRED_NO_AGREEMENT_EXECUTED'
};

export const ProjectInterconnectionDisplayValues = {
  [ProjectInterconnectionStatus.EXECUTED_BY_UTILITY]: 'Executed by utility',
  [ProjectInterconnectionStatus.STUDY_COMPLETED_NO_AGREEMENT]: 'Study completed by utility, but no agreement is executed',
  [ProjectInterconnectionStatus.CONSIDERED_FORMALITY]: 'Considered a formality due to nature of project',
  [ProjectInterconnectionStatus.APPLICATION_SUBMITTED]: 'Study is in process',
  [ProjectInterconnectionStatus.APPLICATION_NOT_YET_SUBMITTED]: 'Application not yet submitted',
  [ProjectInterconnectionStatus.APPLICATION_SUBMITTED_NOT_YET_RETURNED]: 'Application submitted, not yet returned',
  [ProjectInterconnectionStatus.AGREEMENT_FINALIZED_NOT_YET_EXECUTED]: 'Agreement finalized, not yet executed',
  [ProjectInterconnectionStatus.AGREEMENT_EXECUTED]: 'Agreement executed',
  [ProjectInterconnectionStatus.PRE_APPLICATION_IN_PROCESS]: 'Pre-application in process',
  [ProjectInterconnectionStatus.PRE_APPLICATION_COMPLETED_BY_UTILITY]: 'Pre-application completed by utility',
  [ProjectInterconnectionStatus.IMPACT_STUDY_REQUIRED_BUT_NOT_YET_COMPLETED]: 'Impact study required but not yet completed',
  [ProjectInterconnectionStatus.IMPACT_STUDY_COMPLETED_OR_NOT_REQUIRED_NO_AGREEMENT_EXECUTED]: 'Impact study completed or not required, no agreement executed'
};

export const ProjectInterconnectionDropdownValues = [
  { 
    value: ProjectInterconnectionStatus.APPLICATION_NOT_YET_SUBMITTED, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.APPLICATION_NOT_YET_SUBMITTED]
  },
  {
    condition: 'BTM',
    value: ProjectInterconnectionStatus.APPLICATION_SUBMITTED_NOT_YET_RETURNED, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.APPLICATION_SUBMITTED_NOT_YET_RETURNED] 
  },
  {
    condition: 'BTM',
    value: ProjectInterconnectionStatus.AGREEMENT_FINALIZED_NOT_YET_EXECUTED, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.AGREEMENT_FINALIZED_NOT_YET_EXECUTED] 
  },
  {
    condition: 'FTM',
    value: ProjectInterconnectionStatus.PRE_APPLICATION_IN_PROCESS, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.PRE_APPLICATION_IN_PROCESS] 
  },
  { 
    condition: 'FTM',
    value: ProjectInterconnectionStatus.PRE_APPLICATION_COMPLETED_BY_UTILITY, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.PRE_APPLICATION_COMPLETED_BY_UTILITY] 
  },
  { 
    condition: 'FTM',
    value: ProjectInterconnectionStatus.IMPACT_STUDY_REQUIRED_BUT_NOT_YET_COMPLETED, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.IMPACT_STUDY_REQUIRED_BUT_NOT_YET_COMPLETED] 
  },
  { 
    condition: 'FTM',
    value: ProjectInterconnectionStatus.IMPACT_STUDY_COMPLETED_OR_NOT_REQUIRED_NO_AGREEMENT_EXECUTED,
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.IMPACT_STUDY_COMPLETED_OR_NOT_REQUIRED_NO_AGREEMENT_EXECUTED] 
  },
  {
    value: ProjectInterconnectionStatus.AGREEMENT_EXECUTED, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.AGREEMENT_EXECUTED] 
  },

  // Deprecated values
  { 
    deprecated: true, 
    value: ProjectInterconnectionStatus.EXECUTED_BY_UTILITY, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.EXECUTED_BY_UTILITY] 
  },
  { 
    deprecated: true, 
    value: ProjectInterconnectionStatus.STUDY_COMPLETED_NO_AGREEMENT, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.STUDY_COMPLETED_NO_AGREEMENT] 
  },
  { 
    deprecated: true, 
    value: ProjectInterconnectionStatus.CONSIDERED_FORMALITY, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.CONSIDERED_FORMALITY]
  },
  { 
    deprecated: true, 
    value: ProjectInterconnectionStatus.APPLICATION_SUBMITTED, 
    text: ProjectInterconnectionDisplayValues[ProjectInterconnectionStatus.APPLICATION_SUBMITTED]
  }
];