import { useProjectWizardContext } from '~/features/projectWizard/providers/ProjectWizardProvider';
import { WorksheetTabContainer } from './styles';
import Tab from '~/components/Tab';
import { usePageHeaderContext } from '~/components/PageHeader/PageHeaderProvider';
import { useProjectContext } from '~/hooks';
import { MenuItem } from 'semantic-ui-react';
import { knownErrors } from '~/schema/project';
import FieldErrorCount from '../ProjectWizardHeader/FieldErrorCount';

export default function Worksheet() {
  // Get current screen from the wizard context
  const { config, currentScreen, selectScreen } = useProjectWizardContext();
  const {
    conditions: {
      subsectionLabel,
      isSubsectionDisplaying
    }
  } = useProjectContext();
  const section = config.find(section => section.id === currentScreen?.sectionId) ?? config[0];
  const pageHeaderContext = usePageHeaderContext();
  const categories = knownErrors[section.id] as any;

  // Determine active tab index to set based on sectionId/subsectionId
  let activeTabIndex = section?.subsections?.findIndex(subsection => subsection.id === currentScreen?.subsectionId);
  if (!activeTabIndex || activeTabIndex < 0) {
    activeTabIndex = 0;
  }
  
  // Return the list of tabs and render the active tab
  return (
    <WorksheetTabContainer pageHeaderContext={pageHeaderContext}>
      <Tab 
        panes={section?.subsections.filter(subsection => isSubsectionDisplaying(subsection.id)).map(subsection => {
          return {
            menuItem: (
              <MenuItem>
                {subsectionLabel(subsection.id, subsection.label)}
                <FieldErrorCount categoryData={categories[subsection.id]} style={{ marginLeft: 'var(--x-small)' }} />
              </MenuItem>
            ),
              render: () => {
              const Component = subsection.component;
              return <Component />;
            }
          };
        })}
        activeIndex={activeTabIndex}
        onTabChange={(_ignored: any, { activeIndex }: { activeIndex: number }) => {
          window.scrollTo({ top: 0 });
          const subsection = section?.subsections[activeIndex];
          selectScreen(section.id, subsection?.id);
        }}
      />
    </WorksheetTabContainer>
  );
}