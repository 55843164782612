import styled from 'styled-components';

export default styled.div`
  &&& {
    position: sticky;
    inset: 0;
    background: var(--color-off-white);
    z-index: 1;
    width: 100%;
  }
`;