import React from 'react';
import { Form } from 'semantic-ui-react';
import { InvestorBidRate } from '~/constants';
import { FormNumberInput } from '~/components/form';
import { usePromiseWatcher, useForm, useProjectContext } from '~/hooks';
import { InputType } from '~/schema/InputType';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import Link from '../Link';
import { Button } from '~/components/index';

interface EditInvestorRateFormProps {
  setOpenEdit(arg: boolean): void;
  updateInvitation: any;
  defaults?: any;
}

const EditInvestorRateFormImpl: React.FC<EditInvestorRateFormProps> = ({
  setOpenEdit, 
  updateInvitation
}) => {
  const {id} = useProjectContext();
  const {formState} = useForm() as any;
  const {execute: executeUpdateInvestor} = usePromiseWatcher(
    () => updateInvitation(formState),
    {messageStub: 'updating the investor invitation'},
    [id, formState]
  );

  return (
    <>
      <Form style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 'var(--x-small)'}}>
        <FormNumberInput
          schemaKey="nrsFee"
          fieldStyle={{width: '6rem', display: 'inline-block', marginBottom: '0' }}
          inputStyle={{fontSize: '0.875rem', padding: 'var(--3x-small) 0', border: '3px solid var(--color-green)'}}
          numberType={formState.nrsFeeUnit === InvestorBidRate.DOLLARS_PER_WDC ? InputType.DOLLARS : InputType.PERCENT}
          decimals={formState.nrsFeeUnit === InvestorBidRate.DOLLARS_PER_WDC ? 3 : 2}
        />
        <Link 
          underline
          as={Button} 
          style={{ fontSize: '0.875rem' }}
          onClick={() => setOpenEdit(false)} 
        >
          cancel
        </Link>
        <span>|</span>
        <Link
          underline
          as={Button}
          style={{ fontSize: '0.875rem' }}
          onClick={() => {
            if (formState.nrsFee !== 0) {
              executeUpdateInvestor();
            }
            setOpenEdit(false);
          }}
        >
          save
        </Link>
    </Form>
    </>
  );
};


const EditInvestorRateForm: React.FC<EditInvestorRateFormProps> = ({
  setOpenEdit, 
  updateInvitation, 
  defaults
}) => {
  return (
    <FormContextProvider defaults={defaults}>
      <EditInvestorRateFormImpl {...{setOpenEdit, updateInvitation}}/>
    </FormContextProvider>
  );
};

export default EditInvestorRateForm;
