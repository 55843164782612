import { FormConditionEffects, FormConditionFunction, FormConditionKeys, FormConditionObject, FormFieldValue } from './types';

export const when = (fieldIdOrFunction: string | FormConditionFunction, condition?: FormConditionKeys, value?: FormFieldValue) => {
  if (typeof fieldIdOrFunction === 'string' && (!condition || (((condition === '=' || condition === '!=')) && value === undefined))) {
      throw new Error('Bad when function for form condition');
  }
  return {
    then(effects: FormConditionEffects): FormConditionObject {
      if (typeof fieldIdOrFunction === 'string') {
        let conditionValue = condition === '=' || condition === '!=' ? value : true;
        const conditionKey = condition as FormConditionKeys;
        return {
          fieldId: fieldIdOrFunction,
          [conditionKey]: conditionValue,
          effects
        };
      } else {
        return {
          conditionFn: fieldIdOrFunction,
          effects
        };
      }
    }
  };
};