import { formatDollars } from '~/utils/formatNumber';
import targetITCPercent from './targetITCPercent';
import { PricingType } from '~/constants';
import { Project } from '~/types/project';

export default function targetITC(project: Project, projectCosts: any) {
  let adderStr = '';
  project?.itcAdders?.forEach((adder: any, i: number) => {
    if (i > 0) {
      adderStr += ', ';
    }
    adderStr += adder.label;
  });

  const itcPlusAddersString = `(${targetITCPercent(project)} ITC${adderStr ? ` — ${adderStr}` : ''})`;

  if (projectCosts.expectedItcCost && !(project.pricingType === PricingType.ACQUISITION && projectCosts?.bid === undefined)) {
    // Show $ amount of how much of the project value the ITC percent will cover
    return `${formatDollars(projectCosts.expectedItcCost)} ${itcPlusAddersString}`;
  } else {
    // Show ITC percent without a $ amount since the $ value has not yet been determined
    return `TBD pending bids ${itcPlusAddersString}`;
  }
}