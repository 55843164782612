import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
`;

export default function MainContainer({ children }: { children: ReactNode }) {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  );
}