import { usePortfolioContext } from '~/hooks';
import styled from 'styled-components';

export const DealSummaryInvestor = styled.div`
  &&& {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: bold;
    text-decoration: underline;
    color: var(--color-pure-black);
    border-left: 0;
    border-right: 0;
  }
`;

export default function InvestorRow() {
  const { portfolioDisplayValues } = usePortfolioContext();
  const dealSummary = portfolioDisplayValues?.dealSummary;

  return (
    <>
      <div />
      {dealSummary?.investors?.map((investor: any) => (
        <DealSummaryInvestor key={investor?.id}>{investor?.name}</DealSummaryInvestor>
      ))}
    </>
  );
}