import bidDeadline from './bidDeadline';
import headerText from './headerText';
import bidUnit from './bidUnit';
import bidAmount from './bidAmount';
import conductorFee from './conductorFee';
import { Project } from '~/types/project';

export default function (project: Project, projectCosts: any) {
  const bidUnitDisplay = bidUnit(project);

  const detailsTable: [label: string, value: string][] = [
    [`Current bid${bidUnitDisplay ? ` (${bidUnitDisplay})` : ''}`, bidAmount(project)],
    ['Conductor Solar fee', conductorFee(project, projectCosts)]
  ];

  return {
    headerText: headerText(project),
    bidDeadline: bidDeadline(project),
    detailsTable
  };
}