import styled from 'styled-components';
import ReactModal from 'react-modal';
import { Icon } from 'semantic-ui-react';

export const Modal = styled(ReactModal)<{ width: string }>`
  width: calc(100% - var(--large));
  position: absolute;
  top: 0;
  max-width: ${props => props.width};
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.32), 1px 2px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  background: var(--color-off-white);
`;

export const ModalContainer = styled.div`
  position: relative;
`;

export const ModalCloseIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-charcoal);
  &:hover {
    cursor: pointer;
  }
`;
ModalCloseIcon.defaultProps = {
  name: 'close',
  role: 'button',
  tabIndex: '0'
};

export const ModalContent = styled.section`
  margin-top: 1.5rem;
  > *:not(:last-child) {
    margin-bottom: var(--medium);
  }
`;

export const ModalTitle = styled.h2`
  color: var(--color-pure-black);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.625rem;
  letter-spacing: -0.015rem;
  margin: 0;
  padding-right: var(--large);
  + * {
    margin-top: var(--medium);
  }
`;

export const ModalFooter = styled.section`
  &&& {
    display: flex;
    margin-top: var(--medium);
    padding-top: var(--x-small);
    align-items: center;
    justify-content: flex-end;
    > * + * {
      margin: 0;
      margin-left: 1.5rem;
    }
  }
`;
