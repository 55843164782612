import { Checkbox, Form } from 'semantic-ui-react';
import { CheckboxContainer } from '~/components/form/styles';
import { useProjectContext } from '~/hooks';
import { ItcAdders } from '~/types';

export default function ItcAddersForm() {
  const { project, updateProjectValue, config } = useProjectContext();
  const itcAddersList: ItcAdders = config?.itcAdders ?? [];
  const selectedItcAdders: ItcAdders = project?.itcAdders ?? [];

  return (
    <CheckboxContainer>
        <Form.Field className="label">
          <label>ITC adders</label>
          <p>Select all that apply.</p>
        </Form.Field>
        <Form.Group>
          {itcAddersList.map((itcAdder: any) => {
            const isSelected = !!selectedItcAdders.find((selectedItcAdder: any) => selectedItcAdder.id === itcAdder.id);
            return (
              <Form.Field key={itcAdder.id}>
                <Checkbox
                  checked={isSelected}
                  label={`${itcAdder.label} (${itcAdder.value}%)`}
                  onChange={() => {
                    if (isSelected) {
                      // Remove the ITC adder
                      updateProjectValue('', { 
                        itcAdders: selectedItcAdders.filter(adder => adder.id !== itcAdder.id) 
                      }, { patch: true });
                    } else {
                      // Add the ITC adder
                      updateProjectValue('', { 
                        itcAdders: [...selectedItcAdders, itcAdder] 
                      }, { patch: true });
                    }
                  }}
                />
              </Form.Field>
            );
          })}
        </Form.Group>
      </CheckboxContainer>
  );
}