import { Container, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { useUserContext } from '~/hooks';
import { UserRole } from '~/constants';

const StyledHeader = styled(Header)`
  font-size: 32px;
  i {
    width: 10px;
    margin-right: 35px;
    font-size: 32px;
  }
`;

const StyledContainer = styled(Container)`
  padding-left: 25px;
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
`;

const Acknowledgements = () => {
  const {loggedInUser} = useUserContext() as any;
  if (loggedInUser?.role?.code === UserRole.INSTALLER) {
    return (
      <StyledContainer fluid>
        <StyledHeader as="h1">Developer / EPC Acknowledgements</StyledHeader>
        <p>Developer / EPC Acknowledges the following:</p>
        <p>
          Pricing contemplates a Notice to Proceed (NTP) closing. Investor should not be expected to provide
          capital in advance of NTP. Developer/EPC is solely responsible for getting a project “NTP-Ready” and
          will bear both the cost and risk to do so. NTP-Ready projects typically require at least 30 days
          before closing will occur. NTP Ready means the flowing items are, at a minimum are completed:
        </p>
        <p>
          All Projects
          <ul>
            <li>Signed offtake agreement</li>
            <li>Signed site lease or easement</li>
            <li>Signed EPC agreement</li>
            <li>Stamped engineering drawings</li>
            <li>Major permits in-hand</li>
            <li>Interconnection agreement approved with required upgrade costs known</li>
            <li>Property tax estimates have been provided with proper support</li>
          </ul>
        </p>
        <p>
          Rooftop
          <ul>
            <li>Stamped structural letter confirming the roof can handle the load of the solar array</li>
          </ul>
        </p>
        <p>
          Ground mount / Carport
          <ul>
            <li>Phase 1 Environmental Site Assessment</li>
            <li>ALTA Land Title Survey</li>
            <li>
              Wetlands analysis, cultural resources (SHPO) analysis, and/or endangered species analysis
              may all be required
            </li>
            <li>
              Geotech report has been completed (or seller agrees to take on change-order risk for
              subsurface issues that arise)
            </li>
          </ul>
        </p>
        <p>
          Production estimates will be heavily analyzed by the investor due to their importance to revenue
          projections. Some areas of focus will be:
          <ul>
            <li>System design matches the engineering drawings</li>
            <li>GHI estimates</li>
            <li>Monthly soiling and snow loss estimates (rarely a flat 2%)</li>
            <li>Shading analyses (from both nearby objects and far horizons)</li>
            <li>Unavailability of the system</li>
          </ul>
        </p>
        <p>
          EPC price is considered turn-key and includes customary costs. If investor specifies any unique
          requirements, developer/EPC will revise pricing. Turn-key EPC price should include:
          <ul>
            <li>Tier 1 equipment (modules, inverters, and racking)</li>
            <li>
              All permits, metering equipment, monitoring equipment of ~$5k per address, fencing, etc.
            </li>
            <li>A standard test to ensure the system is operating as expected upon being built</li>
          </ul>
        </p>
        <p>Commercially reasonable accommodations will be made for necessary changes to major contracts.</p>
        <p>
          Certain installation costs are often not to be considered ITC-eligible, including:
          <ul>
            <li>Roof replacements</li>
            <li>Interconnection upgrade costs</li>
            <li>LED retrofits</li>
          </ul>
        </p>
        <p>
          To the extent that these costs are not known up-front and not disclosed separately, they will reduce
          the price
        </p>
        <p>
          If COD slips into the following year, the economics may change for the investor and that may, in
          turn, impact their pricing.
        </p>
      </StyledContainer>
    );
  } else {
    return (
      <StyledContainer fluid>
        <StyledHeader as="h1">Investor’s Acknowledgements</StyledHeader>
        <p>
          Bids submitted on Conductor are fully intended to close. By submitting a bid, you represent that you
          have the relationships with financing parties and/or capital on-hand to fund said project.
        </p>
        <p>If you need specific documents to make an accurate bid, please request them.</p>
        <p>
          A reasonable closing timeline for the project will be set after a letter of intent is signed by the
          developer/EPC. Expectation is that closing will happen within 8 weeks after the LOI is signed and
          within 4-6 weeks after all materials are in-hand.
        </p>
        <p>
          Bids include turn-key EPC pricing for a NTP ready project. If you intend to transact at a later
          stage during construction (e.g., Mechanical Completion), be sure to indicate that in the comments
          when submitting your bid
        </p>
        <p>
          Turn-key EPC pricing on Conductor assumes the following falls within the EPC budget:
          <ul>
            <li>1-3 year workmanship warranty</li>
            <li>Basic monitoring equipment (~$5k per address for equipment, including weather stations)</li>
            <li>Tier 1 equipment</li>
          </ul>
        </p>
        <p>
          Any change to pricing during the transaction must be supported by a material change in the project
          revenue, expense, or budget as compared to the information known for bidding.
        </p>
      </StyledContainer>
    );
  }
};

export default Acknowledgements;
