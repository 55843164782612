import { useMutation } from 'react-query';
import { addCommentMessage } from '~/api/conversations';

export const useAddCommentMutation = (params = {}) => {
  return useMutation({
    mutationFn: async (params: { conversationId: string, comment: string, replyThreadId?: string }) => {
      return addCommentMessage(params.conversationId, params.comment, params.replyThreadId);
    },
    ...params
  });
};