import { useProjectContext } from '~/hooks';
import { Project } from '~/types/project';
import { formatNumber } from '~/utils';
import { System } from '~/utils/calculators';

export default function Totals() {
  const project: Project = useProjectContext().project;
  const projectArrays = (project?.projectArrays || []);
  const sizeKwdc = System.sizeKwdc(projectArrays);
  const productionFactor = System.productionFactor(projectArrays);

  const hasData = sizeKwdc || productionFactor;

  return (
    <h3>
      Total size / production -{' '}
      <span style={{ color: 'var(--color-primary)' }}>{hasData ? (
        <>
          {sizeKwdc ? `${formatNumber(sizeKwdc) || 0} kWdc` : ''}
          {productionFactor ? ', ' : ''}
          {productionFactor ? `${formatNumber(productionFactor) || 0} kWh/kW` : ''}
        </>
      ) : 'TBD'}</span>
    </h3>
  );
}