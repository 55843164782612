import { Link as RouterLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

export interface CustomLinkProps extends Partial<LinkProps> {
  color?: string;
  linkText?: string;
  lineHeight?: string;
  primary?: boolean;
  underline?: boolean;
  noUnderline?: boolean;
  fontSize?: string;
  bold?: boolean;
  as?: any;
  disabled?: boolean;
}

// Link styling to be changed based on usage
// Base link: orange, bold, and underlined
/*
  <Link 
    to='/' 
    linkText='Go to project' 
    primary
    bold
    underline
  />
*/  

const StyledLink = styled(RouterLink)<CustomLinkProps>`
  &&& {
    all: unset;
    color: ${props => props.primary ? 'var(--color-primary)' : (props.color || 'var(--color-secondary)')}; //Primary state color is orange if primary prop is true, otherwise blue
    text-decoration: ${props => props.noUnderline ? 'none' : (props.underline ? 'underline' : 'none')};            //Underline if underline prop is true, otherwise none
    font-size: ${props => props.fontSize || 'inherit'};                               //Default font size is inherit unless defined by prop
    font-weight: ${props => props.bold ? 'bold' : 'inherit'};                         //Bold if bold prop is true, otherwise inherit text weight
    transition: filter 0.3s ease-in-out;
    overflow-wrap: break-word;
    &:hover {
      cursor: pointer;
      filter: brightness(90%);
      // a11y: add or remove underline on hover based on styling
      text-decoration: ${props => !props.noUnderline && !props.underline ? 'underline' : 'none'};
      background: transparent;
      ${props => props.color === 'red' && 'color: var(--color-red);'}
    }
    &:focus-visible {
      outline: 2px solid var(--color-secondary);
    }  
    &:active {
      filter: brightness(85%);
    }
  }
`;

export default function Link({ linkText, children, ...props }: CustomLinkProps) {
  return (
    <StyledLink {...props}>
      {linkText ?? children}
    </StyledLink>
  );
}