import { ProjectArraysShortDisplay } from '~/constants';
import { formatNumber } from '~/utils/formatNumber';
import { display } from '~/utils/displayValues/common';
import { Project } from '~/types/project';

export default function technologies(project: Project) {
  let technologies = '';

  const projectArraysMap = Object.values(project?.projectArrays || {}).reduce((acc, array) => {
    if (!acc[array.type]) {
      acc[array.type] = 0;
    }
    acc[array.type] += (array.sizeKwdc ?? 0);
    return acc;
  }, {} as { [key: string]: number }); // Map of array type to sum of sizes of that type

  Object.entries(projectArraysMap).forEach(([type, size]) => {
    if (ProjectArraysShortDisplay[type]) {
      if (technologies) {
        technologies += '; ';
      }
      technologies += `${formatNumber(size)} kWdc, ${ProjectArraysShortDisplay[type]}`;
    }
  });

  if (project?.hasStorage && (project?.storage?.find(row => row.wattage || row.discharge))) {
    if (technologies) {
      technologies += '; ';
    }

    const sums = project.storage.reduce((acc, storage) => {
      acc.wattage += (storage?.wattage ?? 0);
      acc.discharge += (storage?.discharge ?? 0);
      return acc;
    }, { wattage: 0, discharge: 0 });

    technologies += sums.wattage ? `${formatNumber(sums.wattage)} kW` : 'N/A';
    technologies += ' / ';
    technologies += sums.discharge ? `${formatNumber(sums.discharge)} kWh` : 'N/A';
    technologies += ', Storage';
  }

  return display(technologies);
}