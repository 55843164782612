import { TutorialCodes } from '~/constants';
import TutorialModal from '../components/TutorialModal';

export default function FirstDevInvestorInvited() {

  return (
    <TutorialModal
      title='First investor invited to bid'
      content={
        <>
          <p>
            You’ve been matched with your first investor! As additional investors are invited, they will appear next to each other in the bidding grid at the top of your project summary screen. From there you’ll see their bids come in, and you can grant or remove access to your project documents. 
          </p>
        </>
      }
      hasCancelButton={false}
      submitButtonText='OK'
      tutorialCode={TutorialCodes.FIRST_INVESTOR_INVITED}
    />  
  );
};  