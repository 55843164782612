import { Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { useProjectContext } from '~/hooks';

const Container = styled.div`
  * {
    padding: 0;
    margin: 0;
  }

  h3 {
    margin: var(--medium) 0;
  }

  span {
    color: var(--color-primary);
  }
`;

const TextContainer = styled.div`
  max-width: 29.25rem;
  padding: var(--x-small) 0;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export default function BudgetSummary() {
  const { projectDisplayValues } = useProjectContext();

  const budget = projectDisplayValues?.budget;
  const total = budget?.totals?.totalMinusFee;
  const totalPerWdc = budget?.totals?.perWdcMinusFee;

  return (
  <Container>
    <Header as="h3">Budget Summary</Header>
    <TextContainer>
      <p>Project budget - <span>{total}</span></p>
      <p>Project budget per Wdc - <span>{totalPerWdc}</span>
      </p>
    </TextContainer>
  </Container>
  );
};