import { useEffect, useRef } from 'react';
import { useProjectContext } from '~/hooks';
import { System } from '~/utils/calculators';

export const useDerivedSavingsAutocalculateFields = () => {
  const { project, updateProjectValue } = useProjectContext();

  // Automatically handle certain data changes
  const yearOneProduction = System.yearOneProduction(project?.projectArrays) as number;
  const avoidedCostOfSolarRef = useRef<number | null | undefined>(project?.avoidedCostOfSolar);
  const yearOneSavingsRef = useRef<number | null | undefined>(project?.yearOneSavings);
  const yearOnyProductionRef = useRef<number | null>(yearOneProduction);

  useEffect(() => {
    const yearOneProductionChanged = yearOneProduction !== yearOnyProductionRef.current;
    const yearOneSavingsChanged = yearOneSavingsRef.current !== project?.yearOneSavings && !(isNaN(yearOneSavingsRef.current as number) && isNaN(project?.yearOneSavings as number));
    const avoidedCostOfSolarChanged = avoidedCostOfSolarRef.current !== project?.avoidedCostOfSolar && !(isNaN(avoidedCostOfSolarRef.current as number) && isNaN(project?.avoidedCostOfSolar as number));

    // Handle change to avoided cost of solar or a change to year one production when the avoided cost of solar is user-provided
    // Calculate the year one savings in this case
    if (avoidedCostOfSolarChanged || (yearOneProductionChanged && project?.avoidedCostOfSolarInputType === 'provided')) {
      // Calculate value of other field
      const yearOneSavings = yearOneProduction && (project?.avoidedCostOfSolar || project?.avoidedCostOfSolar === 0)
        ? Number(parseFloat(String(yearOneProduction * (project.avoidedCostOfSolar ?? 0))).toFixed(2)) 
        : null;

      // Update refs
      avoidedCostOfSolarRef.current = project?.avoidedCostOfSolar;
      yearOneSavingsRef.current = yearOneSavings;
      yearOnyProductionRef.current = yearOneProduction;

      // Save project data
      updateProjectValue('', {
        avoidedCostOfSolarInputType: 'provided',
        yearOneSavings,
        yearOneSavingsInputType: 'calculated'
      }, {
        patch: true,
      });
    }

    // Handle change to year one savings or a change to year one production when the year one savings is user-provided
    // Calculate the avoided cost of solar in this case
    if (yearOneSavingsChanged || (yearOneProductionChanged && project?.yearOneSavingsInputType === 'provided')) {
      // Calculate value of other field
      const avoidedCostOfSolar = yearOneProduction && (project?.yearOneSavings || project?.yearOneSavings === 0)
        ? Number(parseFloat(String(project.yearOneSavings / yearOneProduction)).toFixed(4)) 
        : null;

      // Update refs
      avoidedCostOfSolarRef.current = avoidedCostOfSolar;
      yearOneSavingsRef.current = project?.yearOneSavings;
      yearOnyProductionRef.current = yearOneProduction;

      // Update project data
      updateProjectValue('', {
        yearOneSavingsInputType: 'provided',
        avoidedCostOfSolar,
        avoidedCostOfSolarInputType: 'calculated'
      }, {
        patch: true,
      });
    }
  }, [project, project?.avoidedCostOfSolar, project?.yearOneSavings, yearOneProduction]);
};