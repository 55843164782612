import { Comment } from 'semantic-ui-react';
import React, { Fragment, useState } from 'react';
import EditComments from './EditComments';
import Metadata from './Metadata';
import { useMessagingContext } from '../providers/MessagingProvider';
import { useUserContext } from '~/hooks';
import { useFadeInStyles } from '~/hooks';
import { Button } from '~/components';
import * as S from '../styles';
import CommentMessageResponse from './CommentEventResponse';
import { CommentMessage } from '~/types/conversations/Message';
import { useMutation } from 'react-query';
import { withPromiseToaster } from '~/utils';
import { deleteCommentMessage, editCommentMessage } from '~/api/conversations';
import { useConversationContext } from '../providers/ConversationProvider';
import CommentAvatar from './CommentAvatar';

const CommentEvent: React.FC<{ message: CommentMessage }> = ({ message }) => {
  const { loggedInUser } = useUserContext() as any;
  const { selectedConversation } = useConversationContext();
  const { setCommentEdit, onMessageUpdate, initCommentReply } = useMessagingContext();
  const [commentState, setCommentState] = useState<'default' | 'editing' | 'deleting'>('default');

  // Split comments by newline character so we can render <br /> tags for them
  const commentParts = message?.data?.comment?.split('\n') as string[] ?? [];
  const isOwnComment = loggedInUser?.id === message?.user?.id;

  const { mutate: submitDeleteComment, status: deleteCommentStatus } = useMutation(
    (params: { conversationId: string; messageId: string }) => withPromiseToaster(
      deleteCommentMessage(params.conversationId, params.messageId).then(message => {
        onMessageUpdate(message);
        setCommentState('default');
      }),
      {
        messageStub: 'deleting your comment'
      }
    )
  );

  const handleSubmitDeleteComment = () => {
    submitDeleteComment({ 
      conversationId: selectedConversation?.id as string, 
      messageId: message.id
    });
  };

  const { mutate: submitEditComment, status: editCommentStatus } = useMutation(
    (params: { conversationId: string; messageId: string, text: string }) => withPromiseToaster(
      editCommentMessage(params.conversationId, params.messageId, params.text).then(message => {
        onMessageUpdate(message);
        setCommentState('default');
      }),
      {
        messageStub: 'editing your comment'
      }
    )
  );

  const handleSubmitEditComment = (newComment: string) => {
    if (newComment !== message?.data?.comment) {
      submitEditComment({ 
        conversationId: selectedConversation?.id as string, 
        messageId: message.id, 
        text: newComment 
      });
    } else {
      setCommentState('default');
    }
  };

  const isDeleted = message.data.commentDeleted;
  const deletePending = deleteCommentStatus === 'loading';
  const editPending = editCommentStatus === 'loading';
  const hasActions = !isDeleted && selectedConversation?.isActive;

  return (
    <Comment.Content style={{ position: 'relative', paddingLeft: 'calc(var(--x-large) + var(--small))', ...useFadeInStyles() }}>

      <div style={{ position: 'absolute', top: 0, left: 0 }}>
        <CommentAvatar message={message} />
      </div>

      <Metadata entry={message} />

      {commentState !== 'editing' && isDeleted && (
        <Comment.Text style={{ fontStyle: 'italic' }}>
          Message deleted
        </Comment.Text>
      )}
      
      {commentState !== 'editing' && !isDeleted && (
        <Comment.Text>
          {commentParts.map((part, i: number) => (
            <Fragment key={i}>
              {part}
              {i < commentParts.length - 1 && <br />}
            </Fragment>
          ))}
        </Comment.Text>
      )}

      {hasActions && commentState === 'default' && hasActions && (
        <S.CommentActions>
          <CommentMessageResponse message={message} />
          {isOwnComment && (
            <>
              <Button
                onClick={() => {
                  setCommentState('editing');
                  setCommentEdit(message);
                }}
              >
                Edit
              </Button>
              <Button onClick={() => setCommentState('deleting')}>
                Delete
              </Button>
            </>
          )}
          <Button onClick={() => initCommentReply(message)}>
            Reply
          </Button>
        </S.CommentActions>
      )}

      {commentState === 'deleting' && (
        <S.CommentActions>
          <Button
            disabled={deletePending} 
            onClick={() => handleSubmitDeleteComment()}
            style={{ color: 'var(--color-red)' }}
          >
            Confirm delete
          </Button>
          <Button
            disabled={deletePending} 
            onClick={() => setCommentState('default')}
          >
            Cancel
          </Button>
        </S.CommentActions>
      )}

      {commentState === 'editing' && (
        <EditComments
          comment={message?.data?.comment}
          onCancel={() => setCommentState('default')}
          onConfirm={handleSubmitEditComment}
          loading={editPending}  
        />
      )}

      
    </Comment.Content>
  );
};

export default CommentEvent;
