import { DropdownProps } from 'semantic-ui-react';
import { CompanyRolesDropdownOptions } from '~/constants/company-roles';
import { useUpdateUserCompanySettings } from '~/mutations/team/useUpdateUserCompanySettings';
import { SyntheticEvent, useState } from 'react';
import { useUserContext } from '~/hooks';
import { useTeamMembersQuery } from '~/requests/team';
import toast from 'react-hot-toast';
import Modal from '~/components/Modal';
import { Button, Table } from '~/components';
import { storage } from '~/utils';
import { logout } from '~/api/auth';
import { usePermissions } from '~/requests/permissions/usePermissions';

const CompanyRoleTableCell = ({ member }: { member: { id: string, companyRoleCode: string, isTeamInvite?: boolean, isUser?: boolean } }) => {
  const { loggedInUser } = useUserContext() as any;
  const { permissions, refetch: refetchPermissions } = usePermissions();
  const [companyRoleCode, setCompanyRoleCode] = useState(member?.companyRoleCode);
  const { mutateAsync: updateUserCompanySettings, status: updateUserCompanySettingsStatus } = useUpdateUserCompanySettings();
  const { data: teamMembers } = useTeamMembersQuery();
  const [desiredSelfRole, setDesiredSelfRole] = useState<null | 'ADMIN' | 'STANDARD_USER'>(null);
  const isSelf = member.id === loggedInUser.id;

  const handleChange = (_e: SyntheticEvent, data: DropdownProps) => {
    // Get the new companyRole code from the dropdown
    const companyRole = data.value as 'ADMIN' | 'STANDARD_USER';

    // Handle various cases...
    if (isSelf && teamMembers?.filter((member: any) => member?.companyRoleCode === 'ADMIN' && member.isUser).length === 1 && companyRole !== 'ADMIN') {
      // Throw an error toast if they are trying to remove themselves as the last admin
      toast.error('You cannot remove your Admin privileges when there are no other Admins in your organization. Please set another user as an Admin and try again.', { duration: 8000 });
    } else if (isSelf) {
      // Show a modal if they are trying to change their own role
      setDesiredSelfRole(companyRole);
    } else {
      // Update the user's company role
      setCompanyRoleCode(companyRole);
      const payload = member.isTeamInvite ? { inviteId: member.id, companyRole } : { userId: member.id, companyRole };
      updateUserCompanySettings(payload).catch(() => setCompanyRoleCode(member?.companyRoleCode));
    }
  };

  return (
    <>
      <Table.CellDropdown
        inline
        direction='left'
        value={companyRoleCode}
        options={CompanyRolesDropdownOptions}
        onChange={handleChange}
        disabled={updateUserCompanySettingsStatus === 'loading'}
        readOnly={!permissions?.isCompanyAdmin}
        textAlign={permissions?.isCompanyAdmin ? 'right' : 'center'}
      />
      {desiredSelfRole && (
        <Modal closeModal={() => setDesiredSelfRole(null)}>
          <Modal.Title>Changing your privileges</Modal.Title>
          <Modal.Content>
            <p>By changing your user role from Admin, you will lose the ability to set default project leads and to change the roles of your colleagues. Additionally, you will be unable to re-assign yourself the Admin role without assistance.</p>
          </Modal.Content>
          <Modal.Footer>
            <Button
              primary
              outlined
              onClick={() => setDesiredSelfRole(null)}
            >
              Cancel
            </Button>
            <Button
              primary
              disabled={updateUserCompanySettingsStatus === 'loading'}
              loading={updateUserCompanySettingsStatus === 'loading'}
              onClick={() => {
                updateUserCompanySettings({ userId: member.id, companyRole: desiredSelfRole })
                  .then(async () => {
                    try {
                      // Refresh  permissions
                      await refetchPermissions();

                      // Close the modal and show a success toast after refetching permissions
                      setCompanyRoleCode(desiredSelfRole);
                      setDesiredSelfRole(null);
                      toast.success('You successfully changed your own role.');
                    } catch (err) {
                      // If refetching permissions fails, log the user out to force them to login again
                      toast.success('You successfully changed your own role. You will now be redirected to login...');
                      setTimeout(() => {
                        logout().finally(() => {
                          storage.clear();
                          window.location.href = '/login';
                        });
                      }, 1500);
                    }
                  })
                  .catch((err: any) => {
                    console.error(err);
                    toast.error('Error occurred changing your own role. Please try again.');
                  });
              }}
            >
              I understand
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default CompanyRoleTableCell;