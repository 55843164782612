import Modal from '~/components/Modal';
import { DataroomDocument } from '~/types/dataroom';
import { useDataroomContext } from '../providers/DataroomProvider';
import { Button } from '~/components';
import { useMutation } from 'react-query';
import { deleteDocumentType } from '~/api/dataroom';
import { withPromiseToaster } from '~/utils';

interface DeleteCustomDocumentTypeModalProps {
  documentType: DataroomDocument;
  closeModal: () => void;
}

export default function DeleteCustomDocumentTypeModal({ documentType, closeModal }: DeleteCustomDocumentTypeModalProps) {
  // Context values
  const { 
    dataroom,
    setEditingCustomDocumentTypes,
    handleRemoveCustomDocumentType,
  } = useDataroomContext();
  
  // Add/Edit API mutation
  const { mutate: confirmDelete, status } = useMutation(
    async () => {
      // Delete the document type
      await withPromiseToaster(
        deleteDocumentType(dataroom.id, documentType.id), {
          messageStub: 'deleting document type'
        });

      handleRemoveCustomDocumentType(documentType as DataroomDocument);
      setEditingCustomDocumentTypes(false);
      
      // Close the modal dialog
      closeModal();
    }
  );

  return (
    <Modal allowClose={status !== 'loading'} closeModal={closeModal}>
      <Modal.Title>
        Remove custom document - {documentType?.name}
      </Modal.Title>
      <Modal.Content>
        <p>
          By clicking the button below, you acknowledge that this custom document type will be removed from
          the data room, and any files currently associated with it will be removed.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary 
          onClick={() => {
            setEditingCustomDocumentTypes(true);
            confirmDelete();
          }} 
          disabled={status === 'loading'}
          loading={status === 'loading'}
        >
          Confirm
        </Button>
        <Button 
          primary 
          outlined 
          onClick={() => closeModal()} 
          disabled={status === 'loading'}
        >
          Cancel
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
}