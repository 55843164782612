import { ProjectStatus } from '~/constants';
import { Project } from '~/types/project';
import { Portfolio } from '~/types/portfolio';

export default function dealSummary(portfolio: Portfolio, projectBlobs: any) {
  const parentProjectBlobs = projectBlobs?.filter((projectBlob: any) => !projectBlob?.project?.parentId);
 
  // Get list of bid amounts for each parentProjectBlob?.projectDisplayValues.dealSummary
  const projectBids = parentProjectBlobs?.map((projectBlob: any) => {
    return {
      projectId: projectBlob?.project?.id,
      name: projectBlob?.project?.name,
      bids: projectBlob?.projectDisplayValues?.dealSummary?.projectBidAmounts,
    };
  });

  const investorProjectMap = portfolio?.projects?.filter((project: Project) => !!project?.parentId)?.reduce((acc: any, project: Project) => {
    if (project?.id && project?.investorId) {
      acc[project.investorId] = acc[project.investorId] || {};
      acc[project.investorId].projects = acc[project.investorId].projects || {};
      acc[project.investorId].projects[project.parentId] = project;
      if (project?.investor?.company?.name) {
        acc[project.investorId].investor = project?.investor;
      }
    }
    return acc;
  }, {});

  if (investorProjectMap) {
    Object.values(investorProjectMap).forEach((row: any) => {
      const projectList = Object.values(row?.projects ?? {});
      if (projectList.length) {
        // Set conversation ended status if all projects are declined
        const declined = projectList.filter((p: any) => p.status === ProjectStatus.BID_REJECTED || p.status === ProjectStatus.DECLINED_TO_BID);
        if (declined?.length === projectList.length) {
          row.conversationEnded = true;
        }
      }
    });
  }

  // Create map of projectIds to investorId to projectBidAmounts
  const projectBidAmountsMap = projectBids?.reduce((acc: any, projectBid: any) => {
    // Convery projectBid.bids to map of investorId to bidAmount
    acc[projectBid.projectId] = projectBid.bids?.reduce((acc: any, bid: any) => {
      acc[bid.investorId] = bid;
      return acc;
    }, {});
    return acc;
  }, {});

  // Create map of investorId to projectId to projectBidAmount
  const investorProjectBidMap = Object.entries(projectBidAmountsMap)?.reduce((acc: any, [projectId, investorBidAmountsMap]: any) => {
    Object.entries(investorBidAmountsMap)?.forEach(([investorId, projectBidAmount]: any) => {
      acc[investorId] = acc[investorId] || {};
      acc[investorId][projectId] = projectBidAmount;
    });
    return acc;
  }, {});

  // Create a Set of investors from portfolio?.projects and then create a sorted array from the Set
  const investors = Array.from(
    new Set(
      portfolio?.projects
        ?.filter((p: Project) => !!p.parentId)
        ?.map((project: Project) => (
          JSON.stringify({
            id: project?.investor?.id,
            name: project?.investor?.company?.name
          }))
        )
      )
    )
    .map((json: any) => JSON.parse(json))
    .sort((a: any, b: any) => a.name.localeCompare(b.name));

  return { investors, projectBids, investorProjectMap, investorProjectBidMap };
}