import styled from 'styled-components';

const Container = styled.div`
  max-width: calc(70.5rem + var(--medium) * 2);
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--medium);
  min-height: 100vh;
`;

export default Container;