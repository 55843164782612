import styled from 'styled-components';
import { Grid, GridProps } from 'semantic-ui-react';

const StyledGrid = styled(Grid)`
  &&& {
    transition: 0.25s opacity ease-in-out;
    padding-top: 2.75rem;
    > .row:first-child {
      padding-bottom: 0;
    }
  }
`;

export default function PageContainerGrid({ children, ...props }: GridProps) {
  return (
    <StyledGrid container {...props}>
      {children}
    </StyledGrid>
  );
}