import React, { useState } from 'react';
import { Label } from 'semantic-ui-react';
import { InvestorBidStatus } from '~/constants';
import { usePromiseWatcher } from '~/hooks';
import Modal from '~/components/Modal';
import { Button } from '~/components';
import Link from '../Link';

interface InvestorButtonOptionsProps {
  label: string;
  queueItem: any;
  updateInvitation: any;
  unassignInvestor: any;
}

const InvestorButtonOptions: React.FC<InvestorButtonOptionsProps> = ({
  label, 
  queueItem, 
  updateInvitation, 
  unassignInvestor
}) => {
  const [modalDataState, setModalDataState] = useState({}) as any;

  const {execute: executeSendInvitation} = usePromiseWatcher(
    () => updateInvitation({
      investorId: queueItem.investor.id,
      status: InvestorBidStatus.INVITED,
    }),
    {messageStub: 'sending bid invitation to investor'},
    []
  );
  const {execute: executeCancelInvitation} = usePromiseWatcher(
    () => updateInvitation({
      investorId: queueItem.investor.id,
      status: InvestorBidStatus.PENDING,
    }),
    {messageStub: 'canceling investor\'s bid invitation'},
    []
  );
  const {execute: executeUnassignInvestor} = usePromiseWatcher(
    () => unassignInvestor({investorId: queueItem.investor.id}),
    {messageStub: `removing investor from ${label}`},
    []
  );

  const SendConfirmationModal = () => (
    <Modal
      closeModal={() => setModalDataState({})}
    >
      <Modal.Title>{modalDataState.title}</Modal.Title>
      <Modal.Content>{modalDataState.message}</Modal.Content>
      <Modal.Footer>
        <Button 
          primary 
          outlined 
          onClick={() => setModalDataState({})}
        >
          Cancel
        </Button>
        <Button
          primary
          onClick={() => {
            setModalDataState({});
            modalDataState.functionToCall();
          }}
          >
            OK
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const InvestorButtonOptionsContent = () => {
    if (
      (queueItem.status === InvestorBidStatus.PENDING || queueItem.status === InvestorBidStatus.DECLINED) &&
      queueItem.responseDueAt === null &&
      queueItem.sentAt === null
    ) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 'var(--2x-small)'}}>
          <Link
            as={Button}
            color='var(--color-red)'
            onClick={() => {
              setModalDataState({
                title: 'Invite investor',
                message: 'Are you sure you want to send an invitation to bid to this investor?',
                functionToCall: executeSendInvitation
              });
            }}
          >
            invite
          </Link>
          <span style={{ color: 'var(--color-red)' }}>|</span>
          <Link
            as={Button}
            color='var(--color-red)'
            onClick={() => {
              setModalDataState({
                title: 'Remove investor from queue',
                message: 'Are you sure you want to remove this investor from the queue?',
                functionToCall: executeUnassignInvestor
              });
            }}
          >
            remove from queue
          </Link>
        </div>
      );
    } else if (queueItem.status === InvestorBidStatus.INVITED && queueItem.responseDueAt !== null) {
      return (
          <div>
            <Link
              as={Button}
              color='var(--color-red)'
              onClick={() => {
                setModalDataState({
                  title: 'Cancel invitation',
                  message: 'Are you sure you want to cancel the invitation to bid for this investor?',
                  functionToCall: executeCancelInvitation
                });
              }}
            >
              recall invitation
            </Link>
          </div>
      );
    } else if (queueItem.status === InvestorBidStatus.ACCEPTED) {
      return (
        <Label 
          color='olive'
          style={{
            margin: '0', 
            marginRight: 'auto', 
            padding: '.25rem .5rem',
            borderRadius: '4px',
          }}
        >
          developer accepted bid
        </Label>
      );
    } else if (queueItem.status === InvestorBidStatus.DECLINED) {
      return (
          <div style={{display: 'flex', alignItems: 'center', gap: 'var(--2x-small)'}}>
            <Label 
              color="red"
              style={{
                margin: '0', 
                marginRight: '1rem', 
                padding: '.25rem .5rem',
                borderRadius: '4px'
              }}
            >
                conversation ended
            </Label>
            <Link
              as={Button}
              color='var(--color-red)'
              onClick={() => {
                setModalDataState({
                  title: 'Re-invite investor',
                  message: 'Are you sure you want to resend an invitation to bid to this investor?',
                  functionToCall: executeSendInvitation
                });
              }}
            >
              re-invite
            </Link>
            <span className="investor-option-divider">|</span>
            <Link
              as={Button}
              color='var(--color-red)'
              onClick={() => {
                setModalDataState({
                  title: 'Remove investor from queue',
                  message: 'Are you sure you want to remove this investor from the queue?',
                  functionToCall: executeUnassignInvestor
                });
              }}
            >
              remove from queue
            </Link>
          </div>
          
      );
    } else {
      return <div/>;
    }
  };

  return (
    <>
      {modalDataState.title && modalDataState.message && <SendConfirmationModal/>}
      <InvestorButtonOptionsContent/>
    </>
  );
};

export default InvestorButtonOptions;
