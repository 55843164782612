import { fetchProjects } from '~/api/projects';
import { useQuery } from 'react-query';
import { Project } from '~/types/project';

export const getListProjectsKey = () => {
  return 'projects/list';
};

interface ProjectListParams {
  showArchived?: boolean;
}

export const useProjectList = (params: ProjectListParams = {}, queryParams = {}) => {
  if (params?.showArchived === undefined) {
    // By default, allow archived projects to be fetched
    params = params ?? {};
    params.showArchived = true;
  }

  return useQuery({
    queryKey: getListProjectsKey(),
    queryFn() {
      // Query for projects, automaticall convert updatedAt and createdAt to Date objects
      return fetchProjects(params).then((projects: any) => {
        return projects.map((project: Project) => ({
          ...project,
          updatedAt: project.updatedAt ? new Date(project.updatedAt) : null,
          createdAt: project.createdAt ? new Date(project.createdAt) : null
        }));
      });
    },

    // Turn of cache... for now
    staleTime: 0,

    // No retries
    retry: false,

    // Don't automatically fetch the file.
    // This is meant to be called as-needed in response to a
    // button click via the "download" prop returned by this hook.
    enabled: true,

    // Turn off refetch on window focus
    refetchOnWindowFocus: false,

    // Allow overriding default react-query props
    ...queryParams
  });
};