import styled from 'styled-components';
import { Button } from '~/components';

export const CommentResponseButton = styled(Button)`
  &&& {
    > * {
      margin: 0;
    }
    > div.reply-checkmark {
      display: inline-flex;
      width: 1rem;
      height: 1rem;
      padding: 0;
      margin: 0;
      border-radius: 100%;
      background: var(--color-green);
      justify-content: center;
      align-items: center;
      font-size: 0.6rem;
      padding-left: 1px;
      > i.icon {
        margin: 0;
        color: var(--color-pure-white);
      }
    }

    > svg,
    > div.reply-checkmark {
      margin-right: var(--2x-small);
    }
    
  }
`;