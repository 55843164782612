/*
    NOTE: It's important that we keep these in RGB hex format -- the Excel export uses these variables, and expects
    strings in #RRGGBB format.
 */

const colors = {

  orange: '#CC5500',
  lightOrange: '#F5DDCC',
  midnightBlue: '#011B2A',
  blue: '#03598C',
  lightBlue: '#CDDEE8',
  red: '#CC0000',
  green: '#59B755',

  pureBlack: '#000000',
  darkGray: '#2E2E2E',
  charcoal: '#5A5A5A',
  gray: '#E4E4E4',
  offWhite: '#F7F7F7',
  pureWhite: '#FFFFFF',

};

export default colors;
