import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 1.5rem;
  > * {
    width: 100%;
  }
  @media(min-width: 1061px) {
    .field {
      margin-bottom: 0;
    }
  }
  @media(max-width: 1060px) {
    flex-direction: column;
    gap: 0;
    > * + * {
      margin-top: var(--medium);
    }
  }
  > * {
    margin: 0 !important;
    width: 100%;
  }
  & + & {
    margin-top: var(--medium);
  }
  .yearOneProduction {
    .ui.input {
      max-width: calc(100% - 8rem);
      > input { 
        width: 9.5rem !important;
      }
    }
    .radio-container .inline.fields {
      flex-direction: column;
      position: absolute;
      bottom: -1.5rem;
      align-items: flex-start;
      gap: .5rem;
      left: -0.5rem;
    }
    label {
      white-space: nowrap;
    }
  }
  .labelled-input {
    input { width: 0!important; }
  }
`;