import { Project } from '~/types/project';
import { usePermissions } from '~/requests/permissions/usePermissions';
import ProductionEstimateButton from '~/components/ProductionEstimateButton';
import { useProjectContext } from '~/hooks';

export default function ProductionValidationEstimateButton({ project, values }: { project: Project, values: any })  {
  const { permissions } = usePermissions();
  const { projectErrors } = useProjectContext();

  return (
    <div 
      style={{ 
        display: 'flex', 
        justifyContent: permissions.hasProjectDeveloperAccess ? 'space-between' : 'right',       
        marginTop: 'var(--x-small)' 
      }}
    >       
    {permissions.hasProjectDeveloperAccess ? <ProductionEstimateButton project={project} values={values} errors={projectErrors} /> : null}
    <p style={{ color: 'var(--color-charcoal)', fontStyle: 'italic', fontWeight: '700' }}>Note: All production units are in kWh/kWdc</p> 
  </div>
  );
}