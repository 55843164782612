import { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { Button, Modal } from '~/components';

export default function AdminNotesModal({ 
  company, 
  onSave, 
  closeModal 
}: { 
  company: any, 
  onSave: (notes: string) => void,
  closeModal: () => void
}) {
  const [notes, setNotes] = useState<string>(company.notes ?? '');
  const [saving, setSaving] = useState<boolean>(false);
  
  return (
    <Modal closeModal={() => {
      if (!saving) {
        closeModal();
      }
    }}>
      <Modal.Title>
        Customer Notes — {company.companyName}
      </Modal.Title>
      <Modal.Content>
        <Form>
          <Form.TextArea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder='Add notes...'
            autoFocus
          />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Button primary outlined onClick={closeModal} disabled={saving}>
          Cancel
        </Button>
        <Button 
          primary 
          disabled={saving}
          loading={saving}
          onClick={async () => {
            setSaving(true);
            try {
              await onSave(notes);
            } finally {
              setSaving(false);
            }
          }}
        >
          Save note
        </Button>
      </Modal.Footer>
    </Modal>
  );
}