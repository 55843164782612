import { useDropzone } from 'react-dropzone';
import { useMessagingContext } from '../providers/MessagingProvider';
import MessagingHistory from './MessageHistory';
import NewCommentForm from './NewComment';
import MessagingHeader from './MessagingHeader';
import { useEffect, useRef, useState } from 'react';
import useHeightCalculation from '~/hooks/useHeightCalculation';
import { usePageHeaderContext } from '~/components/PageHeader/PageHeaderProvider';
import toast from 'react-hot-toast';
import { useUploadFileMessageMutation } from '~/mutations/conversations/useUploadFileMessageMutation';
import { useConversationContext } from '../providers/ConversationProvider';
import { Loader } from 'semantic-ui-react';

const MessageCanvas = function () {
  const scrollableBodyRef = useRef(null);
  const { conversations, conversationsStatus, selectedConversation } = useConversationContext();
  const { 
    addNewMessage, 
    isLoading, 
    hasError,
    commentEdit,
    scrollableBodyDomId,
    messageList
  } = useMessagingContext();
  const uploadFileMessage = useUploadFileMessageMutation();

  const pageHeaderContext = usePageHeaderContext();

  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  useHeightCalculation(headerRef, setHeaderHeight);

  const newCommentRef = useRef(null);
  const [newCommentHeight, setNewCommentHeight] = useState(0);
  useHeightCalculation(newCommentRef, setNewCommentHeight);

  useEffect(() => {
    // Scroll to bottom of scrollable body
    const scrollableBody = scrollableBodyRef.current as any;
    if (scrollableBody) {
      scrollableBody.scrollTop = scrollableBody.scrollHeight;
    }
  }, [messageList?.length]);

  useEffect(() => {
    if (commentEdit && messageList?.length && messageList?.findIndex((entry) => entry.id === commentEdit.id) === messageList?.length - 1) {
      // Scroll to bottom of scrollable body if the comment being edited is the last comment
      const scrollableBody = scrollableBodyRef.current as any;
      if (scrollableBody) {
        scrollableBody.scrollTop = scrollableBody.scrollHeight;
      }
    }
  }, [commentEdit]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({   
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    async onDrop(acceptedFiles) {
      if (acceptedFiles?.length) {
        const message = await uploadFileMessage.mutateAsync({
          file: acceptedFiles?.[0],
          conversationId: selectedConversation?.id as string
        });
        addNewMessage(message);
      } else {
        toast.error('Only one file can be uploaded at a time.');
      }
    }
  });

  if (hasError) {
    return null;
  }

  return (
    <div 
      {...(selectedConversation?.isActive ? getRootProps() : {})}
      style={{
        maxWidth: '46.4375rem', 
        width: '100%',
        margin: conversations?.length <= 1 ? '0 auto' : '0 0 0 auto'
      }}
    >
      {conversationsStatus === 'loading' && (
        <div style={{ 
          position: 'absolute',
          height: '100%',
          width: '100%',
          inset: 0,
          zIndex: 1,
          background: 'var(--color-off-white)'
        }}>
          <Loader active size="massive" />
        </div>
      )}
      <div ref={headerRef}>
        <MessagingHeader />
      </div>
      <div 
        ref={scrollableBodyRef}
        id={scrollableBodyDomId}
        style={{
          height: `calc(100vh - var(--large) * 2 - 1rem - ${(pageHeaderContext.height ?? 0) / 16}rem - ${(headerHeight ?? 0) / 16}rem - ${(newCommentHeight ?? 0) / 16}rem)`,
          overflowY: 'auto',
          overflowX: 'hidden',
          WebkitOverflowScrolling: 'touch',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={!(!isLoading && !messageList?.length) ? { marginTop: 'auto' } : {}}>
          <MessagingHistory />
        </div>
      </div>
      <div ref={newCommentRef}>
        <NewCommentForm getInputProps={getInputProps} fileDragging={isDragActive} fileUploading={uploadFileMessage.status === 'loading'} />
      </div>
    </div>
  );
};

export default MessageCanvas;