import React, { useState } from 'react';
import { Button } from '~/components';
import { Form } from 'semantic-ui-react';
import Modal from '~/components/Modal';
import { usePortfolioContext } from '~/hooks';
import { useUpdatePortfolio } from '~/mutations/portfolio/useUpdatePortfolio';
import PortfolioTheme from '~/components/PortfolioTheme';
import toast from 'react-hot-toast';

export default function UpdatePortfolioNameModal({ closeModal }: { closeModal: () => void }) {
  const { portfolio, fetchPortfolio } = usePortfolioContext();
  const [portfolioName, setPortfolioName] = useState(portfolio?.name ?? '');
  const [portfolioDesc, setPortfolioDesc] = useState(portfolio?.description ?? '');
  const { mutate, status } = useUpdatePortfolio();
  const hasNameError = !portfolioName;
  const hasDescError = !portfolioDesc;
  const hasError = hasNameError || hasDescError;

  const handleSubmit = async () => {
    if (!hasError) {
     try { 
      await mutate({
        id: portfolio.id, 
        data: {
          name: portfolioName,
          description: portfolioDesc
        },
        refetchFunction: fetchPortfolio
      }, {
        onSuccess: closeModal
      });
    } catch (error) {
      toast.error('Error occurred updating the portfolio. Please refresh the page and try again.', { duration: 4000 });
    }
  };
  };
  
  return (
    <Modal closeModal={closeModal}>
      <PortfolioTheme active>
        <Modal.Title>
          Edit portfolio
        </Modal.Title>
        <Modal.Content>
          <Form onSubmit={handleSubmit}>
            <Form.Input
              label="Portfolio name"
              placeholder="Enter the portfolio name"
              value={portfolioName}
              onChange={(_e, { value }) => setPortfolioName(value)}
              error={hasNameError}
            />
            <Form.TextArea
              label="Portfolio description"
              placeholder="Enter the portfolio description"
              value={portfolioDesc}
              onChange={(_e, { value }) => setPortfolioDesc(value)}
              error={hasDescError}
            />
          </Form>
        </Modal.Content>
        <Modal.Footer>
          <Button outlined primary onClick={closeModal}>Cancel</Button>
          <Button primary disabled={hasError || status === 'loading'} loading={status === 'loading'} onClick={handleSubmit}>Save changes</Button>
        </Modal.Footer>
      </PortfolioTheme>
    </Modal>
  );
}