import { sumBy } from 'lodash';
import { ProductionUnit } from '~/constants';
import { display } from '../common';
import { formatNumber } from '~/utils/formatNumber';
import { Project } from '~/types/project';

export default function yearOneProduction(project: Project) {
  if (!project?.projectArrays) {
    return display(null);
  }
  const projectArrays = Object.values(project.projectArrays);
  const production =
    sumBy(
      projectArrays.filter((mount: any) => mount.yearOneProductionUnit === ProductionUnit.KWH),
      (mount: any) => Number(mount.yearOneProduction),
    ) ?? 0;
  const productionkW =
    sumBy(
      projectArrays.filter((mount: any) => mount.yearOneProductionUnit === ProductionUnit.KWHKW),
      (mount: any) => {
        return Number(mount.yearOneProduction) * Number(mount.sizeKwdc);
      },
    ) ?? 0;
  const sizeKwdc = project?.projectArrays ? sumBy(Object.values(project?.projectArrays), (mount: any) => Number(mount.sizeKwdc)) : 0;
  const prod = Number(production) + Number(productionkW);
  
  return display(formatNumber(prod / sizeKwdc, 0, { default0: '' }));
}