import styled from 'styled-components';

export const DealSummaryContainer = styled.div`
  margin-top: var(--x-large);
`;

export const SummaryTableContainer = styled.div`
  margin-top: var(--x-large);
  + ${DealSummaryContainer} {
    margin-top: 0;
  }
`;

export const SubmissionFormContainer = styled.div<{ readyForSubmission: boolean }>`
  margin-top: var(--large);
  padding-bottom: 0.625rem;
  ${props => props.readyForSubmission && `
    max-width: 40.1875rem;
  `}
`;

export const ProjectContainer = styled.div`
  margin-top: var(--x-large);
  > * {
    margin-bottom: 0;
  }
  & + & {
    margin-top: calc(var(--x-large) + 0.375rem);
  }
`;