import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

interface QueryParamStateOptions {
  historyBehavior: 'push' | 'replace'
}

/**
 * Hook that allows easy saving of small json data in query params as a base64 string
 */
export const useQueryParamState = function(paramName: string, options?: QueryParamStateOptions) {
  const historyBehavior = options?.historyBehavior || 'replace';

  const { search, pathname } = useLocation();
  const history = useHistory();
  let paramDataString = queryString.parse(search)?.[paramName] as string;
  let data: any = null;
  if (paramDataString) {
    try {
      paramDataString = paramDataString ? atob(decodeURIComponent(paramDataString)) : '';
      data = JSON.parse(paramDataString);
    } catch (err) {
      console.error(err);
      data = null;
      const params = queryString.parse(search);
      delete params[paramName];
      history[historyBehavior](pathname + '?' + queryString.stringify(params));
    }
  }

  const updateData = (newData: any) => {
    const newDataString = encodeURIComponent(btoa(JSON.stringify(newData)));
    const params = queryString.parse(search);
    params[paramName] = newDataString;
    history[historyBehavior](pathname + '?' + queryString.stringify(params));
  };

  return [data, updateData];
};