import { Dropdown, Loader } from 'semantic-ui-react';
import React, { useState } from 'react';
import CopyProjectDocumentTypesModal from '../../modals/CopyProjectDocumentTypesModal';
import { Project } from '~/types/project';

interface CopyProjectDropdownProps {
  projectsLength: number;
  projectListStatus: string;
  copyProjectList: any;
}

const CopyProjectDropdown: React.FC<CopyProjectDropdownProps> = ({
  projectsLength,
  projectListStatus,
  copyProjectList
}) => {
  const [projectToCopy, setProjectToCopy] = useState<any>(null);
  
  return (
    <>
      <Dropdown 
        pointing 
        scrolling={projectsLength > 12} 
        direction="left" 
        text="Reuse doc list from other project"
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'var(--color-primary)',
          fontWeight: 'bold',
          fontSize: '0.875rem'
        }}
      >
      <Dropdown.Menu>
        {projectListStatus === 'loading' && (
          <div style={{ height: 'var(--2x-large)' }}>
            <Loader active />
          </div> 
        )}
        {projectListStatus === 'error' && (
          <p style={{ padding: 'var(--small)'}}>
            An error occurred fetching projects. Please refresh the page and try again.
          </p>
        )}
        {projectListStatus === 'success' && copyProjectList?.length && copyProjectList.map((project: Project) => (
          <Dropdown.Item key={project.id} onClick={() => setProjectToCopy(project)}>
            {project.name}
          </Dropdown.Item>
        ))}
        {projectListStatus === 'success' && !copyProjectList?.length && (
          <p style={{ padding: 'var(--small)'}}>
            There are no other projects to copy from.
          </p>
        )}
      </Dropdown.Menu>
    </Dropdown>
    {projectToCopy && <CopyProjectDocumentTypesModal projectToCopy={projectToCopy} closeModal={() => setProjectToCopy(null)} />}
  </>
  );
};

export default CopyProjectDropdown;