export const InvestorBidRate = {
  DOLLARS_PER_WDC: 'DOLLARS_PER_WDC',
  PERCENT_OF_BUDGET: 'PERCENT_OF_BUDGET',
};

export const InvestorBidStatus = {
  PENDING: 'PENDING',
  INVITED: 'INVITED',
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED' //not in use?
};