import { CompanyLogo } from '~/components';
import { UserRole } from '~/constants';
import { Company } from '~/types/company';
import { CommentMessage, FileMessage } from '~/types/conversations/Message';
import { ProjectEvent } from '../types';

const SIZE_CONFIG = {
  normal: {
    avatarSize: '2.75rem',
    fontSize: '1.125rem',
    borderWidth: 2 // px
  },
  small: {
    avatarSize: '1.5rem',
    fontSize: '0.75rem',
    borderWidth: 1 // px
  }
};

export default function CommentAvatar({ message, size = 'normal' }: { message: CommentMessage | FileMessage | ProjectEvent, size?: 'normal' | 'small' }) {
  const { avatarSize, fontSize, borderWidth } = SIZE_CONFIG[size];

  const company = message?.user?.company as Company;
  const isConductorLogo = message?.user?.role?.code === UserRole.ADMIN;

  if (!company && !isConductorLogo) return null;

  const { name, logoS3Url } = (company ?? {});
  const [firstInitial, secondInitial] = name?.split(' ')?.map(name => name[0]) ?? [];
  const hasLogoImage = logoS3Url?.startsWith('http') || isConductorLogo;

  return (
    <div style={{
      borderRadius: '50%',
      color: 'var(--color-pure-white)',
      backgroundColor: 'var(--color-pure-white)',
      width: avatarSize,
      height: avatarSize,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: `calc(${avatarSize} - ${borderWidth * 2}px)`,
        width: `calc(${avatarSize} - ${borderWidth * 2}px)`,
        lineHeight: `calc(${avatarSize} - ${borderWidth * 2}px)`,
        fontSize,
        fontWeight: 500,
        textTransform: 'uppercase',
        borderRadius: '50%',
        letterSpacing: '-1px',
        overflow: 'hidden',
        backgroundColor: hasLogoImage ? 'none' : 'var(--color-charcoal)'
      }}>
        {hasLogoImage
          ? <CompanyLogo src={logoS3Url} isConductorLogo={isConductorLogo} size={`calc(${avatarSize} - ${borderWidth * 2}px)`} useBackgroundImage />
          : (firstInitial + (secondInitial ? ' ' + secondInitial : ''))}
      </div>
    </div>
  );
}