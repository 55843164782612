import { Header } from 'semantic-ui-react';
import UserInfoForm from './UserInfoForm';
import NavigationTabs from '~/components/PageHeader/NavigationTabs';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container } from '~/components';
import NotificationSettings from './NotificationSettings';
import CompanySettings from './CompanySettings';
import { usePermissions } from '~/requests/permissions/usePermissions';
import SettingsPageLoader from './SettingsPageLoader';
import { useUserContext } from '~/hooks';

export default function UserSettings() {
  const { path } = useRouteMatch();
  const { isLoading } = usePermissions();
  const { loggedInUser } = useUserContext() as any;

  return (
    <Container style={{ paddingTop: 'var(--x-large)'}}>
      <Header as="h1" style={{ margin: '0' }}>Settings</Header>
      <div style={{ padding: 'var(--x-small) 0'}}>
        <NavigationTabs 
          tabs={[{ 
            path: `${path}`,
            content: 'General settings'
          },
          {
            path: `${path}/company`,
            content: 'Company settings'
          },
          !loggedInUser.isAdmin && {
            path: `${path}/notifications`,
            content: 'Notification settings'
          },
        ]} 
        />
      </div>
      <div style={{ paddingTop: 'var(--small)', position: 'relative' }}>
        {isLoading ? <SettingsPageLoader /> : (
          <Switch>
            <Route exact path={path}>
              <UserInfoForm />
            </Route>
            <Route path={`${path}/company`}>
              <CompanySettings />
            </Route>
            <Route path={`${path}/notifications`}>
              <NotificationSettings />
            </Route>
          </Switch>
        )}
      </div>
    </Container>
  );
}