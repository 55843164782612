import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import useProjectTeamApiEndpoints from '~/hooks/useProjectTeamQueryApi';

export const useUpdateProjectTeamMemberMutation = () => {
  const { updateTeamMember } = useProjectTeamApiEndpoints();

  return useMutation(async (params: { memberId: string; role?: 'LEAD' | 'MEMBER'; isActive?: boolean }) => {
    return updateTeamMember({ ...params }).catch(err => {
      console.error(err);
      toast.error('Failed to update team member. Please try again.');
      throw err;
    });
  }
);};