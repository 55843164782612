import { getInvestorPortfolioDataroomAccess, getInvestorProjectDataroomAccess } from 'api/dataroom';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { usePageType } from '~/hooks';

export const getInvestorProjectDataroomAccessKey = (pageType: string, id: string) => {
  return `${pageType}/${id}/dataroom/permissions/investorAccess`;
};

export const useInvestorDataroomAccess = (queryParams = {}) => {
  const pageType = usePageType();
  const { id } = useParams<{ id: string }>();

  return useQuery({
    queryKey: getInvestorProjectDataroomAccessKey(pageType, id),
    queryFn() {
      if (pageType === 'portfolio') {
        return getInvestorPortfolioDataroomAccess(id);
      } else if (pageType === 'project') {
        return getInvestorProjectDataroomAccess(id);
      } else {
        return null;
      }
    },
    staleTime: Infinity, // Cache until invalidated
    retry: false,
    ...queryParams
  });
};