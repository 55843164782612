import { RecAllocationRadioOptions, UnitDecimals } from '~/constants';
import { WizardField } from '~/features/projectWizard/components/Field';
import { useProjectContext } from '~/hooks';
import { InputType } from '~/schema';

export default function NewJerseyRECForm() {
  const { project } = useProjectContext();
  const schemaKey = (term: string) => `recsData.${project.addressState}.${term}`;

  return (
    <>
      <WizardField
        type="radio"
        fieldLabel="Have you secured a REC allocation?"
        schemaKey={schemaKey('recAllocation')}
        values={RecAllocationRadioOptions}
      />
      <WizardField.Row>
        <WizardField
          type="number"
          fieldLabel="REC Value ($/MWh)"
          schemaKey={schemaKey('recValue')}
          numberType={InputType.DOLLARS}
          decimals={UnitDecimals.DOLLARS_PER_MWH}
        />
        <WizardField
          type="number"
          fieldLabel="Contract term"
          schemaKey={schemaKey('recContractTerm')}
          placeholder='Enter length of contract term'
          label='years'
        />
      </WizardField.Row>
    </>
  );
}