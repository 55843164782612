import { ReactNode, useEffect, useRef, useState } from 'react';
import NavigationTabs, { NavigationTabConfig } from './NavigationTabs';
import * as S from './styles';
import useHeightCalculation from '~/hooks/useHeightCalculation';
import useScrollState from '~/hooks/useScrollState';
import EditableName from './EditableName';
import { usePageHeaderContext } from './PageHeaderProvider';

interface PageHeaderProps {
  portfolio?: any
  project?: any
  navTabs: NavigationTabConfig[]
  content?: ReactNode
  navContent?: ReactNode
}

export default function PageHeader({
  project,
  portfolio,
  content,
  navTabs,
  navContent
}: PageHeaderProps) {
  const { setHeight, setHasScrollState } = usePageHeaderContext();

  // Determine type.
  // 1. If portfolio prop is supplied, 'portfolio' is the type.
  // 2. If project prop is supplied and it is not within a portfolio, 'project' is the type.
  // 3. If project prop is supplied and it is within a portfolio, 'portfolioProject' is the type.
  const type = portfolio ? 'portfolio' : project?.portfolioId ? 'portfolioProject' : 'project';

  // Track header height
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  useHeightCalculation(headerRef, setHeaderHeight);

  // Track the table height
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  useHeightCalculation(contentRef, setContentHeight);

  // Track window scroll position
  const { scrollTop } = useScrollState();
  const shrinkHeader = Number(scrollTop) >= headerHeight + (contentHeight ?? 0) + 40;

  // Track nav height
  const navRef = useRef(null);
  const [navHeight, setNavHeight] = useState(0);
  useHeightCalculation(navRef, setNavHeight);

  useEffect(() => {
    setHeight(headerHeight + navHeight);
    setHasScrollState(shrinkHeader);
  }, [headerHeight, navHeight, shrinkHeader]);

  return (
    <>
      <S.HeaderContainer ref={headerRef} shrinkHeader={shrinkHeader}>
        <EditableName type={type} />
      </S.HeaderContainer>
      {content && <div ref={contentRef}>{content}</div>}
      <div ref={navRef} style={{
        position: 'sticky', 
        top: `${headerHeight/16}rem`, 
        background: 'var(--color-off-white)', 
        zIndex: 1
      }}>
        <NavigationTabs tabs={navTabs} />
        {navContent && (
          <S.NavigationContent>
            {navContent}
          </S.NavigationContent>
        )}
      </div>
    </>
  );
}
