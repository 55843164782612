import styled from 'styled-components';

export const DealSummaryContainer = styled.div`
  > h2 {
    margin: 0;
    + p {
      margin: 0.75rem 0;
    }
  }
`;

export const DealSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  .ui.header {
    margin: 0;
    + * {
      margin-left: var(--large);
    }
  }
`;

export const DealSummaryTable = styled.div<{ bidSummary: any }>`
  margin: var(--x-large) 0;
  display: grid;
  ${props => props.bidSummary.length === 1 ? 'grid-template-columns: 7.5rem 21rem;' : `grid-template-columns: 7.5rem repeat(${props.bidSummary.length}, 1fr);`}
  > div, > label {
    padding: 0 var(--small);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 3rem;
    border-bottom: 1px solid var(--color-grey);
    border-left: 1px solid var(--color-grey);
    &:last-child {
      border-right: 1px solid var(--color-grey);
    }
    > strong {
      font-size: 0.75rem;
      line-height: 1rem;
      + div {
        font-size: 0.6875rem;
        line-height: 1rem;
      }
    }
  }
  > label:hover {
    cursor: pointer;
  }
  + p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--color-charcoal);
    a {
      color: var(--color-primary);
    }
  }
`;

export const DealSummaryInvestor = styled.div`
  &&& {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: bold;
    text-decoration: underline;
    color: var(--color-pure-black);
    border-left: 0;
    border-right: 0;
  }
`;

export const DealSummaryComments = styled.div`
  &&& {
    border: none;
    height: 3.5rem;
    font-size: 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
  }
`;