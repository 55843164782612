const buildFauxStorage = () => {
  return {
    // this is a faux Storage object -- we have to skip the 6 "built-in" properties in a lot of the code below

    get length() {
      return Object.keys(this).length - 6;
    },

    clear() {
      for (let key of [...Object.keys(this).slice(6)]) {
        delete (this as any)[key];
      }
    },

    getItem(key: any) {
      if (Object.keys(this).indexOf(key, 6) === -1) {
        return null;
      }
      return (this as any)[key];
    },

    key(index: any) {
      const keys = Object.keys(this);
      if (index + 6 > keys.length) {
        return null;
      }
      return keys[index + 6];
    },

    removeItem(key: any) {
      if (Object.keys(this).indexOf(key, 6) > -1) {
        delete (this as any)[key];
      }
    },

    setItem(key: any, value: any) {
      if (Object.keys(this).slice(0, 6).indexOf(key) > -1) {
        return;
      }
      (this as any)[key] = String(value);
    },
  };
};

const testStorage = (storage: any) => {
  try {
    const key = 'something something dark side';
    storage.setItem(key, 'something something complete');
    storage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};


const secondaryStorage = testStorage(sessionStorage) ? sessionStorage : buildFauxStorage();
const primaryStorage = testStorage(localStorage) ? localStorage : secondaryStorage;

const queryString = window.location.search?.startsWith('?') ? window.location.search.substring(1) : window.location.search;
const params = URLSearchParams ? new URLSearchParams(queryString) as any : null;
if (params?.get('spoof')) {
  secondaryStorage.setItem('spoofed_email', params?.get('spoof'));
  params?.delete('spoof');
  const queryString = params.toString();
  window.history.replaceState(window.history.state, '', window.location.href.split('?')[0] + (queryString ? `?${queryString}` : ''));
}
const spoofedEmail = secondaryStorage.getItem('spoofed_email');
const currentStorage = spoofedEmail ? secondaryStorage : primaryStorage;


const getItem = (storageKey: any) => {
  const str = currentStorage.getItem(storageKey) as string;
  if (!str) {
    return undefined;
  }
  return JSON.parse(str);
};

const setItem = (storageKey: any, value: any) => {
  currentStorage.setItem(storageKey, JSON.stringify(value));
};


export const getStoredCurrentUser = () => {
  return getItem('logged_in_user');
};

export const storeCurrentUser = (user: any) => {
  return setItem('logged_in_user', user);
};

export const clear = () => {
  currentStorage.clear();
};

export const getSpoofedEmail = () => {
  return secondaryStorage.getItem('spoofed_email');
};
