import { Dropdown } from 'semantic-ui-react';

const NoAutocompleteDropdown = ({children, onFocus, ...props}: any) => {
  const handleFocus = (e: any) => {
    e.target.setAttribute('autocomplete', 'nope');
    if (onFocus) {
      return onFocus(e);
    }
  };

  return (
    <Dropdown
      onFocus={handleFocus}
      {...props}
    >
      {children}
    </Dropdown>
  );
};

export default NoAutocompleteDropdown;
