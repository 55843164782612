import { TutorialCodes } from '~/constants';
import TutorialModal from '../components/TutorialModal';
import { useModalContext } from '~/hooks/providers/ModalProvider';

export default function DevEarlyDataroomWelcome() {
  const { modalProps } = useModalContext();

  return (
    <TutorialModal
      title='Setting up your documents'
      content={
        <>
          <p>
            Welcome to the documents area for <strong>{modalProps.project.name}</strong>. Here you can organize documents to share with investors. 
          </p>
          <p>
            Edit the doc list to choose which docs to share. Upload files by selecting them or dragging and dropping them onto the screen.
          </p>
          <p>
            This is entirely optional, and you can control which investors have access to these files.
          </p>
        </>
      }
      hasCancelButton={false}
      submitButtonText='OK'
      tutorialCode={TutorialCodes.EARLY_DATAROOM_LANDING_WELCOME}
    />  
  );
};  