import { RecStates } from '~/constants';
import NewJerseyRECForm from './NewJerseyRECForm';
import IllinoisRECForm from './IllinoisRECForm';
import { useProjectContext } from '~/hooks';

const RECForms = {
  [RecStates.NEW_JERSEY]: NewJerseyRECForm,
  [RecStates.ILLINOIS]: IllinoisRECForm,
};

export default function StateSpecificRECForm() {
  const { project } = useProjectContext();

  const RECForm = RECForms[project?.addressState as keyof typeof RECForms];

  if (RECForm && project?.recOwnership === 'INVESTOR') {
    return <RECForm />;
  } else {
    return null;
  }
}