import { formatRelative } from 'date-fns';
import React from 'react';
import { Comment } from 'semantic-ui-react';
import styled from 'styled-components';
import { useUserContext } from '~/hooks';
import { ProjectEvent } from '../types';
import { Message } from '~/types/conversations/Message';

const UppercaseDate = styled.div`
  color: var(--color-charcoal);
  &::first-letter {
    text-transform: capitalize;
  }
`;

const CommentAuthor = styled(Comment.Author)<{ active?: boolean }>`
  &&& {
    ${props => !props.active && `
      color: var(--color-charcoal);
    `}
    ${props => props.active && `
      color: var(--color-pure-black);
      font-weight: bold;
    `}
  }  
`;

const Metadata: React.FC<{ entry: Message | ProjectEvent }> = ({ entry }) => {
  const { loggedInUser } = useUserContext() as any;
  const data = (entry as ProjectEvent)?.eventData ?? (entry as Message)?.data;
  const messageData = (entry as Message)?.data;
  if (!data) return null;

  const start = entry?.createdAt as string;
  const editTimestamp = messageData?.editedAt ?? entry?.updatedAt as string;
  const isDeleted = messageData?.commentDeleted;
  const hasEdit = messageData?.updatedById;
  
  const startDate = new Date(start);
  const editedDate = new Date(editTimestamp);
  const endDate = new Date();

  const formattedStartDate = formatRelative(startDate, endDate);
  const formattedEditedDate = formatRelative(editedDate, endDate);
 
  const isLoggedInUser = loggedInUser?.id === entry?.user?.id;

  return (
    <>
      <CommentAuthor active={entry?.user?.active} as='span'>{isLoggedInUser ? 'Me' : entry?.user?.name}</CommentAuthor>
      <Comment.Metadata>
        <UppercaseDate>
          {isDeleted ? `${formattedStartDate} / Deleted ${formattedEditedDate}` 
          : hasEdit ? `${formattedStartDate} / Edited ${formattedEditedDate}`
          : formattedStartDate}
        </UppercaseDate>
      </Comment.Metadata>
    </>
  );
};

export default Metadata;