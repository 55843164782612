import React from 'react';
import * as S from './styles';
import { usePortfolioContext } from '~/hooks';
import InvestorRow from './InvestorRow';
import { DealStates } from '~/constants';
import DataroomAccess from './DataroomAccess';
import ProjectBids from './ProjectBids';
import Actions from './Actions';

const DealSummaryTable: React.FC<{}> = () => {
  const { portfolioDisplayValues, portfolioState } = usePortfolioContext();

  return (
    <S.DealSummaryTable dealSummary={portfolioDisplayValues.dealSummary}>
      <InvestorRow />
      {portfolioState?.latestState < DealStates.AWAITING_DATAROOM && (
        <DataroomAccess />
      )}
      <ProjectBids />
      <Actions />
    </S.DealSummaryTable>
  );
};

export default DealSummaryTable;