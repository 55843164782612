

function nullifyEmptyStrings(data: any) {
  for (let key in data) {
    if (data[key] === '') data[key] = null;
  }
  return data;
}

export default nullifyEmptyStrings;
