import { LongTermOwner, Unit, UnitDecimals } from '~/constants';
import { WizardField } from '../../../Field';
import { InputType } from '~/schema';
import { useProjectContext } from '~/hooks';

export default function BudgetCosts() {
  const { project } = useProjectContext();

  return (
    <>
      {/* TODO figure out how to use conditional engine for this */}
      {project?.longTermOwner !== LongTermOwner.END_USER && (
        <>
          <WizardField
            fieldLabel="Interconnection upgrade costs"
            type="number"
            schemaKey="budgetCosts.INTERCONNECTION.amount"
            placeholder="Enter Value"
            numberType={InputType.DOLLARS}
            unitField="unit"
            units={[
              {value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC},
              {value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL},
            ]}
          />
        </>
      )}
      <WizardField
        fieldLabel="Other ITC-eligible costs"
        type="number"
        schemaKey="budgetCosts.OTHER_ITC.amount"
        placeholder="Enter Value"
        numberType={InputType.DOLLARS}
        unitField="unit"
        units={[
          {value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC},
          {value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL},
        ]}
      />
      <WizardField
        fieldLabel="Other non-ITC eligible costs"
        type="number"
        schemaKey="budgetCosts.OTHER_NON_ITC.amount"
        placeholder="Enter Value"
        numberType={InputType.DOLLARS}
        unitField="unit"
        units={[
          {value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC},
          {value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL},
        ]}
      />
      <WizardField 
        fieldLabel="Budget items - Notes"
        type="textarea"
        schemaKey="budgetCosts.OTHER_NON_ITC.notes"
        placeholder="Please use this field to provide any additional details on the project budget."
        rows="6"
        optional
      />
    </>
  );
}