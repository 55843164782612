import React from 'react';
import { toast } from 'react-hot-toast';
import { usePortfolioContext, usePromiseWatcher } from 'hooks';
import { acceptPortfolioBids } from 'api/portfolioBids';
import { useHistory } from 'react-router-dom';
import Modal from '~/components/Modal';
import { Button } from '~/components';

interface AcceptBidProps {
  investorId: string;
  closeModal: () => void;
}

const AcceptPortfolioBidModal: React.FC<AcceptBidProps> = ({ investorId, closeModal }) => {
  const history = useHistory();
  const { portfolio, fetchPortfolio, projectBlobs } = usePortfolioContext();

  const investorProjectBlobs = projectBlobs.filter((projectBlob: any) => 
    projectBlob?.project?.investorId === investorId
  );
  const biddedProjectBlobs = investorProjectBlobs.filter((projectBlob: any) => 
    !!projectBlob?.projectCosts?.bid
  );
  const declineCount = investorProjectBlobs.length - biddedProjectBlobs.length;

  const { execute: acceptBid, status } = usePromiseWatcher(
    () => {
      return acceptPortfolioBids(portfolio?.id, { investorId })
        .then((response) => {
          toast.success('Successfully accepted bids!');
          if (response?.revertStandaloneProject) {
            // Redirect to standalone project
            history.push(`/project/${response.revertStandaloneProject.id}`);
          } else {
            fetchPortfolio();
          }
          closeModal();
        });
    },
    'accepting the project bids',
    []
  );

  const acceptingBid = status === 'pending';

  const willRemoveProjectsFromPortfolio = declineCount > 0;
  const willBeConvertedToStandalone = declineCount === investorProjectBlobs.length - 1;

  return (
    <Modal closeModal={closeModal} allowClose={!acceptBid}>
      <Modal.Title>Accept project bids?</Modal.Title>
      <Modal.Content>
        <p>Please confirm that you’re ready to accept the submitted bids on all projects within this portfolio.</p>
        {willBeConvertedToStandalone && 
          <p><strong>By accepting this bid on a single project, the remaining projects in the portfolio will be returned to draft status and this will become a standalone project.</strong></p>
        }
        {willRemoveProjectsFromPortfolio && !willBeConvertedToStandalone &&
          <p><strong>Note: Projects that did not receive bids will be removed from the portfolio and remain in draft status.</strong></p>
        }
      </Modal.Content>
      <Modal.Footer>
        <Button primary outlined disabled={acceptingBid} onClick={closeModal}>
          Go back
        </Button>
        <Button primary onClick={acceptBid} disabled={acceptingBid} loading={acceptingBid}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );    
};

export default AcceptPortfolioBidModal;