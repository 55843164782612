import { PricingType } from '~/constants';
import { useProjectContext } from '~/hooks';

export default function RateText() {
  const { project } = useProjectContext();
  const { pricingType } = project;

  if (pricingType === PricingType.OFFTAKE) {
    return (
      <>
        <h3>Offtake rate</h3>
        <p style={{ margin: ' var(--x-small) 0 var(--medium) 0' }}>
          Please note - this rate is what investors will be bidding.          
        </p>
      </>
    ); 
  }
  return null;
}