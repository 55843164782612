import { get } from 'lodash';

export const updateByPath = (data: any, path: any, value: any, {patch = false, deleteKeys = true} = {}) => {
  const keys = path ? path.split('.') : [];
  keys.unshift('data');
  const finalKey = keys.pop();

  const updated = {data};
  let partial = updated as any;
  for (let i = 0; i < keys.length; i++) {
    if (Array.isArray(partial[keys[i]])) {
      partial[keys[i]] = [...partial[keys[i]]];
    } else {
      partial[keys[i]] = {...partial[keys[i]]};
    }
    partial = partial[keys[i]];
  }

  if (patch) {
    partial[finalKey] = {...partial[finalKey], ...value};
  } else if (deleteKeys && value == null) {
    delete partial[finalKey];
  } else if (!deleteKeys && value == null) {
    partial[finalKey] = null;
  } else {
    partial[finalKey] = value;
  }

  return updated.data;
};

export const getByPath = (data: any, path: any, defaultValue = undefined) => {
  return get(data, path, defaultValue);
};
