import { TextArea, Form, Comment } from 'semantic-ui-react';
import React from 'react';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import { FormSchemaAwareWidget } from '~/components/form';
import * as S from '../styles';
import { Button } from '~/components';

interface EditCommentsProps {
  onConfirm: (newComment: string) => void;
  onCancel: () => void;
  loading: boolean;
}  

const EditCommentsImpl: React.FC<EditCommentsProps> = ({ 
  onConfirm,
  onCancel,
  loading
}) => {
  const { formState } = useForm() as any;

  return (
    <Comment.Group 
      style={{
        maxWidth: '30rem',
        width: '100%',
        padding: '0',
        margin: '0'
      }}>
      <Form>
        <FormSchemaAwareWidget 
          as={TextArea}
          schemaKey="comment"
          fieldStyle={{ margin: 'var(--x-small) 0' }}     
        />
      </Form>
      <S.CommentActions>
        <Button
          disabled={loading || !formState.comment}
          onClick={() => onConfirm(formState.comment)}
          style={{ color: 'var(--color-primary)' }}
        >
          Save edit
        </Button>
        <Button disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
      </S.CommentActions>      
    </Comment.Group>
  );
};

const EditComments = ({ comment, onConfirm, onCancel, loading }: any) => {
  return (
    <FormContextProvider defaults={{comment}}>
      <EditCommentsImpl 
        onConfirm={onConfirm} 
        onCancel={onCancel} 
        loading={loading}
      />
    </FormContextProvider>
  );
};

export default EditComments;