export const PortfolioTeamRoles = {
  LEAD: 'LEAD',
  MEMBER: 'MEMBER',
};

export const PortfolioTeamRolesDisplay = {
  [PortfolioTeamRoles.LEAD]: 'Portfolio lead',
  [PortfolioTeamRoles.MEMBER]: 'Team member',
};

export const PortfolioTeamRolesDropdownOptions = [
  { 
    key: PortfolioTeamRoles.LEAD,
    text: PortfolioTeamRolesDisplay[PortfolioTeamRoles.LEAD],
    value: PortfolioTeamRoles.LEAD 
  },
  { 
    key: PortfolioTeamRoles.MEMBER,
    text: PortfolioTeamRolesDisplay[PortfolioTeamRoles.MEMBER],
    value: PortfolioTeamRoles.MEMBER 
  }
];