import { Button } from '~/components';
import { Link } from 'react-router-dom';
import { Form, Icon, Popup } from 'semantic-ui-react';
import { useProjectContext } from '~/hooks';
import { DealStates } from '~/constants';
import { ProjectSubmissionModal } from './ProjectSubmissionModal';
import { useState } from 'react';

export default function ProjectSubmissionButtons() {  
  const { project, id, projectState } = useProjectContext();
  const dealState = projectState?.dealState ?? 0;
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
 
  let buttonDisplayState: 'hasErrors' | 'readyToSubmitProject' | 'readyToSubmitPortfolio';
  if (dealState === DealStates.PROJECT_HAS_ERRORS) {
    buttonDisplayState = 'hasErrors';
  } else {
    buttonDisplayState = project?.portfolioId ? 'readyToSubmitPortfolio' : 'readyToSubmitProject';
  }

  const errorStateString = 'Go back to your project worksheet and fill in all required fields in order to submit your project for review.';
  const portfolioStateString = 'Projects that are within a portfolio must be submitted collectively from the portfolio summary screen.';

  return (
    <div style={{display: 'flex', alignItems: 'center', gap: 'var(--x-small)'}}>
      
      {/* Tooltip */}
      {buttonDisplayState !== 'readyToSubmitProject' && (
        <Popup
          position="bottom center"
          trigger={<Icon name="info circle" size="large" style={{ color: 'var(--color-charcoal' }} tabIndex={0} />}
          content={
            (buttonDisplayState === 'hasErrors' && errorStateString) ||
            (buttonDisplayState === 'readyToSubmitPortfolio' && portfolioStateString)
          }          
          inverted
          on={['hover', 'focus']}
        />
        )
      }
      
      {/* Error state */}
      {buttonDisplayState === 'hasErrors' && (
        <Form>
          <Button
            primary
            as={Link}
            to={`/project/${id}/wizard`}
          >
            Continue building my project
          </Button>
        </Form>
      )}

      {/* Submit portfolio state */}
      {buttonDisplayState === 'readyToSubmitPortfolio' && (
        <Form>
          <Button
            primary
            as={Link}
            to={`/portfolio/${project.portfolioId}/review`}
          >
            Submit from portfolio
          </Button>
        </Form>
      )}

      {/* Submit project state */}
      {buttonDisplayState === 'readyToSubmitProject' && (
        <Form>
          <Button 
            primary  
            onClick={() => setShowSubmissionModal(true)}
          >
            Submit my project 
          </Button>
        </Form>
      )}
      {showSubmissionModal && <ProjectSubmissionModal closeModal={() => setShowSubmissionModal(false)}/>}
    </div> 
  );
};


