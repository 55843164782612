import { PricingType } from '~/constants';
import { useProjectContext } from '~/hooks';

export default function EpcCostsText() {
  const { project } = useProjectContext();
  const { pricingType } = project;

  if (pricingType === PricingType.ACQUISITION) {
    return (
      <>
        <h3>EPC cost + Dev fee</h3>
        <p style={{ margin: ' var(--x-small) 0 var(--medium) 0' }}>
          Please note that investors will be bidding a combined EPC cost plus development fee; you should not include EPC costs anywhere on this page.
        </p>
      </>
    );
  }
    return null;
}