import { useQuery } from 'react-query';
import { fetchUnreadNotificationsCount } from '~/api/user';
import { minutesToMilliseconds } from 'date-fns';

export const getNotificationCountKey = () => {
  return 'notifications/count';
};

export const useNotificationCount = () => {
  return useQuery({
    queryKey: getNotificationCountKey(),
    queryFn() {
      return fetchUnreadNotificationsCount().then(data => data?.count);
    },
    staleTime: minutesToMilliseconds(5), 
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false
  });
};