import { CommentIconAndLabel } from './CommentElements';
import { ResponseIcon } from './CommentElements';
import { CheckmarkIcon } from './CommentElements';
import { CommentMessage } from '~/types/conversations/Message';
import CommentResponseButton from './CommentResponseButton';

interface AdminSupportCommentResponseToggleProps {
  requiresResponse: boolean | undefined;
  message: CommentMessage;
  isOwnComment?: boolean;
  isAdmin?: boolean | undefined;
};

export default function AdminSupportCommentResponseToggle ({ 
  requiresResponse,
  message,
  isOwnComment,
  isAdmin
}: AdminSupportCommentResponseToggleProps) {

  if (isOwnComment || !isAdmin) {
    return null;
  }

  if (requiresResponse) {
    return (
      <CommentResponseButton message={message}> 
        <CommentIconAndLabel icon={ResponseIcon} label="Needs a response" />
      </CommentResponseButton>
    );
  } else {
    return (
      <CommentResponseButton message={message}>
        <CommentIconAndLabel icon={CheckmarkIcon} label="Resolved" />
      </CommentResponseButton>
    );
  }
};

