import React from 'react';

import { Button } from '~/components';
import * as S from './styles';

export default function Buttons() {
  return (
    <S.Section>
      <h2>Buttons</h2>
      <S.SectionRow>
        
        <Button primary size="small">Primary Button</Button>
        <Button primary size="medium">Primary Button</Button>
        <Button primary disabled>Disabled Primary Button</Button>
        <Button primary loading disabled>Disabled Primary Button</Button>
        <pre>
          {'<Button primary size="mini">Primary Button</Button>\n<Button primary size="tiny">Primary Button</Button>\n<Button primary size="small">Primary Button</Button>\n<Button primary size="medium">Primary Button</Button>\n<Button primary size="large">Primary Button</Button>\n<Button primary size="big">Primary Button</Button>\n<Button primary size="huge">Primary Button</Button>\n<Button primary size="massive">Primary Button</Button>\n<Button primary>Primary Button</Button>\n<Button primary disabled>Disabled Primary Button</Button>\n<Button primary loading disabled>Disabled Primary Button</Button>'}
        </pre>
      </S.SectionRow>
      <S.SectionRow>
        <div>
          <Button secondary>Secondary Button</Button>
          <Button secondary disabled>Secondary Primary Button</Button>
          <Button secondary loading disabled>Secondary Primary Button</Button>
        </div>
        <code>
          {'<Button secondary>Secondary Button</Button>\n<Button secondary disabled>Disabled Secondary Button</Button>\n<Button secondary loading disabled>Disabled Secondary Button</Button>'}
        </code>
      </S.SectionRow>
      <S.SectionRow>
        <div>
          <Button>Regular Button</Button>
        </div>
        <code>
          {'<Button>Regular Button</Button>'}
        </code>
      </S.SectionRow>
      <S.SectionRow>
        <div>
          <Button primary outlined>Outline Primary Button</Button>
        </div>
        <code>
          {'<Button primary outlined>Outline Primary Button</Button>'}
        </code>
      </S.SectionRow>
      <S.SectionRow>
        <div>
          <Button secondary outlined>Outline Secondary Button</Button>
        </div>
        <code>
          {'<Button secondary outlined>Outline Secondary Button</Button>'}
        </code>
      </S.SectionRow>
      <S.SectionRow>
        <div>
          <Button outlined>Outline Regular Button</Button>
        </div>
        <code>
          {'<Button outlined>Outline Regular Button</Button>'}
        </code>
      </S.SectionRow>
    </S.Section>
  );
}