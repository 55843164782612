import { FormConfig } from '../types';
import formConditions from './conditions';

const config: FormConfig = {
  conditions: formConditions,
  sections: {
    overview: {
      subsections: {
        projectFoundations: {
          fields: ['developmentStage', 'deploymentType']
        },
        projectRoles: {
          fields: ['longTermOwner', 'epcCompanyName', 'epcCompanyType', 'partnerType', 'pricingType']
        },
        location: {
          fields: ['addressStreet', 'addressCity', 'addressState', 'addressZip', 'utilityZone']
        },
        keyDatesAndStatuses: {
          fields: ['expectedProceedDate', 'expectedCommercialOperationDate', 'customerPricingStatus', 'offtakeContractStatus', 'permitsStatus', 'siteControlStatus', 'interconnectionStatus']
        }
      }
    },
    system: {
      subsections: {
        pv: {
          fields: ['projectArrays']
        },
        storage: {
          fields: ['storage']
        },
        productionValidation: {
          fields: []
        }
      }
    },
    budget: {
      subsections: {
        upfrontIncentives: {
          fields: ['itcAdders', 'upfrontIncentives', 'upfrontIncentiveUnit', 'upfrontIncentiveNotes']
        },
        budgetItems: {
          fields: ['epcCost', 'epcCostUnit', 'budgetCosts']
        }
      }
    },
    revenue: {
      subsections: {
        customerInformation: {
          fields: ['customerName', 'customerType', 'avoidedCostOfSolar', 'yearOneSavings', 'customerCreditRating', 'communitySolarSubscriptionDetails']
        },
        customerContract: {
          fields: ['revenueAssumptionsFlag', 'contractType', 'contractTerm', 'contractRate', 'communitySolarDiscountPercentage', 'contractEscalator', 'communitySolarRevenueDetails']
        },
        renewableEnergyCredits: {
          fields: ['recOwnership', 'recDetails']
        },
        otherRevenueStreams: {
          fields: ['additionalRevenues']
        }
      }
    },
    expenses: {
      subsections: {
        siteExpenses: {
          fields: ['siteLeaseCost', 'siteLeaseCostUnit', 'siteLeaseEscalator', 'siteLeaseTerm', 'siteLeaseExtensions', 'siteLeaseAcreage', 'unusualSiteConditions', 'propertyTaxType', 'propertyTaxAmount', 'propertyTaxEscalator', 'propertyTaxNotes']
        },
        operationAndMaintenance: {
          fields: ['omProposeFlag', 'omPrice', 'omPriceUnit', 'omEscalator', 'storageOmFlag', 'storageExpenseAmount', 'storageExpenseUnit', 'storageExpenseEscalator', 'storageExpenseExpectedTerm', 'storageExpenseNotes']
        },
        subscriptionManagement: {
          fields: ['subscriptionManagementAssumptionsFlag', 'subscriptionExpenseAmount', 'subscriptionExpenseUnit', 'subscriptionExpenseEscalator', 'subscriptionExpenseExpectedTerm', 'subscriptionExpenseNotes']
        },
        operatingExpenses: {
          fields: ['additionalOperatingExpenses']
        }
      }
    }
  },
};

Object.entries(config.sections ?? {}).forEach(([key, value]) => {
  if (value.subsections) {
    Object.entries(value.subsections).forEach(([subKey, subValue]) => {
      if (subValue.fields) {
        subValue.fields.forEach((field) => {
          config.mappedFields = config.mappedFields ?? {};
          config.mappedFields[field] = { sectionId: key, subsectionId: subKey };
        });
      }
    });
  }
});

export default config;