import { sumBy } from 'lodash';
import { InvestorBidStatus, PricingType, Unit } from '~/constants';
import { Project } from '~/types/project';

const epcCost = (project: Project) => {
  if (!project) {
    return null;
  }

  let epcCost = project.epcCost as number;
  let epcCostUnit = project.epcCostUnit;

  if (project.pricingType === PricingType.ACQUISITION) {
    const bid = project?.projectBids?.find((bid: any) => bid.bidStatus === InvestorBidStatus.ACCEPTED || bid.bidStatus === InvestorBidStatus.PENDING);
    if (bid) {
      epcCost = bid.amount;
      epcCostUnit = bid.unit;
    }
  }

  if (epcCostUnit === Unit.WDC) {
    const sizeKwdc = project.projectArrays ? sumBy(Object.values(project.projectArrays), (mount: any) => Number(mount.sizeKwdc)) : 0;
    return epcCost * sizeKwdc * 1000;
  }
  return epcCost;
};

export default epcCost;
