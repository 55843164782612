import React from 'react';

import { useProjectWizardContext } from '~/features/projectWizard/providers/ProjectWizardProvider';
import { Button } from '~/components';
import { Link } from 'react-router-dom';
import { useProjectContext } from '~/hooks';

export default function SubmitButton() {
  const { id } = useProjectContext();
  const { selectScreen, getNextScreen } = useProjectWizardContext();
  const nextScreen = getNextScreen();

  if (nextScreen?.isFormSubmit) {
    // Navigate to project review page if last step of the form
    return (
      <Button 
        as={Link}
        to={`/project/${id}/review`}
        className="submit-button" 
        primary
      >
        {nextScreen?.submitButtonLabel}
      </Button>
    );
  } else {
    // Navigate to next step of the form
    return (
      <Button 
        className="submit-button" 
        primary 
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          window.scrollTo({ top: 0 });
          if (nextScreen?.isFormSubmit) {
            // TODO handle form submit
            alert('Form submit coming soon...');
          } else {
            selectScreen(nextScreen?.sectionId as string, nextScreen?.subsectionId as string);
          }
          e.currentTarget.blur();
        }}
      >
        {nextScreen?.submitButtonLabel}
      </Button>
    );
  }
}