import { useQuery } from 'react-query';
import { getUsers } from '~/api/admin';
import { User } from '~/types/user';

export const adminUsersKey = () => 'adminUsers';

export const useAdminUsersQuery = () => {
  return useQuery(adminUsersKey(), () => {
    return getUsers({ teamOnly: true })
      .then((users) => {
        return users.filter((user: User) => user.active);
      });
  }, {
    refetchOnWindowFocus: false
  });
};