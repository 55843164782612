import { CSSProperties, ReactNode } from 'react';
import * as S from './styles';
import Tab from './Tab';

interface SidebarTabsProps {
  children: ReactNode;
  style?: CSSProperties;
}

const SidebarTabs = ({ children, style }: SidebarTabsProps) => {
  return <S.SidebarTabs style={style ?? {}}>{children}</S.SidebarTabs>;
};

SidebarTabs.Tab = Tab;

export default SidebarTabs;