import React from 'react';
import Modal from '~/components/Modal';
import { withForm } from '~/utils/withForm';
import { ProductionUnit } from '~/constants';
import { useProjectConfig } from '~/requests/projects/useProjectConfig';
import { validation } from '~/schema/instant-ppa';
import { useForm } from '~/hooks';
import { SpecialRECStates } from '~/constants/special-rec-states';
import { Loader } from 'semantic-ui-react';
import SpecialRECStateForm from './forms/SpecialRECStateForm';
import PPAForm from './forms/PPAForm';

const defaults = {
  name: '',
  addressState: '',
  arrayType: '',
  yearOneProduction: undefined,
  yearOneProductionUnit: ProductionUnit.KWH,
  sizeKwdc: undefined,
  upfrontIncentives: 0,
  yearOneSavings: 0,
  contractTerm: 25,
  contractEscalator: 2
};

const InstantQuoteModal = withForm({ defaults, validation }, () => {
  // Call hooks and parent contexts
  const { status } = useProjectConfig();
  const { formState } = useForm() as any;

  const isLoading = status === 'loading';

  // Use normal PPA form (PPAForm component) if the selected state is not a
  // special RECs state (like MA, NJ, IL, etc.)
  // Otherwise, render the SpecialRECStateForm component.
  const Form = SpecialRECStates.includes(formState.addressState) ? SpecialRECStateForm : PPAForm;

  return (
    <Modal width="36rem">
      {isLoading ? (
        <div style={{ paddingTop: '15rem', marginBottom: '-2rem' }}>
          <Loader active size="huge" />
        </div>
      ): (
        <>
          <Modal.Title>
            Get an initial market quote
          </Modal.Title>
          <Form />
        </>
      )}
    </Modal>
  );
});

export default InstantQuoteModal;