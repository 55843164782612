export const DevelopmentStages = {
  PROSPECTING: 'PROSPECTING',
  DEVELOPMENT: 'DEVELOPMENT',
  CONSTRUCTION: 'CONSTRUCTION',
  OPERATING: 'OPERATING'
};

export const DevelopmentStageDisplay = {
  [DevelopmentStages.PROSPECTING]: 'Prospecting',
  [DevelopmentStages.DEVELOPMENT]: 'Development',
  [DevelopmentStages.CONSTRUCTION]: 'Construction',
  [DevelopmentStages.OPERATING]: 'Operating'
};

export const DevelopmentStageRadioOptions = [
  {
    value: DevelopmentStages.PROSPECTING,
    label: DevelopmentStageDisplay[DevelopmentStages.PROSPECTING]
  },
  {
    value: DevelopmentStages.DEVELOPMENT,
    label: DevelopmentStageDisplay[DevelopmentStages.DEVELOPMENT]
  },
  {
    value: DevelopmentStages.CONSTRUCTION,
    label: DevelopmentStageDisplay[DevelopmentStages.CONSTRUCTION]
  },
  {
    value: DevelopmentStages.OPERATING,
    label: DevelopmentStageDisplay[DevelopmentStages.OPERATING]
  }
];