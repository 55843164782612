import productionEstimates from './productionEstimates';
import projectDetails from './projectDetails';
import budget from './budget';
import customer from './customer';
import status from './status';
import updatedAt from './updatedAt';
import dealSummary from './dealSummary';
import targetITCPercent from './targetITCPercent';
import yearOneProduction from './yearOneProduction';
import investorBidArea from './investorBidArea';
import desiredTransactionStage from './desiredTransactionStage';
import storage from './storage';
// import { Project } from '~/types/project'; //TODO Project typing

export default function projectDisplayValues(project: any, projectCosts: any, permissions: any = {}) {
  return {
    // This data shows on the Project Details area of the Project Summary page
    projectDetails: projectDetails(project, projectCosts),

    // This data shows on the Budget area of the Project Summary page
    budget: budget(project, projectCosts, permissions),

    // Customer name display for Portfolio Summary Table
    customer: customer(project),

    // This data pertains to the DNV data section of the Project Summary page
    productionEstimates: productionEstimates(project),

    // Pricing estimate section of the Project Summary page pulls directly from project context

    // The human-readable project status
    status: status(project, permissions),

    // Last updated timestamp
    updatedAt: updatedAt(project),

    // Deal Summary display values
    dealSummary: dealSummary(project),

    // Target ITC percent value
    targetITCPercent: targetITCPercent(project),

    // Year-One production calculation
    yearOneProduction: yearOneProduction(project),

    // Investor Bid Area display values
    investorBidArea: investorBidArea(project, projectCosts),

    desiredTransactionStage: desiredTransactionStage(project),

    storage: storage(project)
  };
};