import { Grid } from 'semantic-ui-react';
import { Table } from '~/components';
import Link from '~/components/Link';
import { usePortfolioContext } from '~/hooks';
import PortfolioSummaryTableStatus from './PortfolioSummaryTableStatus';
import { formatNumber } from '~/utils';

export default function PortfolioSummaryTable() {
  const { projectBlobs } = usePortfolioContext();
  
  return (
    <div style={{margin: '0 auto var(--large)'}}>
      <Grid.Row>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Project name</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Size (kWdc)</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">City, State</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Customer</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {projectBlobs?.map((projectBlob: any) => {
                const { project, projectCosts, projectDisplayValues } = projectBlob;

                return (
                  <Table.Row key={project.id}>
                    <Table.Cell>
                      <Link 
                        to={`/project/${project.id}`}
                        primary
                        bold
                        underline  
                      >
                        {project.name}
                      </Link>
                    </Table.Cell>
                    <Table.Cell textAlign="center">{formatNumber(projectCosts.sizeKwdc)}</Table.Cell>
                    <Table.Cell textAlign="center">{projectDisplayValues.projectDetails.location}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {projectDisplayValues.customer.name}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <PortfolioSummaryTableStatus projectBlob={projectBlob} />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>          
        </Grid.Column>
      </Grid.Row>
    </div>
  );
}
