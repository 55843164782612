import styled from 'styled-components';

const ErrorMessage = styled.div`
  position: absolute;
  bottom: auto;
  margin-top: var(--2x-small);
  left: 0;
  color: var(--color-red);
  font-size: 0.6875rem;
  line-height: 1rem;
  font-weight: 400;
`;

export default function FieldErrorMessage({ content }: { content: any }) {
  if (!content) {
    return null;
  }

  return (
    <ErrorMessage className="error-message">{content}</ErrorMessage>
  );
}
