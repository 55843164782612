export const TutorialCodes = {
  FIRST_LOGIN: 'FIRST_LOGIN',
  FIRST_PROJECT_CREATED: 'FIRST_PROJECT_CREATED',
  FIRST_INVESTOR_INVITED: 'FIRST_INVESTOR_INVITED',
  PROJECT_SUBMISSION_READY: 'PROJECT_SUBMISSION_READY',
  FIRST_PROJECT_MATCH: 'FIRST_PROJECT_MATCH',
  BIDS_RECEIVED: 'BIDS_RECEIVED',
  DATA_ROOM_SETUP: 'DATA_ROOM_SETUP',
  EARLY_DATAROOM_PROJECT_SUMMARY_WELCOME: 'EARLY_DATAROOM_PROJECT_SUMMARY_WELCOME',
  EARLY_DATAROOM_LANDING_WELCOME: 'EARLY_DATAROOM_LANDING_WELCOME',
  DATAROOM_ACCESS_TUTORIAL: 'DATAROOM_ACCESS_TUTORIAL',
  INVESTOR_EARLY_DATAROOM_WELCOME: 'INVESTOR_EARLY_DATAROOM_WELCOME',
  V2_UPDATE_MESSAGE: 'V2_UPDATE_MESSAGE'
};