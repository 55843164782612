import { InputType } from '~/schema';
import { WizardField } from '../../../Field';
import { RevenueSource, UnitDecimals } from '~/constants';
import { useProjectContext } from '~/hooks';
import { System } from '~/utils/calculators';
import { useDerivedSavingsAutocalculateFields } from '~/features/projectWizard/derived/useDerivedSavingsAutocalculateFields';
import styled from 'styled-components';

const Container = styled.div`
  .savings-field {
    max-width: 10rem;
    width: 100%;
    label {
      white-space: nowrap;
    }
    + strong {
      font-size: 0.875rem;
      padding: 1.5rem calc(var(--x-large) + 0.25rem) 0;
    }
  }
`;

export default function () {
  const { project } = useProjectContext();
  const yearOneProduction = System.yearOneProduction(project?.projectArrays);

  // Determine if either of the fields should be disabled.
  // This happens when year one production is not set and the user types a value into one of the fields
  const yearOneSavingsDisabled = !yearOneProduction && project?.avoidedCostOfSolar !== null && project?.avoidedCostOfSolar !== undefined;
  const avoidedCostOfSolarDisabled = !yearOneProduction && project?.yearOneSavings !== null && project?.yearOneSavings !== undefined;

  // Update avoided cost of solar / year one savings when either of the fields change
  useDerivedSavingsAutocalculateFields();

  // Don't show this component if project is community solar
  if (project?.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS) {
    return null;
  }

  return (
    <Container>
      <h3>
        Customer Savings
      </h3>
      <p style={{ marginTop: 'var(--x-small)' }}>
        Please provide either the avoided cost of power ($/kWh) or the total year 1 savings ($) on the customer’s electric bill from avoided energy costs. Providing one value will automatically calculate the other, assuming you’ve provided system production data.      </p>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 'var(--small)' }}>
        <div className="savings-field">
          <WizardField
            type="number"
            fieldLabel="Avoided cost of power ($/kWh)"
            schemaKey="avoidedCostOfSolar"
            numberType={!avoidedCostOfSolarDisabled ? InputType.DOLLARS : undefined}
            decimals={UnitDecimals.KWH}
            disabled={avoidedCostOfSolarDisabled}
            placeholder={avoidedCostOfSolarDisabled ? 'N/A' : undefined}
          />
        </div>
        <strong>
          or
        </strong>
        <div className="savings-field">
          <WizardField
            type="number"
            fieldLabel="Year 1 savings ($)"
            schemaKey="yearOneSavings"
            numberType={!yearOneSavingsDisabled ? InputType.DOLLARS : undefined}
            decimals={UnitDecimals.DOLLARS}
            disabled={yearOneSavingsDisabled}
            placeholder={yearOneSavingsDisabled ? 'N/A' : undefined}
          />
        </div>
      </div>
    </Container>
  );
}