export const RecStates = {
  ILLINOIS: 'Illinois',
  MASSACHUSETTS: 'Massachusetts',
  NEW_JERSEY: 'New Jersey',
};

export const RecOwnership = {
  INVESTOR: 'INVESTOR',
  OFFTAKE_CUSTOMER: 'OFFTAKE_CUSTOMER'
};

export const RecOwnershipDisplay = {
  [RecOwnership.INVESTOR]: 'Investor',
  [RecOwnership.OFFTAKE_CUSTOMER]: 'Offtake Customer'
};

export const RecOwnershipRadioOptions = [
  { value: RecOwnership.INVESTOR, label: RecOwnershipDisplay[RecOwnership.INVESTOR] },
  { value: RecOwnership.OFFTAKE_CUSTOMER, label: RecOwnershipDisplay[RecOwnership.OFFTAKE_CUSTOMER] },
];

export const RecShinesProgram = {
  YES: 'YES',
  NO: 'NO',
};

export const RecShinesProgramDisplay = {
  [RecShinesProgram.YES]: 'Yes',
  [RecShinesProgram.NO]: 'No'
};

export const RecShinesProgramRadioOptions = [
  { value: RecShinesProgram.YES, label: RecShinesProgramDisplay[RecShinesProgram.YES] },
  { value: RecShinesProgram.NO, label: RecShinesProgramDisplay[RecShinesProgram.NO] },
];

export const RecAllocation = {
  SECURED_ALLOCATION: 'YES',
  SUBMITTED_APPLICATION: 'SUBMITTED_APPLICATION',
  NOT_SUBMITTED_APPLICATION: 'NO_SUBMITTED_APPLICATION',
};

export const RecAllocationDisplay = {
  [RecAllocation.SECURED_ALLOCATION]: 'Yes',
  [RecAllocation.SUBMITTED_APPLICATION]: 'No, but I have submitted an application',
  [RecAllocation.NOT_SUBMITTED_APPLICATION]: 'No, and I have not submitted an application'
};

export const RecAllocationRadioOptions = [
  { value: RecAllocation.SECURED_ALLOCATION, label: RecAllocationDisplay[RecAllocation.SECURED_ALLOCATION] },
  { value: RecAllocation.SUBMITTED_APPLICATION, label: RecAllocationDisplay[RecAllocation.SUBMITTED_APPLICATION] },
  { value: RecAllocation.NOT_SUBMITTED_APPLICATION, label: RecAllocationDisplay[RecAllocation.NOT_SUBMITTED_APPLICATION] },
];

export const RecProjectCategory = {
  DISTRIBUTED_GENERATION: 'DISTRIBUTED_GENERATION',
  TRADITIONAL_COMMUNITY_SOLAR: 'TRADITIONAL_COMMUNITY_SOLAR',
  COMMUNITY_DRIVEN_COMMUNITY_SOLAR: 'COMMUNITY_DRIVEN_COMMUNITY_SOLAR',
  PUBLIC_SCHOOLS: 'PUBLIC_SCHOOLS',
};

export const RecProjectCategoryDisplay = {
  [RecProjectCategory.DISTRIBUTED_GENERATION]: 'Distributed generation',
  [RecProjectCategory.TRADITIONAL_COMMUNITY_SOLAR]: 'Traditional community solar',
  [RecProjectCategory.COMMUNITY_DRIVEN_COMMUNITY_SOLAR]: 'Community-driven community solar',
  [RecProjectCategory.PUBLIC_SCHOOLS]: 'Public schools',
};

export const RecProjectCategoryRadioOptions = [
  { value: RecProjectCategory.DISTRIBUTED_GENERATION, label: RecProjectCategoryDisplay[RecProjectCategory.DISTRIBUTED_GENERATION] },
  { value: RecProjectCategory.TRADITIONAL_COMMUNITY_SOLAR, label: RecProjectCategoryDisplay[RecProjectCategory.TRADITIONAL_COMMUNITY_SOLAR] },
  { value: RecProjectCategory.COMMUNITY_DRIVEN_COMMUNITY_SOLAR, label: RecProjectCategoryDisplay[RecProjectCategory.COMMUNITY_DRIVEN_COMMUNITY_SOLAR] },
  { value: RecProjectCategory.PUBLIC_SCHOOLS, label: RecProjectCategoryDisplay[RecProjectCategory.PUBLIC_SCHOOLS] },
];

export const RecUtilityGroup = {
  GROUP_A: 'GROUP_A',
  GROUP_B: 'GROUP_B',
};

export const RecUtilityGroupDisplay = {
  [RecUtilityGroup.GROUP_A]: 'Group A',
  [RecUtilityGroup.GROUP_B]: 'Group B',
};

export const RecUtilityGroupExcelDisplay = {
  [RecUtilityGroup.GROUP_A]: 'A',
  [RecUtilityGroup.GROUP_B]: 'B',
};

export const RecUtilityGroupRadioOptions = [
  { value: RecUtilityGroup.GROUP_A, label: RecUtilityGroupDisplay[RecUtilityGroup.GROUP_A] },
  { value: RecUtilityGroup.GROUP_B, label: RecUtilityGroupDisplay[RecUtilityGroup.GROUP_B] },
];

// Do not change this format of "YEAR_X_Y". Our underlying logic depends on it.
export const RecProgramYear = {
  YEAR_2023_2024: 'YEAR_2023_2024',
  YEAR_2024_2025: 'YEAR_2024_2025',
  FUTURE_PROGRAM_YEAR: 'FUTURE_PROGRAM_YEAR',
};

export const RecProgramYearDisplay = {
  [RecProgramYear.YEAR_2023_2024]: '2023 / 2024',
  [RecProgramYear.YEAR_2024_2025]: '2024 / 2025',
  [RecProgramYear.FUTURE_PROGRAM_YEAR]: 'Future program year',
};

export const RecProgramYearRadioOptions = [
  { value: RecProgramYear.YEAR_2023_2024, label: RecProgramYearDisplay[RecProgramYear.YEAR_2023_2024] },
  { value: RecProgramYear.YEAR_2024_2025, label: RecProgramYearDisplay[RecProgramYear.YEAR_2024_2025] },
  { value: RecProgramYear.FUTURE_PROGRAM_YEAR, label: RecProgramYearDisplay[RecProgramYear.FUTURE_PROGRAM_YEAR] },
];
