import { CollapsibleTrigger } from '@radix-ui/react-collapsible';
import Link from '~/components/Link';
import { ProjectArraysDisplay } from '~/constants';
import { ProjectArray } from '~/types/project';
import { formatNumber } from '~/utils';
import { System } from '~/utils/calculators';

export default function ProjectArraySectionHeader({ 
  projectArray, 
  isCollapsed = false
}: { 
  projectArray: ProjectArray, 
  isCollapsed: boolean
}) {
  let arrayHeaderValues = '';
  if (projectArray.type) {
    arrayHeaderValues = ProjectArraysDisplay[projectArray.type];
  }
  if (projectArray.sizeKwdc) {
    if (arrayHeaderValues) {
      arrayHeaderValues += ', ';
    }
    arrayHeaderValues += `${formatNumber(projectArray.sizeKwdc)} kWdc`;
  }
  if (projectArray.sizeKwdc && projectArray.yearOneProduction && projectArray.yearOneProductionUnit) {
    const productionFactor = System.productionFactor(projectArray);
    arrayHeaderValues += `, ${formatNumber(productionFactor) || 0} kWh/kW`;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <h3 style={{ margin: 0 }}>Array {projectArray.order + 1} - {arrayHeaderValues || 'TBD'}</h3>
      <Link as={CollapsibleTrigger} bold>
        {isCollapsed ? '+ Expand' : '- Collapse'} section
      </Link>
    </div>
  );
}