import { useEffect, useState } from 'react';

interface ScrollState {
  scrollTop?: number;
  scrollDirection?: 'down'|'up';
  isScrolledToTop?: boolean;
  isScrolledToBottom?: boolean;
  isDocumentScrollable?: boolean;
}

const DEFAULT_SCROLL_DIRECTION_THRESHOLD = 10;

export default function useScrollState(props?: { 
  scrollDirectionThreshold?: number
}) {
  const { scrollDirectionThreshold = DEFAULT_SCROLL_DIRECTION_THRESHOLD } = props || {};
  
  const [scrollState, setScrollState] = useState<ScrollState>({});

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScroll = () => {
      const { scrollY } = window;
      const direction = scrollY > lastScrollY ? 'down' : 'up';

      // Get the total height of the document
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );

      // Get the height of the viewport
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      const newScrollState: ScrollState = {
        scrollTop: scrollY,
        scrollDirection: scrollState.scrollDirection,
        isScrolledToTop: scrollY === 0,
        isScrolledToBottom: scrollY + windowHeight >= documentHeight - 20,
        isDocumentScrollable: documentHeight > windowHeight
      };

      if (direction !== scrollState.scrollDirection && (scrollY - lastScrollY > scrollDirectionThreshold || scrollY - lastScrollY < (-1 * scrollDirectionThreshold))) {
        newScrollState.scrollDirection = direction;
      }


      lastScrollY = scrollY > 0 ? scrollY : 0;
      setScrollState(newScrollState);
    };
    window.addEventListener('scroll', updateScroll); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScroll); // clean up
    };
  }, [scrollState]);

  return scrollState;
};