import { Dropdown, Icon, Label, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

interface DocumentProps {
  state?: string;
  isEditing?: boolean;
  isHidden?: boolean;
  showSpacing?: boolean;
}

export const DocumentRowContainer = styled.div<DocumentProps>`
  display: flex;
  max-width: 100%;
  transition: 0.3s ease-in-out;
  align-items: center;
  // this is so unselected documents in edit mode have a transition when they appear
  ${props => props.isHidden && `
    transition: .3s ease-in-out;
    opacity: ${Number(!props.isHidden)};
  `}
  .document-row-checkbox {
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    max-width: 0;
    opacity: 0;
    margin-right: 0;
    transition: 0.3s ease-in-out;
  }
`;

export const DocumentRowTitle = styled.h3<DocumentProps>`
  font-size: 0.875rem;
  line-height: 1.25rem;
  ${props => {
    switch (props.state) {
      case 'DEFAULT_NO_DOC':
        return {
          color: 'var(--color-charcoal)',
          fontWeight: '400',
          textDecoration: 'none',
          fontStyle: 'normal',
        };
      case 'DRAGGING_WITH_DOC':
      case 'SELECTED_WITH_DOC':
        return {
          color: 'var(--color-pure-black)',
          fontWeight: '700',
          textDecoration: 'underline',
          fontStyle: 'normal',
        };
      case 'DRAGGING_NO_DOC':
      case 'SELECTED_NO_DOC':
        return {
          color: 'var(--color-charcoal)',
          fontWeight: '400',
          textDecoration: 'none',
          fontStyle: 'italic',
        };  
      default:
        return {};
    }}
  };
`;
DocumentRowTitle.defaultProps = { className: 'document-row-title' };

export const DocumentRowInfoIcon = styled(Icon)`
  &.icon {
    color: var(--color-charcoal);
    margin: 0;
  }
`;
DocumentRowInfoIcon.defaultProps = {
  name: 'info circle',
  size: 'small',
  className: 'document-row-info-icon'
};

export const DocumentRow = styled.div<DocumentProps>`
  all: unset;
  width: 100%;
  display: flex;
  align-items: center;
  height: 2.75rem;
  padding-left: var(--x-small);
  padding-right: var(--small);
  margin-bottom: var(--2x-small);
  border: 1px solid var(--color-grey);
  box-shadow: 0 1px 1px -1px var(--color-grey);
  position: relative;

  ${props => props.isEditing && `
    margin-left: 1.5rem;
    transition: .3s ease-in-out;
  `}
    ${props => !props.isEditing && `
    margin-left: 0;
    transition: .3s ease-in-out;
  `}

  ${props => {
      switch (props.state) {
        case 'DEFAULT_NO_DOC':
          return `
            border: none;
          `;
        case 'SELECTED_NO_DOC':
          return `
            border: 1px dashed var(--color-gray);
          `;
        case 'DRAGGING':
        case 'DRAGGING_WITH_DOC':
        case 'DRAGGING_NO_DOC':
          return `
            border: 1px dashed var(--color-primary);
            background: var(--color-primary-light);
          `;
        case 'SELECTED_WITH_DOC':
        case 'REPLACING':
        case 'DOWNLOADING':
          return `
            background: var(--color-grey);
            ${DocumentRowTitle} {
              max-width: 16rem;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          `;
        default:
          return '';
      }}
    };
  
  ${DocumentRowTitle} {
    margin: 0;
    padding: 0;
  }
  ${DocumentRowInfoIcon} {
    margin-right: var(--x-small);
  }
  &:hover {
    cursor: ${props => props.isEditing ? 'default' : 'pointer'};
    background-color: ${props => props.isEditing ? 'none' : 'var(--color-grey)'};
  }
  &:focus-visible {
    outline: 1px solid var(--color-charcoal);
    background: var(--color-grey);
  }
`;
DocumentRow.defaultProps = {
  role: 'button',
  tabIndex: 0,
  className: 'document-row'
};

export const SelectedDocumentTypesRow = styled.div<DocumentProps>`
  ${props => props.showSpacing && `
    padding-top: 2.25rem;
    transition: .3s ease-in-out;
  `}
  ${props => props.isEditing && `
    padding-top: 0;
    transition: .3s ease-in-out;
  `}
`;

export const UnselectedDocumentRow = styled.div<DocumentProps>`
    height: ${props => props.isEditing ? 'auto' : '0'};
    position: relative;
    overflow: hidden;
`;

export const DocumentList = styled.section<DocumentProps>`
  width: 100%;
  ${props => props.isEditing && `
    ${DocumentRowContainer} > .document-row-checkbox {
      opacity: 1;
      padding-right: 1.5rem;
      max-width: 1rem;
      transition: 0.3s ease-in-out;
    }
  `}
`;

export const UploadIcon = styled(Icon)`
  &.icon {
    margin-top: -0.5rem;
    margin-right: 0;
  }
`;
UploadIcon.defaultProps = {
  title: 'Click to Upload'
};

export const UploadMessage = styled.div`
  color: var(--color-primary);
`;

export const DocumentRowDropdown = styled(Dropdown)`
  &.ui.pointing.dropdown > .menu {
    margin-top: var(--2x-small);
    transform: translateX(1rem);
    &:after {
      left: calc(100% - 1.4rem);
    }
  }
`;
DocumentRowDropdown.defaultProps = {
  pointing: true,
  direction: 'left'
};

export const DocumentRowLoaderContainer = styled.div`
  position: relative;
  width: 1rem;
`;

export const DocumentRowLoader = styled(Loader)``;
DocumentRowLoader.defaultProps = {
  size: 'mini',
  active: true
};

export const DocumentRowLabel = styled(Label)`
  &.ui.label {
    color: var(--color-charcoal);
    font-weight: 700;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
`;

export const DocumentRowAlignRight = styled.div`
  display: flex;
  position: relative;
  margin-left: auto;
  > * {
    margin: 0;
    + * {
      margin-left: var(--medium);
    }
  }
  &&& {
    .dropdown.icon {
      margin: 0;
      width: 1rem;
    }
  }
`;

export const ChecklistContainer = styled.div`
  > * {
    margin: var(--small) 0 0 0;
  }
`;

export const CheckboxContainer = styled.label`
  display: inline-flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  > div:first-child {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25rem;
    > * {
      margin: 0;
      + * {
        margin-left: calc(var(--medium) - 1rem);
      }
    }
  }

  .name {
    display: flex;
    align-items: center;
    > * {
      margin: 0;
    }
    > * + * {
      margin-left: var(--x-small);
    }
    [role="button"] {
      display: flex;
      align-items: center;
      > i {
        font-size: 0.875rem;
        margin-top: -0.35rem;
        margin-right: 0;
      }
    }
  }

  .description {
    margin-top: var(--3x-small);
    padding-left: var(--medium);
    font-size: 0.875rem;
    color: var(--color-charcoal);
    letter-spacing: -0.07px;
    line-height: 1.25rem;
  }


`;