import { useState } from 'react';
import { rejectProject } from '~/api/admin';
import { useProjectContext, useForm, usePromiseWatcher} from '~/hooks';
import { useParams } from 'react-router-dom';
import { DealStates, ProjectRejectionReasons } from '~/constants';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import { FormSchemaAwareWidget } from '~/components/form';
import Modal from '~/components/Modal';
import { Button } from '~/components';
import { Icon, Form, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';

const RejectionFormContainer = styled.div`
  max-width: 40.1875rem;
`;

function ProjectRejectionImpl(){
  const { id } = useParams() as any;
  const { formState } = useForm() as any;
  const { project, updateProjectValue, projectState } = useProjectContext();
  const [showRejectConfirm, setShowRejectConfirm] = useState(false);
  const isRejected = projectState.dealState < DealStates.IN_REVIEW ? project?.rejectionDetail : undefined;
  const rejectionOptions = Object.entries(ProjectRejectionReasons).map(([code, text]) => ({key: code, value: code, text}));

  const { execute: submitProjectRejection, status: projectRejectionStatus } = usePromiseWatcher(
    async () => {
      const project = await rejectProject(id, {
        reason: formState.reason,
        message: formState.message,
      });
      updateProjectValue('', {
          status: project.status,
          rejectionDetail: project.rejectionDetail,
        }, {doSave: false, patch: true}
      );
    },
    {messageStub: 'rejecting the project'},
    [id, formState]
  );

  return (
    <>
      {!isRejected && (
        <RejectionFormContainer>
          <Form>
            <FormSchemaAwareWidget
              schemaKey="reason"
              placeholder="Reason for returning project to draft"
              options={rejectionOptions}
              fieldStyle={{width: '100%'}}
              fieldLabel="Return project to draft"
            />
            <FormSchemaAwareWidget as={TextArea}
              schemaKey="message"
              placeholder="Use this space to provide any additional context."
              fieldStyle={{width: '100%', marginBottom: 'var(--large)'}}
            />
            <Button
                primary
                disabled={!formState.reason}
                style={{ display: 'block', marginLeft: 'auto'}}
                onClick={() => setShowRejectConfirm(true)}
              >
                Reject project
            </Button>
          </Form>
        </RejectionFormContainer>
      )}

      {showRejectConfirm && (
        <Modal closeModal={() => projectRejectionStatus !== 'pending' ? setShowRejectConfirm(false) : undefined}> 
          <Modal.Title>Reject project</Modal.Title>
          <Modal.Content>Are you sure you want to reject this project?</Modal.Content>
          <Modal.Footer>
            <Button
              primary
              disabled={projectRejectionStatus === 'pending'}
              onClick={() => {
                submitProjectRejection();
                setShowRejectConfirm(false);
              }}
            >
              <Icon name="checkmark"/> Yes
            </Button>
            <Button 
              primary 
              outlined 
              onClick={() => setShowRejectConfirm(false)}
            >
              <Icon name="remove"/> No
            </Button>
          </Modal.Footer>
        </Modal> 
      )}
    </>
  );
}

const ProjectRejection = ({defaults = {
  reason: '',
  message: '',
}}) => {
  return (
    <FormContextProvider defaults={defaults}>
      <ProjectRejectionImpl/>
    </FormContextProvider>
  );
};

export default ProjectRejection;