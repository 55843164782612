import React from 'react';
import { WizardField } from '~/features/projectWizard/components/Field';
import WizardForm from '~/features/projectWizard/components/WizardForm';
import { DeploymentTypeRadioOptions } from '~/constants/deployment-type';
import { DevelopmentStageRadioOptions } from '~/constants/development-stage';
import { useDerivedRECFields, useDerivedProjectSetupFields } from '~/features/projectWizard/derived';

export default function ProjectFoundations () {
  // Update certain values automatically based on field changes
  useDerivedProjectSetupFields();
  useDerivedRECFields();

  return (
    <WizardForm>
      <WizardField
        type="radio"
        schemaKey="deploymentType"
        fieldLabel="What type of project is this?"
        values={DeploymentTypeRadioOptions}
      />
      <WizardField
        type="radio"
        schemaKey="developmentStage"
        fieldLabel="What is the project's status?"
        values={DevelopmentStageRadioOptions}
      />
    </WizardForm>
  );
}