import { Grid, Step } from 'semantic-ui-react';
import styled from 'styled-components';


export const FieldStatusCircle = styled.div`
  color: var(--color-pure-white);
  font-size: 0.6875rem;
  min-width: 1rem;
  height: 1rem;
  padding: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
  font-weight: normal;

  &.success {
    background: var(--color-success);
  }

  &.has-errors {
    background: var(--color-orange);
  }

  .icon {
    margin: 0;
  }
`;

export const BreadcrumbsGridRow = styled(Grid.Row)`
  &&& {
    transition: 0.15s ease-in-out;
    padding-top: var(--medium);
    padding-bottom: calc(var(--medium) / 2);
    font-weight: 600;
    color: var(--color-charcoal);
    position: relative;
    z-index: 1;
    background: var(--color-off-white);
  }
`;

export const ProjectNameGridRow = styled(Grid.Row)`
  &&& {
    width: 100%;
    background: var(--color-off-white);
    padding-top: 0;
    padding-bottom: 0;
    z-index: 1;
  }
`;

export const StepsGridRow = styled(Grid.Row)`
  &&& {
    background: var(--color-off-white);
    z-index: 1;
    padding-top: calc(var(--medium) / 2);
    padding-bottom: var(--x-large) !important;
    box-shadow: var(--color-off-white) 0px 1rem 0.625rem 0px;
  }
`;

export const HeaderContainer = styled.header`
  position: sticky;
  inset: 0;
  top: -1px;
  padding-top: 1px;
  background: var(--color-off-white);
  h1, .button, ${BreadcrumbsGridRow} {
    transition: 0.15s ease-in-out;
  }
  &.header-pinned {
    max-height: 156px;
  }
  &.header-pinned > .container > .row:first-child .button {
    opacity: 0;
    transform: scale(0);
  }
  &.header-pinned h1 {
    font-size: 1.5rem;
    padding-top: var(--medium);
  }
  &.header-pinned ${BreadcrumbsGridRow} {
    transform: scale(0);
    opacity: 0;
  }
`;

export const WizardStep = styled(Step)`
  &&& {
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--medium);
    position: relative;
    &, &:first-child {
      padding-left: 3.5rem;
    }
    ${FieldStatusCircle} {
      position: absolute;
      top: 50%;
      left: 1.5rem;
      margin-top: -0.5rem;
    }
    .content {
      overflow: hidden;
      .title {
        margin: 0;
        font-size: 1rem;
        color: var(--color-pure-black);
        line-height: 20px;
        margin-bottom: 0.5rem;
        ~ .description {
          font-size: 0.6875rem;
          font-weight: 400;
          color: var(--color-pure-black);
          margin-top: 0;
          line-height: 1rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
    &.selected {
      &:hover {
        cursor: default;
        background: var(--color-pure-white);
        &::after {
          background: var(--color-pure-white);
        }
      }
    }
    &:not(.selected) {
      .content {
        opacity: 0.5;
        .title {
          color: var(--color-charcoal);
          ~ .description {
            color: var(--color-charcoal);
          }
        }
      }
      ${FieldStatusCircle} {
        &:not(.success) {
          background: var(--color-charcoal);
        }
        opacity: 0.5;
      }
    }
  }
`;