import { useRef, useEffect } from 'react';
import { FormSchemaAwareWidget } from '~/components/form';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import { usePageType } from '~/hooks';
import { Form, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { useUpdateProject } from '~/mutations/project/useUpdateProject';
import { useUpdatePortfolio } from '~/mutations/portfolio/useUpdatePortfolio';
import toast from 'react-hot-toast';
import { Project } from '~/types/project';
import { Portfolio } from '~/types/portfolio';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

interface NotesFormProps {
  project?: Project;
  portfolio?: Portfolio;
}  

const Container = styled.div`
  &&& {
    textarea {
      width: 28rem;
      height: 5.5rem;
      resize: none;
      color: var(--color-charcoal);
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: var(--x-small) var(--small);
    }
  }
`;

function NotesFormImpl({
  project,
  portfolio,
}: NotesFormProps) {
  const { formState } = useForm() as any;
  const pageType = usePageType();
  const { id } = useParams() as any;

  const placeholderText = `Use this space to provide any additional information or context you believe to be relevant to the ${project ? 'project' : 'portfolio'}.`;
  const successMessage = `${project ? 'Project' : 'Portfolio'} notes updated successfully.`;
  const errorMessage = `Error occurred updating the ${project ? 'project' : 'portfolio'} notes. Please refresh the page and try again.`;

  const { mutate: updateProject } = useUpdateProject();
  const { mutate: updatePortfolio } = useUpdatePortfolio();

  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    try {
      if (project) { 
        // Update react-query's cache with the new project notes
        if (pageType === 'project') {
          queryClient.setQueryData(`project/${id}`, { ...project, projectNotes: formState.notes });
        } else {
          // For a project within a portfolio page, 
          // we must reach into the portfolio to update the project notes for the project row in the portfolio
          const portfolio = queryClient.getQueryData(`portfolio/${id}`) as any;
          if (portfolio?.projects) {
            portfolio.projects = portfolio.projects.reduce((acc: any, p: Project) => {
              if (p.id === project.id) {
                p.projectNotes = formState.notes;
              }
              acc.push(p);
              return acc;
            }, [] as Project[]);
            queryClient.setQueryData(`portfolio/${id}`, portfolio);
          }
        }

        // Update the project notes in the database
        await updateProject({
          id: project.id, 
          data: {
            projectNotes: formState.notes
          }
        });
      }  
      if (portfolio) {
        // Update react-query's cache with the new portfolio notes
        queryClient.setQueryData(`portfolio/${id}`, { ...portfolio, portfolioNotes: formState.notes });

        // Update the portfolio notes in the database
        await updatePortfolio({
          id: portfolio.id, 
          data: {
            portfolioNotes: formState.notes
          }
        });
      }
      toast.success(successMessage, { duration: 4000 });
    } catch (error) {
      console.error(error);
      toast.error(errorMessage, { duration: 4000 });
    }
  };

  // Auto-save project or portfolio notes
  const formStateRef = useRef(formState);
  const autoSaveNotesTimeoutRef = useRef();
  const formStateStatus = formState.notes || formState.notes === '' ;

  useEffect(() => {
    // Only autosave if a value actually changed
    if (formStateStatus && !isEqual(formState, formStateRef.current)) {

      // Clear any existing timeout so tons of saves don't happen for each key stroke
      if (autoSaveNotesTimeoutRef.current) {
        clearTimeout(autoSaveNotesTimeoutRef.current);
      }

      // Set a timeout to call the execute function after 3 seconds
      autoSaveNotesTimeoutRef.current = setTimeout(handleSubmit, 3000) as any;

      // Update the formState ref
      formStateRef.current = formState;
    }
  }, [formState]);

  return (
    <Form>
      <Container>
        <FormSchemaAwareWidget
          schemaKey='notes'
          as={TextArea}
          placeholder={placeholderText}
        />
      </Container>
    </Form>
  );
}

const NotesForm = ({
  project,
  portfolio,
}: NotesFormProps) => {

  const defaults =  {
    notes: project?.projectNotes || portfolio?.portfolioNotes || '' 
  };

  return (
    <FormContextProvider defaults={defaults}>
      <NotesFormImpl 
        project={project} 
        portfolio={portfolio} 
      />
    </FormContextProvider>
  );
};

export default NotesForm;