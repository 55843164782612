import { validate } from '~/schema';
import { validation as projectValidation } from '~/schema/project';
import { dedupeFilter, getEarliestProjectState, getProjectState, state2abbreviation, stringFieldSorter, stringSorter } from '~/utils';
import { calculateProjectCosts } from '~/utils/calculators';
import { PricingType, Unit } from '~/constants';
import { useEffect, useMemo } from 'react';
import { usePromiseWatcher } from '~/hooks/index';
import { fetchPortfolio } from '~/api/portfolios';
import projectDisplayValues from '~/utils/displayValues/project';
import { usePermissions } from '~/requests/permissions/usePermissions';
import formConditions from '~/utils/formConditions';
import formConfig from '~/schema/project/config';
import { Project } from '~/types/project';

export const usePortfolioCalculatedValues = (portfolio: any, projects: any[]) => {
  const { permissions } = usePermissions();

  const projectBidCountMap = useMemo(() => {
    const bidCountMap = {} as any;
    projects?.forEach((project: Project) => {
      if (project?.parentId && project?.pendingBid) {
        bidCountMap[project.parentId]  = (bidCountMap[project.parentId] || 0) + 1;
      }
    });
    return bidCountMap;
  }, [projects]);

  const projectCount = projects?.filter((p: any) => p.portfolioId === portfolio.id)?.length;

  const parentProjects = projects?.filter((p: any) => !p.parentId);
  const childProjects = projects?.filter((p: any) => p.parentId && p.investorId);

  // Create a map of parent IDs to child projects
  const parentProjectChildProjectMap = childProjects?.reduce((acc: any, childProject: any) => {
    if (!acc[childProject.parentId]) {
      acc[childProject.parentId] = [];
    }
    acc[childProject.parentId].push(childProject);
    return acc;
  }, {});

  const projectBlobs = useMemo(
    () => {
      if (!projects?.length) {
        return [];
      }
      let blobs = projects
        .map((project: Project) => {
          const conditions = formConditions({
            data: project,
            config: formConfig,
            getTransformedData: false
          });
          const projectErrors = validate(project, projectValidation, {}, '', project, project, formConfig, conditions);
          const projectState = getProjectState(project, projectErrors, portfolio);
          const projectCosts = calculateProjectCosts(project, { overall: true, perWdc: true, nrsFee: permissions?.hasPortfolioInvestorAccess });
          const displayValues = projectDisplayValues(project, projectCosts, permissions);
          return {project, projectErrors, projectState, projectCosts, projectDisplayValues: displayValues};
        });

      if (!portfolio.parentId) {
        // This is a portfolio still being bid on. Filter out child projects and set state accordingly
        const projectDealStateMap = blobs.filter((blob: any) => blob?.project?.parentId).reduce((acc: any, blob: any) => {
          if (blob?.projectState && blob?.project?.parentId) {
            if (!acc[blob.project.parentId]) {
              acc[blob.project.parentId] = blob.projectState.dealState;
            } else if (blob.projectState.dealState > acc[blob.project.parentId]) {
              acc[blob.project.parentId] = blob.projectState.dealState;
            }
          }
          return acc;
        }, {});
        blobs.forEach((blob: any) => {
          if (blob?.projectState && projectDealStateMap[blob?.project?.id] !== undefined) {
            blob.projectState.dealState = projectDealStateMap[blob.project.id];
          }
        });
      }
      blobs.sort(stringFieldSorter('project.name'));
      return blobs;
    },
    [projects]
  );

  const displayBlobs = useMemo(
    () => projectBlobs.filter((projectBlob: any) => !projectBlob.projectState.isDeclined),
    [projectBlobs]
  );

  const portfolioState = useMemo(
    () => getEarliestProjectState(projectBlobs, !permissions?.isAdmin && permissions?.hasPortfolioInvestorAccess),
    [projectBlobs, permissions]
  );

  const locations = useMemo(
    () => {
      return displayBlobs
        ?.map((projectBlob: any) => state2abbreviation(projectBlob.project.addressState) || 'unspecified')
        .filter(dedupeFilter)
        .sort(stringSorter)
        .join('; ') || <span>&nbsp;</span>;
    },
    [displayBlobs]
  );

  const totalBid = useMemo(
    () => {
      let allFlatUnits = undefined;
      let amount = 0;
      for (let projectBlob of displayBlobs) {
        const bid = projectBlob.project.acceptedBid ?? projectBlob.project.pendingBid;
        let isFlatUnit = bid?.unit ? (bid?.unit === Unit.TOTAL) : undefined;
        if (isFlatUnit === undefined) {
          continue;
        }
        if (allFlatUnits === undefined) {
          allFlatUnits = isFlatUnit;
        }
        if (isFlatUnit !== allFlatUnits) {
          return {unitLabel: 'Bids'};
        }
        if (isFlatUnit) {
          amount += bid?.amount as number;
        }
      }
      if (allFlatUnits === undefined) {
        return {};
      }
      if (allFlatUnits) {
        return {unitLabel: 'EPC Cost + Dev Fee', unit: Unit.TOTAL, amount};
      }
      return {unitLabel: 'Offtake Rate'};
    },
    [displayBlobs]
  );

  const portfolioTotals = useMemo(
    () => {
      let totals = {
        epcCost: 0,
        interconnection: 0,
        otherItc: 0,
        otherNonItc: 0,
        nrsFee: 0,
        total: 0,
        sizeKwdc: 0
      } as any;
      for (let projectBlob of displayBlobs) {
        const {project, projectCosts} = projectBlob;
        if (project.pricingType === PricingType.ACQUISITION && projectCosts.bid === undefined) {
          totals.epcCost = null;
        } else if (totals.epcCost != null) {
          totals.epcCost += projectCosts.overall.epcCost;
        }
        totals.interconnection += projectCosts.overall.interconnection;
        totals.otherItc += projectCosts.overall.otherItc;
        totals.otherNonItc += projectCosts.overall.otherNonItc;
        if (project.pricingType === PricingType.ACQUISITION && projectCosts.bid === undefined) {
          totals.nrsFee = null;
        } else if (totals.nrsFee != null && projectCosts.overall.nrsFee != null) {
          totals.nrsFee += projectCosts.overall.nrsFee;
        }
        totals.total += projectCosts.overall.total;
        if (projectCosts.sizeKwdc) {
          totals.sizeKwdc += projectCosts.sizeKwdc;
        }
      }
      return totals;
    },
    [displayBlobs]
  );

  

  return {
    projectBlobs,
    projectBidCountMap,
    portfolioState,
    totalBid,
    locations,
    portfolioTotals,
    displayBlobs,
    projectCount,
    parentProjects,
    childProjects,
    parentProjectChildProjectMap
  };
};

export const useFetchPortfolioCalculatedValues = (id: any, callback: any) => {
  const {execute: fetchPortfolioData, status: fetchPortfolioStatus, value: portfolio} = usePromiseWatcher(
    async () => id ? fetchPortfolio(id) : null,
    'fetching the portfolio\'s data',
    [id]
  );
  const {
    projectBlobs,
    portfolioState,
    totalBid,
    locations,
    portfolioTotals,
    displayBlobs
  } = usePortfolioCalculatedValues(portfolio, []); //TODO Portfolio typing

  useEffect(
    () => {
      if (!portfolio) {
        return;
      }
      callback({
        portfolio,
        projectBlobs,
        portfolioState,
        totalBid,
        locations,
        portfolioTotals,
        displayBlobs
      });
    },
    [portfolio, projectBlobs]
  );

  return {
    initiateValueCalculation: fetchPortfolioData,
    calculatedValuesStatus: fetchPortfolioStatus
  };
};
