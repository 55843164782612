import { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { useDataroomContext } from '../../providers/DataroomProvider';
import { DataroomDocument } from '~/types/dataroom';
import CustomDocumentTypeModal from '../../modals/CustomDocumentTypeModal';
import DeleteCustomDocumentTypeModal from '../../modals/DeleteCustomDocumentTypeModal';
import styled from 'styled-components';

const Button = styled.button`
  all: unset;
  &:hover {
    cursor: pointer;
  }
  &:focus-visible {
    outline: 2px solid var(--color-secondary);
  }
`;

export default function EditCustomDocuments({ documentType }: { documentType: DataroomDocument }) {
  const { editingDocumentTypes } = useDataroomContext();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      {editingDocumentTypes && documentType.isCustom && (
        <div style={{ display: 'flex', gap: 'var(--x-small)'}}>
          <Button 
            aria-label="Edit custom document type"
            onClick={(e: any) => {
              // Prevent the checkbox from being toggled when the edit button is clicked
              e.preventDefault();

              // Show the edit modal
              setShowEditModal(true);
            }}
          >
            <Icon name="pencil" color="blue" />
          </Button>
          <Button 
            onClick={(e: any) => {
              // Prevent the checkbox from being toggled when the delete button is clicked
              e.preventDefault();
              // Show the delete modal
              setShowDeleteModal(true);
            }}
          >
            <Icon name="trash alternate outline" color="red" />
          </Button>
        </div>
      )}
      {showEditModal && 
        <CustomDocumentTypeModal 
          documentType={documentType} 
          closeModal={() => setShowEditModal(false)}  
        />}
      {showDeleteModal && 
        <DeleteCustomDocumentTypeModal 
          documentType={documentType} 
          closeModal={() => setShowDeleteModal(false)} 
        />}
    </>
  );
}