import { CommentMessage } from '~/types/conversations/Message';
import { CommentIconAndLabel } from './CommentElements';
import { ResponseIcon } from './CommentElements';
import { RequestIcon } from './CommentElements';
import CommentResponseButton from './CommentResponseButton';

interface UserCommentResponseToggleProps {
  requiresResponse: boolean | undefined;
  message: CommentMessage;
  isOwnComment?: boolean;
};

export default function UserCommentResponseToggle ({ 
  requiresResponse,
  message,
  isOwnComment,
}: UserCommentResponseToggleProps) {

  if (!isOwnComment) {
    return null;
  }

  if (requiresResponse) {
    return (
      <CommentResponseButton message={message}> 
        <CommentIconAndLabel icon={ResponseIcon} label="Needs a response" />
      </CommentResponseButton>
    );
  } else {
    return (
      <CommentResponseButton message={message}>
        <CommentIconAndLabel icon={RequestIcon} label="Request a response" />
      </CommentResponseButton>
    );
  }
};

