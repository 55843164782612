import { usePromiseWatcher } from '~/hooks';
import { Button } from '~/components';
import { Form } from 'semantic-ui-react';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import Modal from '~/components/Modal/index';
import { useState } from 'react';
import { inviteTeammate } from '~/api/team';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { getListTeamMembersKey } from '~/requests/team';
import styled from 'styled-components';

const FormText = styled.p`
  &&& {
    color: var(--color-charcoal);
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 0;
    margin: 0 0 var(--medium) 0;
  }
`;

export default function InviteTeamMemberModal() {
  const { closeModal } = useModalContext();
  const [email, setEmail] = useState('');
  const queryClient = useQueryClient();

  const {execute: inviteTeamMember, status: inviteStatus} = usePromiseWatcher(
    () => inviteTeammate(email).then(() => {
      // Clear team member cache
      queryClient.invalidateQueries(getListTeamMembersKey());

      // Set a success toast
      toast.success('Successfully invited new team member');
      closeModal();
    }),
    `inviting ${email} to your team`,
    [email]
  );

  return (
    <Modal>
      <Modal.Title>
        Invite new team member
      </Modal.Title>
      <Modal.Content>
        <FormText>This person will receive an e-mail invitation from Conductor Solar and needs to create a Conductor Solar account through that link in order to automatically join your team.</FormText>
        <Form onSubmit={inviteTeamMember}>
          <Form.Field>
            <Form.Input 
              type="email" 
              placeholder="Enter team member's e-mail address"
              name="inviteTeamMemberEmail"
              onChange={evt => setEmail(evt.target.value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Button
          primary
          outlined
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          primary
          onClick={inviteTeamMember}
          disabled={!email || inviteStatus === 'pending'}
          loading={inviteStatus === 'pending'}
        >
          Send invitation
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
