import * as S from './styles';
import { Button, Modal } from '~/components';
import PPAModalDescriptionText from '../texts/PPAModalDescriptionText';
import { FormNumberInput, FormSchemaAwareWidget } from '~/components/form';
import { ProductionUnit, ProjectArrayDropdownOptions } from '~/constants';
import { STATES } from '~/utils';
import { CheckboxContainer } from '~/components/form/styles';
import { Checkbox, Form } from 'semantic-ui-react';
import { useProjectConfig } from '~/requests/projects/useProjectConfig';
import { ItcAdder, ItcAdders } from '~/types';
import { useForm } from '~/hooks';
import { InputType } from '~/schema';
import PriceEstimate from '../PriceEstimate';
import GeneratePPAButton from './GeneratePPAButton';
import { createProjectFromPPAQuote } from '~/api/projects';
import { useMutation } from 'react-query';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

export default function PPAForm () {
  const { formState, updateFormValue } = useForm() as any;
  const { data: config } = useProjectConfig();
  const { closeModal } = useModalContext();
  const itcAddersList: ItcAdders = config?.itcAdders ?? [];
  const history = useHistory();
  const [generatingQuote, setGeneratingQuote] = useState(false);

  const { mutate: saveProject, status: saveProjectStatus } = useMutation(async (id: string) => {
    try {
      await createProjectFromPPAQuote(id);
      // Navigate to the project page
      history.push(`/project/${id}/wizard`);
      toast.success('Successfully created a project with your initial market quote!', { duration: 5000 });
      closeModal();
    } catch (err) {
      console.error(err);
      toast.error('An error occurred saving your initial market quote. Please refresh the page and try again', { duration: 5000 });
    }
  });

  return (
    <>
      <Modal.Content>
        <PPAModalDescriptionText />
        <Form>
          <S.Row>
            <FormSchemaAwareWidget
              fieldLabel="Project name"
              placeholder="Enter value"
              schemaKey="name"
            />
          </S.Row>
          <S.Row>
            <FormSchemaAwareWidget
              fieldLabel="State"
              placeholder="Enter value"
              schemaKey="addressState"
              search
              options={STATES}
            />
            <FormSchemaAwareWidget
              fieldLabel="Array type"
              placeholder="Select an array type"
              schemaKey="arrayType"
              options={ProjectArrayDropdownOptions}
            />
          </S.Row>
          <S.Row>
            <div className="labelled-input">
              <FormNumberInput
                fieldLabel="Size in kWdc"
                placeholder="Enter value"
                schemaKey="sizeKwdc"
                label="kWdc"
              />
            </div>
            <div className="yearOneProduction">
              <FormNumberInput
                schemaKey="yearOneProduction"
                fieldLabel="Year one production"
                placeholder="Enter production"
                unitField="yearOneProductionUnit"
                defaultUnit={ProductionUnit.KWH}
                units={[
                  { value: ProductionUnit.KWH, label: 'kWh' },
                  { value: ProductionUnit.KWHKW, label: 'kWh/kW' },
                ]}
              />
            </div>
          </S.Row>
          <CheckboxContainer style={{ marginTop: '0.75rem' }}>
            <Form.Field className="label">
              <label>ITC adders</label>
            </Form.Field>
            <Form.Group>
              {itcAddersList.map((itcAdder) => {
                const isSelected = !!formState?.itcAdders?.find((selectedItcAdder: ItcAdder) => selectedItcAdder.code === itcAdder.code);
                return (
                  <Form.Field key={itcAdder.id}>
                    <Checkbox
                      checked={isSelected}
                      label={`${itcAdder.label} (${itcAdder.value}%)`}
                      onChange={() => {
                        if (isSelected) {
                          // Remove the ITC adder
                          updateFormValue('itcAdders', formState?.itcAdders?.filter((adder: ItcAdder) => adder.id !== itcAdder.id));
                        } else {
                          // Add the ITC adder
                          updateFormValue('itcAdders', [...(formState?.itcAdders ?? []), itcAdder]);
                        }
                      }}
                    />
                  </Form.Field>
                );
              })}
            </Form.Group>
          </CheckboxContainer>
          <S.Row>
            <div className="labelled-input">
              <FormNumberInput
                schemaKey="epcCost"
                fieldLabel="Total project budget"
                label="$ / Wdc"
              />
            </div>
            <div /> {/* Empty div here forces epcCost field to take only half width */}
          </S.Row>
          <S.Row>
            <FormNumberInput
              schemaKey="upfrontIncentives"
              fieldLabel="Upfront incentives"
              numberType={InputType.DOLLARS}
              decimals={0}
              inputStyle={{ width: '100%' }}
            />
            <FormNumberInput
              schemaKey="yearOneSavings"
              fieldLabel="Year one customer savings"
              numberType={InputType.DOLLARS}
              decimals={0}
              inputStyle={{ width: '100%' }}
            />
          </S.Row>
          <S.Row>
            <FormNumberInput
              schemaKey="contractTerm"
              fieldLabel="Contract term"
              decimals={0}
              inputStyle={{ width: '100%' }}
            />
            <FormNumberInput
              schemaKey="contractEscalator"
              fieldLabel="Annual contract escalator"
              numberType={InputType.PERCENT}
              decimals={2}
              inputStyle={{ width: '100%' }}
            />
          </S.Row>
        </Form>
        {formState.project?.priceEstimateLow !== undefined && formState.project?.priceEstimateHigh !== undefined && (
          <PriceEstimate />
        )}
      </Modal.Content>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <GeneratePPAButton 
          disabled={saveProjectStatus === 'loading'} 
          onClick={() => setGeneratingQuote(true) } 
          onFinish={() => setGeneratingQuote(false)}
        />
        {!!formState?.project?.id && !generatingQuote && (
          <Button 
            primary 
            onClick={() => saveProject(formState.project.id)}
            disabled={saveProjectStatus === 'loading'}
            loading={saveProjectStatus === 'loading'}
          >
            Save my quote
          </Button>
        )}
      </Modal.Footer>
    </>
  );
}