import React, { useEffect, useMemo } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { InvestorBidRate } from '~/constants';
import { usePromiseWatcher, useForm, useProjectContext, usePortfolioContext } from '~/hooks';
import { getInvestors } from '~/api/admin';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import { InputType } from '~/schema/InputType';
import { FormNumberInput, FormSchemaAwareWidget } from '~/components/form';

interface InvestorInvitationProps {
  investorQueue: any;
  label: string;
  assignToInvestor: any;
  preferredInvestors: any;
  defaults?: any;
}

const InvestorInvitationImpl: React.FC<InvestorInvitationProps> = ({
  investorQueue, 
  label, 
  assignToInvestor, 
  preferredInvestors
}) => {
  const { portfolio } = usePortfolioContext();
  const { project } = useProjectContext();
  const {formState} = useForm() as any;

  const {execute: executeGetInvestors, value: allInvestors } = usePromiseWatcher(
    async () => {
      if (!investorQueue) {
        return [];
      }
      return getInvestors();
    },
    'fetching the available investors list',
    [investorQueue],
    {defaultValue: [] as any}
  ) as any;
  useEffect(executeGetInvestors, []);
  const possibleInvestors = useMemo(
    () => {
      const allPossible = allInvestors.filter((investor: any) => {
          return !investorQueue
            .map((iq: any) => iq.investorId)
            .includes(investor.id);
        })
        .map((investor: any) => ({
          key: investor.id,
          text: investor.company.name,
          value: investor.id,
        }));

      if (!preferredInvestors?.length) {
        return allPossible;
      }

      const preferred = allPossible
        .filter((investor: any) => !!preferredInvestors.find((pInvestor: any) => {
          return investor.key === pInvestor.id;
        }))
        .map((investor: any) => ({
          ...investor,
          text: `*** ${investor.text}`
        }));
      const others = allPossible.filter((investor: any) => !preferredInvestors.find((pInvestor: any) => investor.key === pInvestor.id));
      return preferred.concat(others);
    },
    [investorQueue,allInvestors]
  );

  const {execute: executeAssignToInvestor} = usePromiseWatcher(
    () => assignToInvestor(formState),
    {messageStub: `adding the investor to the ${label}'s queue`},
    [assignToInvestor, formState]
  );

  return (
    <div >
      <Form 
        style={{
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'left', 
          gap: 'var(--medium)',
        }}
      >
        <FormSchemaAwareWidget
          schemaKey="investorId"
          fieldLabel="Select Investor"
          options={possibleInvestors}
          search
          placeholder='Select Investor'
          fieldStyle={{ marginBottom: '0'}}
        />
        <Form.Group 
          style={{
            display: 'flex',
            justifyContent: 'space-between', 
            marginBottom: '0'
            }}
          >
            <FormNumberInput
              schemaKey="nrsFee"
              fieldLabel='Conductor Solar Fee'
              numberType={formState.nrsFeeUnit === InvestorBidRate.DOLLARS_PER_WDC ? InputType.DOLLARS : InputType.PERCENT}
              inputStyle={{ color: 'var(--color-charcoal)', textAlign: 'right' }}
              decimals={formState.nrsFeeUnit === InvestorBidRate.DOLLARS_PER_WDC ? 3 : 2}
            />
          <Form.Field style={{ alignSelf: 'end'}}>
            <Button
              size="medium"
              secondary
              onClick={executeAssignToInvestor}
              disabled={!portfolio?.bidDeadline && !project?.bidDeadline}
            >
              Add to Queue
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
};

const InvestorInvitationForm: React.FC<InvestorInvitationProps> = ({
  investorQueue, 
  label, 
  assignToInvestor, 
  preferredInvestors, 
  defaults = {
    investorId: '',
    nrsFee: 1,
    nrsFeeUnit: InvestorBidRate.PERCENT_OF_BUDGET,
}}) => {
  return (
    <FormContextProvider defaults={defaults}>
      <InvestorInvitationImpl {...{investorQueue, label, assignToInvestor, preferredInvestors}}/>
    </FormContextProvider>
  );
};

export default InvestorInvitationForm;
