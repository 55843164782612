import { ContractType, ContractTypeDisplayShort, PricingType, ProjectStatus, RevenueSource } from '~/constants';
import { getProjectBidOverallAmount } from '~/utils/calculators/costs';
import { formatNumber } from '~/utils/formatNumber';
import { Project } from '~/types/project';

function getOfftakeRate(project: Project) {
  const bid = project?.pendingBid || project?.acceptedBid;
  let value = 'Rate TBD';
  
  if (project.pricingType === PricingType.OFFTAKE && project.contractType === ContractType.PPA && bid) {
    // Is OFFTAKE pricing type and is PPA contract type
    value = `$${formatNumber(bid?.amount, 4)} per kWh`;
  } else if (project.pricingType === PricingType.OFFTAKE && bid) {
    // Is OFFTAKE pricing type and is LEASE or ESA contract type
    value = `$${formatNumber(getProjectBidOverallAmount(project, bid, false), 0)} per year`;
  } else if (project?.contractRate && project?.contractType === ContractType.PPA) {
    // Is PPA contract type with a contract rate set
    value = `$${formatNumber(project?.contractRate, 4)} per kWh`;
  } else if (project?.contractRate) {
    // Is LEASE or ESA contract type with a contract rate set
    value = `$${formatNumber(project?.contractRate, 0)} per year`;
  }

  // Return TBD if none of the above scenarios apply
  return value;
}

const getOfftakeRateValueString = (project: Project) => {
  if (project.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS && !project.revenueAssumptionsFlag) {
    return 'Community solar — investor to make own assumptions';
  } else if (project.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS) {
    return 'Community solar - see data for recommended assumptions';
  }

  const type = project?.contractType ? ContractTypeDisplayShort[project.contractType] : undefined;
  const rate = getOfftakeRate(project);
  const term = project?.contractTerm === undefined || project?.contractTerm === null ? 'Term TBD' : `${project.contractTerm} Year Term`;
  const escalator = project?.contractEscalator === undefined || project?.contractEscalator === null ? 'Escalator TBD' : `${project.contractEscalator}% Escalator`;



  return `${type}: ${rate}, ${term}, ${escalator}`;
};

export default function offtakeRate(project: Project) {
  

  return {
    isDisplaying: !!project.contractType,
    isHighlighted: project?.status === ProjectStatus.IN_DILIGENCE && project?.pricingType === PricingType.OFFTAKE,
    value: getOfftakeRateValueString(project)
  };
}