import Modal from '~/components/Modal';
import { useMutation } from 'react-query';
import { Button } from '~/components';
import { copyProjectDoclist } from '~/api/dataroom';
import { withPromiseToaster } from '~/utils';
import { Dataroom } from '~/types/dataroom';
import { useDataroomQuery } from '~/requests/dataroom';
import { useDataroomContext } from '../providers/DataroomProvider';
import { Project } from '~/types/project';

interface CopyProjectDocumentTypesModalProps {
  projectToCopy: Project;
  closeModal: () => void;
}

export default function CopyProjectDocumentTypesModal({ projectToCopy, closeModal }: CopyProjectDocumentTypesModalProps) {
  const { dataroom, setEditingDocumentTypes } = useDataroomContext();
  const { updateDataroomCache } = useDataroomQuery();

  // Mutation for copying the project document list 
  // and then updating the queryClient's doclist and dataroom data
  const { mutate: copyProject, status } = useMutation(() => {
    return withPromiseToaster(
      copyProjectDoclist(dataroom.id, projectToCopy.id), 
      { messageStub: 'copying project document list' },
    ).then((dataroom: Dataroom) => {
      updateDataroomCache(dataroom);
      setEditingDocumentTypes(false);
      closeModal();
    });
  });

  return (
    <Modal allowClose={status !== 'loading'} closeModal={closeModal}>
      <Modal.Title>Are you sure?</Modal.Title>
      <Modal.Content>
        <p>Copying a previous project document list will overwrite your current document list.</p>
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary
          loading={status === 'loading'}
          disabled={status === 'loading'}
          onClick={() => copyProject()}
        >
          Copy
        </Button>
        <Button
          primary 
          outlined
          disabled={status === 'loading'}
          onClick={closeModal}
        >
          Go Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}