import { cloneDeep } from 'lodash';

const hasValue = (value: any) => {
  return value !== undefined && value !== null && value !== '';
};

export const format = (project: any) => {
  const data = cloneDeep(project);
  data.operatingExpenses = data.additionalOperatingExpenses || [];
  delete data.additionalOperatingExpenses;
  if (
    hasValue(data.storageExpenseAmount) ||
    hasValue(data.storageExpenseEscalator) ||
    hasValue(data.storageExpenseNotes) ||
    hasValue(data.storageExpenseExpectedTerm)
  ) {
    data.operatingExpenses.push({
      id: data.storageExpenseId,
      type: 'STORAGE',
      unit: data.storageExpenseUnit || 'KWDC',
      amount: data.storageExpenseAmount,
      escalator: data.storageExpenseEscalator,
      notes: data.storageExpenseNotes,
      expectedTerm: data.storageExpenseExpectedTerm,
      order: -1
    });
    delete data.storageExpenseId;
    delete data.storageExpenseUnit;
    delete data.storageExpenseAmount;
    delete data.storageExpenseEscalator;
    delete data.storageExpenseNotes;
    delete data.storageExpenseExpectedTerm;
  }

  if (
    hasValue(data.subscriptionExpenseAmount) ||
    hasValue(data.subscriptionExpenseEscalator) ||
    hasValue(data.subscriptionExpenseNotes) ||
    hasValue(data.subscriptionExpenseExpectedTerm)
  ) {
    data.operatingExpenses.push({
      id: data.subscriptionExpenseId,
      type: 'SUBSCRIPTION',
      unit: data.subscriptionExpenseUnit || 'KWDC',
      amount: data.subscriptionExpenseAmount,
      escalator: data.subscriptionExpenseEscalator,
      notes: data.subscriptionExpenseNotes,
      expectedTerm: data.subscriptionExpenseExpectedTerm,
      order: -1
    });
    delete data.subscriptionExpenseId;
    delete data.subscriptionExpenseUnit;
    delete data.subscriptionExpenseAmount;
    delete data.subscriptionExpenseEscalator;
    delete data.subscriptionExpenseNotes;
    delete data.subscriptionExpenseExpectedTerm;
  }

  return data;
};

export const parse = (project: any) => {
  if (project.redirectToProjectId) return project;
  
  const data = cloneDeep(project);
  data.additionalOperatingExpenses = data.operatingExpenses.filter((o: any) => o.type !== 'STORAGE' && o.type !== 'SUBSCRIPTION');

  const storageExpense = data.operatingExpenses.find((o: any) => o.type === 'STORAGE');
  data.storageExpenseId = storageExpense?.id;
  data.storageExpenseUnit = storageExpense?.unit;
  data.storageExpenseAmount = storageExpense?.amount;
  data.storageExpenseEscalator = storageExpense?.escalator;
  data.storageExpenseNotes = storageExpense?.notes;
  data.storageExpenseExpectedTerm = storageExpense?.expectedTerm;

  const subscriptionExpense = data.operatingExpenses.find((o: any) => o.type === 'SUBSCRIPTION');
  data.subscriptionExpenseId = subscriptionExpense?.id;
  data.subscriptionExpenseUnit = subscriptionExpense?.unit;
  data.subscriptionExpenseAmount = subscriptionExpense?.amount;
  data.subscriptionExpenseEscalator = subscriptionExpense?.escalator;
  data.subscriptionExpenseNotes = subscriptionExpense?.notes;
  data.subscriptionExpenseExpectedTerm = subscriptionExpense?.expectedTerm;

  // Always have at least one array
  if (!data.projectArrays?.length) {
    data.projectArrays = [{ projectId: data.id, order: 0 }];
  }

  delete data.operatingExpenses;
  return data;
};