import styled from 'styled-components';
import { useProjectContext } from '~/hooks';
import WizardForm from '../../../WizardForm';
import ProductionValidationInfo from './ProductionValidationInfo';
import ProductionValidationOptions from './ProductionValidationOptions';
import ProductionValidationTable from './ProductionValidationTable';
import ProductionValidationEstimateButton from './ProductionValidationEstimateButton';

const ProductionValidationContainer = styled.div`
  > * + * {
    margin-top: var(--medium);
  }
`;

export default function ProductionValidation() {
  const { project, projectDisplayValues } = useProjectContext();

  return ( 
    <ProductionValidationContainer>
      <ProductionValidationInfo />
      <WizardForm>
        <ProductionValidationOptions />
        <ProductionValidationTable project={project} />
        <ProductionValidationEstimateButton project={project} values={projectDisplayValues} />
      </WizardForm>
    </ProductionValidationContainer>
  );
}