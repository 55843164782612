import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import UserSignup from '~/features/user/signup/UserSignup';
import { usePromiseWatcher } from '~/hooks';
import { verifyToken } from '~/api/team';
import { AsyncLoading } from '~/components';

function InviteSignup() {
  const {token} = useParams() as any;
  const {execute, status, value} = usePromiseWatcher(
    () => verifyToken(token),
    {
      loading: null,
      success: null,
      messageStub: 'verifying your invite token',
      error: (e: any) => {
        if (e.status === 404) {
          return e.message;
        }
        return undefined;
      }
    },
    [token]
  ) as any;

  useEffect(execute, []);

  return (
    <Container>
      <AsyncLoading status={status}>Verifying Token...</AsyncLoading>
      {value && 
          <UserSignup 
            presetEmail={value.email} 
            inviteToken={token} 
            userType={value.role?.code} 
            externalCompanyId={value.externalCompanyId} 
          /> 
      }
    </Container>
  );
}

export default InviteSignup;
