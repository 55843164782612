import { Table } from '~/components';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > button {
      all: unset;
      width: 100%;
      height: 100%;
      transition: 0.2s background-color;
      &:hover,
      &:focus-visible {
        background-color: var(--color-grey);
        cursor: pointer;
      }
    }
  }
`;

const TableCellButton = ({
  textAlign = 'center',
  ...props
}: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  textAlign?: 'center' | 'left' | 'right';
}) => {
  
  return (
    <Table.Cell textAlign={textAlign} style={{ padding: 0 }}>
      <ButtonWrapper>
        <button
          style={{ 
            width: '100%', 
            height: '100%', 
            minHeight: '3rem' // Something to revisit. Ideally we could make this take up full height
          }} 
          {...props} 
        />
      </ButtonWrapper>
    </Table.Cell>
  );
};

export default TableCellButton;