import axios from 'axios';
import Cookies from 'js-cookie';
import { withPromiseToaster, storage } from '~/utils';

// Constants
const CSRF_TOKEN_HEADER = 'x-csrf-token';
const CSRF_TOKEN_COOKIE = 'csrf_token';

// Internal promise for requesting an access token.
// Used so subsequent requests can wait for access token to be fetched
let accessTokenRequest: Promise<any> = Promise.resolve();
let getCsrfTokenRequest: Promise<any> = Promise.resolve();
let isRefreshing = false; // NOT_RUNNING, REFRESHING, REFRESH_ERROR
let isGettingCsrf = false;
const spoofing = storage.getSpoofedEmail() ? {'x-spoofed-email': storage.getSpoofedEmail()} : {};


// Axios instance to use everywhere in the app
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  withCredentials: true,
  headers: {
    [CSRF_TOKEN_HEADER]: Cookies.get(CSRF_TOKEN_COOKIE),
    ...spoofing,
  },
});

// Interceptor to get CSRF token before running the request
axiosInstance.interceptors.request.use(async request => {
  if (Cookies.get(CSRF_TOKEN_COOKIE) && !request.headers[CSRF_TOKEN_HEADER]) {
    // Set the header before running the request
    request.headers[CSRF_TOKEN_HEADER] = Cookies.get(CSRF_TOKEN_COOKIE);
  } else if (!Cookies.get(CSRF_TOKEN_COOKIE) && request.url !== '/auth/csrf') {
    // Start csrf token request if it has not been started yet
    if (!isGettingCsrf) {
      isGettingCsrf = true;
      getCsrfTokenRequest = new Promise((resolve, reject) => {
        return axiosInstance
          .get('/auth/csrf')
          .then(response => {
            Cookies.set(CSRF_TOKEN_COOKIE, response.data.token);
            axiosInstance.defaults.headers.common[CSRF_TOKEN_HEADER] = response.data.token;
            resolve(response);
          })
          .catch(err => {
            console.error('Error occurred getting csrf token: ', err);
            reject(err);
          })
          .finally(() => {
            isGettingCsrf = false;
          });
      });
    }

    // Wait for access token refresh to complete
    await withPromiseToaster(getCsrfTokenRequest, 'getting CSRF token');

    // Set the CSRF Token header
    request.headers[CSRF_TOKEN_HEADER] = Cookies.get(CSRF_TOKEN_COOKIE);
  }

  // Run the request
  return request;
});

// Interceptor to handle auth and request retries
axiosInstance.interceptors.response.use(
  // No need to do anything if everything is good.
  response => response,

  // Attempt refresh token fetch if we get a 401.
  // Redirect to login page if cannot refresh token
  async err => {
    const originalRequest = err.config;

    if (err?.response?.status === 401 && originalRequest.url === '/auth/refresh-token') {
      const currentPath = window.location.pathname;
      const currentSearch = window.location.search;
      Cookies.remove(CSRF_TOKEN_COOKIE);
      storage.storeCurrentUser(null);
      window.location.href = '/login?redirect_uri=' + encodeURIComponent(currentPath + currentSearch);
    } else if (err?.response?.status === 401) {
      // Start access token refresh if it has not been started yet
      if (!isRefreshing) {
        isRefreshing = true;
        accessTokenRequest = new Promise((resolve, reject) => {
          return axiosInstance
            .get('/auth/refresh-token')
            .then(response => {
              storage.storeCurrentUser(response.data);
              resolve(response);
            })
            .catch(err => {
              console.error('Error occurred refreshing token: ', err);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }

      try {
        // Wait for access token refresh to complete
        await accessTokenRequest;

        // Re-run request after acquiring a fresh access token
        return axiosInstance(originalRequest);
      } catch (err) {
        console.error(`Unable to complete request to ${originalRequest.url}. Login required.`);
      }
    } else {
      return Promise.reject(err);
    }
  },
);

export default axiosInstance;
