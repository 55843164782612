import { useQuery } from 'react-query';
import { getExternalUsers } from '~/api/team';

export const listExternalTeamMembersKey = () => {
  return 'team/listExternalUsers';
};

export const useExternalTeamMembersQuery = (queryParams = {}) => {
  return useQuery({
    queryKey: listExternalTeamMembersKey(),
    queryFn: getExternalUsers,

    // Cache forever until the cache is cleared manually
    staleTime: Infinity,

    // No retries
    retry: false,

    // Allow overriding default react-query props
    ...queryParams
  });
};