import { declineProjectBid } from '~/api/projectBids';
import { useMutation } from 'react-query';
import { withPromiseToaster } from '~/utils';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import Modal from '~/components/Modal';
import { Button } from '~/components';


export default function DeclineProjectBidModal() {
  const { modalProps, closeModal } = useModalContext();
  const { projectId, onComplete } = modalProps;

  if (!projectId || !onComplete) {
    throw new Error('Missing 1 or more required modal props');
  }

  const { mutate: declineBid, status } = useMutation(() => {
    return withPromiseToaster(
      declineProjectBid(projectId),
      {
        messageStub: 'declining the project bid'
      }
    ).then(() => {
      onComplete();
      closeModal();
    });
  });
    
  const decliningBid = status === 'loading';
      
  return (
    <Modal allowClose={!decliningBid}>
      <Modal.Title>Decline project bid?</Modal.Title>
      <Modal.Content>
        <p>Are you sure you want to decline this project bid? This cannot be undone without contacting an administrator.</p>
      </Modal.Content>
      <Modal.Footer>
        <Button primary outlined disabled={decliningBid} onClick={closeModal}>Go back</Button>
        <Button primary loading={decliningBid} disabled={decliningBid} onClick={() => declineBid()}>Continue</Button>
      </Modal.Footer>
    </Modal>
  );
}