import WizardForm from '~/features/projectWizard/components/WizardForm';
import BudgetSummary from './BudgetSummary';
import EpcCosts from './EpcCosts';
import BudgetCosts from './BudgetCosts';
import EpcCostsText from './EpcCostsText';


export default function BudgetItems() {
  return (
    <WizardForm>
      <BudgetSummary />
      <EpcCosts /> 
      <EpcCostsText />      
      <BudgetCosts />
    </WizardForm>
  );
};