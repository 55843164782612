import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  > * {
    margin: 0;
    + *:nth-child(2) {
      margin-top: var(--medium);
    }
  }
`;

export const BidAreaHeader = styled.div`
  display: flex;
  align-items: center;
  .ui.header {
    margin: 0;
    + * {
      margin-left: var(--large);
    }
  }
`;

export const BidForm = styled(Form)`
  > * + * {
    margin-top: var(--medium);
  }
`;

export const BidFormCheckbox = styled.div`
  &&& {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    .field {
      margin: 0;
      margin-right: var(--x-small);
      display: flex;
      align-items: center;
    }
    & + & {
      margin-top: var(--small);
    }
  }
`;

export const BidFormActions = styled.div`
  display: flex;
  align-items: center;
  > .ui.button + .ui.button {
    margin-left: var(--small);
  }
  margin-bottom: var(--large);
`;