import toast from 'react-hot-toast';
import { getFileMessagePresignedUrl } from '~/api/conversations';
import { useQuery } from 'react-query';

interface DownloadMessageFileParams {
  conversationId: string
  messageId: string
}

export const getDownloadMessageFileKey = ({ conversationId, messageId }: DownloadMessageFileParams) => {
  return ['downloadMessageFile', conversationId, messageId];
};

export const useDownloadMessageFileQuery = (params: DownloadMessageFileParams, queryParams = {}) => {
  return useQuery({
    queryKey: getDownloadMessageFileKey(params),
    queryFn() {
      return getFileMessagePresignedUrl(params.conversationId, params.messageId)
        .catch((error: any) => {
          console.error(error);
          toast.error('An error occurred downloading the file. Please try again');
        });
    },
    onSuccess(presignedUrl) {
      // Download the file by navigating to the presigned url
      if (presignedUrl) {
        (window as Window).location = presignedUrl as string;
      }
    },

    // Cache for 5 minutes
    staleTime: 60 * 1000 * 5, 

    // Don't automatically fetch the file.
    // This is meant to be called as-needed in response to a
    // button click via the "download" prop returned by this hook.
    enabled: false,

    // No retries
    retry: false,

    // Allow overriding default react-query props
    ...queryParams
  });
};