import { Container } from 'semantic-ui-react';
import styled from 'styled-components';

export const ClientLibraryContainer = styled(Container)`
  &&& {
    max-width: 800px !important;
    padding-top: 32px;
  }
`;

export const Section = styled.section`
  & + & {
    margin-top: 32px;
  }
  code, pre {
    display: block;
    width: 100%;
    margin-right: auto;
    background: #f1f1f1;
    padding: 16px;
    color: black;
    margin-top: 16px;
    border-radius: 6px;
  }
`;

export const SectionRow = styled.div`
  & + & {
    margin-top: 20px;
  }
`;