import ProjectFoundations from './ProjectFoundations';
import ProjectRoles from './ProjectRoles';
import KeyDatesAndStatuses from './KeyDatesAndStatuses';
import Location from './Location';

const Overview = {
  ProjectFoundations,
  ProjectRoles,
  KeyDatesAndStatuses,
  Location,
};

export default Overview;