import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

// TODO - figure out how to not need !important here (it's getting overridden in certain cases)
export const HelpText = styled.p`
  font-size: 0.6875rem !important;
  color: var(--color-charcoal) !important;
  line-height: 1rem !important;
`;
HelpText.defaultProps = { className: 'help-text' };

export const SchemaAwareWidgetField = styled(Form.Field)`
  position: relative;
  .error-message {
    bottom: calc(-1 * var(--2x-small) - 0.8125rem);
  }
  &&& {
    > label.withHelpText {
      margin-bottom: 0;
      + .help-text {
        margin-bottom: var(--2x-small);
      }
    }
    .react-datepicker {
      font-family: var(--font-family);
      border-color: var(--color-charcoal);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.32), 1px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 0.1875rem;
      background-color: var(--color-pure-white);
      &-wrapper {
        width: 100%;
      }
      &__day-name {
        font-size: 0.6875rem;
        line-height: 1rem;
        font-weight: 400;
        color: var(--color-charcoal);
      }
      &__day {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
      }
      &__day--selected,
      &__day--keyboard-selected {
        background-color: var(--color-primary);
      }
      &__header {
        background: var(--color-grey);
      }
      &-popper[data-placement^="top"] .react-datepicker__triangle {
        border-top-color: var(--color-pure-white);
        &::before {
          border-top-color: var(--color-charcoal);
        }
      }
      &-popper[data-placement^="bottom"] .react-datepicker__triangle {
        border-bottom-color: var(--color-grey);
        &::before {
          border-bottom-color: var(--color-charcoal);
        }
      }
      &__navigation--previous {
        border-right-color: var(--color-charcoal);
      }
      &__navigation--next {
        border-left-color: var(--color-charcoal);
      }
    }
    .ui.input {
      display: flex;
      .radio-container {
        align-self: center;
        margin-left: var(--medium);
      }
    }
    .ui.labeled.input > input {
      border-right: none;
    }
    .ui.labeled.input > .ui.label {
      font-size: 0.875rem;
      background: var(--color-charcoal);
      color: var(--color-off-white);
      font-weight: 700;
      padding: 0 var(--small);
      display: flex;
      align-items: center;
      border-radius: 0px 0.1875rem 0.1875rem 0px;
    }
    &.error .ui.labeled.input > .ui.label {
      border-top: 1px solid var(--color-red);
      border-right: 1px solid var(--color-red);
      border-bottom: 1px solid var(--color-red);
    }
  }
`;

const RadioCheckboxContainer = styled.div<{ stretched?: boolean }>`
  &&& {
    position: relative;
    .error-message {
      bottom: calc(-1 * var(--x-small));
      font-size: 0.75rem;
    }
    .field.label {
      margin: 0;
      padding: 0;
      label {
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem; /* 125% */
        letter-spacing: -0.01rem;
        padding: var(--small) 0 var(--x-small) 0;
        margin: 0;
        + p {
          margin-bottom: var(--x-small);
          color: var(--color-charcoal);
        }
      }
    }

    ${HelpText} {
      font-size: .875rem;
      line-height: 1.25rem;
    }

    .fields.inline {
      margin: 0;
      ${props => props.stretched && `
        padding-top: var(--x-small);
        justify-content: space-between;
      `}
      .error-message {
        bottom: calc(-1 * var(--medium));
      }
    }
    .fields:not(.inline) {
      flex-direction: column;
      margin: 0 auto;
      padding: 0;
      padding-top: var(--x-small);
      padding-bottom: 1.25rem;
      .field {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        + .field {
          margin-top: 0.75rem;
        }
      }
      label {
        padding: 0;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: -0.004375rem;
      }
    }
    .field > .checkbox > label {
      padding-left: calc(1rem + var(--x-small));
      &::before,
      &::after {
        top: 50%;
        margin-top: -0.5rem;
      }
      line-height: 1.25rem;
    }
    .field > .checkbox:not(.radio) > label {
      &::after {
        margin-top: -0.625rem;
        font-size: 0.75rem;
      }
    }
  }
`;

export const RadioContainer = RadioCheckboxContainer;
export const CheckboxContainer = RadioCheckboxContainer;