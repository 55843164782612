export const PricingType = {
  ACQUISITION: 'ACQUISITION',
  OFFTAKE: 'OFFTAKE',
  ITC: 'ITC',
  DEV_FEE: 'DEV_FEE',
  BRIDGE_LOAN: 'BRIDGE_LOAN'
};

export const PricingTypeDisplay = {
  [PricingType.ACQUISITION]: 'EPC cost + dev fee',
  [PricingType.DEV_FEE]: 'Development fee',
  [PricingType.OFFTAKE]: 'Offtake rate',
  [PricingType.ITC]: 'ITC transferability',
  [PricingType.BRIDGE_LOAN]: 'Construction Bridge Loan'
};

export const PricingTypeDescriptions = {
  [PricingType.ACQUISITION]: '(how much will an investor pay for the development and turnkey construction of this project)',
  [PricingType.DEV_FEE]: '(what is the specific development fee an investor will pay for this project, given an EPC price and all other budget items)',
  [PricingType.OFFTAKE]: '(quoting a PPA, lease, or ESA rate)',
  [PricingType.ITC]: '(selling just the tax credits)',
  [PricingType.BRIDGE_LOAN]: '(for bridging other funding events anticipated soon after construction is completed, such ITC transfer fundings, ITC direct pay, REAP grants, or other incentives)'
};

export const PricingTypeDropdownOptions = [
  {
    value: PricingType.ACQUISITION,
    label: `${PricingTypeDisplay[PricingType.ACQUISITION]} ${PricingTypeDescriptions[PricingType.ACQUISITION]}`
  },
  // {
  //   value: PricingType.DEV_FEE,
  //   label: `${PricingTypeDisplay[PricingType.DEV_FEE]} ${PricingTypeDescriptions[PricingType.DEV_FEE]}`
  // },
  {
    value: PricingType.OFFTAKE,
    label: `${PricingTypeDisplay[PricingType.OFFTAKE]} ${PricingTypeDescriptions[PricingType.OFFTAKE]}`
  },
  // {
  //   value: PricingType.ITC,
  //   label: `${PricingTypeDisplay[PricingType.ITC]} ${PricingTypeDescriptions[PricingType.ITC]}`
  // },
  // {
  //   value: PricingType.BRIDGE_LOAN,
  //   label: `${PricingTypeDisplay[PricingType.BRIDGE_LOAN]} ${PricingTypeDescriptions[PricingType.BRIDGE_LOAN]}`
  // }
];