import useResizeObserver from '@react-hook/resize-observer';
import { CSSProperties, FC, ReactNode, useRef, useState } from 'react';
import { useDataroomContext } from '../../providers/DataroomProvider';

const MainContentContainer: FC<{ children: ReactNode }> = function({ children }) {
  const { isSimplifiedUI } = useDataroomContext();

  // Calculate the exact placement of the mainContentRef to determine where to but a sticky fixed element at the bottom of it
  // const mainContentRef = useRef<HTMLDivElement>(null);
  const [footerFadeStyles, setFooterFadeStyles] = useState<CSSProperties>({});
  const mainContentRef = useRef<HTMLDivElement>(null);

  useResizeObserver(document.body, () => {
    if (mainContentRef?.current?.getBoundingClientRect) {
      const rect = mainContentRef.current.getBoundingClientRect();
      setFooterFadeStyles({
        opacity: 1,
        position: 'fixed',
        width: `${rect.width}px`,
        left: `${rect.left}px`,
        bottom: 0,
        background: 'linear-gradient(rgb(255, 255, 255, 0) 0%, rgba(255, 255, 255) 100%)',
        height: 'var(--2x-large)',
      });
    }
  });

  return (
    <div className="main-content" ref={mainContentRef} style={isSimplifiedUI ? { margin: '0 auto' } : {}}>
      {children}
      <div style={{ opacity: 0, height: 0, ...footerFadeStyles }} />
    </div>
  );
};

export default MainContentContainer;