import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useProjectTeamQueryApi } from '~/hooks';

export const useAddProjectTeamMember = () => {
  const { addUserToTeam } = useProjectTeamQueryApi();

  return useMutation(async ({ userId }: { userId: string }) => {
    return addUserToTeam(userId).catch(err => {
      console.error(err);
      toast.error('Failed to update team member. Please try again.');
      throw err;
    });
  }
);};