import { useState } from 'react';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { Checkbox } from 'semantic-ui-react';
import { DataroomDocument } from '~/types/dataroom';
import { useDataroomContext } from '../../providers/DataroomProvider';
import { toggleProjectDocumentType } from '~/api/dataroom';

export default function EditDocListCheckbox({ documentType }: { documentType: DataroomDocument }) {
  const {
    dataroom,
    selectedDoclistCategory, 
    handleDocumentTypesChange
  } = useDataroomContext();
  
  const [checked, setChecked] = useState(documentType.isSelected);

  const { mutate: toggleCheckbox, status } = useMutation(async () => {
    const newCheckedState = !checked;
    try {
      // Set checked to the opposite of what it is now
      setChecked(newCheckedState);

      // Update the document type in the database
      const documentTypeUpdateResponse = await toggleProjectDocumentType({
        dataroomId: dataroom.id,
        documentTypeId: documentType.id,
        isCustom: documentType.isCustom,
        isSelected: newCheckedState 
      }) as DataroomDocument;

      handleDocumentTypesChange(selectedDoclistCategory?.id, documentTypeUpdateResponse); 

    } catch (err) {
      // If there is an error, log the error an unset the checkbox
      console.error(err);
      setChecked(!newCheckedState);
      toast.error('An error occurred while updating the document type. Please refresh the page and try again.', { duration: 5000 });
    }
  });

  return (
      <Checkbox
        aria-label={documentType.name}
        className="document-row-checkbox"
        checked={checked}
        onChange={() => toggleCheckbox()}   
        disabled={status === 'loading'}
      />
   );
}