import { getByPath } from '~/utils/updateByPath';

export const stringFieldSorter = (path: any) => {
  return (a: any, b: any) => {
    const valueA = getByPath(a, path);
    const valueB = getByPath(b, path);
    return stringSorter(valueA, valueB);
  };
};

export const stringSorter = (a: any, b: any) => {
  return a?.localeCompare(b, 'en', { sensitivity: 'base' });
};

export const dedupeFilter = (e: any, i: any, arr: any) => {
  return arr.indexOf(e) === i;
};

export const countingReducer = (map: any, val: any) => {
  map[val] = (map[val] || 0) + 1;
  return map;
};
