import { ReactNode } from 'react';
import { DropdownItemProps, Loader, Table as SemanticUITable, TableProps as SemanticUITableProps, TableCellProps } from 'semantic-ui-react';
import styled from 'styled-components';
import TableCellCheckbox from './TableCellCheckbox';
import TableCellDropdown from './TableCellDropdown';
import EditableCell from './EditableCell';
import TableCellButton from './TableCellButton';

interface TableProps extends SemanticUITableProps {
  fluid?: boolean;
  children?: ReactNode;
}

export default function Table({ children, ...props }: TableProps) {
  return (
    <SemanticUITable {...props}>
      {children}
    </SemanticUITable>
  );
}

Table.Body = SemanticUITable.Body;
Table.Header = SemanticUITable.Header;
Table.Footer = SemanticUITable.Footer;
Table.HeaderCell = SemanticUITable.HeaderCell;
Table.Row = styled(SemanticUITable.Row)`
  &.selectable:hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
`;

export type CustomTableCellProps = TableCellProps & {
  isEditable?: boolean;
  value?: any;
  defaultValue?: any;
  options?: DropdownItemProps[];
  onChange?: (value: any) => void;
  disabled?: boolean;
}
Table.Cell = (props: CustomTableCellProps) => {
  const singleLine = props.singleLine ?? true;

  if (props.isEditable) {
    return (
      <SemanticUITable.Cell 
        {...props} 
        singleLine={singleLine}
        style={{
          padding: '0'
        }}
      >
        <EditableCell
          value={props.value}
          defaultValue={props.defaultValue}
          options={props.options}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </SemanticUITable.Cell>
    );
  }

  return (
    <SemanticUITable.Cell {...props} singleLine={singleLine} />
  );
};

const TableLoader = styled(Loader)`
  &&& {
    position: absolute;
    left: 0;
    right: 0;
    margin: 1rem auto 0;
  }
`;
TableLoader.defaultProps = { active: true, size: 'big' };
Table.Loader = TableLoader;

Table.CellCheckbox = TableCellCheckbox;

Table.CellDropdown = TableCellDropdown;

Table.CellButton = TableCellButton;