import { fetchNotifications } from '~/api/user';
import { useInfiniteQuery } from 'react-query';

export const getNotificationsKey = () => {
  return 'notifications';
};

export const useNotifications = () => {
  return useInfiniteQuery({
    queryKey: getNotificationsKey(),
    queryFn(props?: { pageParam?: number }) {
      const page = props?.pageParam ?? 0;
      return fetchNotifications({ page });
    },
    getNextPageParam: (lastPage: any, pages: any) => {
      const resultCount = pages.reduce((acc: any, page: any) => {
        return acc + page.results?.length;
      }, 0);
      if (resultCount < lastPage?.total) {
        return lastPage.nextPage;        
      }
    },
    staleTime: 0, 
    enabled: true,
    retry: false,
    refetchOnWindowFocus: false
  });
};