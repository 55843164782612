import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { revokeInvestorDataroomAccess } from 'api/dataroom';
import { useInvestorDataroomAccess } from '~/requests/dataroom';

export const useRevokeInvestorDataroomAccess = (params = {}) => {
  const dataroomAccess = useInvestorDataroomAccess();
  const dataroom = dataroomAccess?.data?.dataroom;

  return useMutation({
    mutationFn: async ({ investorId }: any) => {
      try {
        await revokeInvestorDataroomAccess(dataroom?.id, investorId);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    onError() {
      toast.error('Error occurred revoking access to the data room. Please try again.', { duration: 4000 });
    },
    onSuccess() {
      toast.success('Successfully revoked investor access to the data room.', { duration: 4000 });
    },
    ...params
  });
};