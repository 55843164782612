import { Comment, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import React from 'react';
import Metadata from './Metadata';
import { useFadeInStyles } from '~/hooks';
import FileMessageActions from './FileMessageActions';
import { ProjectEvent } from '~/types/projectEvent';
import { FileMessage, Message } from '~/types/conversations/Message';
import { useDownloadMessageFileQuery } from '~/requests/conversations/useDownloadMessageFileQuery';
import { useConversationContext } from '../providers/ConversationProvider';
import CommentAvatar from './CommentAvatar';

// TODO - this file has some code smells. We need to find a way to make the styles for "label events" reusable but not have it be a component that tries to
// do too much. We should not be handling file uploads, bid acceptance, and new bid events all in one component like this.

const Container = styled.div`
  * {
    padding: 0;
    margin: 0;
  }
  text-align: center;
  padding-bottom: var(--small);
`;

const Line = styled.div`
  position: absolute;
  background-color: var(--color-gray);
  height: 1px;
  width: 100%;
  top: 50%;
`;

const MessageContainer = styled.div`
  display: inline-block;
  background: var(--color-off-white);
  padding: 0 var(--2x-large);
  position: relative;
`;

const Event = styled(Label)`
  &&&.ui.label {
    background-color: var(--color-secondary-light);
    display: block;
    font-size: 0.875rem;
    padding: var(--x-small) var(--small);
    border-radius: 3px;
    &[role='button'] {
      background-color: var(--color-primary-light);
      color: var(--color-secondary);
      font-weight: bold;
      text-decoration: underline;
      &:hover {
        filter: brightness(0.9);
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
`;

const LabelEvent: React.FC<{ event: ProjectEvent | FileMessage }> = ({ event }) => {
  const { selectedConversation } = useConversationContext();

  // Download handler for file uploads
  const { refetch: downloadMessageFile } = useDownloadMessageFileQuery({
    conversationId: selectedConversation?.id as string,
    messageId: event?.id
  });

  // Replace the all-caps bid text with only the first letter being capitalized
  let bidText = (event as ProjectEvent)?.eventData?.bid;
  if (bidText) {
    const parts = bidText.split(':');
    if (parts.length > 1) {
      parts[0] = parts[0].toLowerCase();
    }
    const temp = parts.join(':');
    bidText = temp.charAt(0).toUpperCase() + temp.slice(1);
  }

  return (
    <Container style={useFadeInStyles()}>
      <Comment>
        <Comment.Content style={{ position: 'relative' }}>
          <Line />
          <MessageContainer>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ marginRight: '0.25rem' }}>
                <CommentAvatar message={event as Message} size="small" />
              </div>
              <Metadata entry={event as Message} />
            </div>
            <Comment.Text style={{ margin: 'var(--small) 0 0' }}>
              <Event 
                size='medium'
                underline 
                bold 
                {...event?.type === 'FILE' && event?.data?.commentDeleted ? { style: { fontStyle: 'italic', backgroundColor: 'var(--color-grey)' } } : {}}
                {...(event?.type === 'FILE' && !event?.data?.commentDeleted ? { 
                  onClick: downloadMessageFile,
                  role: 'button',
                  tabIndex: 0,
                  onKeyPress: (e: any) => {
                    // Handle enter key press
                    if (e.key === 'Enter') {
                      downloadMessageFile();
                    }
                  }
                } : {})}
              >
                {event?.type === 'BID' || event?.type === 'BID_ACCEPTED' ? bidText : null}
                {event?.type === 'FILE' && !event?.data?.commentDeleted ? `New document uploaded — ${event?.data.fileName}` : null}
                {event?.type === 'FILE' && event?.data?.commentDeleted ? <em>Document deleted — {event?.data.fileName}</em> : null}
              </Event>
              {event?.type === 'FILE' && <FileMessageActions message={event as FileMessage} />}
            </Comment.Text>
          </MessageContainer>
        </Comment.Content>
      </Comment>
    </Container>
  );
};

export default LabelEvent;

