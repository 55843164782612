import { useQuery } from 'react-query';
import { fetchTeam } from '~/api/team';

export const teamQueryKey = () => {
  return 'team';
};

export const useTeamQuery = (queryParams = {}) => {
  return useQuery({
    queryKey: teamQueryKey(),
    queryFn: fetchTeam,

    // Cache forever until the cache is cleared manually
    staleTime: Infinity,

    // No retries
    retry: false,

    // Allow overriding default react-query props
    ...queryParams
  });
};