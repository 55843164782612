import styled from 'styled-components';
import { usePortfolioContext } from '~/hooks';
import { usePermissions } from '~/requests/permissions/usePermissions';
import NotesForm from '~/components/form/NotesForm';

const Row = styled.div`
  display: grid;
  grid-template-columns: 10.375rem 1fr;
  grid-gap: var(--medium);
  padding: var(--x-small) var(--2x-small) var(--x-small) var(--3x-small);
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--color-pure-black);
  strong {
    color: var(--color-charcoal);
  }
  > * {
    display: flex;
    align-items: center;
  }
`;

const Container = styled.div`
  padding: var(--small) 0;
  width: 100%;
  background-color: transparent;
`;

export default function PortfolioSummary() {
  const { permissions } = usePermissions();
  const { portfolio, portfolioDisplayValues } = usePortfolioContext();

  return (
    <Container>
      {permissions.hasPortfolioInvestorAccess ? (
        <Row>
          <strong>Developer</strong>
          <div>{portfolioDisplayValues?.summary?.developer}</div>
        </Row>
      ): null}
      <Row>
        <strong>Total size</strong>
        <div>{portfolioDisplayValues?.summary?.technologies}</div>
      </Row>
      <Row>
        <strong>Project quantity</strong>
        <div>{portfolioDisplayValues?.summary?.projectQuantity}</div>
      </Row>
      <Row>
        <strong>Pricing goal</strong>
        <div>{portfolioDisplayValues?.summary?.pricingType}</div>
      </Row>
      <Row>
        <strong>Description</strong>
        <div>{portfolioDisplayValues?.summary?.description}</div>
      </Row>
        {permissions.hasPortfolioDeveloperAccess ? (
          <Row>
            <strong>Portfolio Notes</strong>
            <div>
              <NotesForm portfolio={portfolio} /> 
            </div>
          </Row>
        ) : portfolio?.portfolioNotes ? (
          <Row>
            <strong>Portfolio Notes</strong>
            <div>
              {portfolio?.portfolioNotes}
            </div>
          </Row>
        ) : null}
    </Container>
  );
}