import React, { createContext, useContext, ReactNode, useCallback, useMemo, useState, useEffect } from 'react';
import { useQueryParamState } from '~/hooks/useQueryParamState';
import {  useDataroomQuery, useProjectDoclist } from '~/requests/dataroom';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { Dataroom, DataroomCategory, DataroomDocument, DataroomDocumentFileData } from '~/types/dataroom';
import { DataroomCounts, getDataroomCounts } from '~/utils/calculators/dataroomCounts';
import { cloneDeep } from 'lodash';
import { usePageType, usePortfolioCalculatedValues, usePortfolioContext, useProjectContext } from '~/hooks';
import { useTutorials } from '~/hooks/providers/TutorialProvider';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import toast from 'react-hot-toast';

type DocumentTypeChangeHandler = (categoryId: string, documentTypes: DataroomDocument) => void; 
interface DataroomContextProps {
  dataroom: Dataroom;
  doclist: null | DataroomCategory[];
  isLoading: boolean;
  hasError: boolean;
  selectedCategory: any;
  selectedDoclistCategory: any;
  editingDocumentTypes: boolean;
  dataroomCounts: Partial<DataroomCounts>;
  dealState: number;
  isSimplifiedUI?: boolean;
  setEditingDocumentTypes: (isEditing: boolean) => void;
  setEditingCustomDocumentTypes: (isCustomEditing: boolean) => void;
  setSelectedCategory: (category: any) => void;
  updateDataroomDocument: (obj: any) => void;
  handleDocumentTypesChange: (categoryId: string, documentTypes: DataroomDocument) => void;
  handleRemoveCustomDocumentType: (documentType: DataroomDocument) => void;
  refreshDataroomSnapshot: () => void;
}

const DataroomContext = createContext<DataroomContextProps>({
  dataroom: {} as Dataroom,
  doclist: null,
  isLoading: false,
  hasError: false,
  selectedCategory: null as any,
  selectedDoclistCategory: null as any,
  editingDocumentTypes: false,
  dealState: 0,
  isSimplifiedUI: false,
  setEditingDocumentTypes: (isEditing: boolean) => {},
  setEditingCustomDocumentTypes: (isCustomEditing: boolean) => {},
  setSelectedCategory: (category: any) => {},
  dataroomCounts: {},
  updateDataroomDocument: () => {},
  handleDocumentTypesChange: (categoryId: string, documentTypes: DataroomDocument) => {},
  handleRemoveCustomDocumentType: () => {},
  refreshDataroomSnapshot: () => {},
});
export const useDataroomContext = () => useContext(DataroomContext);

export const DataroomProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { project, projectState } = useProjectContext();
  let { portfolio } = usePortfolioContext();
  const { permissions } = usePermissions();
  const { setTutorialContext } = useTutorials() as any;
  const pageType = usePageType();
  const location = useLocation();
  const history = useHistory();

  // Get the portfolio from the project data if that's the page type
  portfolio = pageType === 'project' ? project?.portfolio : portfolio;

  // Set tutorial context for project datarooms
  useEffect(() => {
    if (project && pageType === 'project') {
      setTutorialContext({
        page: 'dataroom',
        data: {
          project,
        }
      });
    }
  }, [pageType]);

  // Determine portfolio deal state if applicable
  const { portfolioState } = usePortfolioCalculatedValues(portfolio, portfolio?.projects);
  const dealState = pageType === 'project' ? projectState?.dealState : portfolioState?.latestState;

  const [dataroomState, setDataroomState] = useQueryParamState('s'); 

  const setSelectedCategory = useCallback((category: any) => {
    const categoryId = category?.id ?? '';
    setDataroomState({
      ...dataroomState,
      selectedCategoryId: categoryId
    });
  }, [dataroomState]);

  const setEditingDocumentTypes = useCallback((flag: boolean) => {
    setDataroomState({
      ...dataroomState,
      isEditingDocumentTypes: flag,
    });
  }, [dataroomState]);

  const setEditingCustomDocumentTypes = useCallback((customFlag: boolean) => {
    setDataroomState({
      ...dataroomState,
      isEditingCustomDocumentTypes: customFlag,
    });
  }, [dataroomState]);

  // This breaks routing if it's a useCallback()
  const removeDocTypeId = () => {
    const newSearch = queryString.stringify({
      ...queryString.parse(location.search),
      docTypeId: undefined
    });
    history.replace(location.pathname + (newSearch ? '?' + newSearch : ''));
  };

  // API fetches happen here for dataroom/doclist
  const { data: _dataroom, status: dataroomStatus, updateDataroomCache } = useDataroomQuery();
  const { data: _fetchedDocList, status: doclistStatus } = useProjectDoclist();

  const editingDocumentTypes = dataroomState?.isEditingDocumentTypes ?? false;
  const editingCustomDocumentTypes = dataroomState?.isEditingCustomDocumentTypes ?? false;
  const [dataroomSnapshot, setDataroomSnapshot] = useState(cloneDeep(_dataroom));
  const [fetchedDocListSnapshot, setFetchedDocListSnapshot] = useState(_fetchedDocList);

  const dataroom = editingDocumentTypes ? dataroomSnapshot : _dataroom as Dataroom;

  const fetchedDocList = editingDocumentTypes ? fetchedDocListSnapshot : _fetchedDocList as DataroomCategory[];

  const dataroomCounts = useMemo(
    () => getDataroomCounts(dataroom?.documents),
    [dataroom]
  );

  const paramDocTypeId = queryString.parse(location?.search)?.docTypeId;

  useEffect(() => {
    if (paramDocTypeId && dataroom?.isSimplifiedUi) {
      // Update history
      removeDocTypeId();
    } else if (paramDocTypeId && dataroom) {
      // Find doc category from notification docTypeId param
      const documentCategory = dataroom.documents.find(category => {
        const categoryHasDocType = category.documentTypes.some(docType => docType.id === paramDocTypeId);
        return categoryHasDocType;
      });

      if (documentCategory) {
        setSelectedCategory(documentCategory);
      } else {
        // Fires for hidden docs and deleted docs
        removeDocTypeId();
        toast.error('The file you are attempting to access has been removed.');
      }
    }
  }, [dataroom]);

  // Filter the doclist based on the user's permissions and the project's state
  const doclist = fetchedDocList?.filter((category: any) => {
    // Filter Additional Documents category if there are not additional documents uploaded yet
    if (category?.code === 'ADDITIONAL_DOCUMENTS' && !category.documentTypes.length) {
      return false;
    }

    // Always show category if the user has permission to update the dataroom
    if (permissions?.canUpdateDataroom) {
      return true;
    }

    // Otherwise, only show the category if it has 1 or more uploaded documents
    const { uploaded } = dataroomCounts.categorizedCounts?.[category?.id as string] ?? { uploaded: 0 };
    return Boolean(uploaded);
  });

  // category selection for doclist checkboxes
  const selectedDoclistCategory = doclist?.find((category: any) => category?.id === dataroomState?.selectedCategoryId) || doclist?.[0];

  useEffect(() => {
    setDataroomSnapshot(cloneDeep(_dataroom));
    setFetchedDocListSnapshot(_fetchedDocList);
  }, [editingDocumentTypes, editingCustomDocumentTypes, selectedDoclistCategory, dataroomStatus, doclistStatus]);

  const selectedCategory = dataroomState?.selectedCategoryId
    ? doclist?.find((category: any) => category?.id === dataroomState?.selectedCategoryId)
    : doclist?.[0] ?? null;

  useEffect(() => {
    if (paramDocTypeId && selectedCategory && dataroomState?.selectedCategoryId === selectedCategory.id) {
      // Update history 
      removeDocTypeId();
    }
  }, [paramDocTypeId, selectedCategory, dataroomState]);

   // Handler for updating document types in the dataroom and doclist
  const handleDocumentTypesChange: DocumentTypeChangeHandler = (categoryId, documentType) => {
    
    // Update dataroom state by updating queryClient's doclist and dataroom data
    const newDataroom = cloneDeep(_dataroom) as Dataroom;

    const category = newDataroom.documents.find((category: any) => category.id === categoryId);
    const existingDocumentType = category?.documentTypes.find((docType: any) => docType.id === documentType.id);

    if (existingDocumentType) {
      Object.assign(existingDocumentType, documentType);
    } else if (category) {
      category.documentTypes.push(documentType);
    }

    // Update queryClient with new dataroom and doclist data
    updateDataroomCache(newDataroom);
  };

  const handleRemoveCustomDocumentType = (documentType: DataroomDocument) => {
    // Update the dataroom and doclist state
    const newDataroom = cloneDeep(dataroom);
    const newDoclist = cloneDeep(doclist);

    // Remove the document type from the dataroom
    const dataroomCategory = newDataroom?.documents?.find((category: any) => category?.documentTypes?.some((docType: DataroomDocument) => docType?.id === documentType?.id));
    const dataroomDocumentTypeIndex = dataroomCategory?.documentTypes.findIndex((docType: any) => docType.id === documentType?.id);
    if (dataroomDocumentTypeIndex !== undefined && dataroomDocumentTypeIndex !== -1) {
      dataroomCategory?.documentTypes.splice(dataroomDocumentTypeIndex, 1);
    }

    // Remove the document type from the doclist
    const doclistCategory = newDoclist?.find((category: any) => category?.documentTypes?.some((docType: DataroomDocument) => docType?.id === documentType?.id));
    const doclistDocumentTypeIndex = doclistCategory?.documentTypes?.findIndex((docType: any) => docType.id === documentType?.id);
    if (doclistDocumentTypeIndex !== undefined && doclistDocumentTypeIndex !== -1) {
      doclistCategory?.documentTypes?.splice(doclistDocumentTypeIndex, 1);
    }

    // Update queryClient for dataroom and doclist
    updateDataroomCache(newDataroom as Dataroom);
  };

  const updateDataroomDocument = ({
    dataroom,
    documentTypeId,
    newDocument,
    documentTypeName
  }: {
    dataroom: Dataroom;
    documentTypeId: string;
    newDocument: DataroomDocumentFileData | null;
    documentTypeName?: string;
  }) => {
    const newDataroom = cloneDeep(dataroom) as Dataroom;
    for (const category of newDataroom.documents) {
      for (const documentType of category.documentTypes) {
        if (documentType.id === documentTypeId) {
          if (documentTypeName) {
            documentType.name = documentTypeName;
          }
          if (newDocument) {
            documentType.document = newDocument;
          } else {
            delete documentType.document;
          }
          updateDataroomCache(newDataroom);
          return;
        }
      }
    }
  };

  return (
    <DataroomContext.Provider value={{
      dataroom: dataroom as Dataroom,
      doclist: doclist as DataroomCategory[],
      isLoading: dataroomStatus === 'loading' || doclistStatus === 'loading',
      hasError: dataroomStatus === 'error' || doclistStatus === 'error',
      selectedCategory,
      setSelectedCategory,
      selectedDoclistCategory,
      editingDocumentTypes,
      dealState,
      isSimplifiedUI: dataroom?.isSimplifiedUi,
      setEditingDocumentTypes,
      setEditingCustomDocumentTypes,
      dataroomCounts,
      handleDocumentTypesChange,
      updateDataroomDocument,
      handleRemoveCustomDocumentType,
      refreshDataroomSnapshot: () => setDataroomSnapshot(cloneDeep(_dataroom)),
    }}>
      {children}
    </DataroomContext.Provider>
  );
};