import { ReactNode } from 'react';
import * as S from '../../styles';
import SubmitButton from '../SubmitButton';

export default function WizardForm({ children, isInner }: { children: ReactNode, isInner?: true }) {
  return (
    <S.WizardForm isInner={isInner}>
      {children}
      {!isInner && <SubmitButton />}
    </S.WizardForm>
  );
}