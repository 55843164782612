import { TutorialCodes } from '~/constants';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import CreatePortfolioModal from '~/modals/CreatePortfolioModal';
import TutorialModal from '../components/TutorialModal';

export default function FirstDevProjectCreated() {
  const { openModal } = useModalContext();

  return (
    <TutorialModal
      title='Setting up a portfolio'
      content={
        <>
          <p>
            You’ve created your first project - nice work! If you have multiple projects to finance at the same time, consider putting them into a portfolio. First, create a portfolio and then add projects to the portfolio.
          </p>
        </>
      }
      hasCancelButton
      cancelText='OK'
      submitButtonText='Create portfolio'
      tutorialCode={TutorialCodes.FIRST_PROJECT_CREATED}
      onSubmit={() => {openModal(CreatePortfolioModal);}}
    />  
  );
};  



       