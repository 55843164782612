import * as S from './styles';
import SidebarTabs from '../../../components/SidebarTabs';
import { Dropdown, Icon, Label } from 'semantic-ui-react';
import { usePageHeaderContext } from '~/components/PageHeader/PageHeaderProvider';
import { useEffect, useState } from 'react';
import { useConversationContext } from '../providers/ConversationProvider';
import { Conversation } from '~/types/conversations/Conversation';
import { useRequiredResponseCountsQuery } from '~/requests/projects/useRequiredResponseCounts';
import ConversationDropdownList from './ConversationDropdownList';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { Project } from '~/types/project';

export default function MessagingSidebar() {
  const {
    activeConversations, 
    inactiveConversations,
    supportConversations,
    selectedConversation,
    isSupportConversation,
    setConversation,
    projectList, 
    selectedProject,
    setSelectedProject
  } = useConversationContext();
  const { permissions } = usePermissions();
  const [showInactiveConversations, setShowInactiveConversations] = useState(false);
  const [allowInactiveMenuToggle, setAllowInactiveMenuToggle] = useState(true);

  const [showSupportConversations, setShowSupportConversations] = useState(false);
  const [allowSupportMenuToggle, setAllowSupportMenuToggle] = useState(true);

  const pageHeaderContext = usePageHeaderContext();

  const { data: requiredResponseCountsData } = useRequiredResponseCountsQuery();

  // Handle toggling the dropdown menu's functionality based on if the selected conversation is active or not
  useEffect(() => {
    if (isSupportConversation && (!showSupportConversations || allowSupportMenuToggle)) {
      setShowSupportConversations(true);
      setAllowSupportMenuToggle(false);
    } else if (!isSupportConversation && (showSupportConversations || !allowSupportMenuToggle)) {
      setShowSupportConversations(false);
      setAllowSupportMenuToggle(true);
    }

    if (selectedConversation && selectedConversation.isActive && (showInactiveConversations || !allowInactiveMenuToggle)) {
      setShowInactiveConversations(false);
      setAllowInactiveMenuToggle(true);
    } else if (selectedConversation && !selectedConversation?.isActive && (!showInactiveConversations || allowInactiveMenuToggle)) {
      setShowInactiveConversations(true);
      setAllowInactiveMenuToggle(false);
    }
  }, [selectedConversation]);

  const conversations = !permissions.isAdmin && (permissions.hasProjectInvestorAccess || permissions.hasPortfolioInvestorAccess)
    ? [...activeConversations, ...inactiveConversations]
    : activeConversations;

  const isInvestor = permissions.hasProjectInvestorAccess && !permissions.isAdmin;

  return (
    <div style={{
      width: '16.5rem',
      position: 'sticky',
      top: `calc(var(--large) + ${(pageHeaderContext?.height ?? 0) / 16}rem)`,
      height: `calc(100vh - ${(pageHeaderContext?.height ?? 0) / 16}rem - var(--large) * 2)`,
      overflowY: 'auto'
    }}>
      <SidebarTabs>
        {!!projectList?.length && !isInvestor && (
          <div style={{ marginBottom: 'var(--medium)' }}>
            <Dropdown 
              trigger={<S.ProjectDropdownTrigger>Switch project conversation</S.ProjectDropdownTrigger>} 
              style={{ '--color-primary': 'var(--color-secondary)' }}
              pointing="top right"
            >
              <Dropdown.Menu>
                {projectList?.map((project: Project) => {
                  const requiredResponseCount = requiredResponseCountsData
                    ?.filter((response: any) => response.projectId === project?.id)
                    ?.reduce((acc: number, curr: any) => acc + curr.count, 0) ?? 0;

                  return (
                    <Dropdown.Item
                      key={project?.id}
                      active={project?.id === selectedProject?.id}
                      onClick={() => setSelectedProject(project)}
                      style={{
                        maxWidth: '14.375rem',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: '1.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '1rem'
                      }}
                    > 
                      {project?.name}
                      {requiredResponseCount ? (
                        <Label circular color='red' style={{ margin: '0', marginLeft: 'var(--x-small)', flexShrink: '0' }}>
                          {requiredResponseCount}
                        </Label>
                      ) : null}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        {!!(conversations.length) && (
          <div style={{paddingBottom: 'var(--large)'}}>
            {conversations.map((conversation: Conversation) => {
              const requiredResponseCount = requiredResponseCountsData?.find((response: any) => response.id === conversation?.id)?.count ?? 0;

              return (
                <SidebarTabs.Tab
                  key={conversation.id}
                  active={conversation.id === selectedConversation?.id}
                  onSelect={() => setConversation(conversation)}
                >
                  <S.TabContent>
                    <div style={{color: 'var(--color-black)'}}>{conversation.name}</div>
                    {requiredResponseCount ? (
                      <Label circular color='red' style={{ flexShrink: '0' }}>
                        {requiredResponseCount}
                      </Label>
                    ): null}
                  </S.TabContent>
                </SidebarTabs.Tab>
              );
            })}
          </div>
        )}

        {supportConversations?.length === 1 ? (
          <div style={{ marginBottom: 'var(--large)' }}>
            <SidebarTabs.Tab
              active={supportConversations[0].id === selectedConversation?.id}
              onSelect={() => setConversation(supportConversations[0])}
            >
              <S.TabContent>
                <div style={{color: 'var(--color-black)'}}>{supportConversations[0].name}</div>
                {(() => {
                  const requiredResponseCount = requiredResponseCountsData?.find((response: any) => response.id === supportConversations[0]?.id)?.count ?? 0;
                  if (requiredResponseCount) {
                    return (
                      <Label circular color='red' style={{ flexShrink: '0' }}>
                        {requiredResponseCount}
                      </Label>
                    );
                  }
                })()}
              </S.TabContent>
            </SidebarTabs.Tab>
          </div>
        ) : null}

        {supportConversations?.length > 1 ? (
          <S.ConversationDropdown>
            <S.ConversationDropdownButton
              onClick={() => setShowSupportConversations(!showSupportConversations)}
              active={showSupportConversations}
              allowToggle={allowSupportMenuToggle}
            >
              <span>Support conversations</span>
              <Icon name="chevron right" style={{ display: 'inline-block', marginTop: showSupportConversations ? '0' : '-0.35rem' }} />
            </S.ConversationDropdownButton>

            <ConversationDropdownList show={showSupportConversations} conversations={supportConversations} />
          </S.ConversationDropdown>
        ) : null}


        {!isInvestor && inactiveConversations?.length > 0 && (
          <S.ConversationDropdown>
            <S.ConversationDropdownButton
              onClick={() => setShowInactiveConversations(!showInactiveConversations)}
              active={showInactiveConversations}
              allowToggle={allowInactiveMenuToggle}
            >
              <span>Inactive conversations</span>
              <Icon name="chevron right" style={{ display: 'inline-block', marginTop: showInactiveConversations ? '0' : '-0.35rem' }} />
            </S.ConversationDropdownButton>

            <ConversationDropdownList show={showInactiveConversations} conversations={inactiveConversations} />
          </S.ConversationDropdown>
        )}
      </SidebarTabs>
    </div>
  );
}