import Modal from '~/components/Modal';
import { DataroomDocument } from '~/types/dataroom';
import { useDataroomContext } from '../providers/DataroomProvider';
import { Button } from '~/components';
import { Form } from 'semantic-ui-react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { createDocumentType, editDocumentType } from '~/api/dataroom';
import { withPromiseToaster } from '~/utils';

interface CustomDocumentTypeModalProps {
  documentType?: DataroomDocument;
  closeModal: () => void;
}

export default function CustomDocumentTypeModal({ documentType, closeModal }: CustomDocumentTypeModalProps) {
  // Context values
  const { 
    dataroom,
    selectedCategory, 
    handleDocumentTypesChange, 
    setEditingCustomDocumentTypes,
  } = useDataroomContext();

  // Name/Description field values
  const [name, setName] = useState(documentType?.name ?? '');
  const [description, setDescription] = useState(documentType?.description ?? '');
  
  // Add/Edit API mutation
  const { mutate: submitForm, status } = useMutation(
     async () => {
       const response =  documentType ? 
        await withPromiseToaster(
          editDocumentType({
            dataroomId: dataroom.id,
            documentTypeId: documentType.id,
            name,
            description,
            isCustom: true,
            isSelected: documentType.isSelected,
            isUserProvidedName: name !== documentType.name ? true : undefined
          }), {
            messageStub: 'editing document type'
          } 
        ) : 
        await withPromiseToaster(
          createDocumentType(dataroom.id, {
            categoryId: selectedCategory?.id,
            name,
            description,
            isUserProvidedName: true
          }), {
            messageStub: 'creating document type'
          }
        );
      handleDocumentTypesChange(selectedCategory.id, response);
      setEditingCustomDocumentTypes(false);
      closeModal();
    }
  );

  return (
    <Modal allowClose={status !== 'loading'} closeModal={closeModal}>
      <Modal.Title>
        {documentType ? 'Edit' : 'Add'} custom document type {documentType ? 'in' : 'to'} {selectedCategory?.name}
      </Modal.Title>
      <Modal.Content>
        <Form>
          <Form.Field
            label="Document name"
            control={Form.Input}
            placeholder="Enter name"
            value={name}
            onChange={(e: any) => setName(e.target.value as string)}
          />
          <Form.Field
            label="Document description"
            control={Form.TextArea}
            placeholder="Add description"
            value={description}
            onChange={(e: any) => setDescription(e.target.value as string)}
          />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary 
          outlined 
          onClick={() => closeModal()} 
          disabled={status === 'loading'}
        >
          Cancel
        </Button>
        <Button 
          primary 
          onClick={() => {
            setEditingCustomDocumentTypes(true);
            submitForm();
          }}
          disabled={!name || !description || status === 'loading'}
          loading={status === 'loading'}
        >
          {documentType ? 'Save changes' : 'Add document type'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}