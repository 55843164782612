import { useCallback } from 'react';
import InvestorInvitations from '~/components/investorInvitations';
import { assignProjectToInvestor, unassignProjectFromInvestor, updateInvestorInProjectQueue } from '~/api/adminInvestorInvitations';
import { useProjectContext } from '~/hooks';

export default function AdminProjectInvestorInvites() {
  const {project, fetchProject, id, projectState} = useProjectContext();

  const refreshProject = useCallback(
    () => {
      fetchProject();
    },
    [fetchProject]
  );

   const assignToInvestor = useCallback(
    (formState: any) => assignProjectToInvestor(id, formState).then(refreshProject),
    [refreshProject, id]
  );
  
  const updateInvitation = useCallback(
    (formState: any) => updateInvestorInProjectQueue(id, formState).then(refreshProject),
    [refreshProject, id]
  );
  
  const unassignInvestor = useCallback(
    (data: any) => unassignProjectFromInvestor(id, data).then(refreshProject),
    [refreshProject, id]
  );

  return (
    <div>
      <InvestorInvitations
          {...{assignToInvestor, updateInvitation, unassignInvestor}}
          label='project'
          investorQueue={project?.investorQueue}
          preferredInvestors={project?.preferredInvestors}
          preferredInvestorDetails={project?.preferredInvestorDetails as string}
          dealState={projectState.dealState}
          preferencesOnly={Boolean(project?.portfolioId)}
        />
    </div>
  );
}