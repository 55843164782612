import { validation } from '~/schema/project';
import { AutoSaveContextProvider, useAutoSaveContext } from '~/hooks/providers/AutoSaveContextProvider';
import { fetchProject, updateProject } from '~/api/projects';
import { getProjectState } from '~/utils';
import projectDisplayValues from '~/utils/displayValues/project';
import { useMemo } from 'react';
import { calculateProjectCosts } from '~/utils/calculators';
import { useProjectConfig } from '~/requests/projects/useProjectConfig';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import formConfig from '~/schema/project/config';
import { useFormConditions } from '../useFormConditions';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { Project } from '~/types/project';

export const useProjectContext = () => {
  const { permissions } = usePermissions();
  const { data: config } = useProjectConfig();
  const { state: rawState, updateValue, errors, handleOnChangeEvent, getValue, fetchFreshData, fetchStatus, id} = useAutoSaveContext() as any;

  const conditions = useFormConditions({
    data: rawState,
    config: formConfig,
    updateData: updateValue,
    getTransformedData: true
  });

  const state = conditions?.transformedData;

  const projectState = useMemo(
    () => getProjectState(state, errors),
    [state, errors]
  );

  const projectCosts = useMemo(
    () => calculateProjectCosts(state, { overall: true, perWdc: true, nrsFee: permissions?.hasProjectInvestorAccess }),
    [state]
  );

  const displayValues = useMemo(
    () => projectDisplayValues(state, projectCosts, permissions), //TODO Project typing
    [state, projectCosts, permissions] 
  );

  return {
    config,
    project: rawState as Project,
    formattedProject: state as Project, // This is the transformed state using the visibility engine
    projectDisplayValues: displayValues,
    updateProjectValue: updateValue,
    projectErrors: errors,
    handleOnChangeEvent,
    getProjectValue: getValue,
    fetchProject: fetchFreshData,
    fetchStatus,
    projectState,
    projectCosts,
    conditions,
    id
  };
};

export const ProjectContextProvider = ({id, testValue, children}: any) => {
  
  // Perform a redirect if API is redirecting to a different project ID
  const history = useHistory();
  const location = useLocation();
  const { id: projectId } = useParams() as any;

  return (
    <AutoSaveContextProvider
      {...{id, testValue, validation}}
      label='project'
      api={{update: updateProject, fetch(id: any) {
        return fetchProject(id).then((data) => {
          // Redirect to different project if needed. Be sure to retain any search params
          if (data.redirectToProjectId && projectId !== data.redirectToProjectId && location.pathname.includes(`/project/${projectId}`)) {
            const newPathname = location.pathname.replace(`/project/${projectId}`, `/project/${data.redirectToProjectId}`);
            history.replace(newPathname + location.search);
          }
          return data;
        });
      }}}
      config={formConfig}
    >
      <div style={{ background: 'var(--color-off-white)' }}>
        {children}
      </div>
    </AutoSaveContextProvider>
  );
};
