import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '~/components';
import { Form, TextArea } from 'semantic-ui-react';
import { createPortfolio } from '~/api/portfolios';
import { AddProjectsDropdown, getAvailableProjectOptions } from '~/components';
import Modal from '~/components/Modal';
import { FormSchemaAwareWidget } from '~/components/form';
import { usePromiseWatcher } from '~/hooks';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import { useProjectList } from '~/requests/projects/useProjectList';
import CreateProjectModal from './CreateProjectModal';

const CreatePortfolioModal = () => {
  // Call hooks and parent contexts
  const { formState } = useForm() as any;
  const { openModal, closeModal } = useModalContext();
  const history = useHistory();

  const { execute, status } = usePromiseWatcher(
    async () => {
      const portfolio = await createPortfolio(formState);
      closeModal();
      history.push(`/portfolio/${portfolio.id}`);
    },
    'creating the portfolio',
    [formState, history]
  );

  // Handle the form submission
  const handleSubmit = () => {
    if (!hasError) {
      execute();
    }
  };

  // Get the list of projects that can be added to the portfolio
  const { data: projects } = useProjectList();
  const projectOptions = useMemo(() => getAvailableProjectOptions(projects), [projects]);

  // Form is not valid if the name or description is empty
  const hasError = !formState?.name || !formState?.description || !formState?.projectIds?.length;

  // If there are no projects, show a message explaining that you need at least two projects to create a portfolio
  if (!projectOptions.length || projectOptions.length < 2) {
    return (
      <Modal>
        <Modal.Title>
          Create new portfolio
        </Modal.Title>
        <Modal.Content>
          <p>
            Portfolios are a great way to link together related projects so that they can be sent as a group to a single investor.
          </p>
          <p>
            <strong>In order to create a portfolio, you need to have at least two active projects.</strong> You can create a project by clicking the button below.
          </p>
        </Modal.Content>
        <Modal.Footer>
          <Button primary outlined onClick={closeModal}>Go Back</Button>
          <Button primary onClick={() => openModal(CreateProjectModal)}>
            Create a project
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  // Otherwise, show the form
  return (
    <Modal>
      <Modal.Title>
        Create new portfolio
      </Modal.Title>
      <Modal.Content>
        <p>
          Portfolios are a great way to link together related projects so that they can be sent as a group to a single investor.
        </p>
        <p>
          To get started, enter a portfolio name, some details on how the constituent projects will be linked together, and at least two active projects.
        </p>
        <Form onSubmit={handleSubmit}>
          <FormSchemaAwareWidget
            fieldLabel="Portfolio name"
            placeholder="Enter value"
            schemaKey="name"
          />
          {/* @ts-ignore */}
          <AddProjectsDropdown projectOptions={projectOptions} />
          <FormSchemaAwareWidget
            as={TextArea}
            fieldLabel="Portfolio description"
            placeholder="Please use this space to describe how the projects relate to each other and provide high-level details about the portfolio."
            schemaKey="description"
          />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Button primary outlined onClick={closeModal}>Cancel</Button>
        <Button
          primary
          disabled={hasError || status === 'pending'}
          loading={status === 'pending'}
          onClick={handleSubmit}
        >
          Create portfolio
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function() {
  const defaults = { name: '', description: '' };

  return (
    <FormContextProvider defaults={defaults}>
      <CreatePortfolioModal />
    </FormContextProvider>
  );
}