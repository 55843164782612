import { useQueryParamState } from '~/hooks/useQueryParamState';
import { DASHBOARD_QUERY_PARAM_STATE } from '../constants';
import { Dropdown } from 'semantic-ui-react';
import { useAdminUsersQuery } from '~/requests/admin/useAdminUsersQuery';

export default function OwnershipDropdownFilter() {
  const [dashboardState, setDashboardState] = useQueryParamState(DASHBOARD_QUERY_PARAM_STATE);
  const ownerIds = dashboardState?.ownerIds;

  const { data: users, status } = useAdminUsersQuery();

  return (
    <Dropdown 
      disabled={status === 'loading'}
      placeholder={status === 'loading' ? 'Loading...' : 'Filter by owner(s)'}
      selection
      multiple
      options={users?.map((user) => ({
        key: user.id,
        text: user.name,
        value: user.id
      }))}
      value={ownerIds}
      onChange={(_ignored, { value: ownerIds }) => {
        const newDashboardState = { ...dashboardState };
        if ((ownerIds as string[]).length) {
          newDashboardState.ownerIds = ownerIds;
        } else {
          delete newDashboardState.ownerIds;
        }

        if (!Object.keys(newDashboardState).length) {
          setDashboardState(undefined);
        } else {
          setDashboardState(newDashboardState);
        }
      }}
    />
  );
}