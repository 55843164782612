import { CommentIconAndLabel } from './CommentElements';
import { CheckmarkIcon } from './CommentElements';
import CommentResponseButton from './CommentResponseButton';
interface CommentReplyStatusProps {
  replyReceived: boolean | undefined;
  isOwnComment: boolean;
};

export default function CommentReplyStatus ({ 
  replyReceived, 
  isOwnComment,
}: CommentReplyStatusProps) {
  
  if (!replyReceived) {
    return null;
  }

  return (
    <CommentResponseButton isDiv>
      <CommentIconAndLabel icon={CheckmarkIcon} label={`Reply ${isOwnComment ? 'received' : 'sent'}`} />
    </CommentResponseButton>
  );
};

