import React from 'react';
import * as S from './styles';
import { Icon } from 'semantic-ui-react';
import { useProjectContext } from '~/hooks';

interface FieldErrorCountProps {
  categoryData: string[];
  showSuccessState?: boolean;
  style?: React.CSSProperties;
}

export default function FieldErrorCount({ categoryData, showSuccessState, style }: FieldErrorCountProps) {
  const { projectErrors } = useProjectContext();

  const count = Object.keys(projectErrors).filter(field => {
    return categoryData?.includes(field.replace(/\.\d+/g, '[]'));
  }).length;

  if (count === 0 && !showSuccessState) {
    return null;
  }

  return (
    <S.FieldStatusCircle style={style} className={count === 0 ? 'success' : 'has-errors'}>
      {count === 0 
        ? <Icon name="check" size="small" />
        : <div>{count}</div>
      }
    </S.FieldStatusCircle>
  );
}

