// @ts-nocheck

import * as S from './styles';
import { useFormGeneric, useAutoSaveContext } from '~/hooks';
import { Checkbox, Input, Select } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import { CSSProperties, ReactNode, useMemo } from 'react';
import FieldErrorMessage from './FieldErrorMessage';
import FieldLabel from './FieldLabel';
import { HTMLInputTypeAttribute } from 'react';

export type FormSchemaAwareWidgetProps = {
  as?: any
  autoComplete?: string | boolean
  schemaKey: string
  checkbox?: boolean
  datepicker?: boolean
  options?: any
  label?: ReactNode // label for checkboxes
  fieldStyle?: CSSProperties
  fieldLabel?: ReactNode
  fieldLabelStyle?: CSSProperties
  helpText?: string
  helpTextStyle?: CSSProperties
  optional?: boolean
  multiple?: boolean
  icon?: string
  iconPosition?: 'left' | 'right'
  inline?: boolean
  onChange?: Function
  onBlur?: Function
  search?: boolean
  style?: any
  maxSelections?: any
  selection?: boolean
  placeholder?: string
  fluid?: boolean
  maxLength?: number
  checked?: boolean
  readOnly?: any
  rows?: string | number
  type?: HTMLInputTypeAttribute 
  errorText?: string
  allowAdditions?: boolean
  selectOnBlur?: boolean
  additionLabel?: string
  onAddItem?: Function
}

export interface SchemaAwareWidgetImplProps extends FormSchemaAwareWidgetProps {
  context?: any
  children?: any
}
function SchemaAwareWidgetImpl({
  context,
  as: Widget,
  schemaKey,
  checkbox,
  datepicker,
  options,
  fieldStyle,
  fieldLabel,
  fieldLabelStyle,
  helpText,
  helpTextStyle,
  optional,
  inline,
  onChange,
  children,
  maxSelections,
  errorText,
  upward,
  ...props
}: SchemaAwareWidgetImplProps) {
  let {updateValue, handleOnChangeEvent, getValue, errors} = context;
  const value = getValue(schemaKey);

  if (typeof value === 'boolean') {
    checkbox = true;
  }

  const callback = useMemo(
    () => {
      if (onChange) {
        updateValue = onChange;
        handleOnChangeEvent = ({target: {value}}: any) => onChange(schemaKey, value);
      }
      if (datepicker) {
        return (value: any) => updateValue(schemaKey, value);
      } else if (options) {
        return (key: string, {value}: any) => updateValue(schemaKey, value);
      } else if (checkbox) {
        return () => updateValue(schemaKey, !getValue(schemaKey));
      } else {
        return (ev: any) => handleOnChangeEvent(ev, schemaKey);
      }
    },
    [schemaKey, onChange, !!datepicker, !!options, !!checkbox]
  );

  if (!Widget) {
    // noinspection JSUnusedAssignment
    Widget = options ? Select : (checkbox ? Checkbox : Input);
  }

  const error = errorText ?? errors[schemaKey as string];

  return (
    <S.SchemaAwareWidgetField
      error={!!error}
      inline={inline}
      style={{ ...fieldStyle }}
    >
      <FieldLabel
        text={fieldLabel}
        htmlFor={schemaKey}
        style={fieldLabelStyle}
        optional={optional}
        helpText={helpText}
      />
      { datepicker ? (
        <DatePicker {...props}
          selected={typeof value === 'string' ? parseISO(value) : value}
          onChange={callback}
          placeholderText={props?.placeholder ?? '––/––/––––'}
          calendarClassName="datepicker-calendar"
        />
      ) : (
        <Widget {...props}
          id={schemaKey}
          name={schemaKey}
          onChange={(_e: any, data: any) => {
            if (maxSelections !== undefined && data?.value?.length && maxSelections < data?.value?.length) {
              return;
            }
            callback(_e, data);
          }}
          value={checkbox ? undefined : (value == null ? '' : value)}
          checked={checkbox ? !!value : undefined}
          options={options?.filter((option: any) => !(option.deprecated && option.value !== value))}
          {...(Widget === Select ? { upward: false } : {})}
        >
          {children}
        </Widget>
      )}
      <FieldErrorMessage content={error} />
    </S.SchemaAwareWidgetField>
  );
}



// this could be much more concise by relying entirely on ...props, but doing this facilitates IDE autocompletion
export function FormSchemaAwareWidget({
  as, schemaKey, checkbox, datepicker, options, fieldStyle, fieldLabel, fieldLabelStyle,
  helpText, helpTextStyle, optional, inline, onChange, maxSelections, ...props
}: FormSchemaAwareWidgetProps) {
  return (
    <SchemaAwareWidgetImpl
      context={useFormGeneric()}
      {...{
        as, schemaKey, checkbox, datepicker, options, fieldStyle, fieldLabel, fieldLabelStyle,
        helpText, helpTextStyle, optional, inline, onChange, maxSelections
      }}
      {...props}
    />
  );
}

// this could be much more concise by relying entirely on ...props, but doing this facilitates IDE autocompletion
export function AutoSaveSchemaAwareWidget({
  as, schemaKey, checkbox, datepicker, options, fieldStyle, fieldLabel, fieldLabelStyle,
  helpText, helpTextStyle, optional, inline, onChange, maxSelections, ...props
}: FormSchemaAwareWidgetProps) {
  return (
    <SchemaAwareWidgetImpl
      context={useAutoSaveContext()}
      {...{
        as, schemaKey, checkbox, datepicker, options, fieldStyle, fieldLabel, fieldLabelStyle,
        helpText, helpTextStyle, optional, inline, onChange, maxSelections
      }}
      {...props}
    />
  );
}
