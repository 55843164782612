import React, { useReducer } from 'react';
import { Table } from '~/components';
import * as S from './styles';
import { sortBy } from 'lodash';

interface TableSortState {
  column: string;
  data: any[];
  direction: 'ascending' | 'descending';
}

interface TableSortAction {
  type: string;
  column: string;
}

function sortReducer(state: TableSortState, action: TableSortAction): TableSortState {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }

      return {
        column: action.column,
        data: sortBy(state.data, [action.column]),
        direction: 'ascending',
      };
    default:
      throw new Error();
  }
}

const tableData = [
  { name: 'Marc Palmer', email: 'marc@conductor.solar', lastActiveOn: 'Sep 1, 2023' },
  { name: 'Aaron James', email: 'aaron@conductor.solar', lastActiveOn: 'Sep 15, 2023' },
  { name: 'Jerry Dixon', email: 'jerry@conductor.solar', lastActiveOn: 'May 23, 2023' }
];

export default function TableExample() {
  const [state, dispatch] = useReducer(sortReducer, {
    column: 'lastActiveOn',
    data: tableData,
    direction: 'descending',
  });
  
  const getSort = (column: string) => {
    return state.column === column ? state.direction : undefined;
  };

  const updateSort = (column: string) => {
    return dispatch({ type: 'CHANGE_SORT', column });
  };

  return (
    <S.Section>
      <h2>Table</h2>
      <S.SectionRow>
        <Table sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={getSort('name')}
                onClick={() => updateSort('name')}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={getSort('email')}
                onClick={() => updateSort('email')}
              >
                Email Address
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="right"
                sorted={getSort('lastActiveOn')}
                onClick={() => updateSort('lastActiveOn')}
              >
                Last Active On
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.data.map(({ name, email, lastActiveOn }) => (
              <Table.Row key={name}>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{email}</Table.Cell>
                <Table.Cell textAlign="right">{lastActiveOn}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </S.SectionRow>
    </S.Section>
  );
}