import * as S from './styles';
import { ReactNode } from 'react';
import { CommentMessage } from '~/types/conversations/Message';
import { useToggleMessageResponseFlag } from '~/mutations/conversations/useToggleMessageResponseFlag';

interface CommentResponseButtonProps {
  isDiv?: boolean;
  children?: ReactNode;
  message?: CommentMessage;
}  

export default function CommentResponseButton({
  isDiv,
  children,
  message,
}: CommentResponseButtonProps) {
  const { mutate: toggleFlag, status: toggleFlagStatus } = useToggleMessageResponseFlag();
  
  if (!isDiv && !message) {
    return null;
  }

  if (isDiv) {
    return (
      <S.CommentResponseButton as="div">
        {children}
      </S.CommentResponseButton>
    );
  } 
  
  if (message) {
    return (
      <S.CommentResponseButton 
        onClick={() => toggleFlag(message)} 
        disabled={toggleFlagStatus === 'loading'}
      > 
        {children}
      </S.CommentResponseButton>
    );
  }
}