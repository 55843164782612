import axios from 'axios';
import { useMutation } from 'react-query';
import { addFileMessage } from '~/api/conversations';

export const useUploadFileMessageMutation = (params = {}) => {
  return useMutation({
    mutationFn: async ({ conversationId, file }: { conversationId: string, file: File }) => {
      const result = await addFileMessage(conversationId, file.name);

      await axios.put(result.presignedUrl, file).catch(error => {
        if (result.undoKey) {
          // TODO add undo functionality
        }
        throw error;
      });

      return result.message;
    },
    ...params
  });
};