import { useEffect, useState } from 'react';

export default function useFadeInStyles() {
  // Set initial opacity to 0 and immediately transition to 1
  // when the component mounts. This will fade in the component.
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    // set opacity to 1 with timeout
    const timeout = setTimeout(() => {
      setOpacity(1);
    }, 0);

    return () => clearTimeout(timeout);
  }, []);

  // Return the opacity and transition styles to be applied to the component.
  return {
    transition: 'opacity 0.5s ease-in-out',
    opacity
  };
}