import axios from '~/utils/axiosInstance';
import { nullifyEmptyStrings } from '~/utils';
import { parse as parseProject } from '~/transforms/project';
import { TeamManagementResponse } from '~/types/teamManagement';


export const getPortfolioResponseRequiredCounts = async (id: string) => {
  const response = await axios.get(`/portfolios/${id}/response-required-counts`);
  return response.data;
};

export const fetchPortfolio = async (id: string) => {
  const response = await axios.get(`/portfolios/${id}`);
  if (response.data?.projects?.length) {
    response.data.projects = response.data.projects.map(parseProject);
  }
  return response.data;
};

export const fetchPortfolioList = async function (params: any = {}) {
  const response = await axios.get('/portfolios', {params});
  return response.data;
};

export const updatePortfolio = async function (id: string, data: any) {
  const response = await axios.patch(`/portfolios/${id}`, nullifyEmptyStrings(data));
  return response.data;
};

export const archivePortfolio = async (id: string) => {
  const response = await axios.post(`/portfolios/${id}/archive`);
  return response.data;
};

export const unArchivePortfolio = async (id: string) => {
  const response = await axios.post(`/portfolios/${id}/unarchive`);
  return response.data;
};

export const createPortfolio = async (data: any) => {
  const response = await axios.post('/portfolios', data);
  return response.data;
};

export const getPortfolioPermissionList = async function (id: string) {
  const response = await axios.get(`/portfolios/${id}/permissions`);
  return response.data ?? {};
};

export const getPortfolioTeam = async function (id: string) {
  const response = await axios.get(`/portfolios/${id}/team`);
  return response.data as TeamManagementResponse;
};

export const addPortfolioTeamMember = async function (portfolioId: string, userId: string) {
  const response = await axios.post(`/portfolios/${portfolioId}/team/users`, { userId });
  return response.data;
};

export const updatePortfolioTeamMember = async function ({
  id,
  memberId,
  role,
  isActive
}: {
  id: string; 
  memberId: string;
  role?: 'LEAD' | 'MEMBER';
  isActive?: boolean
}) {
  const response = await axios.patch(`/portfolios/${id}/team/${memberId}`, { role, isActive });
  return response.data;
};

export const inviteExternalPortfolioTeamMember = async function (portfolioId: string, email: string) {
  const response = await axios.post(`/portfolios/${portfolioId}/team/external-users`, { email });
  return response.data;
};