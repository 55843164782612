import { formatNumber } from '~/utils/formatNumber';
import { display } from '~/utils/displayValues/common';
import { Project } from '~/types/project';

export default function productionEstimates(project: Project) {
  const min = project?.dnvEnergyYieldLow !== undefined && project?.dnvEnergyYieldLow !== null ? project.dnvEnergyYieldLow : undefined;
  const max = project?.dnvEnergyYieldHigh !== undefined && project?.dnvEnergyYieldHigh !== null ? project.dnvEnergyYieldHigh : undefined;
  const estVal = project?.dnvEnergyYieldEst !== undefined && project?.dnvEnergyYieldEst !== null ? project?.dnvEnergyYieldEst : undefined;

  return {
    hasProductionEstimate: min !== undefined || max !== undefined,
    min: display(min !== undefined ? formatNumber(min) : null),
    max: display(max !== undefined ? formatNumber(max) : null),
    estVal: display(estVal !== undefined ? formatNumber(estVal) : null)
  };
}