import { JSX } from 'react';
import { Icon } from 'semantic-ui-react';

interface CommentIconAndLabelProps {
  icon: JSX.Element;
  label: string;
  commentType?: boolean;
  requiresResponse?: boolean | undefined;
};

export const CommentIconAndLabel = ({ 
  icon, 
  label,
  commentType,
  requiresResponse, 
}: CommentIconAndLabelProps) => (
  <>
    {icon}
    <span style={{ color: commentType && requiresResponse ? 'var(--color-red)' : 'var(--color-charcoal)' }}>{label}</span>
  </>
);

export const ResponseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M2.66699 9.3157L9.33366 2.07886V6.68412H13.3337L6.66699 13.921V9.3157H2.66699Z" fill="#CC5500" stroke="#5A5A5A" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const RequestIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="line_icon lightning-bolt">
      <path id="Vector" d="M2.66699 9.3157L9.33366 2.07886V6.68412H13.3337L6.66699 13.921V9.3157H2.66699Z" stroke="#5A5A5A" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>
);

export const CheckmarkIcon = (
  <div className="reply-checkmark">
    <Icon name="check" />
  </div>
);