import styled from 'styled-components';
import { PageHeaderContextValue } from '~/components/PageHeader/PageHeaderProvider';

export const WorksheetTabContainer = styled.div<{ pageHeaderContext: PageHeaderContextValue }>`
  max-width: calc(42rem + var(--medium) * 2);
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--medium);
  .ui.pointing.secondary.menu {
    background: var(--color-off-white);
    top: calc(var(--medium) + 3.875rem + ${props => (props.pageHeaderContext.height && `${props.pageHeaderContext.height / 16}rem`) ?? 0});
    position: sticky;
    z-index: 1;
  }
`;