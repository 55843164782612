import { useProjectContext } from '~/hooks';
import styled from 'styled-components';

const ExpectedITCPercentageContainer = styled.div`
  padding: var(--x-small) 0;
  border-top: 1px solid var(--color-pure-black);
  h3 em {
    font-style: normal;
    color: var(--color-primary);
  }
`;

export default function ExpectedITCPercentage() {
  const { projectState } = useProjectContext();

  return (
    <ExpectedITCPercentageContainer>
      <h3>
        Expected solar tax credit: <em>{projectState.expectedItcPercentage}%</em>
      </h3>
    </ExpectedITCPercentageContainer>
  );
}