import { Table } from '~/components';
import { Dropdown as _Dropdown, DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';

const Dropdown = styled(_Dropdown)`
  &&&.ui.inline.dropdown  {
    .dropdown.icon {
      margin-left: var(--x-small);
    }
    > .text {
      font-weight: normal;
    }
  }
`;

const TableCellDropdown = ({
  textAlign = 'right',
  readOnly = false,
  ...dropdownProps
}: DropdownProps & {
  textAlign?: 'center' | 'left' | 'right';
  readOnly?: boolean
}) => {
  
  if (readOnly) {
    const displayValue = dropdownProps.options?.find(option => option.value === dropdownProps.value)?.text ?? '—';
    return (
      <Table.Cell textAlign={textAlign}>
        {displayValue}
      </Table.Cell>
    );
  }

  return (
    <Table.Cell textAlign={textAlign}>
      <Dropdown
        inline
        direction='left'
        closeOnBlur
        {...dropdownProps}
      />
    </Table.Cell>
  );
};

export default TableCellDropdown;