import { PricingTypeDisplay } from '~/constants';
import { display } from '~/utils/displayValues/common';
import technologies from './technologies';
import { Project } from '~/types/project';
import { Portfolio } from '~/types/portfolio';

export default function summary(portfolio: Portfolio) {
  const portfolioProjects = portfolio?.projects?.filter((project: Project) => project?.investorId === portfolio?.investorId) ?? [];

  return {
    developer: display(portfolio?.installer?.company?.name),
    projectQuantity: display(portfolioProjects?.length),
    pricingType: display(PricingTypeDisplay[portfolio?.projects?.[0]?.pricingType] ?? ''),
    description: display(portfolio?.description),
    technologies: technologies(portfolioProjects),
  };
}