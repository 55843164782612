import styled from 'styled-components';
import { Button as _Button } from 'semantic-ui-react';

export const HeaderContainer = styled.div<{ shrinkHeader: boolean }>`
  &&& {
    inset: 0;
    position: sticky;
    padding-top: var(--x-small);
    background: var(--color-off-white);
    z-index: 1;
    font-size: .5rem;
    .editable-name {
      transition: 0.2s ease-in-out;
      padding-top: var(--x-large);
      ${props => props.shrinkHeader && `
        padding-top: var(--x-small);
      `}
    }
    h1 {
      transition: 0.2s ease-in-out;
      ${props => props.shrinkHeader && `
        font-size: 1rem;
        margin-bottom: -1rem;
        transform: translate3d(0, -0.75rem, 0);
      `}
    }
    .ui.button.edit {
      transition: all 0.2s ease-in-out 0s;
      max-width: 2rem;
      max-height: 2rem;
      opacity: 1;
      ${props => props.shrinkHeader && `
        opacity: 0;
        max-width: 0;
        max-height: 0;
      `}
    }
  }
`;

export const NavigationContent = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  > * {
    margin: 0;
    + * {
      margin-left: var(--small);
    }
  }
  &&& .ui.top.right.pointing.dropdown > .menu {
    margin-top: 0.5rem;
  }
`;

export const EditableNameContainer = styled.section<{ useSecondaryColor?: boolean }>`
  // Use alternative of secondary color as needed
  ${props => props.useSecondaryColor && `
    --color-primary: var(--color-secondary);
  `}

  display: flex;
  align-items: center;
  gap: var(--medium, 24px);
  h1, input {
    color: var(--color-primary);
    margin: 0;
    font-family: Lato;
    letter-spacing: -0.05rem;
    margin-top: -1px; // Not sure why we have to do this...
  }
  button {
    padding: 0.4375rem;
  }
`;

export const EditableNameButton = styled(_Button)`
  &&& {
    background: var(--color-grey);
    padding: 0.4375rem;
    margin: 0;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 1.125rem;
      color: black;
      margin: 0 !important;
    }
  }
`;