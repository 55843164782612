import * as S from '../styles';
import { useHandleOutsideClick } from '~/hooks';
import React, { useRef } from 'react';
import { Loader } from 'semantic-ui-react';
import PopupMenuCaret from './PopupMenuCaret';
import { arrow, useFloating, offset, autoUpdate } from '@floating-ui/react';
import { PopupMenuProps } from './types';
import { zIndex } from '~/theme';

export default function PopupMenu({
  trigger,
  onClose,
  isLoading,
  children,
  ...props
}: PopupMenuProps) {
  const arrowRef = useRef(null);
  
  const { refs, x, y, elements: { reference, floating } } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    placement: 'right',
    elements: {
      reference: trigger
    },
    middleware: [
      arrow({ element: arrowRef }),
      offset(16)
    ]
  });

  useHandleOutsideClick(refs.floating, () => {
    onClose();
  });

  const arrowStyles: React.CSSProperties = {
    position: 'fixed',
    left: `${x - 4}px`,
    top: `${(reference as any)?.offsetTop + ((reference as any)?.clientHeight / 2) - 5.5}px`
  };

  let top = y;
  if (y < 28) {
    top = 28;
  } else if ((floating as any)?.clientHeight + y > window.innerHeight) {
    top = (window.innerHeight - (floating as any)?.clientHeight) / 2;
  }

  return (
    <>
      <PopupMenuCaret style={arrowStyles} />
      <S.PopupMenuContainer
        ref={refs.setFloating}
        style={{
          zIndex: zIndex.popupMenu,
          borderRadius: '4px',
          position: 'fixed',
          left: 0,
          top: 0,
          transition: 'max-height 0.1s ease-in-out',
          transform: `translate(${x}px, ${top}px)`,
          maxHeight: isLoading ? '64px' : 'calc(100vh - 28px)',
          overflowY: 'scroll',
        }}
        {...props}
      >
          
        <S.PopupMenu vertical>
          {isLoading 
            ? <div style={{ height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Loader active inverted /></div>
            : children}
        </S.PopupMenu>
      </S.PopupMenuContainer>
    </>
    
  );
}