import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { updatePortfolio } from '~/api/portfolios';

export const useUpdatePortfolio = (params = {}) => {
  return useMutation({
    mutationFn: async (params: { id: string; data: any; additionalParams?: any, refetchFunction?: () => Promise<any> }) => {
      try {
        await updatePortfolio(params.id, params.data);
        if (params.refetchFunction) {
          await params.refetchFunction();
        }
      } catch (err) {
        console.error('Error occurred updating portfolio with ID ' + params?.id);
        console.error(err);
        throw err;
      }
    },
    onError() {
      toast.error('Error occurred updating portfolio. Please try again.', { duration: 5000 });
    },
    ...params
  });
};