import React, { createContext, useContext, ReactNode, useState, useCallback } from 'react';
import ProjectsPopupMenu from './popups/ProjectsPopupMenu';
import NotificationsPopupMenu from './popups/NotificationsPopupMenu';
import PortfoliosPopupMenu from './popups/PortfoliosPopupMenu';
import { PopupMenuConfig, PopupMenuType, SidebarContextType } from './popups/types';

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

interface SidebarProviderProps {
  children: ReactNode;
}

const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
  const [popupMenus, setPopupMenus] = useState<PopupMenuConfig>({});

  const openPopupMenu = useCallback((name: PopupMenuType, triggerRef: React.RefObject<any>) => {
    setPopupMenus((prev) => ({
      ...prev,
      [name]: { ...prev[name], triggerRef, open: true }
    }));
  }, [popupMenus]);

  const closePopupMenu = useCallback((name: PopupMenuType) => {
    setPopupMenus((prev) => ({
      ...prev,
      [name]: { ...prev[name], open: false }
    }));
  }, [popupMenus]);

  return (
    <SidebarContext.Provider value={{ openPopupMenu, closePopupMenu, popupMenus }}>
      {children}
      {popupMenus.projects?.open && 
        <ProjectsPopupMenu 
          trigger={popupMenus.projects.triggerRef.current} 
          onClose={() => closePopupMenu('projects')} 
        />
      }
      {popupMenus.notifications?.open && 
        <NotificationsPopupMenu 
          trigger={popupMenus.notifications.triggerRef.current} 
          onClose={() => closePopupMenu('notifications')} 
          />
      }
      {popupMenus.portfolios?.open && 
        <PortfoliosPopupMenu 
          trigger={popupMenus.portfolios.triggerRef.current} 
          onClose={() => closePopupMenu('portfolios')} 
        />
      }
    </SidebarContext.Provider>
  );
};

// Custom hook to access the sidebar context
const useSidebarContext = (): SidebarContextType => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarProvider');
  }
  return context;
};

export { SidebarProvider, useSidebarContext };
