import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { reportWebVitals } from './utils';
import 'semantic-ui-less/semantic.less';
import 'react-datepicker/dist/react-datepicker.css';
import 'index.scss';
import { Toaster } from 'react-hot-toast';

ReactDOM.render(
  <>
    <div>
      <Toaster
        toastOptions={{
          duration: 15000,
        }}
      />
    </div>
    <App/>
  </>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
