import axios from '~/utils/axiosInstance';


export const addPortfolioProjects = async (id: string, data: any) => {
  const response = await axios.patch(`/portfolios/${id}/projects`, data);
  return response.data;
};

export const removePortfolioProject = async (portfolioId: string, projectId: string) => {
  const response = await axios.delete(`/portfolios/${portfolioId}/projects/${projectId}`);
  return response.data;
};

export const submitPortfolioProjects = async (id: string, data: any) => {
  const response = await axios.post(`/portfolios/${id}/projects/submit`, data);
  return response.data;
};

export const fetchPortfolioProjects = async (id: string) => {
  const response = await axios.get(`/portfolios/${id}/projects`);
  return response.data;
};
