import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import React, { useEffect, useRef } from 'react';
import { withPromiseToaster } from '~/utils';
import { FormSchemaAwareWidget } from '~/components/form';
import { Form, TextArea } from 'semantic-ui-react';
import { Button } from '~/components';
import { useMessagingContext } from '../providers/MessagingProvider';
import { useRequiredResponseCountsQuery } from '~/requests/projects/useRequiredResponseCounts';
import { CommentMessage } from '~/types/conversations/Message';
import { useAddCommentMutation } from '~/mutations/conversations';
import { useConversationContext } from '../providers/ConversationProvider';

const CommentReplyFormImpl: React.FC<{ message: CommentMessage }> = ({ message }) => {
  const { formState } = useForm() as any;
  const { selectedConversation } = useConversationContext();
  const { addNewMessage, closeCommentReply, scrollableBodyDomId, newCommentReplies } = useMessagingContext();
  const replyContainerRef = useRef(null);
  const replyMapNumber = newCommentReplies[message.id];

  const { refetch: refetchRequiredResponseCounts } = useRequiredResponseCountsQuery();

  useEffect(() => {
    // On first component render OR when the reply map number updates, scroll to element within scrollable body
    const scrollableBody = document.getElementById(scrollableBodyDomId);
    const replyContainer = replyContainerRef.current as any;

    // Scroll to the reply container, but only if it's not already in view
    if (replyContainer && scrollableBody) {
      const replyContainerTop = replyContainer.getBoundingClientRect().top;
      const scrollableBodyTop = scrollableBody.getBoundingClientRect().top;
      const scrollableBodyBottom = scrollableBody.getBoundingClientRect().bottom;
      const replyContainerBottom = replyContainer.getBoundingClientRect().bottom;

      if (replyContainerTop < scrollableBodyTop || replyContainerBottom > scrollableBodyBottom) {
        scrollableBody.scrollTop = replyContainer.offsetTop;
      }
    }

    // Focus on the reply container's textarea
    const replyContainerTextArea = replyContainer?.querySelector('textarea');
    if (replyContainerTextArea) {
      replyContainerTextArea.focus();
    }
  }, [replyMapNumber]);

  const { mutateAsync: addComment, status } = useAddCommentMutation();
  const saveComment = () => withPromiseToaster(
    (async () => {
      const newReply = await addComment({
        conversationId: selectedConversation?.id,
        comment: formState.comment,
        replyThreadId: message.id,
      } as any);

      addNewMessage(newReply);
      closeCommentReply(message);
    })(),
    {
      messageStub: 'replying to message',
      loading: null,
      success: null
    }
  );

  return (
    <div ref={replyContainerRef} className="comment-reply-form">
      <Form>
        <div style={{ position: 'relative' }}>
          <FormSchemaAwareWidget as={TextArea}
            schemaKey="comment"
            fieldStyle={{ marginBottom: 'var(--small)' }}
          />
        </div>
        <div>
          <Button
            primary
            size='medium'
            onClick={() => saveComment().then(refetchRequiredResponseCounts)}
            disabled={status === 'loading' || !formState?.comment}
            loading={status === 'loading'}
          >
            Add reply
          </Button>
          <Button 
            primary 
            outlined 
            style={{ marginLeft: 'var(--small)' }}
            disabled={status === 'loading'}
            onClick={() => closeCommentReply(message)}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

const CommentReplyForm: React.FC<{ message: CommentMessage }> = ({ message }) => {
  return (
    <FormContextProvider defaults={{ comment: '' }}>
      <CommentReplyFormImpl message={message} />
    </FormContextProvider>
  );
};

export default CommentReplyForm;