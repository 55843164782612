import { TutorialCodes } from '~/constants';
import TutorialModal from '../components/TutorialModal';

export default function InvV2Update() {
  return (
    <TutorialModal
      title='Check out our new look!'
      content={
        <>
          <p>
            Welcome to the new version of Conductor Solar! You&apos;ll notice that we have updated the look, feel, and navigation on the platform. You can learn more via the post on your dashboard.
          </p>
          <p>
            If you have any questions, please contact us via{' '}
            <a href="mailto:support@conductor.solar">support@conductor.solar</a>, or{' '}
            <a href="https://calendly.com/conductorsolar/conductor-solar-projects-to-finance?month=2023-12" rel="noopener noreferrer" target="_blank">
              click here
            </a>{' '}
            to book a support session.
          </p>
        </>
      }
      hasCancelButton={false}
      submitButtonText='OK'
      tutorialCode={TutorialCodes.V2_UPDATE_MESSAGE}
    />  
  );
};  

