import { cloneDeep, orderBy } from 'lodash';
import { useEffect, useReducer } from 'react';
import { TeamManagementResponse, TeamMember } from '~/types/teamManagement';

interface TableSortState {
  column: string;
  data: TeamManagementResponse;
  direction: 'asc' | 'desc';
}

interface TableSortAction {
  type: string;
  column: string;
  data?: TeamManagementResponse;
}

const getSortedData = (data: TeamManagementResponse, column: string, direction: 'asc' | 'desc') => {
  if (column === 'role') {
    // Role has special custom ordering in a prioritized order:
    // 1. Project leads first
    // 2. Internal team members second
    // 3. External team members third
    //4. Non-project members fourth
    // Then also, sort alphabetically within each group by last name/email
    const sortedData = cloneDeep(data);
    sortedData.sort((a: TeamMember, b: TeamMember) => {
      const order = direction === 'desc' ? 1 : -1;

      if (!a.isActive && b.isActive) {
        return order;
      }

      if (a.isActive && !b.isActive) {
        return -order;
      }
            
      if (a.role === 'LEAD' && b.role !== 'LEAD') {
        return -order; 
      }

      if (a.role !== 'LEAD' && b.role === 'LEAD') {
        return order;
      }

      if (!a.isExternal && b.isExternal) {
        return -order;
      }

      if (a.isExternal && !b.isExternal) {
        return order;
      }

      const lastNameA = a.name?.split(' ').pop() ?? '';
      const lastNameB = b.name?.split(' ').pop() ?? '';
     
      if (lastNameA || lastNameB) {
        return lastNameA.localeCompare(lastNameB);
      } else {
        // sort by email then.
        return a.email.localeCompare(b.email);
      }
    });
    return sortedData;
  } else {
    // Just sort alphabetically
    return orderBy(data, [column], [direction]);
  }

};

function sortReducer(state: TableSortState, action: TableSortAction): TableSortState {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        data: getSortedData(action.data as TeamManagementResponse, state.column, state.direction)
      };
    case 'CHANGE_SORT': {
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'asc' ? 'desc' : 'asc',
        };
      }

      const direction = action.column === 'role' ? 'desc' : 'asc';
      return {
        column: action.column,
        data: getSortedData(state.data, action.column, direction),
        direction: direction,
      };
    }
      
    
    default:
      throw new Error();
  }
}

export const useTeamTableSortReducer = (team: TeamManagementResponse) => {
  const [state, dispatch] = useReducer(sortReducer, {
    column: 'role', // TODO change to role when role sort is ready
    data: team,
    direction: 'desc',
  });

  useEffect(() => {
    dispatch({ 
      type: 'INIT', 
      column: 'role',
      data: team
    });
  }, [team]);

  const getSort = (column: string) => {
    const sort = state.column === column ? state.direction : undefined;
    if (sort === 'asc') return 'ascending';
    if (sort === 'desc') return 'descending';
    return sort;
  };

  const updateSort = (column: any) => {
    return dispatch({ type: 'CHANGE_SORT', column });
  };

  return {
    getSort,
    updateSort,
    data: state.data
  };
};