import { createContext, useCallback, useContext, useRef, useState } from 'react';
import { getByPath } from '~/utils';
import { validate } from '~/schema';

const FormContext = createContext({});

export const FormContextProvider = ({ defaults, children, validation }: any) => {
  const [errors, setErrors] = useState(validation ? validate(defaults, validation) : {});
  const [formState, setFormState] = useState(defaults);
  const formStateRef = useRef(formState);
  formStateRef.current = formState;

  const resetFormState = useCallback(
    () => {
      if (validation) {
        setErrors(validate(defaults, validation));
      }
      setFormState(defaults);
    },
    [defaults, setFormState, setErrors]
  );
  const updateFormValue = useCallback(
    (nameOrObject: any, value = undefined) => {
      const updatedForm = typeof nameOrObject === 'string'
        ? {...formStateRef.current, [nameOrObject]: value}
        : {...formStateRef.current, ...nameOrObject};
      if (validation) {
        setErrors(validate(updatedForm, validation));
      }
      setFormState(updatedForm);
    },
    [setFormState, setErrors],
  );
  const handleOnChangeEvent = useCallback(
    ({target: {value, name}}: any) => {
      updateFormValue(name, value);
    },
    [updateFormValue]
  );
  const getFormValue = useCallback(
    (schemaKey: any) => {
      return getByPath(formStateRef.current, schemaKey);
    },
    []
  );

  return <FormContext.Provider value={{
    formState,
    errors,
    getFormValue,
    resetFormState,
    updateFormValue,
    handleOnChangeEvent
  }}>{children}</FormContext.Provider>;
};

export const useForm = () => useContext(FormContext);
export const useFormGeneric = () => {
  const {formState: state, updateFormValue: updateValue, errors, handleOnChangeEvent, getFormValue: getValue} = useContext(FormContext) as any;
  return {state, updateValue, errors, handleOnChangeEvent, getValue};
};
