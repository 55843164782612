import { ProjectStorage } from '~/types/project';
import * as Collapsible from '@radix-ui/react-collapsible';
import Link from '~/components/Link';
import { useState } from 'react';
import { WizardField } from '../../../Field';
import WizardForm from '../../../WizardForm';
import * as S from '../styles';
import { useFadeInStyles, useProjectContext } from '~/hooks';
import { formatNumber } from '~/utils';

export default function StorageForm({ 
  storage,
  addRow,
  removeRow
}: { 
  storage: ProjectStorage 
  addRow: () => void
  removeRow: (storage: ProjectStorage) => void
}) {
  const [open, setOpen] = useState(true);
  const { project } = useProjectContext();
  const storageList = project.storage as ProjectStorage[];

  // Returns a schemaKey like projectArrays.0.type for saving the value in the project object
  const schemaKey = (term: string) => `storage.${storage.order}.${term}`;
  const isLast = storage.order === storageList.length - 1;

  return (
    <Collapsible.Root open={open} onOpenChange={() => setOpen(!open)} style={useFadeInStyles()}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <h3 style={{ margin: 0 }}>
          BESS {storage.order + 1}
          {storage.wattage && storage.discharge ? ` - 
            ${formatNumber(storage.wattage)} kW / ${formatNumber(storage.discharge)} kWh
          ` : null}
        </h3>
        <Link as={Collapsible.Trigger} bold>
          {!open ? '+ Expand' : '- Collapse'}
        </Link>
      </div>
      <Collapsible.Content className="CollapsibleContent">
        <WizardForm isInner>
          <WizardField.Row style={{ marginTop: 'var(--medium)' }}>
            <WizardField
              schemaKey={schemaKey('make')}
              fieldLabel="Make"
              placeholder="Enter make"
            />
            <WizardField
              schemaKey={schemaKey('model')}
              fieldLabel="Model"
              placeholder="Enter model"
            />
          </WizardField.Row>
          <WizardField.Row style={{ marginBottom: '1rem' }}>
            <WizardField
              type="number"
              fieldLabel="Wattage"
              schemaKey={schemaKey('wattage')}
              placeholder="Enter wattage"
              decimals={0}
              label="kW"
            />
            <WizardField
              type="number"
              fieldLabel="Capacity"
              schemaKey={schemaKey('discharge')}
              placeholder="Enter capacity"
              decimals={0}
              label="kWh"
            />
          </WizardField.Row>
        </WizardForm>
      </Collapsible.Content>
      <S.FormListActions>
        {isLast && (
          <Link 
            as="button" 
            underline 
            style={{ marginRight: 'var(--large)' }}
            onClick={addRow}
          >
            Add another storage system
          </Link>
        )}
        <Link 
          as="button" 
          underline 
          onClick={() => removeRow(storage)}
        >
          Remove this storage system
        </Link>
      </S.FormListActions>
    </Collapsible.Root>
  );
}