export const PartnerTypes = {
  OWN: 'OWN',
  TAX_CREDITS: 'TAX_CREDITS',
};

export const PartnerTypeDisplay = {
  [PartnerTypes.OWN]: 'Someone to own and operate the project',
  [PartnerTypes.TAX_CREDITS]: 'Someone to buy the tax credits',
};

export const PartnerTypeRadioOptions = [
  {
    value: PartnerTypes.OWN,
    label: PartnerTypeDisplay[PartnerTypes.OWN]
  },
  {
    value: PartnerTypes.TAX_CREDITS,
    label: PartnerTypeDisplay[PartnerTypes.TAX_CREDITS]
  }
];