// @ts-nocheck

import styled from 'styled-components';
import { Label } from 'semantic-ui-react';
import { Menu } from 'semantic-ui-react';

const SIDEBAR_PADDING_LEFT = '2rem';
const SIDEBAR_PADDING_RIGHT = '1rem';
const LOGO_HEIGHT = '7rem';

export const MenuHeader = styled(Menu.Header)`
  height: ${LOGO_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    display: block;
    width: 10rem;
    img {
      width: 100%;
      }
  }  
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - ${LOGO_HEIGHT});
`;

export const NavContainerTop = styled.div`
  margin-bottom: 2rem;
`;

export const NavContainerBottom = styled.div`
  margin-top: auto;
`;

export const MenuItem = styled(Menu.Item)<{ isActive?: boolean }>`
  &&& {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--color-off-white);
    padding-left: ${SIDEBAR_PADDING_LEFT};
    padding-right: ${SIDEBAR_PADDING_RIGHT};
    transition: .1s ease-in-out;
    display: flex;
    align-items: center;
    
    &:hover {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.08);
      color: white;
    }

    .project {
      &&& {
        height: 8rem;
      }
      }
    
    > * {
      margin: 0;
    }
    
    > * + * {
      margin-left: 0.5rem;
    }
    
    ${props => props.isActive ? `
      &, &:hover {
        background: transparent;
        color: var(--color-primary);
      }
    ` : ''}
    
    .ui.label {
      margin-left: auto;
    }

    .ui.vertical.menu {
      width: 22.5rem;
  
    }
  }
    `;

export const MenuItemLabel = styled(Label)``;
MenuItemLabel.defaultProps = { color: 'red' };

export const NavBottomUserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding-left: ${SIDEBAR_PADDING_LEFT};
  padding-right: ${SIDEBAR_PADDING_RIGHT};
  padding-bottom: var(--large);
  margin-top: 3rem;
  > * + * {
    margin-left: 1rem;
  }
  * {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.004375rem;
  }
`;

export const NavBottomUserName = styled.div`
  color: var(--color-off-white);
`;

export const NavBottomUserEmail = styled.div`
  color: var(--color-charcoal);
`;

export const PopupMenu = styled(Menu)`
  &&& {
    width: 22.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg-primary-dark);
    color: var(--color-gray);
    padding: .5rem 0 .75rem;
    .item {
      color: var(--color-gray);
    &:hover {
        cursor: pointer;
        color: var(--color-pure-white);
        background: rgba(255, 255, 255, 0.08);
      }
    }
    ${MenuItem} + ${MenuItem} {
      border-top: 1px solid rgba(255, 255, 255, 0.08);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 2.5rem;
      padding-top: 0;
      padding-bottom: 0;
    }
  }  
`;

export const PopupMenuContainer = styled.div`
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
`;

export const PopupMenuItem = PopupMenu.Item;

export const PopupMenuTrigger = styled(Menu.Item)`
  
`;

PopupMenuTrigger.defaultProps = { as: 'button' };

