import { PricingType } from '~/constants';
import { formatDollars } from '~/utils/formatNumber';
import { display } from '~/utils/displayValues/common';
import { UnitDecimals } from '~/constants';
import { Project } from '~/types/project';

export default function conductorSolarFee(project: Project, projectCosts: any, permissions: any) {
  const isDisplaying = project.pricingType !== PricingType.ITC && permissions.hasProjectInvestorAccess;
  
  const perWdc = project.pricingType === PricingType.ACQUISITION && projectCosts.bid === undefined
    ? 'TBD'
    : display(projectCosts?.perWdc?.nrsFee && formatDollars(projectCosts.perWdc.nrsFee, { dec: UnitDecimals.WDC }));

  const total = project.pricingType === PricingType.ACQUISITION && projectCosts.bid === undefined
    ? 'TBD'
    : display(projectCosts?.overall?.nrsFee && formatDollars(projectCosts.overall.nrsFee));

  return {
    isDisplaying,
    perWdc,
    total
  };
}