import styled from 'styled-components';
import { zIndex } from '~/theme';

const PopupMenuCaretStyles = styled.div`
  position: fixed;
  top: 4px;
  z-index: ${zIndex.popupMenuCaret};
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  background: var(--bg-primary-dark);
`;

export default function PopupMenuCaret(props: any) {
  return <PopupMenuCaretStyles {...props} />;
}