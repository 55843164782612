import { useEffect } from 'react';
import * as System from '~/utils/calculators/system';
import { useProjectContext } from '~/hooks';
import { IllinoisRecValues, RecOwnership, RecProgramYear, RecStates } from '~/constants';

// Util functions to get Illinois REC values
// (exporting these as functions so they are unit-testable)
export const getIllinoisRecValueData = (
  size: number,
  category: string,
  year: string,
  group: 'GROUP_A' | 'GROUP_B'
): number | undefined => {
  const data = IllinoisRecValues.find(item => item.category === category && item.year === year)?.data;
  const valueData = data?.find(item => size >= item.min && size <= item.max);
  const value = valueData ? valueData[group] : undefined;  
  return value;
};

export const getIllinoisRecValue = (
  size: number,
  category: string,
  year: string,
  group: 'GROUP_A' | 'GROUP_B'
  ): number | undefined => {
    const isFutureProgramYear = year === RecProgramYear.FUTURE_PROGRAM_YEAR;
    const programYear = isFutureProgramYear
      ? Object.keys(RecProgramYear).filter(key => key.startsWith('YEAR_')).reduce((max, current) => (current > max ? current : max))
      : year;
    
    const value = getIllinoisRecValueData(size, category, programYear, group) ?? 0;
    if (isFutureProgramYear) {
      return Math.round((value - (value * 0.05)) * 100) / 100;
    }

    return value;
};

// Custom hook to derive Illinois REC fields on:
// 1. State change
// 2. Size change
// 3. Program category change
// 4. Utility group change
// 5. Program year change
//
// This only runs for Illinois state
export const useDerivedIllinoisRECFields = () => {
  const { project, updateProjectValue } = useProjectContext();

  // Get sizeKwac and Illinois REC fields from project data
  const sizeKwac = System.sizeKwac(project);
  const { recProjectCategory, recUtilityGroup, recProgramYear, isShinesProgram }: any = project.recsData?.[project.addressState as string] ?? {};

  useEffect(() => {
    // Check if all required data is present.
    // No need to attempt to update rec value if required ata is not present.
    const hasRequiredFields = Boolean(
      project?.recsData?.[RecStates.ILLINOIS] &&
      sizeKwac && 
      project.recOwnership === RecOwnership.INVESTOR && 
      project.addressState === RecStates.ILLINOIS && 
      isShinesProgram === 'YES' && 
      recProjectCategory && 
      recUtilityGroup && 
      recProgramYear &&
      !project?.projectArrays?.some((projectArray) => projectArray.sizeKwac === undefined || projectArray.sizeKwac === null)
    );
    if (!hasRequiredFields) {
      return; 
    }

    // Update Illinois REC value while retaining all other REC data.
    // Only run update if the value has changed
    const currentRecValue = project?.recsData?.[RecStates.ILLINOIS]?.recValue;
    const newRecValue = getIllinoisRecValue(sizeKwac, recProjectCategory, recProgramYear, recUtilityGroup);
    if ((!currentRecValue && newRecValue) || (currentRecValue && newRecValue && currentRecValue !== newRecValue)) {
      updateProjectValue('recsData', {
        ...project.recsData,
        [RecStates.ILLINOIS]: {
          ...project.recsData?.[RecStates.ILLINOIS],
          recValue: getIllinoisRecValue(sizeKwac, recProjectCategory, recProgramYear, recUtilityGroup)
        }
      });
    }
  }, [project?.projectArrays, project?.recOwnership, project?.addressState, sizeKwac, isShinesProgram, recProjectCategory, recUtilityGroup, recProgramYear]);
};