import epcCost from './epcCost';
import otherCosts from './otherCosts';
import conductorSolarFee from './conductorSolarFee';
import totals from './totals';
import { Project } from '~/types/project';

export default function budget(project: Project, projectCosts: any, permissions: any) {
  return {
    epcCost: epcCost(project, projectCosts),
    interconnection: otherCosts('interconnection', projectCosts),
    otherItc: otherCosts('otherItc', projectCosts),
    otherNonItc: otherCosts('otherNonItc', projectCosts),
    conductorSolarFee: conductorSolarFee(project, projectCosts, permissions),
    totals: totals(project, projectCosts)
  };
}