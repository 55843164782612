import { ReactNode, useRef } from 'react';
import * as S from '../styles';
import { useSidebarContext } from '../context';
import { PopupMenuType } from './types';

export interface PopupMenuTriggerProps {
  name: PopupMenuType;
  children: ReactNode;
}

export default function PopupMenuTrigger({ name, children }: PopupMenuTriggerProps) {
  const triggerRef = useRef(null);
  const { openPopupMenu, popupMenus } = useSidebarContext();

  return (
    <S.MenuItem
      as="button"
      ref={triggerRef}
      onClick={() => openPopupMenu(name, triggerRef)} isActive={popupMenus?.[name]?.open}
    >
      {children}
    </S.MenuItem>
  );
}