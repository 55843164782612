import { sumBy } from 'lodash';
import { Unit } from '~/constants';
import { Project } from '~/types/project';

const getBudgetCostAmount = (project: Project, property: any) => {

  const amountField = project?.budgetCosts?.[property];
  let amount = amountField?.amount ?? NaN;

  if (isNaN(amount)) {
    amount = 0;
  } else if (amountField?.unit === Unit.WDC) {
    const sizeKwdc = project.projectArrays ? sumBy(Object.values(project.projectArrays), (mount: any) => Number(mount.sizeKwdc)) : 0;
    amount = Number(amountField?.amount) * sizeKwdc * 1000;
  } else {
    amount = Number(amountField?.amount);
  }

  return amount;
};

export default getBudgetCostAmount;