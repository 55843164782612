export const ContractType = {
  PPA: 'PPA',
  LEASE: 'LEASE',
  ENERGY_SERVICE_AGREEMENT: 'ENERGY_SERVICE_AGREEMENT',
};

export const ContractTypeDisplay = {
  [ContractType.PPA]: 'PPA',
  [ContractType.LEASE]: 'Lease',
  [ContractType.ENERGY_SERVICE_AGREEMENT]: 'Energy Service Agreement',
};

export const ContractTypeDisplayShort = {
  [ContractType.PPA]: 'PPA',
  [ContractType.LEASE]: 'Lease',
  [ContractType.ENERGY_SERVICE_AGREEMENT]: 'ESA',
};

export const ContractTypeDropdownOptions = [
  { value: ContractType.PPA, label: ContractTypeDisplay[ContractType.PPA] },
  { value: ContractType.LEASE, label: ContractTypeDisplay[ContractType.LEASE] },
  { value: ContractType.ENERGY_SERVICE_AGREEMENT, label: ContractTypeDisplay[ContractType.ENERGY_SERVICE_AGREEMENT] },
];