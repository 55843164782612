import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Route } from 'react-router-dom';
import { fetchLoggedInUser } from '~/api/user';
import { usePromiseWatcher, useUserContext } from '~/hooks';
import AsyncLoading from '~/components/AsyncLoading';
import TutorialProvider from '~/hooks/providers/TutorialProvider';
import { Sidebar } from './Sidebar';
import { ModalProvider } from '~/hooks/providers/ModalProvider';

const SidebarContainer = styled.div`
  position: relative;
  padding-left: var(--sidebar-width);
`;

const AuthRouter = function ({children}: any) {
  const {loggedInUser, setLoggedInUser} = useUserContext()as any;
  // Look up logged-in user info
  const {execute, status} = usePromiseWatcher(
    async () => {
      const user = await fetchLoggedInUser();
      setLoggedInUser(user);
    },
    'checking your login status',
    []
  );
  useEffect(execute, []);

  return (
    <>
      <AsyncLoading status={status}/>
      {loggedInUser && children}
    </>
  );
};

const AuthRoute = function ({children, ...props}: any) {
  return (
    <Route {...props}>
        <AuthRouter>
          <ModalProvider>
            <TutorialProvider>
              <SidebarContainer>
                <Sidebar />
                <main>
                  {children}
                </main>
              </SidebarContainer>
            </TutorialProvider>
          </ModalProvider>
        </AuthRouter>
    </Route>
  );
};

export default AuthRoute;
