import React, { useState, createContext, useContext, ReactNode } from 'react';
 
const ModalContext = createContext({
  modalProps: {} as any,
  openModal: (ModalComponent: React.FC, props?: any) => {},
  closeModal: () => {},
});
export const useModalContext = () => useContext(ModalContext);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [ModalComponent, setModalComponent] = useState<ReactNode>(null);
  const [modalProps, setModalProps] = useState<any>({});

  const openModal = (ModalComponent: React.FC, props: any = {}) => {
    setModalProps(props);
    setModalComponent(typeof ModalComponent === 'function' ? <ModalComponent /> : ModalComponent);
  };

  const closeModal = () => {
    setModalComponent(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, modalProps }}>
      {ModalComponent}
      {children}
    </ModalContext.Provider>
  );
};