import { TutorialCodes } from '~/constants';
import TutorialModal from '../components/TutorialModal';
import Link from '~/components/Link';

export default function FirstInvLogin() {

  return (
    <TutorialModal
      title='Getting started on Conductor Solar'
      content={
        <>
          <p>
            Welcome to Conductor! We’re waiting for developers to submit projects that meet your preferences, 
            and when they do, they’ll show up here on your dashboard. 
          </p>
          <p>
            If you haven’t already, please make sure to <Link bold underline as='a' href="https://calendly.com/marc-conductorsolar/30min" rel="noopener noreferrer" target="_blank">book time</Link> to communicate your investment preferences to the Conductor Solar team, as that’s a gating step before being matched with any opportunities.
          </p>
        </>
      }
      hasCancelButton={false}
      submitButtonText='OK'
      tutorialCode={TutorialCodes.FIRST_LOGIN}
    />  
  );
};


