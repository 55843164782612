import bidRowAmount from './bidRowAmount';
import bidUnit from './bidUnit';
import headerText from './headerText';
import projectBidAmounts from './projectBidAmounts';
import { Project } from '~/types/project';

export default function (project: Project) {
  return {
    headerText: headerText(project),
    bidRowAmount: bidRowAmount(project),
    bidUnit: bidUnit(project),
    projectBidAmounts: projectBidAmounts(project)
  };
}