import { LongTermOwner, RevenueSource, RecOwnership } from '~/constants';
import { formatNumber } from '~/utils';
import { Project } from '~/types/project';

type ValidationFunction = (...args: any) => boolean;

export const validFloat = (value: any) => {
  return parseFloat(value) !== Number(value);
};

export const validEmail = (value: any) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !emailRegex.test(value);
};

export const nonEmptyString = (value: any) => !value;
export const nonEmptyArray = (value: any) => !value?.length;
export const nonEmptyObject = (value: any) => !value || !Object.keys(value).length;

export const showingAllRevenueInfo = (validation: ValidationFunction = () => true) => {
  return (value: any, project: Project) => {
    return (
      project.longTermOwner === LongTermOwner.THIRD_PARTY &&
      validation(value, project)
    );
  };
};

export const areRecStateFieldsEnabled = (state: 'Illinois' | 'New Jersey', validation: ValidationFunction = () => true) => {
  return (value: any, project: Project) => {
    if (state === project.addressState && project.recOwnership === RecOwnership.INVESTOR) {
      return validation(value, project);
    }
  };
};

export const isIllinoisShinesProgram = (validation: ValidationFunction = () => true) => {
  return (value: any, project: Project) => {
    if (project.addressState === 'Illinois' && project.recOwnership === RecOwnership.INVESTOR && project?.recsData?.Illinois?.isShinesProgram === 'YES') {
      return validation(value, project);
    }
  };
};

export const showingExpensesTab = (validation: ValidationFunction = () => true) => {
  return (value: any, project: Project) => {
    return project.longTermOwner !== LongTermOwner.END_USER && validation(value, project);
  };
};

export const showingCustomerInfoFields = (validation: ValidationFunction = () => true) => {
  return (value: any, project: Project) => {
    return project.revenueSource !== RevenueSource.COMMUNITY_SOLAR_SUBS && validation(value, project);
  };
};

export const showingCustomerContractFields = (validation: ValidationFunction = () => true) => {
  return (value: any, project: Project) => {
    return (
      project.revenueSource !== RevenueSource.COMMUNITY_SOLAR_SUBS ||
      (project.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS && project.revenueAssumptionsFlag)
    ) && validation(value, project);
  };
};

export const showingStorageOmOptions = (validation: ValidationFunction = () => true) => {
  return(value: any, project: Project) => {
    return project.hasStorage && project.storageOmFlag && validation(value, project);
  };
};

export const max = (maxValue: any) => (value: any) => {
  if (value >= maxValue) {
    return `Please enter a number less than ${formatNumber(maxValue)}.`;
  }
};

export const range = (min: any, max: any) => (value: any) => {
  if (value < min || value > max) {
    return `Please enter a number between ${formatNumber(min)} and ${formatNumber(max)}.`;
  }
};

export const stringLength = (num: any) => (value: any) => {
  if (value?.length !== num) {
    return `Please enter ${num} characters.`;
  }
};

export const validationChain = (...validationFns: any) => (value: any, project: Project) => {  
  let result;
  for (let i = 0; i < validationFns.length; i++) {
    const validationFn = validationFns[i];
    if (typeof validationFn === 'function') {
      result = validationFn(value, project);
    }
    if (result) break;
  }
  return result;
};
