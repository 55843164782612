import { InvestorBidStatus, PricingType } from '~/constants';


const offtakeRate = (project: any) => {
  if (!project) {
    return null;
  }

  let contractRate = project.contractRate;
  if (project.pricingType === PricingType.OFFTAKE) {
    const bid = project?.projectBids?.find((bid: any) => bid.bidStatus === InvestorBidStatus.ACCEPTED || bid.bidStatus === InvestorBidStatus.PENDING) ?? {};
    contractRate = bid.amount; //TODO Project typing issue here
  }

  return contractRate;
};

export default offtakeRate;
