import { Button } from '~/components';
import Modal from '~/components/Modal';
import { usePortfolioContext } from '~/hooks';
import { useMutation } from 'react-query';
import { withPromiseToaster } from '~/utils';
import { removePortfolioProject } from '~/api/portfolioProjects';
import { useHistory } from 'react-router-dom';
import { DealStates } from '~/constants';
import { Project } from '~/types/project';

// This modal cannot be opened using the openModal() function -- it must be opened within a portfolio context

export default function RemovePortfolioProjectModal({ project, closeModal }: { project: Project, closeModal: () => void }) {
  const history = useHistory();
  const { portfolio, projectBlobs, portfolioState, fetchPortfolio, updatePortfolioValue } = usePortfolioContext();

  const { mutate, status } = useMutation(
    async () => {
      await withPromiseToaster(
        removePortfolioProject(portfolio.id, project.id)
          .then((projects) => {
            if (projects.revertStandaloneProject) {
              // This was converted to a standalone project. Redirect accordingly
              history.push(`/project/${projects.revertStandaloneProject.id}`);
            } else if (portfolio.investorId && projects.map((project: Project) => project.investorId).filter((investorId: string) => !!investorId).length === 0) {
              fetchPortfolio();
            } else {
              updatePortfolioValue('projects', projects, {doSave: false});
            }
            closeModal();
          }),
        'removing project from your portfolio'
      );
    }
  );

  return (
    <Modal closeModal={closeModal}>
      <Modal.Title>
        {projectBlobs.length === 1
          ? 'Confirm deletion'
          : 'Confirm removal from portfolio'}
      </Modal.Title>
      <Modal.Content>
        <p>
          Are you sure you want to remove the <strong>{project.name}</strong> project from the <strong>{portfolio.name}</strong> portfolio?
        </p>
        {projectBlobs.length === 2 && portfolioState.earliestState >= DealStates.IN_REVIEW && (
          <p style={{ fontWeight: '700' }}>
            Since only one project remains, this portfolio will be removed and the project will be a standalone project.
          </p>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button primary disabled={status === 'loading'} loading={status === 'loading'} onClick={() => mutate()}>Confirm</Button>
        <Button outlined disabled={status === 'loading'} primary onClick={closeModal}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}