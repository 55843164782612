import React from 'react';
import { useHistory } from 'react-router-dom';

const RedirectRoute: React.FC<{ to: string }> = ({ to }) => {
  const history = useHistory();
  history.replace(to);
  return null;
};

export default RedirectRoute;
