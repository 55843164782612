import { InputType } from '~/schema';
import { WizardField } from '../../Field';
import WizardForm from '../../WizardForm';
import { ExpensesUnit, UnitDecimals } from '~/constants';

export default function SubscriptionManagement() {
  return (
    <WizardForm>
      <WizardField
        type="radio"
        fieldLabel="Do you have recommended subscription management assumptions?"
        schemaKey="subscriptionManagementAssumptionsFlag"
        values={[
          { label: 'Yes and I’ll input them below', value: true },
          { label: 'No, investor to make own assumptions', value: false },
        ]}
        inline
        stretched
      />
      <WizardField
        fieldLabel="Subscription management costs"
        type="number"
        schemaKey="subscriptionExpenseAmount"
        numberType={InputType.DOLLARS}
        unitField="subscriptionExpenseUnit"
        units={[
          {value: ExpensesUnit.KWDC, label: 'per kWdc', decimals: UnitDecimals.KWDC},
          {value: ExpensesUnit.YEAR, label: 'per year', decimals: UnitDecimals.YEAR},
        ]}
        defaultUnit={ExpensesUnit.KWDC}
      />
      <WizardField.Row>
        <WizardField
          fieldLabel="Annual escalator"
          schemaKey="subscriptionExpenseEscalator"
          type="number"
          numberType={InputType.PERCENT}
          decimals={UnitDecimals.PERCENT}
        />
        <WizardField
          fieldLabel="Expected term"
          schemaKey="subscriptionExpenseExpectedTerm"
          type="number"
          decimals={UnitDecimals.YEAR}
          label="years"
          placeholder="Enter term length"
        />
      </WizardField.Row>
      <WizardField
        fieldLabel="Notes"
        placeholder="Please use this field to describe the project’s ongoing subscription management costs."
        schemaKey="subscriptionExpenseNotes"
        type="textarea"
        optional
      />
    </WizardForm>
  );
}