import { Comment } from 'semantic-ui-react';
import styled from 'styled-components';

export const CommentReplyContainer = styled.div`
  padding-left: 7.5rem;
`;

export const CommentActions = styled(Comment.Actions)`
  &&& {
    display: flex;
    align-items: center;
    button {
      all: unset;
      cursor: pointer;
      outline: none !important;
      
      //custom hover and active states that are more visible and accessible than default
      &:hover {
        filter: brightness(80%);
        text-decoration: underline;
      }
      &:focus-visible {
        outline: 2px solid var(--color-blue) !important;
      }
      &:active {
        filter: brightness(75%);
      }
    }
    button, * {
      margin-right: var(--small);
      color: var(--color-charcoal);
      display: inline-flex;
      align-items: center;
    }
  }
`;

export const Container = styled.div`
  .ui.comments {
    margin-top: 0;
    padding-top: 0;
    .comment {
      margin: 0;
      padding: 0;
      padding-left: var(--x-small);
    }
  }
  .ui.comments .comment {
    & + .comment,
    & + .comment-reply-form,
    & + ${CommentReplyContainer} > *:first-child {
      margin-top: var(--medium);
      &.is-own {
        margin-top: var(--small);
      }
    }
  }
  .ui.comments .comment-reply-form,
  .ui.comments ${CommentReplyContainer} {
    + .comment {
      margin-top: var(--medium);
      &.is-own {
        margin-top: var(--small);
      }
    }
  }
  .ui.comments .comment.is-own {
    background-color: var(--color-primary-light);
    border-radius: var(--x-small);
    padding-top: var(--small);
    padding-bottom: var(--small);
    + * {
      margin-top: var(--small);
    }
  }
  * {
    font-size: 0.875rem; 
  } 
  .ui.placeholder, .ui.placeholder > :before, .ui.placeholder .image.header:after, .ui.placeholder .line, .ui.placeholder .line:after {
    background-color: var(--color-off-white);
  }
`;