import axios from '~/utils/axiosInstance';

export const acceptPortfolioBids = async (portfolioId: string, data: any) => {
  const response = await axios.post(`/portfolios/${portfolioId}/bids/accept`, data);
  return response.data;
};

export const declinePortfolioBids = async (portfolioId: string, data: any) => {
  const response = await axios.post(`/portfolios/${portfolioId}/bids/decline`, data);
  return response.data;
};
