import { Header, Label } from 'semantic-ui-react';
import Link from '~/components/Link';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { DealStates } from '~/constants';
import { useState } from 'react';
import RemovePortfolioProjectModal from '~/modals/RemovePortfolioProjectModal';
import { usePermissions } from '~/requests/permissions/usePermissions';

const Container = styled.div`
  margin-bottom: calc(var(--medium) + var(--2x-small));
  .ui.huge.header {
    margin-bottom: .75rem;
  }
`;

const ElementContainer = styled.div`
  display: flex;
  gap: var(--small);
  align-items: center;
  font-size: 0.875rem;
`;

const LabelLink = styled(RouterLink)`
  &:hover {
    > * {
      filter: brightness(95%);
    }
  }
`;

export default function PortfolioProjectTitle({ projectBlob }: any) {
  const { project, projectState } = projectBlob ?? {};
  const [projectToRemove, setProjectToRemove] = useState<any>(null);
  const dealState = projectState?.dealState as number;
  const { permissions } = usePermissions();

  const needsReview = dealState === DealStates.PROJECT_HAS_ERRORS;
  const readyToSubmit = dealState > DealStates.PROJECT_HAS_ERRORS && dealState <= DealStates.READY_TO_SUBMIT;
  const portfolioEditable = permissions?.hasPortfolioDeveloperAccess && dealState < DealStates.AWAITING_DATAROOM;

  return (
    <Container>
      <Header size="huge" as="h2">{project?.name}</Header>
      <ElementContainer>
        {readyToSubmit && <Label small color='green'>Ready to submit</Label>}
        {needsReview && <LabelLink to={`/project/${project?.id}/wizard`}><Label small color='red'>Needs review</Label></LabelLink>}
        <Link 
          to={`/project/${project?.id}/review`}
          linkText='Go to project page'
          primary
          underline
          bold
        />
        {portfolioEditable && (
          <Link
            as='button'
            linkText='Remove project from portfolio'
            primary
            underline
            bold
            onClick={() => setProjectToRemove(project)}
          />
        )}
        {projectToRemove && <RemovePortfolioProjectModal project={projectToRemove} closeModal={() => setProjectToRemove(null)} />}
      </ElementContainer>
    </Container>
  );
}