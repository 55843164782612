import SiteExpenses from './SiteExpenses';
import OperatingExpenses from './OperatingExpenses';
import OperationAndMaintenance from './OperationAndMaintenance';
import SubscriptionManagement from './SubscriptionManagement';

const Expenses = { 
  SiteExpenses,
  OperationAndMaintenance,
  SubscriptionManagement,
  OperatingExpenses 
};

export default Expenses;