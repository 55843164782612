import { useMutation } from 'react-query';
import { withPromiseToaster } from '~/utils';
import { updateResponseRequiredFlag } from '~/api/conversations';
import { CommentMessage } from '~/types/conversations/Message';
import { useMessagingContext } from '~/features/messaging/providers/MessagingProvider';
import { useConversationContext } from '~/features/messaging/providers/ConversationProvider';
import { useRequiredResponseCountsQuery } from '~/requests/projects/useRequiredResponseCounts';

export const useToggleMessageResponseFlag = () => {
  const { selectedConversation } = useConversationContext();
  const { onMessageUpdate } = useMessagingContext();
  
  const { refetch: refetchRequiredResponseCounts } = useRequiredResponseCountsQuery();
  
  const supportConversation = selectedConversation && selectedConversation?.type === 'SUPPORT'; 

  return useMutation({
    mutationFn: async (message: CommentMessage) => {
      const updatedEvent = await withPromiseToaster(
        updateResponseRequiredFlag(
          selectedConversation?.id as string, 
          message.id, 
          !message.requiresResponse
        ), 
        {
          messageStub: 'updating message data',
          loading: null,
          success: null,
        }
      );
      onMessageUpdate(updatedEvent);
      return updatedEvent;
    },
    onSuccess: (data) => {
      if ((supportConversation) || (supportConversation && data.message.replyThreadId)) {
        refetchRequiredResponseCounts();
      }
    }
  });
};