import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import PopupMenu from './PopupMenu';
import { PopupMenuProps } from './types';
import { getNotificationsKey, useNotifications } from '~/requests/notifications/useNotifications';
import { MenuItem } from '../styles';
import { Loader } from 'semantic-ui-react';
import { formatDistanceToNow } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { getNotificationCountKey, useNotificationCount } from '~/requests/notifications';

const NotificationPopupMenuContainer = styled.div`
  .ui.menu {
    width: 26rem;
    .item:not(.divider) {
      font-weight: normal;
      height: auto;
      padding: 0;
      > div {
        padding: var(--x-small);
        + div {
          font-size: 0.75rem;
          color: var(--grey);
          margin-left: 0;
          padding-top: 0;
        }
      }
    }
  }
`;

const Divider = styled(MenuItem)`
  &&& {
    height: var(--medium);
    display: block;
    padding: var(--small);
    &:hover {
      cursor: default !important;
      background: transparent !important;
    }
  }
`;
Divider.defaultProps = { className: 'divider' };

const EndMessage = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  padding: var(--small) var(--x-small);
  font-size: 0.875rem;
`;

export default function NotificationsPopupMenu ({ trigger, onClose }: PopupMenuProps) {
  const queryClient = useQueryClient();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useNotifications();

  const { data: notificationsCount } = useNotificationCount();

  const history = useHistory();

  const notifications = data?.pages?.map((page: any) => page.results).flat();

  useEffect(() => {
    if (notifications && notificationsCount > 0) {
      // Whenever the notifications menu is open, invalidate the notification count cache
      // so that the notification count updates in real time
      queryClient.invalidateQueries(getNotificationCountKey());
    }
  }, [notifications, notificationsCount]);

  useEffect(() => {
    if (notificationsCount > 0) {
      // If there is a notification count > 0, invalidate the notifications cache
      queryClient.invalidateQueries(getNotificationsKey());
    }
  }, [notificationsCount]);

  const handleClick = (notification: any) => {
    let url;
    if (notification.useNotificationResolver) {
      url = `/notification/${notification.id}`;
    } else if (notification.metadata?.link) {
      url = notification.metadata.link;
    } else if (notification.projectId) {
      url = `/project/${notification.projectId}`;
    } else if (notification.portfolioId) {
      url = `/portfolio/${notification.portfolioId}`;
    }

    if (url) {
      history.push(url);
      onClose();
    }
  };

  return (
    <NotificationPopupMenuContainer>
      <PopupMenu trigger={trigger} onClose={onClose} isLoading={isFetching && !isFetchingNextPage} id="notificationsPopupMenu">
        <InfiniteScroll
          dataLength={notifications?.length ?? 0}
          next={fetchNextPage}
          hasMore={Boolean(hasNextPage)}
          scrollableTarget="notificationsPopupMenu"
          loader={<Loader active inline="centered" />}
          endMessage={<EndMessage>No more notifications to show.</EndMessage>}
        >
          {notifications?.map((notification: any, i: number) => (
            <>
              <MenuItem 
                key={notification.id} 
                onClick={() => handleClick(notification)} 
                style={{
                  whiteSpace: 'normal', 
                  lineHeight: '1.5rem'
                  }}
              >
                <div>
                  {notification.message}
                </div>
                <div>
                  {formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })}
                </div>
              </MenuItem>
              {i < notifications.length - 1 && <Divider />}
            </>
          ))}
        </InfiniteScroll>
      </PopupMenu>
    </NotificationPopupMenuContainer>
  );
}