import * as S from './styles';
import { Button, Modal } from '~/components';
import PPAModalDescriptionText from '../texts/PPAModalDescriptionText';
import { Form } from 'semantic-ui-react';
import { useForm } from '~/hooks';
import { FormSchemaAwareWidget } from '~/components/form';
import { STATES } from '~/utils';
import { createProject } from '~/api/projects';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import toast from 'react-hot-toast';
import SpecialRECsDescriptionText from '../texts/SpecialRECsDescriptionText';
import { defaultValues } from '~/schema/project';

export default function () {
  const { formState } = useForm() as any;
  const history = useHistory();
  const { closeModal } = useModalContext();

  const { mutate: saveProject, status: saveProjectStatus } = useMutation(async () => {
    try {
      const project = await createProject({ ...defaultValues, name: formState.name, addressState: formState.addressState });
      // Navigate to the project page
      history.push(`/project/${project?.id}/wizard`);
      toast.success('Successfully created your project!', { duration: 5000 });
      closeModal();
    } catch (err) {
      console.error(err);
      toast.error('An error occurred saving your project. Please refresh the page and try again', { duration: 5000 });
    }
  });

  const loading = saveProjectStatus === 'loading';
  const disabled = loading || !(formState.name && formState.addressState);
  
  return (
    <>
      <Modal.Content>
        <PPAModalDescriptionText />
        <Form>
          <S.Row>
            <FormSchemaAwareWidget
              fieldLabel="Project name"
              placeholder="Enter value"
              schemaKey="name"
            />
          </S.Row>
          <S.Row>
            <FormSchemaAwareWidget
              fieldLabel="State"
              placeholder="Enter value"
              schemaKey="addressState"
              search
              options={STATES}
            />
          </S.Row>
        </Form>
        <SpecialRECsDescriptionText />
      </Modal.Content>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button 
          primary
          onClick={() => saveProject()} 
          disabled={disabled}
          loading={loading}
        >
          Continue building my project to obtain estimated pricing
        </Button>
      </Modal.Footer>
    </>
  );
}