import styled from 'styled-components';

export const DropdownTrigger = styled.div`
  all: unset;
  &, & + .icon {
    font-weight: bold;
    color: var(--color-secondary);
  }
  &:hover {
    cursor: pointer;
  }
`;

export const DropdownContainer = styled.div`
  &&& {
    > .ui.dropdown {
      .menu {
        width: 22.5rem !important;
        > .item {
          display: flex;
          align-items: center;
          color: var(--color-charcoal);
          line-height: 1.25rem;
          white-space: normal;
          &:not(:first-child) {
            border-top: 1px solid rgba(0, 0, 0, 0.08);
          }
          > * {
            margin: 0;
            + * {
              margin-left: var(--x-small);
            }
          }
          &:hover {
            color: var(--color-charcoal);
            background-color: var(--color-off-white);
            > .checkbox > label::before {
              border-color: var(--color-primary);
            }
          }
          &.selected {
            background-color: var(--color-light-blue);
            &:hover {
              filter: brightness(95%);
            }
          }
        }
      }
    }
  }
`;