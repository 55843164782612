export const SiteLeaseTerm = {
  PROJECT_CO_WILL_OWN_SITE: 'PROJECT_CO_WILL_OWN_SITE',
  MATCHES_OFFTAKE_TERM: 'MATCHES_OFFTAKE_TERM',
  20: '20',
  25: '25',
  '30_PLUS': '30_PLUS',
  35: '35',
  40: '40'
};

export const SiteLeaseTermDisplay = {
  [SiteLeaseTerm.PROJECT_CO_WILL_OWN_SITE]: 'Project Co will own the Site',
  [SiteLeaseTerm.MATCHES_OFFTAKE_TERM]: 'Matches Offtake Term',
  [SiteLeaseTerm['20']]: '20 years',
  [SiteLeaseTerm['25']]: '25 years',
  [SiteLeaseTerm['30_PLUS']]: '30 years',
  [SiteLeaseTerm['35']]: '35 years',
  [SiteLeaseTerm['40']]: '40 years',
};

export const SiteLeaseTermDropdownOptions = [
  { value: SiteLeaseTerm.PROJECT_CO_WILL_OWN_SITE, text: SiteLeaseTermDisplay[SiteLeaseTerm.PROJECT_CO_WILL_OWN_SITE] },
  { value: SiteLeaseTerm.MATCHES_OFFTAKE_TERM, text: SiteLeaseTermDisplay[SiteLeaseTerm.MATCHES_OFFTAKE_TERM] },
  { value: SiteLeaseTerm['20'], text: SiteLeaseTermDisplay[SiteLeaseTerm['20']] },
  { value: SiteLeaseTerm['25'], text: SiteLeaseTermDisplay[SiteLeaseTerm['25']] },
  { value: SiteLeaseTerm['30_PLUS'], text: SiteLeaseTermDisplay[SiteLeaseTerm['30_PLUS']] },
  { value: SiteLeaseTerm['35'], text: SiteLeaseTermDisplay[SiteLeaseTerm['35']] },
  { value: SiteLeaseTerm['40'], text: SiteLeaseTermDisplay[SiteLeaseTerm['40']] },
];

export const SiteLeaseTermDisplayExcel = {
  [SiteLeaseTerm.PROJECT_CO_WILL_OWN_SITE]: 'Project Co will own the Site',
  [SiteLeaseTerm.MATCHES_OFFTAKE_TERM]: 'Matches Offtake Term',
  [SiteLeaseTerm['20']]: '20',
  [SiteLeaseTerm['25']]: '25',
  [SiteLeaseTerm['30_PLUS']]: '30',
  [SiteLeaseTerm['35']]: '35 years',
  [SiteLeaseTerm['40']]: '40 years',
};

export const SiteLeaseExtensionOptions = [0, 5, 10, 15].map(value => ({ label: String(value), value }));