import PortfolioReview from '~/features/portfolioReview';
import { PortfolioContextProvider } from '~/hooks/providers/PortfolioContextProvider';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import PortfolioPage from '~/components/PortfolioPage';
import RedirectRoute from './RedirectRoute';
import Messaging from '~/features/messaging';
import { MessagingProvider } from '~/features/messaging/providers/MessagingProvider';
import { ConversationProvider } from '~/features/messaging/providers/ConversationProvider';
import { validation } from '~/schema/portfolio';
import { fetchPortfolio, updatePortfolio } from '~/api/portfolios';
import { AutoSaveContextProvider } from '~/hooks/providers/AutoSaveContextProvider';
import TeamManagement from '~/components/TeamManagement';
import Dataroom from '~/features/dataroom';
import { DataroomProvider } from '~/features/dataroom/providers/DataroomProvider';

function Portfolio() {
  const { id: portfolioId } = useParams() as any;
  const history = useHistory();
  const location = useLocation();

  return (
    <AutoSaveContextProvider
      id={portfolioId}
      validation={validation}
      label='portfolio'
      api={{update: updatePortfolio, fetch(id: any) {
        return fetchPortfolio(id).then((data) => {
          // Redirect to different project if needed
          if (data.redirectToPortfolioId && portfolioId !== data.redirectToPortfolioId && location.pathname.includes(`/portfolio/${portfolioId}`)) {
            const newPathname = location.pathname.replace(`/portfolio/${portfolioId}`, `/portfolio/${data.redirectToPortfolioId}`);
            history.replace(newPathname);
          }
          return data;
        });
      }}}
    >
      <PortfolioContextProvider>
        <PortfolioPage>
          <Switch>
            <Route path="/portfolio/:id/review">
              <PortfolioReview />
            </Route>
            <Route path="/portfolio/:id/messages">
              <ConversationProvider>
                <MessagingProvider pageType="portfolio">
                  <Messaging />
                </MessagingProvider>
              </ConversationProvider>
            </Route>
            <Route exact path="/portfolio/:id/team">
              <TeamManagement />
            </Route>
            <Route exact path="/portfolio/:id/dataroom">
              <DataroomProvider>
                <Dataroom />
              </DataroomProvider>
            </Route>
            <Route exact path="/portfolio/:id">
              <RedirectRoute to={`/portfolio/${portfolioId}/review`} />
            </Route>
          </Switch>
        </PortfolioPage>
      </PortfolioContextProvider>
    </AutoSaveContextProvider>
  );
}

export default Portfolio;
