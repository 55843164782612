import styled from 'styled-components';

export const SidebarTabs = styled.aside<{ width?: number }>`
  height: 100%;
  max-width: ${({ width }) => width ? `${width / 16}rem` : '16.5rem'};
  width: 100%;
  border-right: 2px solid var(--color-grey);
`;

export const Tab = styled.button`
  all: unset;
  box-sizing: border-box;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.004375rem;
  display: block;
  width: 100%;
  padding: var(--small) 0;
  padding-left: 0.3125rem;
  font-weight: normal;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -2px;
    width: 2px;
    height: 100%;
    background: transparent;
    transition: 0.3s ease-in-out;
  }
  &.active {
    font-weight: bold;
    background: var(--color-primary-light);
    &::after {
      background: var(--color-pure-black);
      z-index: 1;
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:not(.active):hover,
  &:not(.active):focus {
    &::after {
      background: var(--color-charcoal);
    }
  }
`;