export const CustomerTypeOptions = [
  'Public School',
  'Private School',
  'Municipality',
  'Other Governmental Entity',
  'Other Non-profit - Nationwide / Large',
  'Other Non-profit - Local / Small',
  'For-profit - International / Extra Large',
  'For-profit - National / Large',
  'For-profit - Regional / Medium',
  'For-profit - Local / Small',
  'Utility',
  'Federally-Recognized Tribal Land',
  'HOAs'
].map(type => ({ key: type, text: type, value: type }));