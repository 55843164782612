export const CompanyRoles = {
  ADMIN: 'ADMIN',
  STANDARD_USER: 'STANDARD_USER',
};

export const CompanyRolesDisplay = {
  [CompanyRoles.ADMIN]: 'Admin',
  [CompanyRoles.STANDARD_USER]: 'Standard user',
};

export const CompanyRolesDropdownOptions = [
  { 
    key: CompanyRoles.ADMIN,
    text: CompanyRolesDisplay[CompanyRoles.ADMIN],
    value: CompanyRoles.ADMIN 
  },
  {  
    key: CompanyRoles.STANDARD_USER,
    text: CompanyRolesDisplay[CompanyRoles.STANDARD_USER],
    value: CompanyRoles.STANDARD_USER, 
  },
];


