import { useMemo } from 'react';
import { Label, Popup } from 'semantic-ui-react';
import { DealStates, ProjectStatus } from '~/constants';
import { useProjectContext } from '~/hooks';
import { useDataroomQuery } from '~/requests/dataroom/useDataroomQuery';
import { getDataroomCounts } from '~/utils/calculators/dataroomCounts';

export default function DataroomCounts() {
  const { project, projectState } = useProjectContext();
  const dealState = projectState?.dealState ?? 0;
  
  const { data: dataroom } =  useDataroomQuery();

  const dataroomCounts = useMemo(() => {
    return dataroom ? getDataroomCounts(dataroom.documents) : null;
  }, [dataroom]);

  if (dealState < DealStates.AWAITING_DATAROOM) {
    return null;
  }

  return (
    <>
      {dataroomCounts?.total ? (
        <Popup
          inverted
          on={['hover', 'focus']}
          position="bottom center"
          trigger={<Label tabIndex={0} circular style={{ background: 'var(--color-grey)', color: 'var(--color-pure-black)', marginLeft: 'var(--x-small)', marginRight: 0  }}>{dataroomCounts?.uploaded ?? 0}/{dataroomCounts?.total}</Label>}
          content={`${dataroomCounts?.uploaded ?? 0}/${dataroomCounts?.total} recommended documents uploaded`}
        />
      ) : null}
      {dataroomCounts?.total && project.status === ProjectStatus.IN_DILIGENCE ? (
        <Popup
          inverted
          on={['hover', 'focus']}
          position="bottom center"
          style={{ transform: 'translateX(0)' }}
          trigger={<Label tabIndex={0} circular color="green" style={{ marginLeft: 'var(--x-small)', marginRight: 0 }}>{dataroomCounts?.approved}</Label>}
          content={`${dataroomCounts?.approved ?? 0} document${dataroomCounts?.approved !== 1 ? 's' : ''} approved`}
        />
      ) : null}
    </>
  );
}