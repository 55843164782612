import React, { useRef } from 'react';

const CompanyLogo = ({ 
  src,
  size = '3.5rem',
  isConductorLogo = false,
  style = {},
  useBackgroundImage = false
}: { 
  src?: string
  size?: string
  isConductorLogo?: boolean
  style?: React.CSSProperties
  useBackgroundImage?: boolean
}) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [backgroundImageSize, setBackgroundImageSize] = React.useState<[string, string]>();

  if (isConductorLogo) {
    src = '/conductor_avatar.png';
  }

  // Using background image makes true centering easier for rendering within an avatar circle
  if (useBackgroundImage && backgroundImageSize) {
    return (
      <div style={{ width: size, height: size, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{
          width: size,
          height: size,
          backgroundImage: `url(${src})`,
          backgroundSize: backgroundImageSize.join(' '),
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          ...style
        }} />
      </div>
    );
  }

  return (
    <div style={{ width: size, height: size, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img 
        ref={imgRef} 
        src={src ?? ''} 
        alt="Company Logo" 
        style={{ opacity: 0, transition: '0.1s ease-in-out', ...style }}
        onLoad={() => {
          // Reset style attribute to default so that image can be resized when the src is changed
          if (imgRef.current?.style) {
            imgRef.current.style.height = 'auto';
            imgRef.current.style.width = 'auto';
          }

          // Update style attribute to maintain aspect ratio of image
          if (imgRef.current && imgRef.current?.clientHeight > imgRef.current?.clientWidth) {
            imgRef.current.style.height = size;
            imgRef.current.style.width = 'auto';
            imgRef.current.style.opacity = '1';
          } else if (imgRef.current && imgRef.current?.clientWidth > imgRef.current?.clientHeight) {
            imgRef.current.style.width = size;
            imgRef.current.style.height = 'auto';
            imgRef.current.style.opacity = '1';
          } else if (imgRef.current) {
            imgRef.current.style.width = size;
            imgRef.current.style.height = size;
            imgRef.current.style.opacity = '1';
          }

          setBackgroundImageSize([imgRef.current?.style.width as string, imgRef.current?.style.height as string]);
        }}
      />
    </div>
  );
};

export default CompanyLogo;