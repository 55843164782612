export const DesiredTransactionStage = {
  EARLY: 'EARLY',
  MID: 'MID',
  LATE: 'LATE',
  NTP: 'NTP',
  COD: 'COD'
};

export const DesiredTransactionStageDisplayValues = {
  [DesiredTransactionStage.EARLY]: 'Early-stage development',
  [DesiredTransactionStage.MID]: 'Mid-stage development',
  [DesiredTransactionStage.LATE]: 'Late-stage development',
  [DesiredTransactionStage.NTP]: 'Notice to Proceed (NTP)',
  [DesiredTransactionStage.COD]: 'At or Near COD'
};

export const DesiredTransactionStageDropdownValues = [
  {
    value: DesiredTransactionStage.EARLY,
    label: DesiredTransactionStageDisplayValues[DesiredTransactionStage.EARLY]
  },
  {
    value: DesiredTransactionStage.MID,
    label: DesiredTransactionStageDisplayValues[DesiredTransactionStage.MID]
  },
  {
    value: DesiredTransactionStage.LATE,
    label: DesiredTransactionStageDisplayValues[DesiredTransactionStage.LATE]
  },
  {
    value: DesiredTransactionStage.NTP,
    label: DesiredTransactionStageDisplayValues[DesiredTransactionStage.NTP]
  },
  {
    value: DesiredTransactionStage.COD,
    label: DesiredTransactionStageDisplayValues[DesiredTransactionStage.COD]
  }
];