import axios from '~/utils/axiosInstance';
import { storage } from '~/utils';

export const signup = async function (data: any = {}) {
  try {
    const response = await axios.post('/user/signup', {
      name: data.name,
      email: data.email,
      password: data.password,
      companyName: data.company,
      roleId: data.roleId,
      inviteToken: data.inviteToken
    });
    
    return response.data;
  } catch (e: any) {
    if (e?.request?.response && typeof e.request.response === 'string') {
      throw new Error(JSON.parse(e?.request?.response)?.message);
    }
    throw e;
  }
};

export const fetchRoles = async function () {
  const response = await axios.get('/user/roles');
  return response.data;
};

export const fetchNotifications = async function (params: any = {}) {
  const response = await axios.get('/user/notifications', {params});
  return {
    ...response.data,
    nextPage: (params?.page ?? 0) + 1
  };
};

export const fetchUnreadNotificationsCount = async function () {
  const response = await axios.get('/user/notifications/total-unread');
  return response.data;
};

export const fetchLoggedInUser = async function () {
  try {
    const localStorageUser = storage.getStoredCurrentUser();
    if (localStorageUser) {
      return localStorageUser;
    }
  } catch (e: any) {
    // ignored
  }
  const user = await axios.get('/user').then(response => response.data);
  storage.storeCurrentUser(user);
  return user;
};

export const updateUserSettings = async function (data: any = {}) {
  const user = await axios.patch('/user', data).then(response => response.data);
  const currentUser = storage.getStoredCurrentUser();
  storage.storeCurrentUser({...currentUser, ...user});
  return user;
};

export const updateUserPassword = async function (data: any = {}) {
  await axios.post('/user/password', data).then(response => response.data);
};

export type NotificationPreferencesObject = {
  code: string;
  name: string;
  externalUserExempt?: boolean;
  selections: {
    code: 'LEAD' | 'MEMBER' | 'NON_MEMBER';
    selected: boolean;
    updateable: boolean;
  }[]
}[];
export type NotificationPreferencesResponse = {
  developer: NotificationPreferencesObject;
  investor: NotificationPreferencesObject;
};
export const fetchNotificationPreferences = async function () {
  const response = await axios.get('/user/notification-preferences');
  return response.data as NotificationPreferencesResponse;
};

export type UpdateNotificationPreferences = {
  action: 'add' | 'delete';
  group: 'developer' | 'investor';
  preferences: { code: string; category: string } | { code: string; category: string }[];
};
export const updateNotificationPreferences = async function (data: UpdateNotificationPreferences) {
  const response = await axios.put('/user/notification-preferences', data);
  return response.data as NotificationPreferencesResponse;
};

export const updateCompanyRole = async function (userId: string, inviteId: string, companyRole: string) {
  const response = await axios.put('/user/company-role', userId ? {userId, companyRole} : {inviteId, companyRole});
  return response.data;
};

export const getPermissions = async function () {
  const response = await axios.get('/user/permissions');
  return response.data;
};

export const deactivateUser = async function (userId: string) {
  const response = await axios.delete(`user/${userId}`);
  return response.data;
};