import styled from 'styled-components';
import { Header as _Header, Form as _Form, Message as _Message} from 'semantic-ui-react';

export const HeaderLink = styled.a` 
  &&& {
    display: block;
    max-width: 11.75rem;
    text-align: center;
    margin-bottom: var(--medium);
  }

  img {
    width: 100%;
  }
`;

export const Container = styled.div`
  min-height: 100vh;
  background-color: rgba(3, 89, 140, .25);
  padding: 0 .5rem 1rem;
  display: flex;
  align-items: center; 
  flex-direction: column;
  justify-content: center;
`;

export const Box = styled.div`
  &&& {
    width: 100%;
    max-width: 28.125rem; 
    min-width: 17.1875rem;
    background-color: var(--color-off-white);
    margin: 0 auto;
    padding: var(--large) var(--large) var(--2x-large);  
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.32);
  }

  &&& > * + * {
    margin-top: var(--medium);
  }
`;

export const Header = styled(_Header)`
  color: var(--color-midnight-blue);
  text-align: center;
`;

export const Form = styled(_Form)`
  &&& {
      background-color: var(--color-off-white);
      padding: 0;
      border: none;
      box-shadow: none;
    }
    
  .ui.button {
  margin-top: 2rem;
  }

`;

export const Message = styled(_Message)`
  &&& {  
    background-color: var(--color-off-white);
    color: var(--color-blue);
    text-align: center;
    padding: 0;
    margin-top: var(--medium);
    border: none;
    box-shadow: none;
  }  
`;


