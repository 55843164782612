import Contract from './Contract';
import RenewableEnergyCredits from './RenewableEnergyCredits';
import OtherRevenueStreams from './OtherRevenueStreams';
import Information from './Information';

const Revenue = {
  Information,
  Contract,
  RenewableEnergyCredits,
  OtherRevenueStreams
};

export default Revenue;