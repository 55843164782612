import { createContext, useContext, useEffect, useState } from 'react';
import { useUserContext } from '~/hooks/providers/UserContextProvider';

const BreadcrumbsContext = createContext({} as any);

const BreadcrumbsContextProvider = ({children}: any) => {
  const {loggedInUser} = useUserContext() as any;
  const defaultBreadcrumbs = {
    dashboard: {
      label: loggedInUser?.isAdmin ? 'Projects Dashboard' : 'My Projects',
      destination: '/dashboard'
    },
    project: null,
    portfolio: null,
    dataroom: null,
    view: null,
  };
  const breadcrumbsState = {
    dashboard: useState(defaultBreadcrumbs.dashboard),
    project: useState(defaultBreadcrumbs.project),
    portfolio: useState(defaultBreadcrumbs.portfolio),
    dataroom: useState(defaultBreadcrumbs.dataroom),
    view: useState(defaultBreadcrumbs.view),
  };
  const breadcrumbs = {
    dashboard: breadcrumbsState.dashboard[0],
    project: breadcrumbsState.project[0],
    portfolio: breadcrumbsState.portfolio[0],
    dataroom: breadcrumbsState.dataroom[0],
    view: breadcrumbsState.view[0],
  };
  const setters = {
    dashboard: breadcrumbsState.dashboard[1],
    project: breadcrumbsState.project[1],
    portfolio: breadcrumbsState.portfolio[1],
    dataroom: breadcrumbsState.dataroom[1],
    view: breadcrumbsState.view[1],
  };

  return (
    <BreadcrumbsContext.Provider
      value={{breadcrumbs, setters, defaultBreadcrumbs}}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export default BreadcrumbsContextProvider;

export const useBreadcrumbs = () => useContext(BreadcrumbsContext).breadcrumbs;

export const useSetBreadcrumbs = (mergeValues: any, dependencies=[]) => {
  const {setters, defaultBreadcrumbs} = useContext(BreadcrumbsContext) as any;
  useEffect(
    () => {
      for (let key of Object.keys(mergeValues)) {
        setters[key](mergeValues[key]);
      }
      return () => {
        for (let key of Object.keys(mergeValues)) {
          setters[key](defaultBreadcrumbs[key]);
        }
      };
    },
    dependencies
  );
};
