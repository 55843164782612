import axios from '~/utils/axiosInstance';
import { storage } from '~/utils';

interface LoginParams {
  email?: string;
  password?: string;
}

interface ResetPasswordParams {
  password: string;
  passwordResetToken: string;
}

export const login = async function ({email, password}: LoginParams = {}) {
  const response = await axios.post('/auth/login', {email, password});
  storage.storeCurrentUser(response.data);
  return response.data;
};

export const logout = async function () {
  const response = await axios.post('/auth/logout');
  return response.data;
};

export const verifyPasswordResetToken = async function (token: string) {
  await axios.get(`/auth/password-reset/${token}`);
};

export const sendPasswordResetEmail = async function (email: string) {
  const response = await axios.post('/auth/forgot', {email});
  return response.data;
};

export const resetPassword = async function ({password, passwordResetToken}: ResetPasswordParams) {
  const response = await axios.post('/auth/reset-password', {password, passwordResetToken});
  return response.data;
};







