import { useState } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

export default function EditableCell({
  value,
  defaultValue,
  options,
  onChange,
  disabled
}: {
  value: any
  defaultValue?: any
  options?: DropdownItemProps[]
  onChange?: (value: any) => void
  disabled?: boolean
}) {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing && options) {
    return (
      <Dropdown
        fluid
        search
        selection
        options={options}
        defaultOpen
        defaultValue={value ?? null}
        onClose={() => setIsEditing(false)}
        onChange={(e, { value }) => onChange?.(value)}
        selectOnNavigation={false}
        selectOnBlur={false}
      />
    );
  }

  const displayValue =
    (options && value && options.find((option) => option.value === value)?.text)
    ?? value
    ?? defaultValue;

  return (
    <button
      disabled={disabled}
      style={{
        all: 'unset',
        transition: 'background-color 0.2s',
        width: '100%',
        height: '100%',
        borderRadius: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '2.25rem'
      }}
      onMouseOver={(e: any) => {
        e.currentTarget.style.background = 'var(--color-grey)';
        e.currentTarget.style.cursor = 'pointer';
      }}
      onMouseOut={(e: any) => e.currentTarget.style.background = 'initial'}
      onFocus={(e: any) => e.currentTarget.style.background = 'var(--color-grey)'}
      onBlur={(e: any) => e.currentTarget.style.background = 'initial'}
      onClick={() => setIsEditing(true)}
    >
      {displayValue}
    </button>
  );
}