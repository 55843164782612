import { TeamMember } from '~/types/teamManagement';
import { Button, Modal, Table } from '~/components';
import { useState } from 'react';
import { useUpdateProjectTeamMemberMutation } from '~/mutations/team/useUpdateProjectTeamMemberMutation';
import { useAddProjectTeamMember } from '~/mutations/team';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { usePageType, useUserContext } from '~/hooks';
import toast from 'react-hot-toast';
import { useProjectTeamQuery } from '~/requests/team';

const ProjectMemberTableCell = ({ member }: { member: TeamMember }) => {
  const pageType = usePageType();
  const { loggedInUser } = useUserContext() as any;
  const [checkedState, setCheckedState] = useState(member.isActive);
  const { mutateAsync: updateProjectTeamMember, status: updateProjectTeamMemberStatus } = useUpdateProjectTeamMemberMutation();
  const { mutateAsync: addProjectTeamMember, status: addProjectTeamMemberStatus } = useAddProjectTeamMember();
  const { permissions, refetch: refetchPermissions } = usePermissions();
  const [removingSelf, setRemovingSelf] = useState(false);
  const { team } = useProjectTeamQuery();

  const toggleCheckedState = async () => {
    const newCheckedState = !checkedState;
    if (!newCheckedState && !team?.some((otherMember) => otherMember.role === 'LEAD' && otherMember.isActive && !!otherMember.userId && otherMember.userId !== member.userId)) {
      toast.error(`You cannot remove the last lead of the ${pageType}. Please set another user as ${pageType} lead and try again.`, { duration: 8000 });
    } else if (!newCheckedState && member.userId === loggedInUser?.id && !permissions.isProjectTeamCompanyAdmin) {
      setRemovingSelf(true); // Open a modal to confirm removing self
    } else {
      try {
        setCheckedState(newCheckedState);
        if (member.isTeamMember) {
          await updateProjectTeamMember({ memberId: member.id as string, isActive: newCheckedState });
        } else if (member.userId) {
          await addProjectTeamMember({ userId: member.userId });
        }
      } catch (e: any) {
        console.error(e);
        setCheckedState(!newCheckedState);
      }
    }
  };

  return (
    <>
      <Table.Cell textAlign="center" style={{ padding: 0 }}>
        <Table.CellCheckbox
          ariaLabel={`Team member checkbox for ${member.name ?? member.email ?? 'unknown user'}`}
          checked={checkedState}
          height={48}
          onChange={toggleCheckedState}
          disabled={!permissions.canAddOrRemoveTeamMember || updateProjectTeamMemberStatus === 'loading' || addProjectTeamMemberStatus === 'loading'}
        />
      </Table.Cell>

      {removingSelf && (
        <Modal closeModal={() => setRemovingSelf(false)}>
        <Modal.Title>Removing yourself from {pageType} team</Modal.Title>
        <Modal.Content>
          <p>Once you’re removed from the {pageType} team, you will be unable to add yourself back without assistance.</p>
        </Modal.Content>
        <Modal.Footer>
          <Button
            primary
            outlined
            onClick={() => setRemovingSelf(false)}
          >
            Cancel
          </Button>
          <Button
            primary
            disabled={updateProjectTeamMemberStatus === 'loading'}
            loading={updateProjectTeamMemberStatus === 'loading'}
            onClick={async () => {
              await updateProjectTeamMember({ memberId: member.id as string, isActive: false });
              await refetchPermissions();
              setRemovingSelf(false);
              setCheckedState(false);
              toast.success('You have successfully removed yourself from the team.');
            }}
          >
            I understand
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </>
  );
};

export default ProjectMemberTableCell;