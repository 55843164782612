import React from 'react';
import { useMessagingContext } from '../../providers/MessagingProvider';
import { CommentMessage } from '~/types/conversations/Message';
import { useConversationContext } from '../../providers/ConversationProvider';
import { usePermissions } from '~/requests/permissions/usePermissions';
import UserCommentResponseToggle from './UserCommentResponseToggle';
import CommentReplyStatus from './CommentReplyStatus';
import AdminSupportCommentResponseToggle from './AdminSupportCommentResponseToggle';
import AdminCommentResponseToggle from './AdminCommentResponseToggle';
import UserNeedsResponse from './UserNeedsResponse';

const CommentMessageResponse: React.FC<{ message: CommentMessage }> = ({ message }) => {
  const { permissions } = usePermissions();
  const { selectedConversation } = useConversationContext();
  const { companyId } = useMessagingContext();

  const isOwnComment = companyId === message.companyId || (!message.companyId && permissions.isAdmin);
  const isAdmin = permissions.isAdmin;

  if (message.replyReceived) {
    return (
      <CommentReplyStatus
        replyReceived={message.replyReceived} 
        isOwnComment={isOwnComment}
      />
    );
  }

  if (selectedConversation?.type === 'PARTIES') {
    return (
      <>
        <UserNeedsResponse 
          requiresResponse={message.requiresResponse}
          isOwnComment={isOwnComment}
        />
        <UserCommentResponseToggle
          message={message}
          requiresResponse={message.requiresResponse}
          isOwnComment={isOwnComment}
        />
      </>
    );
  }

  if (selectedConversation?.type === 'SUPPORT') {
    return (
      <>
        <UserNeedsResponse
          isAdmin={isAdmin}
          requiresResponse={message.requiresResponse}
          isOwnComment={isOwnComment}
        />
        <AdminSupportCommentResponseToggle 
          message={message}
          isAdmin={isAdmin}
          requiresResponse={message.requiresResponse}
          isOwnComment={isOwnComment}
        />
        <AdminCommentResponseToggle 
          message={message}
          isAdmin={isAdmin}
          requiresResponse={message.requiresResponse}
          isOwnComment={isOwnComment}
        />
      </>
    );
  }

  return null;
};

export default CommentMessageResponse;