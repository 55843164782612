import React, { useState } from 'react';
import { Button } from '~/components';
import { Form } from 'semantic-ui-react';
import Modal from '~/components/Modal';
import { useProjectContext } from '~/hooks';
import { useUpdateProject } from '~/mutations/project/useUpdateProject';

export default function UpdateProjectNameModal({ closeModal }: { closeModal: () => void }) {
  const { project, fetchProject } = useProjectContext();
  const [projectName, setProjectName] = useState(project?.name ?? '');
  const { mutate, status } = useUpdateProject();
  const hasError = !projectName;

  const handleSubmit = async () => {
    if (!hasError) {
      await mutate({
        id: project.id, 
        data: {
          name: projectName
        },
        refetchFunction: fetchProject
      }, {
        onSuccess: closeModal
      });
    }
  };
  
  return (
    <Modal closeModal={closeModal}>
      <Modal.Title>
        Edit project
      </Modal.Title>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Input
            label="Project name"
            placeholder="Enter the project name"
            value={projectName}
            onChange={(_e, { value }) => setProjectName(value)}
            error={hasError}
          />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Button outlined primary onClick={closeModal}>Cancel</Button>
        <Button primary disabled={hasError || status === 'loading'} loading={status === 'loading'} onClick={handleSubmit}>Save changes</Button>
      </Modal.Footer>
    </Modal>
  );
}