import { CommentIconAndLabel } from './CommentElements';
import { ResponseIcon } from './CommentElements';
import { RequestIcon } from './CommentElements';
import { CommentMessage } from '~/types/conversations/Message';
import CommentResponseButton from './CommentResponseButton';
interface CommentResponseToggleProps {
  requiresResponse: boolean | undefined;
  message: CommentMessage;
  isOwnComment?: boolean;
  isAdmin?: boolean | undefined;
};

export default function AdminCommentResponseToggle ({ 
  requiresResponse,
  message,
  isAdmin,
  isOwnComment,
}: CommentResponseToggleProps) {

  if (!isOwnComment || !isAdmin) {
    return null;
  }

  if (requiresResponse) {
    return (
      <CommentResponseButton message={message}> 
        <CommentIconAndLabel icon={ResponseIcon} label="Needs a response" />
      </CommentResponseButton>
    );
  } else {
    return (
      <CommentResponseButton message={message}>
        <CommentIconAndLabel icon={RequestIcon} label="Request a response" />
      </CommentResponseButton>
    );
  }
};