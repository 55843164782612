import axios from '~/utils/axiosInstance';

export const postProjectBid = async function (data: any, id: string) {
  const response = await axios.post(`/projects/${id}/bid`, data);
  return response.data;
};

export const acceptProjectBid = async function (id: string, bidId: string) {
  const response = await axios.post(`/projects/${id}/bid/${bidId}/accept`);
  return response.data;
};

export const declineProjectBid = async function (id: string) {
  const response = await axios.post(`/projects/${id}/bid/decline`);
  return response.data;
};
