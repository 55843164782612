import { toast } from 'react-hot-toast';
import { capitalizeFirst } from '~/utils/capitalizeFirst';


function normalizeToastArgs(toastArgs: any, rest: any) {
  if (typeof toastArgs === 'function') {
    toastArgs = toastArgs(...rest);
  }
  if (typeof toastArgs === 'string' || toastArgs == null || !toastArgs.message) {
    return {message: toastArgs};
  }
  return toastArgs;
}

export function defaultErrorMessage(customizer?: string) {
  return (
    <div>      
      <div style={{marginBottom: '0.3125rem', fontWeight: '700'}}>Conductor ran into an error{customizer && ` while ${customizer}`}.{' '}</div>
      <div style={{marginBottom: '0.625rem'}}>Please attempt your activity again, and if you continue to experience issues, contact{' '}
        <a href="mailto:support@conductor.solar">support@conductor.solar</a>
      </div>
    </div>
  );
}

const DEFAULT_TOAST_OPTS = {
  loading: {duration: Infinity},
  success: {duration: 3000},
  error: {duration: 15000},
};

export function withPromiseToaster(promise: Promise<any>, toastMessages: any, {catchErrors = true, args = [], toastOpts = null}: { catchErrors?: boolean, args?: any[], toastOpts?: any } = {}): Promise<any> {
  // if toastMessages is a string, a function, or an object with a message field, that is treated as syntactic sugar for:
  //    {loading: null, success: null, messageStub: toastMessages}
  // otherwise, toastMessages should be an object optionally containing each of the 4 fields: loading, success, error, and messageStub
  // messageStub, if present, is used to build default messages as described in the message field handling section
  // for each of the loading, success, and error state fields:
  //    if the value is a function:
  //      it is considered to be a builder function, and is called with the following arguments:
  //        the args option as passed in the call to withPromiseToaster()
  //        success and error builders are additionally passed the resolved or rejected value
  //      the return value of the function is then processed as below, the same way as if it had been passed directly
  //    if the value is a string, null, or undefined, it is treated as syntactic sugar for {message: <value>}
  //    otherwise, the value should be an object with a message field, which is further processed as described below
  //    all the object's other (non-message) fields are options to be passed to toast
  // handling the message field (for each state):
  //    a null value explicitly means don't show a toast
  //    an undefined value means (possibly) use a default message:
  //      if messageStub has a value we build a default message from it
  //        the messageStub value is pre-processed just like one of the state fields
  //        the resulting message value is further tailored to to the specific state
  //        messageStub's other (non-message) fields are options to be passed to toast for any state that uses a message built from messageStub
  //      for error only, if there is no messageStub we insert a fully generic error message
  //    a string or JSX string is rendered as the toast content
  // toast options for a given toast are merged together in the following order, from low priority to high:
  //    default options as specified above by DEFAULT_TOAST_OPTS
  //    the toastOpts option passed to withPromiseToaster(promise, toastMessages, {toastOpts})
  //    messageStub's toast opts (if messageStub was used to build the message for that toast)
  //    the state's specific toast opts

  if (typeof toastMessages === 'string' || typeof toastMessages === 'function' || toastMessages?.message) {
    toastMessages = {
      loading: null,
      success: null,
      messageStub: toastMessages,
    };
  }

  let {message: messageStub, ...messageStubOpts} = normalizeToastArgs(toastMessages?.messageStub, args);
  let {message: loading, ...loadingOpts} = normalizeToastArgs(toastMessages?.loading, args);
  if (loading === undefined && messageStub) {
    loading = `${capitalizeFirst(messageStub)}...`;
    loadingOpts = {...messageStubOpts, ...loadingOpts};
  }
  let id: string;
  if (loading == null) {
    if (toastOpts?.id !== undefined) {
      id = toastOpts?.id;
      toast.dismiss(id);
    }
  } else {
    id = toast.loading(loading, {id: toastMessages?.id, ...DEFAULT_TOAST_OPTS.loading, ...(toastOpts as any), ...loadingOpts});
  }
  return promise
    .then((result) => {
      let {message: success, ...successOpts} = normalizeToastArgs(toastMessages?.success, [result, ...args]);
      if (success === undefined && messageStub) {
        success = `Succeeded ${messageStub}!`;
        successOpts = {...messageStubOpts, ...successOpts};
      }
      if (success == null) {
        if (id !== undefined) {
          toast.dismiss(id);
        }
      } else {
        toast.success(success, {id, ...DEFAULT_TOAST_OPTS.success, ...(toastOpts as any), ...successOpts});
      }
      return result;
    })
    .catch((errorResult) => {
      let {message: error, ...errorOpts} = normalizeToastArgs(toastMessages?.error, [errorResult, ...args]);
      if (error === undefined) {
        if (messageStub) {
          error = defaultErrorMessage(messageStub);
          errorOpts = {...messageStubOpts, ...errorOpts};
        } else {
          error = defaultErrorMessage();
        }
      }
      // for error, undefined isn't possible by this point, so we check === null unlike the others
      if (error === null) {
        if (id !== undefined) {
          toast.dismiss(id);
        }
      } else {
        toast.error(error, {id, ...DEFAULT_TOAST_OPTS.error, ...toastOpts, ...errorOpts});
      }

      if (!catchErrors) {
        return Promise.reject(errorResult);
      }
      console.error(`Error caught in withPromiseToaster - args:\n${args}\n`, errorResult);
    });
}
