import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { Checkbox, Dropdown, Label, Loader } from 'semantic-ui-react';
import { addPortfolioProjects, removePortfolioProject } from '~/api/portfolioProjects';
import { usePortfolioContext } from '~/hooks';
import { useProjectTeamQuery } from '~/requests/team';
import { withPromiseToaster } from '~/utils';
import { Project } from '~/types/project';

interface ProjectCheckboxProps {
  project: {
    name: string;
    id: string;
    [key: string]: any; // Any other misc. values are allowd
  };
}

export default function ProjectCheckbox({ project }: ProjectCheckboxProps) {
  const label = project.name;
  const projectId = project.id;
  const { id: portfolioId, portfolio, updatePortfolioValue } = usePortfolioContext();
  const { refetch: refetchPortfolioTeam } = useProjectTeamQuery();

  const [selected, setSelected] = useState(false);
  const selectedRef = useRef(false);

  // Mutation to add existing project to portfolio
  const { mutate: addProject, status: addProjectStatus } = useMutation(
    async () => {
      await withPromiseToaster(
        addPortfolioProjects(portfolioId, { projectIds: [projectId] })
          .then((projects) => {
            // If there's no error, update the portfolio in the cache
            updatePortfolioValue('projects', projects, { doSave: false });

            // Trigger a refetch of portfolio teams
            refetchPortfolioTeam();
          })
          .catch((err: any) => {
            // If there's an error, unselect the checkbox and throw the error
            console.error(err);
            selectedRef.current = false;
            setSelected(false);
            throw err;
          }),
        {
          messageStub: 'adding project to your portfolio'
        }
      );
    }
  );

  // Mutation to remove project from portfolio
  const { mutate: removeProject, status: removeProjectStatus } = useMutation(
    async () => {
      await withPromiseToaster(
        removePortfolioProject(portfolioId, projectId)
          .then((projects) => {
            // If there's no error, update the portfolio in the cache
            updatePortfolioValue('projects', projects, { doSave: false });
          }).catch((err: any) => {
            // If there's an error, re-select the checkbox and throw the error
            console.error(err);
            selectedRef.current = true;
            setSelected(true);
            throw err;
          }),
        {
          messageStub: 'removing the project from your portfolio'
        }
      );
    }
  );

  // Handle toggle of the checkbox
  useEffect(() => {
    if (selected && !selectedRef.current) {
      selectedRef.current = true;
      addProject();
    } else if (!selected && selectedRef.current) {
      selectedRef.current = false;
      removeProject();
    }
  }, [selected]);

  // The checkbox just prevents default behavior and doesn't actually change the state.
  // It just propagates click events to the Dropdown.Item component which handles the click.
  const checkboxHandler = (e: any) => e.preventDefault();

  // The dropdown handler is what handles selecting/unselecting the project
  const dropdownHandler = (e: any) => {
    setSelected(!selected);
    e.preventDefault();
    e.stopPropagation();
  };

  const isLoading = addProjectStatus === 'loading' || removeProjectStatus === 'loading';

  return (
    <Dropdown.Item
      selected={selected}
      onClick={dropdownHandler}
      disabled={isLoading}
    >
      <Checkbox 
        checked={selected}
        onChange={checkboxHandler}
        onClick={checkboxHandler}
        onMouseDown={checkboxHandler}
        onMouseUp={checkboxHandler}
      />
      <div>{label}</div>
      <div style={{ marginLeft: 'auto', position: 'relative', paddingRight: isLoading ? 'var(--small)' : 0 }}>
        {isLoading && <Loader size="tiny" active />}
        {!isLoading && portfolio?.projects?.find((p: Project) => p.id === projectId) && (
          <Label circular style={{ background: 'var(--color-secondary)', color: 'var(--color-pure-white)' }}>Added</Label>
        )}
      </div>
    </Dropdown.Item>
  );
}