import { PricingType, Unit, UnitDecimals } from '~/constants';
import { display } from '../../common';
import { formatDollars, formatNumber } from '~/utils/formatNumber';
import { getProjectBidOverallAmount, normalizeToPerWdc } from '~/utils/calculators/costs';
import { sizeKwdc } from '~/utils/calculators';
// import { Project } from '~/types/project';

interface Bid {
  unit: string;
  amount: number;
}

export default function bidAmount(project: any) { //TODO project typing
  const bid = project?.pendingBid ?? {};
  const { unit, amount } = bid as Bid;
  const decimals = UnitDecimals[unit] ?? 0;

  if (!amount || !unit) {
    // Returns "—"
    return display(undefined);
  } else if (unit === Unit.PERCENT) {
    // Return format X.XX%
    return display(`${formatNumber(amount, decimals)}%`);
  } else if (project.pricingType === PricingType.ACQUISITION) {
    // Return format $X / $X per Wdc
    const overall = getProjectBidOverallAmount(project, bid, false);
    const perWdc = normalizeToPerWdc(sizeKwdc(project), { amount: overall });
    return display(`${formatDollars(overall, { dec: 0 })} / ${formatDollars(perWdc, { dec: UnitDecimals.WDC })} per Wdc`);
  } else {
    return display(formatDollars(amount, { dec: decimals }));
  }
}