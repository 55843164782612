import { RecProjectCategory, RecProgramYear } from './recs';

export interface IllinoisRecValuesProps {
  min: number;
  max: number;
  GROUP_A: number;
  GROUP_B: number;
}

//additional program years to be added in the future
export const IllinoisRecValues = [
  { category: RecProjectCategory.DISTRIBUTED_GENERATION, 
    year: RecProgramYear.YEAR_2023_2024, 
    data: [
      { min: 0, max: 9, GROUP_A: 75.21, GROUP_B: 83.75 },
      { min: 10, max: 24, GROUP_A: 64.71, GROUP_B: 76.27 },
      { min: 25, max: 99, GROUP_A: 59.28, GROUP_B: 69.47 },
      { min: 100, max: 199, GROUP_A: 60.44, GROUP_B: 65.96 },
      { min: 200, max: 499, GROUP_A: 53.39, GROUP_B: 58.36 },
      { min: 500, max: 1999, GROUP_A: 50.01, GROUP_B: 52.06 },
      { min: 2000, max: 5000, GROUP_A: 40.16, GROUP_B: 39.56 },
    ] 
  },
  { category: RecProjectCategory.TRADITIONAL_COMMUNITY_SOLAR, 
    year: RecProgramYear.YEAR_2023_2024, 
    data: [
      { min: 0, max: 24, GROUP_A: 55.08, GROUP_B: 63.48  },
      { min: 25, max: 99, GROUP_A: 58.94, GROUP_B: 71.92 },
      { min: 100, max: 199, GROUP_A: 60.79, GROUP_B: 73.22 },
      { min: 200, max: 499, GROUP_A: 56.96, GROUP_B: 65.20 },
      { min: 500, max: 1999, GROUP_A: 49.94, GROUP_B: 56.08 },
      { min: 2000, max: 5000, GROUP_A: 39.27, GROUP_B: 42.39 },
    ] 
  },
  { category: RecProjectCategory.COMMUNITY_DRIVEN_COMMUNITY_SOLAR, 
    year: RecProgramYear.YEAR_2023_2024, 
    data: [
      { min: 0, max: 24, GROUP_A: 71.10, GROUP_B: 82.37 },
      { min: 25, max: 99, GROUP_A: 76.16, GROUP_B: 93.61 },
      { min: 100, max: 199, GROUP_A: 78.33, GROUP_B: 95.12 },
      { min: 200, max: 499, GROUP_A: 73.02, GROUP_B: 84.10 },
      { min: 500, max: 1999, GROUP_A: 63.72, GROUP_B: 71.80 },
      { min: 2000, max: 5000, GROUP_A: 49.33, GROUP_B: 53.31 },
    ] 
  },
  { category: RecProjectCategory.PUBLIC_SCHOOLS, 
    year: RecProgramYear.YEAR_2023_2024, 
    data: [
      { min: 0, max: 24, GROUP_A: 74.95, GROUP_B: 81.16 },
      { min: 25, max: 99, GROUP_A: 65.57, GROUP_B: 70.42 },
      { min: 100, max: 199, GROUP_A: 66.40, GROUP_B: 66.59 },
      { min: 200, max: 499, GROUP_A: 58.94, GROUP_B: 59.81 },
      { min: 500, max: 1999, GROUP_A: 56.73, GROUP_B: 53.59 },
      { min: 2000, max: 5000, GROUP_A: 45.72, GROUP_B: 37.23 },
    ] 
  },
  { category: RecProjectCategory.DISTRIBUTED_GENERATION, 
    year: RecProgramYear.YEAR_2024_2025, 
    data: [
      { min: 0, max: 9, GROUP_A: 73.71, GROUP_B: 83.87 },
      { min: 10, max: 24, GROUP_A: 63.53, GROUP_B: 77.53 },
      { min: 25, max: 99, GROUP_A: 55.89, GROUP_B: 70.23 },
      { min: 100, max: 199, GROUP_A: 53.63, GROUP_B: 63.34 },
      { min: 200, max: 499, GROUP_A: 46.58, GROUP_B: 54.60 },
      { min: 500, max: 1999, GROUP_A: 43.77, GROUP_B: 49.49 },
      { min: 2000, max: 5000, GROUP_A: 33.03, GROUP_B: 37.05 },
    ] 
  },
  { category: RecProjectCategory.TRADITIONAL_COMMUNITY_SOLAR, 
    year: RecProgramYear.YEAR_2024_2025, 
    data: [
      { min: 0, max: 24, GROUP_A: 57.49, GROUP_B: 70.91 },
      { min: 25, max: 99, GROUP_A: 58.84, GROUP_B: 72.15 },
      { min: 100, max: 199, GROUP_A: 57.50, GROUP_B: 69.58 },
      { min: 200, max: 499, GROUP_A: 53.46, GROUP_B: 64.20 },
      { min: 500, max: 1999, GROUP_A: 46.02, GROUP_B: 54.24 },
      { min: 2000, max: 5000, GROUP_A: 33.99, GROUP_B: 39.98 },
    ] 
  },
  { category: RecProjectCategory.COMMUNITY_DRIVEN_COMMUNITY_SOLAR, 
    year: RecProgramYear.YEAR_2024_2025, 
    data: [
      { min: 0, max: 24, GROUP_A: 73.82, GROUP_B: 91.47 },
      { min: 25, max: 99, GROUP_A: 75.40, GROUP_B: 92.92 },
      { min: 100, max: 199, GROUP_A: 73.28, GROUP_B: 89.36 },
      { min: 200, max: 499, GROUP_A: 67.73, GROUP_B: 82.24 },
      { min: 500, max: 1999, GROUP_A: 57.93, GROUP_B: 68.95 },
      { min: 2000, max: 5000, GROUP_A: 41.94, GROUP_B: 49.79 },
    ] 
  },
  { category: RecProjectCategory.PUBLIC_SCHOOLS, 
    year: RecProgramYear.YEAR_2024_2025, 
    data: [
      { min: 0, max: 24, GROUP_A: 77.17, GROUP_B: 93.17 },
      { min: 25, max: 99, GROUP_A: 68.57, GROUP_B: 84.96 },
      { min: 100, max: 199, GROUP_A: 65.81, GROUP_B: 76.91 },
      { min: 200, max: 499, GROUP_A: 57.72, GROUP_B: 66.88 },
      { min: 500, max: 1999, GROUP_A: 54.51, GROUP_B: 61.04 },
      { min: 2000, max: 5000, GROUP_A: 42.15, GROUP_B: 46.74 },
    ] 
  },
];

