import { RecAllocationRadioOptions, RecProgramYearRadioOptions, RecProjectCategoryRadioOptions, RecShinesProgramRadioOptions, RecUtilityGroupRadioOptions } from '~/constants';
import { WizardField } from '~/features/projectWizard/components/Field';
import { useProjectContext } from '~/hooks';
import { DisplayRECValue } from './DisplayRECValue';

export default function IllinoisRECForm() {
  const { project } = useProjectContext();
  const schemaKey = (term: string) => `recsData.${project.addressState}.${term}`;

  return (
    <>
      <WizardField
        type="radio"
        fieldLabel="Is this part of the IL SHINES program?"
        schemaKey={schemaKey('isShinesProgram')}
        inline
        values={RecShinesProgramRadioOptions}
      />
      {project?.recsData?.Illinois?.isShinesProgram === 'YES' && (
        <>
        <WizardField
          type="radio"
          fieldLabel="Have you secured a REC allocation?"
          schemaKey={schemaKey('recAllocation')}
          values={RecAllocationRadioOptions}
        />
        <WizardField
          type="radio"
          fieldLabel="Project category"
          schemaKey={schemaKey('recProjectCategory')}
          helpText='How is this project classified within the IL SHINES program?'
          values={RecProjectCategoryRadioOptions}
        />
        <WizardField
          type="radio"
          fieldLabel="Utility group"
          schemaKey={schemaKey('recUtilityGroup')}
          inline
          values={RecUtilityGroupRadioOptions}
        />
        <WizardField
          type="radio"
          fieldLabel="Program year"
          schemaKey={schemaKey('recProgramYear')}
          inline
          stretched
          values={RecProgramYearRadioOptions}
        />
        <DisplayRECValue />
      </>
      )}
    </>
  );
}