import location from './location';
import interconnectionType from './interconnectionType';
import pricingType from './pricingType';
import targetITC from '../targetITC';
import technologies from '../technologies';
import offtakeRate from './offtakeRate';
import longTermOwner from './longTermOwner';
import developer from './developer';
import { Project } from '~/types/project';

export default function projectDetails(project: Project, projectCosts: any) {
  return {
    location: location(project),
    interconnectionType: interconnectionType(project),
    pricingType: pricingType(project),
    targetITC: targetITC(project, projectCosts),
    technologies: technologies(project),
    offtakeRate: offtakeRate(project),
    longTermOwner: longTermOwner(project),
    developer: developer(project)
  };
}