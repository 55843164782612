import axios from 'axios';
import { useMutation } from 'react-query';
import { replaceMessageFile } from '~/api/conversations';

export const useReplaceMessageFileMutation = (params = {}) => {
  return useMutation({
    mutationFn: async ({ conversationId, messageId, file }: { conversationId: string, messageId: string, file: File }) => {
      const result = await replaceMessageFile(conversationId, messageId, file.name);
      if (result?.presignedUrl) {
        await axios.put(result.presignedUrl, file);
      } else {
        throw new Error('No presigned url returned when uploading file project event');
      }

      return result.message;
    },
    ...params
  });
};