import { HTMLProps, ReactNode } from 'react';
import * as S from './styles';

interface FieldLabelProps extends HTMLProps<HTMLLabelElement> {
  text: string
  optional?: boolean
  helpText?: string | ReactNode
}

export default function FieldLabel({ text, optional, helpText, ...labelProps }: FieldLabelProps) {
  if (!text || typeof text !== 'string') {
    return null;
  }

  return (
    <>
      <label {...labelProps} className={helpText ? 'withHelpText' : ''}>
        {text} {optional && <span style={{ color: 'var(--color-charcoal)' }}>(optional)</span>}
      </label>
      {helpText && <S.HelpText>{helpText}</S.HelpText>}
    </>
  );
}