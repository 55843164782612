import { Button, Dropdown, Grid } from 'semantic-ui-react';
import ExcelJS from 'exceljs';
import StickyContainer from './StickyContainer';
import styled from 'styled-components';
import { useRef } from 'react';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import CreateProjectModal from '~/modals/CreateProjectModal';
import useHeightCalculation from '~/hooks/useHeightCalculation';
import CreatePortfolioModal from '~/modals/CreatePortfolioModal';
import { useProjectList } from '~/requests/projects/useProjectList';
import { getKPIs } from '~/api/admin';
import download from 'downloadjs';
import { format } from 'date-fns';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import InstantQuoteModal from '~/modals/InstantPPAModal';
import OwnershipDropdownFilter from './OwnershipDropdownFilter';
import { useUserContext } from '~/hooks';

interface ItemCreationRowProps {
  navHeight: number;
  onHeightCalculation: (height: number) => void;
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  .ui.button {
    min-width: 7.25rem;
    margin-right: 0;
    flex-shrink: 0;
  }
  > * + * {
    margin-left: var(--large);
  }
`;

export default function ItemCreationRow({ navHeight, onHeightCalculation }: ItemCreationRowProps) {
  const { loggedInUser } = useUserContext() as any;
  const { data: projects } = useProjectList();
  const { openModal } = useModalContext();
  const navHeightRem = navHeight ? `${navHeight / 16}rem` : '0';
  const ref = useRef(null);
  
  const { mutate: downloadKPIs, status: loadingStatus } = useMutation(async () => {
    try {
      const dateToday = format(new Date(), 'MM_dd_yyyy_HH_mm');

      const kpis = await getKPIs();

      // Parse out excel headers and data
      const headers = kpis.length > 0 ? Object.keys(kpis[0]) : [];
      const data = kpis.reduce((acc: any[], row: any) => {
        acc.push(headers.map((header) => row[header]));
        return acc;
      }, [] as any[]);


      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('KPIs');
      sheet.properties.defaultColWidth = 24;

      // Add headers
      sheet.addRow(headers);
      // Add data
      data.forEach((row: any) => sheet.addRow(row));
      
      // convert buffer to a file
      const buffer = await workbook.xlsx.writeBuffer();

      // convert the buffer to a blob
      download(new Blob([buffer]), `Conductor_KPIs_${dateToday}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      toast.success('Successfully created Excel for download', { duration: 5000 });
    } catch (err) {
      console.error(err);
      toast.error('An error occurred while creating the Excel for download. Please refresh the page and try again', { duration: 5000 });
    }   
  });

  // Report the height of this component to the parent
  useHeightCalculation(ref, onHeightCalculation);

  return (
    <StickyContainer ref={ref} style={{ top: navHeightRem, paddingTop: 'var(--medium)', paddingBottom: 'var(--medium)' }}>
      <Grid.Row>
        <Grid.Column>
          <FlexContainer>
            {loggedInUser?.isInstaller && (
              <Dropdown trigger={<Button primary>Create new...</Button>} icon={null}>
                <Dropdown.Menu style={{ marginTop: 'var(--2x-small)', minWidth: '13.25rem' }}>
                  <Dropdown.Item text="Project" onClick={() => openModal(CreateProjectModal)} />
                  {projects?.length >= 2 && <Dropdown.Item text="Portfolio" onClick={() => openModal(CreatePortfolioModal)} />}
                  <Dropdown.Item text="Initial market quote" onClick={() => openModal(InstantQuoteModal)} />
                </Dropdown.Menu>
              </Dropdown>
            )}
            {loggedInUser?.isAdmin && (
              <>
                <Button 
                  primary
                  disabled={loadingStatus === 'loading'}
                  loading={loadingStatus === 'loading'}
                  onClick={() => downloadKPIs()}
                >
                  Download KPIs
                </Button>
                <div>
                  <OwnershipDropdownFilter />
                </div>
              </>
            )}
            {!loggedInUser?.isAdmin && (
              <p>
                Welcome to your dashboard! Use the <strong>filters</strong> above to focus on the projects you’re most interested in, 
                use the <strong>headers</strong> below to sort your results the way you prefer
                {loggedInUser?.isInstaller ? <>, or use the <strong>button</strong> to the left to create new projects and portfolios.</> : '.'}
              </p>
            )}
          </FlexContainer>
        </Grid.Column>
      </Grid.Row>
    </StickyContainer>
  );
}