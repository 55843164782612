export const EpcCompanyType = {
  SELF: 'SELF',
  OTHER_DEVELOPER: 'OTHER_DEVELOPER',
  OTHER_INVESTOR: 'OTHER_INVESTOR'
};

export const EpcCompanyTypeDisplayValues = {
  [EpcCompanyType.SELF]: 'Self',
  [EpcCompanyType.OTHER_DEVELOPER]: 'Other EPC selected by developer',
  [EpcCompanyType.OTHER_INVESTOR]: 'Other EPC to be selected by investor'
};

export const EpcCompanyTypeDropdownValues = [
  {
    value: EpcCompanyType.SELF,
    label: EpcCompanyTypeDisplayValues[EpcCompanyType.SELF]
  },
  {
    value: EpcCompanyType.OTHER_DEVELOPER,
    label: EpcCompanyTypeDisplayValues[EpcCompanyType.OTHER_DEVELOPER]
  },
  {
    value: EpcCompanyType.OTHER_INVESTOR,
    label: EpcCompanyTypeDisplayValues[EpcCompanyType.OTHER_INVESTOR]
  }
];