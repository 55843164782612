import * as S from '~/components/GridComponent';
import { ProjectArray } from '~/types/project';
import { Project } from '~/types/project';
import { ProjectArraysShortDisplay } from '~/constants';
import { display } from '~/utils/displayValues/common';
import { System } from '~/utils/calculators';
import { formatNumber } from '~/utils';


export default function ProductionValidationTable({ project }: { project: Project }) {

  // Totals
  const arraysTotal = System.sizeKwdc(project?.projectArrays) ? `${display(formatNumber(System.sizeKwdc(project?.projectArrays)))} kWdc - Total` : 'Total TBD'; 
  const yourInputsTotal = display(System.productionFactor(project?.projectArrays) && formatNumber(System.productionFactor(project?.projectArrays)));
  const dnvMinTotal = display(project?.dnvEnergyYieldLow && formatNumber(project?.dnvEnergyYieldLow));
  const dnvEstimateTotal = display(project?.dnvEnergyYieldEst && formatNumber(project?.dnvEnergyYieldEst));
  const dnvMaxTotal = display(project?.dnvEnergyYieldHigh && formatNumber(project?.dnvEnergyYieldHigh));

  return (
    <S.GridContainer style={{ marginTop: '3rem' }}>
      <S.GridTable 
        columnValues='auto 14rem repeat(4, 1fr)'
        style={{ placeItems: 'center'}}
      >
        {/* Header row */}
        <S.GridLabelEl gridColumn='1 / 3'>Array</S.GridLabelEl>
        <S.GridLabelEl >Your Inputs</S.GridLabelEl>
        <S.GridLabelEl>DNV min</S.GridLabelEl>
        <S.GridLabelEl>DNV Estimate</S.GridLabelEl>
        <S.GridLabelEl>DNV Max</S.GridLabelEl>

        <div className='line'></div>

        {project?.projectArrays?.map((projectArray: ProjectArray, index: number) => {
          
          // Arrays
          const projectArrayNum = index + 1;
          const projectArrayType = projectArray?.type ? `${ProjectArraysShortDisplay[projectArray?.type]}` : 'Array TBD' ;
          const projectArraySize = projectArray?.sizeKwdc ? `${display(formatNumber(projectArray?.sizeKwdc))} kWdc - ` : 'Size TBD - ';
          const productionFactor = System.productionFactor(projectArray);
          const yourInput = display(productionFactor && formatNumber(productionFactor));
          const dnvMin = display(projectArray?.dnvEnergyYieldLow && formatNumber(projectArray?.dnvEnergyYieldLow));
          const dnvEstimate = display(projectArray?.dnvEnergyYieldEst && formatNumber(projectArray?.dnvEnergyYieldEst));
          const dnvMax = display(projectArray?.dnvEnergyYieldHigh && formatNumber(projectArray?.dnvEnergyYieldHigh));

          return (
            <>
              <S.GridTableEl 
                gridColumn='1 / 2'
                bold
                style={{ padding: 'var(--x-small)' }}
              >
                {projectArrayNum}
              </S.GridTableEl>
              <S.GridTableEl 
                gridColumn='2 / 3' 
                bold
                style={{ padding: 'var(--x-small)', placeSelf: 'start'}}
              >
                {projectArraySize}{projectArrayType}
              </S.GridTableEl>
              <S.GridTableEl gridColumn='3 / 4' charcoal>{yourInput}</S.GridTableEl>
              <S.GridTableEl gridColumn='4 / 5' charcoal>{dnvMin}</S.GridTableEl>
              <S.GridTableEl gridColumn='5 / 6' charcoal>{dnvEstimate}</S.GridTableEl>
              <S.GridTableEl gridColumn='6 / 7' charcoal>{dnvMax}</S.GridTableEl>
            </>
          );
        })}

        {/* // Totals row */}
        <S.GridTableEl 
          gridColumn='2 / 3' 
          bold
          style={{ padding: 'var(--small) 0 0 var(--x-small)', placeSelf: 'start' }}
        >
          {arraysTotal}
        </S.GridTableEl>
        <S.GridTableEl 
          gridColumn='3 / 4' 
          primary
          bold
          style={{ paddingTop: 'var(--small)'}}
        >
          {yourInputsTotal}
        </S.GridTableEl>
        <S.GridTableEl 
          gridColumn='4 / 5'
          primary
          bold
          style={{ paddingTop: 'var(--small)'}}
        >
          {dnvMinTotal}
        </S.GridTableEl>
        <S.GridTableEl 
          gridColumn='5 / 6'
          primary
          bold
          style={{ paddingTop: 'var(--small)'}}
        >
          {dnvEstimateTotal}
        </S.GridTableEl>
        <S.GridTableEl 
          gridColumn='6 / 7'
          primary
          bold
          style={{ paddingTop: 'var(--small)'}}
        >
          {dnvMaxTotal}
        </S.GridTableEl>
      </S.GridTable>
    </S.GridContainer>
  );
}