import { User } from '~/types/user';
import axios from '~/utils/axiosInstance';

interface CreateUserParams {
  email: string;
  name: string;
  role: string;
  investor: string;
  installer: string;
}

interface RejectProjectParams {
  reason: string;
  message: string;
}

interface UpdateProjectBidDeadlineParams {
  projectId: string;
  portfolioId: string;
  date: string;
}

export const getInvestors = async function () {
  const response = await axios.get('/admin/investors');
  return response?.data;
};

export const createUser = async function ({email, name, role, investor, installer}: CreateUserParams) {
  await axios.post('/admin/users', {email, name, role, investor, installer});
};

export const getUserById = async function (id: string) {
  await axios.get(`/admin/users/${id}`);
};

export const deleteUserById = async function (id: string) {
  await axios.delete(`/admin/users/${id}`);
};

export const rejectProject = async function (id: string, {reason, message}: RejectProjectParams) {
  const response = await axios.post(`/admin/projects/${id}/reject`, {reason, message});
  return response.data;
};

export const getUsers = async function (params: any = {}) {
  const response = await axios.get('/admin/users', {params});
  return response.data as Promise<User[]>;
};

export const updateProjectBidDeadline = async function ({ projectId, portfolioId, date }: UpdateProjectBidDeadlineParams) {
  const response = await axios.patch('/admin/bid-deadline', { projectId, portfolioId, date });
  return response.data;
};

export const getAdminNotes = async function (projectId: string) {
  const response = await axios.get(`/admin/projects/${projectId}/notes`);
  return response.data;
};

export const updateAdminNotes = async function (projectId: string, notes: string) {
  const response = await axios.patch(`/admin/projects/${projectId}/notes`, { notes });
  return response.data;
};

export const toggleDataroomUI = async function (dataroomId: string) {
  const response = await axios.patch(`/admin/dataroom/${dataroomId}/toggle-ui`);
  return response.data;
};

export const getKPIs = async function () {
  const response = await axios.get('/admin/kpis');
  return response.data;
};

export const sendNotifications = async function () {
  const response = await axios.post('/admin/notifications/send');
  return response.data;
};

export const getCompanies = async function () {
  const response = await axios.get('/admin/companies');
  return response.data;
};

export const updateCompanyOwnership = async function (id: string, data: any) {
  const response = await axios.patch(`/admin/companies/${id}/ownership`, data);
  return response.data;
};

export const getProjectOwnership = async function (id: string) {
  const response = await axios.get(`/admin/projects/${id}/ownership`);
  return response.data as { owner: User };
};

export const updateProjectOwnership = async function (projectId: string, ownerId: string) {
  const response = await axios.post(`/admin/projects/${projectId}/ownership`, { ownerId });
  return response.data as { owner: User };
};

export const getPortfolioOwnership = async function (id: string) {
  const response = await axios.get(`/admin/portfolios/${id}/ownership`);
  return response.data as { owner: User };
};

export const updatePortfolioOwnership = async function (portfolioId: string, ownerId: string) {
  const response = await axios.post(`/admin/portfolios/${portfolioId}/ownership`, { ownerId });
  return response.data as { owner: User };
};