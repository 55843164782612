import * as S from './styles';
import { Header, Label } from 'semantic-ui-react';
import { useProjectContext } from '~/hooks';
import { isAfter, parseISO, isSameDay } from 'date-fns';

export default function BidAreaHeader () {
  const { project, projectDisplayValues } = useProjectContext();
  const bidDeadlineDisplayText = projectDisplayValues?.investorBidArea?.bidDeadline;
  const hasPendingBid = project?.pendingBid;

  const currentDate = new Date();
  const bidDeadlineData = (project?.bidDeadline || project?.portfolio?.bidDeadline) as string;
  const bidDeadline = parseISO(bidDeadlineData);
  const isBidDeadlinePassed = isAfter(currentDate, bidDeadline) || isSameDay(currentDate, bidDeadline);


  return (
    <S.BidAreaHeader>
      <Header as="h2">{projectDisplayValues.investorBidArea.headerText}</Header>
      {bidDeadlineDisplayText && !isBidDeadlinePassed ? (
        <Label color= 'olive'>
          {bidDeadlineDisplayText}
        </Label>
      ) : isBidDeadlinePassed && !hasPendingBid ? (
        <Label color= 'red'>
          {bidDeadlineDisplayText}
        </Label>
      ) : null} 
    </S.BidAreaHeader>
  );
}