/**
 * This file is used to fetch the team for a project OR a portfolio.
 * It checks the page type and fetches the team accordingly, by either calling
 * GET /api/projects/{id}/team or GET /api/portfolios/{id}/team based on page type.
 */

import { useQuery } from 'react-query';
import { usePageType, useProjectContext } from '~/hooks';
import { useParams } from 'react-router-dom';
import { getPortfolioOwnership, getProjectOwnership } from '~/api/admin';

export const ownershipKey = (type: string, id: string) => ['ownership', type, id];

export const useOwnershipQuery = () => {
  const pageType = usePageType();
  const { id } = useParams<{ id: string }>();
  const { project } = useProjectContext();
  const entityType = project?.portfolioId ? 'portfolio' : pageType;
  const entityId = project?.portfolioId ?? id;
  
  return useQuery({
    queryKey: ownershipKey(entityType, entityId),
    queryFn() {
      if (entityType === 'project') {
        return getProjectOwnership(entityId);
      } else if (entityType === 'portfolio') {
        return getPortfolioOwnership(project?.portfolioId ?? entityId);
      } else {
        throw new Error('Invalid page type');
      }
    },
    staleTime: Infinity, // Never refetch
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false
  });
};