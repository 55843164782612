import { acceptProjectBid } from '~/api/projectBids';
import Modal from '~/components/Modal';
import { useMutation } from 'react-query';
import { withPromiseToaster } from '~/utils';
import { Button } from '~/components';
import { useModalContext } from '~/hooks/providers/ModalProvider';


export default function AcceptProjectBidModal() {
  const { modalProps, closeModal } = useModalContext();
  const project = modalProps?.project;
  const bid = modalProps?.bid;
  const onComplete = modalProps?.onComplete;

  if (!project || !bid || !onComplete) {
    throw new Error('Missing 1 or more required modal props');
  }

  const { mutate: acceptBid, status } = useMutation(() => {
    return withPromiseToaster(
      acceptProjectBid(project?.id, bid?.id),
      {
        messageStub: 'accepting the project bid'
      }
    ).then(() => {
      onComplete();
      closeModal();
    });
  });

  const acceptingBid = status === 'loading';

  return (
    <Modal closeModal={closeModal} allowClose={!acceptingBid}>
      <Modal.Title>Accept project bid?</Modal.Title>
      <Modal.Content>
        <p><strong>Are you sure you want to accept this project bid?</strong> This cannot be undone without contacting an administrator.</p>
      </Modal.Content>
      <Modal.Footer>
        <Button primary outlined disabled={acceptingBid} onClick={closeModal}>Go back</Button>
        <Button primary loading={acceptingBid} disabled={acceptingBid} onClick={() => acceptBid()}>Continue</Button>
      </Modal.Footer>
    </Modal>
  );    
}