import { cloneDeep } from 'lodash';
import { FormConditionEffects, FormConditionFunctionObject, FormConditionObject, FormConditionSchemaObject, FormConfig, FormFieldConditionObject } from '~/schema/types';
import { get } from 'lodash';

export interface FormConditionsParams {
  data: { [key: string]: any },
  config?: FormConfig
  updateData?: (
    key: string | null | undefined,
    value: any,
    options?: { doSave?: boolean, patch?: boolean, deleteKeys?: boolean }
  ) => void,
  getTransformedData?: boolean;
}

/**
 * Util function that alerts clicks outside of the passed ref
 */
export default function({ data, updateData, config, getTransformedData }: FormConditionsParams) {
  const conditionsObject = config?.conditions ?? {};

  // Loop through conditions, set display states to determine
  // Section/Subsection/Field labels and whether certain sections/fields should display
  const getDisplayState = (key: string, conditions: FormConditionObject[]): FormConditionEffects | null => {
    const displayState: FormConditionEffects = {};

    if (!data) return displayState;

    conditions.forEach(condition => {
      if (!condition.effects) return null;

      let isConditionTrue = false;

      if ((condition as FormConditionFunctionObject)?.conditionFn) {
        const { conditionFn } = condition as FormConditionFunctionObject;
        isConditionTrue = conditionFn(data);
      } else {
        const conditionObject = condition as FormConditionSchemaObject;
        const fieldValue = get(data, conditionObject.fieldId);
        if (conditionObject['='] !== undefined) {
          isConditionTrue =  fieldValue === conditionObject['='];
        } else if (conditionObject['!='] !== undefined) {
          isConditionTrue = fieldValue !== conditionObject['!='];
        } else if (conditionObject['isNull']) {
          isConditionTrue = fieldValue === '' || fieldValue === undefined || fieldValue === null;
        } else if (conditionObject['isNotNull']) {
          isConditionTrue = fieldValue !== null && fieldValue !== undefined && fieldValue !== '';
        }
      }

      if (isConditionTrue) {
        displayState.props = condition.effects.props;
      }
      if ((isConditionTrue && condition.effects.hide) || (!isConditionTrue && condition.effects.show && !displayState.show)) {
        displayState.hide = true;
        displayState.show = undefined;
      } else if ((!isConditionTrue && condition.effects.hide && !displayState.hide) || (isConditionTrue && condition.effects.show)) {
        displayState.show = true;
        displayState.hide = undefined;
      }
      if (isConditionTrue && condition.effects.label) {
        displayState.label = condition.effects.label;
      }
      if (isConditionTrue && condition.effects.description) {
        displayState.description = condition.effects.description;
      }

      const fieldCondition = condition as FormFieldConditionObject;
      if (isConditionTrue && fieldCondition?.effects?.validRadioValues?.length) {
        displayState.validRadioValues = fieldCondition.effects.validRadioValues;
      }

      displayState.persist = condition.effects.persist;
    });

      // Update selected value if necessary (e.g., if a currently-selected radio or checkbox value is no longer a valid choice)
    const validListValues = displayState.validRadioValues ?? displayState.validCheckboxValues;
    if (validListValues && updateData) {
      const selectedValue = get(data, key);
      if (selectedValue && !validListValues.find(value => value === selectedValue)) {
        // Unset the radio selection if the selected option is no longer valid
        updateData(key, undefined);
      }
    }

    // Update dropdown value if necessary (e.g., if a currently-selected dropdown value is no longer a valid choice)
    if (displayState?.props?.options && updateData) {
      const selectedValue = get(data, key);
      if (selectedValue && !displayState.props.options.find((option: { value: string }) => option.value === selectedValue)) {
        // Unset the dropdown selection if the selected option is no longer valid
        updateData(key, undefined);
      }
    }

    return displayState;
  };
  const getDisplayStates = (conditions?: { [key: string]: FormConditionObject | string | (FormConditionObject | string)[] }) => {
    if (!conditions) return {};
    
    return Object.entries(conditions).reduce((acc, curr) => {
      const [key, condition] = curr;
      let conditions = condition;
      if (!Array.isArray(conditions)) {
        conditions = [conditions];
      }
      conditions = conditions.map(condition => {
        if (typeof condition === 'string') {
          return conditionsObject?.defaults?.[condition] ?? '';
        } else {
          return condition;
        }
      });
      acc[key] = getDisplayState(key, conditions as FormConditionObject[]);
      return acc;
    }, {} as { [key: string]: FormConditionEffects | null });
  };
  
  const displayStates = {
    sections: getDisplayStates(conditionsObject?.sections),
    subsections: getDisplayStates(conditionsObject?.subsections),
    fields: getDisplayStates(conditionsObject?.fields)
  };

  const isPersistingData = (category: 'sections' | 'subsections' | 'fields', id: string) => {
    return !!displayStates?.[category]?.[id]?.persist;
  };

  const isDisplaying = (category: 'sections' | 'subsections' | 'fields', id: string) => {
    if (displayStates?.[category]?.[id]?.show === undefined && displayStates?.[category]?.[id]?.hide === undefined) {
      return true;
    } else if (displayStates?.[category]?.[id]?.show) {
      return true;
    } else if (displayStates?.[category]?.[id]?.hide) {
      return false;
    }
    return false;
  };

  const isFieldPersisting = (fieldId: string) => isPersistingData('fields', fieldId);
  const isSectionPersisting = (sectionId: string) => isPersistingData('sections', sectionId);
  const isSubsectionPersisting = (subsectionId: string) => isPersistingData('subsections', subsectionId);
  const isFieldDisplaying = (fieldId: string) => isDisplaying('fields', fieldId);
  const isSectionDisplaying = (sectionId: string) => isDisplaying('sections', sectionId);
  const isSubsectionDisplaying = (subsectionId: string) => isDisplaying('subsections', subsectionId);

  const getValidRadioValues = (fieldId: string, values: { value: any; label: string; disabled?: boolean }[]) => {
    const { validRadioValues } = displayStates?.fields?.[fieldId] ?? {};
    if (!validRadioValues) {
      return values;
    }

    return values.map(valueObject => {
      if (validRadioValues?.length && !validRadioValues?.find(validValue => validValue === valueObject.value)) {
        return {
          ...valueObject,
          disabled: true
        };
      } 
      return valueObject;
    });
  };

  const getLabel = (category: 'sections' | 'subsections' | 'fields', id: string, defaultLabel: string) => {
    return displayStates?.[category]?.[id]?.label ?? defaultLabel;
  };

  const getSectionDescription = (sectionId: string, defaultDescription: string) => {
    return displayStates?.sections?.[sectionId]?.description ?? defaultDescription;
  };

  const fieldLabel = (fieldId: string, defaultLabel: string) => getLabel('fields', fieldId, defaultLabel);
  const fieldPlaceholder = (fieldId: string, defaultPlaceholder: string) => {
    return displayStates?.fields?.[fieldId]?.placeholder ?? defaultPlaceholder;
  };
  const sectionLabel = (sectionId: string, defaultLabel: string) => getLabel('sections', sectionId, defaultLabel);
  const sectionDescription = (sectionId: string, defaultDescription: string) => getSectionDescription(sectionId, defaultDescription);
  const subsectionLabel = (subsectionId: string, defaultLabel: string) => getLabel('subsections', subsectionId, defaultLabel);
  const getFieldProps = (fieldId: string) => {
    return displayStates?.fields?.[fieldId]?.props;
  };

  const transformedData = data && getTransformedData ? (() => {
    const clonedData = cloneDeep(data);
    const fields = config?.mappedFields ?? {};
    Object.keys(data).forEach(key => {
      const fieldConfig = fields[key];
      if (
        fieldConfig && 
        (
          !(isFieldPersisting(key) || isSectionPersisting(fieldConfig.sectionId ?? '') || isSubsectionPersisting(fieldConfig.subsectionId ?? ''))
          && (!isFieldDisplaying(key) 
            || !isSectionDisplaying(fieldConfig.sectionId ?? '') 
            || !isSubsectionDisplaying(fieldConfig.subsectionId ?? ''))
        )
      ) {
        delete clonedData[key];
      }
    });
    return clonedData;
  })() : data;

  return {
    displayStates,
    getValidRadioValues,
    isFieldDisplaying,
    isSectionDisplaying,
    isSubsectionDisplaying,
    fieldLabel,
    fieldPlaceholder,
    sectionLabel,
    sectionDescription,
    subsectionLabel,
    getFieldProps,
    transformedData
  };
};