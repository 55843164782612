import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

export const WizardForm = styled<{ isInner?: boolean } & typeof Form>(Form)`
  &&& {
    p {
      color: var(--color-charcoal);
      font-size: 0.875rem;
      margin-bottom: 0;
    }
    > * {
      margin: 0;
      margin-top: var(--medium);
    }
    .field {
      padding: 0.1875rem 0;
    }
    ${props => !props.isInner && 'padding-bottom: var(--2x-large);'}
    .submit-button {
      margin-top: calc(2 * var(--medium));
    }
    .grid, .row {
      padding: 0;
    }
    .column:not(.grid) {
      padding: 0 calc(var(--medium) / 2);
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
`;