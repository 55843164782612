import { display } from '~/utils/displayValues/common';
import { Project } from '~/types/project';

export default function location(project: Project) {
  let location = '';

  // Add city to string if set
  if (project?.addressCity) {
    location += project.addressCity;
  }

  // Add address to string if set,
  // accounting for whether the city is present or not
  if (project?.addressState) {
    if (location) {
      location += ', ';
    }
    location += project.addressState;
  }

  return display(location);
}