import { CSSProperties, ReactNode, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Tab from '../Tab';

export type NavigationTabConfig = {
  path?: string;
  content: ReactNode,
  style?: CSSProperties
} | null | undefined | false // Allow falsey values to be passed in. These will be filtered out.

interface NavigationTabsProps {
  tabs: NavigationTabConfig[]
}

export default function NavigationTabs({ tabs }: NavigationTabsProps) {
  const { pathname } = useLocation();

  const menuItem = useCallback((tab: NavigationTabConfig, i: number) => {
    if (!tab) return;

    const { path, content, style } = tab;
    const active = pathname === path;
    const defaults = { active, content, key: i, style };

    return {
      menuItem: active || !path ? defaults : { as: Link, to: path, ...defaults }
    };
  }, [pathname]);

  return <Tab panes={tabs.filter(tab => !!tab).map((tab, i: number) => menuItem(tab, i))} />;
}