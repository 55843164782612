import { STATES } from '~/utils';
import { WizardField } from '../../Field';
import WizardForm from '../../WizardForm';
import { useDerivedRECFields } from '~/features/projectWizard/derived';


export default function Location() {
  // Updates REC data based on addressState changes
  useDerivedRECFields();

  return (
    <WizardForm>
      <WizardField.Row>
        <WizardField
          schemaKey="addressStreet"
          placeholder="Enter address"
          fieldLabel='Address'
        />
        <WizardField
          schemaKey="addressCity"
          fluid
          placeholder="Enter city"
          fieldLabel='City'
        />
      </WizardField.Row>
      <WizardField.Row>
        <WizardField
          schemaKey="addressState"
          search
          options={STATES}
          placeholder="Select state"
          fieldLabel='State'
        />
        <WizardField
          schemaKey="addressZip"
          placeholder="Enter zip code"
          fieldLabel='Zip Code'
        />
      </WizardField.Row>
      <WizardField
        schemaKey="utilityZone"
        placeholder="Enter name of electric utility"
        fieldLabel="Electric utility"
        maxLength={250}
      />
    </WizardForm>
  );
}