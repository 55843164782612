import { ReactNode } from 'react';
import { formatDollars } from '~/utils';
import { UnitDecimals, RecProgramYear } from '~/constants';
import { useProjectContext } from '~/hooks';
import { sizeKwac } from '~/utils/calculators';

interface ProjectRecsDataProps {
  recProjectCategory: string;
  recUtilityGroup: string;
  recProgramYear: string;
  recValue: number;
}

export const DisplayRECValue = (): ReactNode => {
  const { project } = useProjectContext();

  const kwac = sizeKwac(project) as number;
  const projectRecsData = project?.recsData ? project?.recsData[project?.addressState as string] : {};
  const { recProjectCategory, recUtilityGroup, recProgramYear, recValue } = projectRecsData as ProjectRecsDataProps; 

  const isRecsData = recProjectCategory && recUtilityGroup && recProgramYear;

  // Determine the value to display
  let recsValueInfo = 'TBD';
  if (project.projectArrays?.some((projectArray) => projectArray.sizeKwac === undefined || projectArray.sizeKwac === null)) {
    recsValueInfo += ' - Please enter array size in kWac';
  } else if (kwac && isRecsData && recValue !== undefined) {
    recsValueInfo = `${formatDollars(recValue, { dec: UnitDecimals.DOLLARS, default0: null })} / MWh`;
  }

  return (
    <>    
      <div style={{ display: 'flex', alignItems: 'center', gap: 'var(--x-small)',  marginTop: 'var(--x-large)'}}>
        <h3 style={{ margin: '0'}}>{recProgramYear === RecProgramYear.FUTURE_PROGRAM_YEAR ? 'Estimated REC value -' :  'REC value — ' }</h3>
        <span style={{ color: 'var(--color-primary)', fontWeight: 'bold' }}>
          {recsValueInfo}
        </span>
      </div>
      {recProgramYear === RecProgramYear.FUTURE_PROGRAM_YEAR && (
        <p style={{ marginTop: 'var(--x-small)' }}>Please note that these numbers assume a <strong>5% decrease</strong> in REC value and are an estimation as future year REC value are not yet available.</p>
      )}
    </>
  );
};