import { Button, Popup } from 'semantic-ui-react';
import { useFormGeneric, useAutoSaveContext } from '~/hooks';
import React from 'react';

interface ButtonSelectGroupProps {
  context?: any;
  schemaKey: string;
  onChange?: (schemaKey: string, value: any) => void;
  values: any;
  buttonStyle?: React.CSSProperties;
  [key: string]: any;
}

function ButtonSelectGroupImpl({
  context,
  schemaKey,
  onChange,
  values,
  buttonStyle = {},
  ...props
}: ButtonSelectGroupProps) {
  let {updateValue, getValue} = context;
  if (onChange) {
    updateValue = onChange;
  }

  return (
    <Button.Group {...props}>
      {values?.map((button: any, i: number) => {
        const buttonRender = (
          <Button
            name={button.label}
            style={{...buttonStyle, ...(i > 0 ? { borderLeft: '1px solid #bcbcbc;' } : {})}}
            onClick={() => {
              updateValue(schemaKey, button.value);
            }}
            secondary={getValue(schemaKey) === button.value}
            key={button.value}
          >
            {button.label}
          </Button>
        );
        if (button.tooltip) {
          return <Popup key={`button-group-popup-${i}`} trigger={buttonRender} content={button.tooltip} inverted position="top center" />;
        } else {
          return buttonRender;
        }
      })}
    </Button.Group>
  );
}

// this could be much more concise by relying entirely on ...props, but doing this facilitates IDE autocompletion
export function FormButtonSelectGroup({schemaKey, onChange, values, buttonStyle, ...props}: ButtonSelectGroupProps) {
  return (
    <ButtonSelectGroupImpl
      context={useFormGeneric()}
      {...{schemaKey, onChange, values, buttonStyle}}
      {...props}
    />
  );
}

// this could be much more concise by relying entirely on ...props, but doing this facilitates IDE autocompletion
export function AutoSaveButtonSelectGroup({schemaKey, onChange, values, buttonStyle, ...props}: ButtonSelectGroupProps) {
  return (
    <ButtonSelectGroupImpl
      context={useAutoSaveContext()}
      {...{schemaKey, onChange, values, buttonStyle}}
      {...props}
    />
  );
}
