import Budget from './components/Worksheet/Budget';
import Expenses from './components/Worksheet/Expenses';
import Overview from './components/Worksheet/Overview';
import System from './components/Worksheet/System';
import Revenue from './components/Worksheet/Revenue';
import { ProjectWizardConfigMapType, ProjectWizardSection, ProjectWizardSubsection } from './types';

export const ProjectWizardConfig: ProjectWizardSection[] = [
  {
    id: 'overview',
    label: 'Overview',
    description: 'Project setup + location',
    subsections: [
      {
        id: 'projectFoundations',
        label: 'Project type + status',
        component: Overview.ProjectFoundations,
        fields: [
          'developmentStage', 
          'deploymentType',
        ]
      },
      {
        id: 'projectRoles',
        label: 'Project roles',
        component: Overview.ProjectRoles,
        fields: [
          'longTermOwner', 
          'partnerType',
          'pricingType',
          'epcCompanyType',
          'epcCompanyName', 
        ]
      },
      {
        id: 'location',
        label: 'Location',
        component: Overview.Location,
        fields: [
          'addressStreet', 
          'addressCity', 
          'addressState', 
          'addressZip', 
          'utilityZone'
        ]
      },
      {
        id: 'keyDatesAndStatuses',
        label: 'Key dates and statuses',
        component: Overview.KeyDatesAndStatuses,
        fields: [
          'expectedProceedDate', 
          'expectedCommercialOperationDate', 
          'customerPricingStatus', 
          'offtakeContractStatus', 
          'permitsStatus', 
          'siteControlStatus', 
          'interconnectionStatus'
        ]
      }
    ]
  },
  {
    id: 'system',
    label: 'System',
    description: 'System details + production',
    subsections: [
      {
        id: 'pv',
        label: 'PV',
        component: System.PV,
        fields: ['projectArrays']
      },
      {
        id: 'storage',
        label: 'Storage',
        component: System.Storage,
        fields: ['storage']
      },
      {
        id: 'productionValidation',
        label: 'Production validation',
        component: System.ProductionValidation,
        fields: []
      }
    ]
  },
  {
    id: 'budget',
    label: 'Budget',
    description: 'Sources and uses of funds',
    subsections: [
      {
        id: 'upfrontIncentives',
        label: 'Upfront incentives',
        component: Budget.UpfrontIncentives,
        fields: [
          'itcAdders', 
          'upfrontIncentives', 
          'upfrontIncentiveUnit', 
          'upfrontIncentiveNotes'
        ]
      },
      {
        id: 'budgetItems',
        label: 'Budget items',
        component: Budget.BudgetItems,
        fields: [
          'epcCost',
          'epcCostUnit',
          'budgetCosts'
        ]
      }
    ]
  },
  {
    id: 'revenue',
    label: 'Revenue',
    description: 'Offtake contract, RECs',
    subsections: [
      {
        id: 'customerInformation',
        label: 'Customer information',
        component: Revenue.Information,
        fields: [
          'customerName',
          'customerType',
          'avoidedCostOfSolar',
          'yearOneSavings',
          'customerCreditRating',
          'communitySolarSubscriptionDetails'
        ]
      },
      {
        id: 'customerContract',
        label: 'Customer contract',
        component: Revenue.Contract,
        fields: [
          'revenueAssumptionsFlag',
          'contractType',
          'contractTerm',
          'contractRate',
          'communitySolarDiscountPercentage',
          'contractEscalator',
          'communitySolarRevenueDetails'
        ]
      },
      {
        id: 'renewableEnergyCredits',
        label: 'RECs',
        submitButtonText: 'renewable energy certificates',
        component: Revenue.RenewableEnergyCredits,
        fields: [
          'recOwnership',
          'recDetails'
        ]
      },
      {
        id: 'otherRevenueStreams',
        label: 'Other revenue streams',
        component: Revenue.OtherRevenueStreams,
        fields: [
         'additionalRevenues'
        ]
      }
    ]
  },
  {
    id: 'expenses',
    label: 'Expenses',
    description: 'Site and operating expenses',
    subsections: [
      {
        id: 'siteExpenses',
        label: 'Site expenses',
        component: Expenses.SiteExpenses,
        fields: [
          'siteLeaseCost',
          'siteLeaseCostUnit',
          'siteLeaseEscalator',
          'siteLeaseTerm',
          'siteLeaseExtensions',
          'siteLeaseAcreage',
          'unusualSiteConditions',
          'propertyTaxType',
          'propertyTaxAmount',
          'propertyTaxEscalator',
          'propertyTaxNotes',
        ]
      },
      {
        id: 'operationAndMaintenance',
        label: 'O&M',
        component: Expenses.OperationAndMaintenance,
        fields: [
          'omProposeFlag',
          'omPrice',
          'omPriceUnit',
          'omEscalator',
          'storageOmFlag',
          'storageExpenseAmount',
          'storageExpenseUnit',
          'storageExpenseEscalator',
          'storageExpenseExpectedTerm',
          'storageExpenseNotes'
        ]
      },
      {
        id: 'subscriptionManagement',
        label: 'Subscription management',
        component: Expenses.SubscriptionManagement,
        fields: [
          'subscriptionManagementAssumptionsFlag',
          'subscriptionExpenseAmount',
          'subscriptionExpenseUnit',
          'subscriptionExpenseEscalator',
          'subscriptionExpenseExpectedTerm',
          'subscriptionExpenseNotes'
        ]
      },
      {
        id: 'operatingExpenses',
        label: 'Other additional expenses',
        component: Expenses.OperatingExpenses,
        fields: [
          'additionalOperatingExpenses'
        ]
      }
    ]
  }
];

export const ProjectWizardConfigMap = ProjectWizardConfig.reduce((acc, curr) => {
  acc[curr.id] = { ...curr, subsections: curr.subsections.reduce((acc, curr, index) => {
    acc[curr.id] = { ...curr, index };
    acc[index] = { ...curr, index };
    return acc;
  }, {} as { [key: string]: ProjectWizardSubsection }) };
  return acc;
}, {} as ProjectWizardConfigMapType);