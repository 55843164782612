import { Dropdown, Icon, Loader, Popup } from 'semantic-ui-react';
import * as S from './styles';
import { Dataroom, DataroomDocument, DataroomDocumentFileData } from '~/types/dataroom';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import * as dataroomApi from '~/api/dataroom';
import { useMutation } from 'react-query';
import { useDataroomContext } from '../../providers/DataroomProvider';
import { useRef, useState } from 'react';
import { DealStates } from '~/constants';
import { withPromiseToaster } from '~/utils';
import { usePermissions } from '~/requests/permissions/usePermissions';
import EditDocListCheckbox from './EditDocListCheckbox';
import EditCustomDocuments from './EditCustomDocuments';
import { Button, Modal } from '~/components';

interface DocumentRowProps {
  data: DataroomDocument;
}

const States = {
  DEFAULT_NO_DOC: 'DEFAULT_NO_DOC',
  SELECTED_WITH_DOC: 'SELECTED_WITH_DOC',
  SELECTED_NO_DOC: 'SELECTED_NO_DOC',
  DRAGGING: 'DRAGGING',
  DRAGGING_NO_DOC: 'DRAGGING_NO_DOC',
  DRAGGING_WITH_DOC: 'DRAGGING_WITH_DOC',
  UPLOADING_NEW: 'UPLOADING_NEW',
  REPLACING: 'REPLACING',
  DELETING: 'DELETING',
  DOWNLOADING: 'DOWNLOADING',
  CHANGING_APPROVAL_STATUS: 'CHANGING_APPROVAL_STATUS'
};

export default function DocumentRow({ data }: DocumentRowProps) {
  const dropdownRef = useRef();
  const { 
    editingDocumentTypes,
    handleRemoveCustomDocumentType,
    updateDataroomDocument,
    dealState
  } = useDataroomContext();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  
  const dataroomContext = useDataroomContext();
  const dataroom = dataroomContext.dataroom as Dataroom;
  const { permissions } = usePermissions();

  // Download file handler
  const { mutateAsync: downloadFile, status: downloadStatus } = useMutation(dataroomApi.downloadFile);

  // Document approval handler
  const { mutate: setDocumentApproval, status: setDocumentApprovalStatus } = useMutation(async (flag: boolean) => {
    return withPromiseToaster(
      dataroomApi.setDocumentApprovalStatus(dataroom.id, data.document?.id, flag),
      {
        messageStub: (flag ? 'approving' : 'unapproving') + ' the document'
      }
    ).then(() => {
      // Update dataroom in queryClient with new document status
      updateDataroomDocument({ dataroom, documentTypeId: data.id, newDocument: {
        ...data.document as DataroomDocumentFileData,
        investorApproved: flag
      }});
    });
  });

  // Mutation for deleting a file
  const { mutateAsync: deleteFile, status: deleteStatus } = useMutation(async () => {
    const { result } = await dataroomApi.deleteFile({ id: dataroom.id, docId: data.document?.id });
    updateDataroomDocument({ dataroom, documentTypeId: data.id, newDocument: null });
    if (result === 'DELETE_DOCUMENT_TYPE') {
      handleRemoveCustomDocumentType(data);
    }
  }, {
    onSuccess() {
      toast.success('Document deleted successfully.', { duration: 3000 });
    },
    onError() {
      toast.error('Error occurred deleting the document. Please refresh the page and try again.', { duration: 4000 });
    }
  });

  // Mutation for uploading a file
  const { mutateAsync: uploadFile, status: uploadStatus } = useMutation((file: File) => {
    return dataroomApi.uploadFile({
      id: dataroom.id,
      documentTypeId: data.id,
      docId: data.document?.id,
      isCustom: data.isCustom,
      fileData: file,
      isUserProvidedName: data.isUserProvidedName,
      isReplace: true
    }).then(({ newDocumentName, ...newDocument }) => {
      toast.success('Document uploaded successfully.', { duration: 3000 });
      updateDataroomDocument({ dataroom, documentTypeId: data.id, newDocument, documentTypeName: newDocumentName });
    }).catch((err: any) => {
      console.error(err);
      toast.error('Error occurred uploading the document. Please refresh the page and try again.', { duration: 4000 });
    });
  }
);

  const { getRootProps, isDragActive, open: openFileDialog, getInputProps } = useDropzone({   
    maxFiles: 1,
    noClick: !!data.document,
    async onDrop(acceptedFiles) {
      if (acceptedFiles?.length !== 1) {
        toast.error('Only one file can be uploaded for a row.');
      } else {
        await uploadFile(acceptedFiles[0]);
      }
    }
  });

  const hasDocument = !!data.document;

  let state = States.DEFAULT_NO_DOC;
  if (!hasDocument && data.isSelected) {
    state = States.SELECTED_NO_DOC;
  }
  if (hasDocument && data.isSelected) {
    state = States.SELECTED_WITH_DOC;
  }
  if (isDragActive && !data.document?.investorApproved) {
    state = States.DRAGGING;
  }
  if (isDragActive && !data.document?.investorApproved && !hasDocument && data.isSelected) {
    state = States.DRAGGING_NO_DOC;
  }
  if (isDragActive && !data.document?.investorApproved && hasDocument && data.isSelected) {
    state = States.DRAGGING_WITH_DOC;
  }
  if (!data.document && uploadStatus === 'loading') {
    state = States.UPLOADING_NEW;
  } else if (uploadStatus === 'loading') {
    state = States.REPLACING;
  }
  if (downloadStatus === 'loading') {
    state = States.DOWNLOADING;
  }
  if (setDocumentApprovalStatus === 'loading') {
    state = States.CHANGING_APPROVAL_STATUS;
  }

  const dropdownClickProps = state === States.SELECTED_WITH_DOC ? {
    onClick() {
      const dropdown = dropdownRef.current as any;
      dropdown?.toggle?.();
    } 
  } : {};

  const showUploadText = state === States.SELECTED_NO_DOC || state === States.DRAGGING_NO_DOC || state === States.DRAGGING_WITH_DOC;

  const canUpload = permissions?.canUpdateDataroom && !data.document?.investorApproved && !editingDocumentTypes;

  return (
    <S.DocumentRowContainer isHidden={!editingDocumentTypes && !data.isSelected}>
      <EditDocListCheckbox documentType={data} />
      <S.DocumentRow 
        {...(canUpload ? getRootProps() : {})} 
        state={state} 
        {...dropdownClickProps} 
        isEditing={editingDocumentTypes}
      >
        <input {...getInputProps()} />
        {data.description && (
          <Popup
            position="bottom center"
            trigger={<Icon name="info circle" size="small" style={{ color: 'var(--color-charcoal' }} tabIndex={0} />}
            content={data.description}
            inverted
            on={['hover', 'focus']}
          />
        )}
        <S.DocumentRowTitle state={state}>
          {!editingDocumentTypes && showUploadText ? `${data.name} - upload here`: data.name}
        </S.DocumentRowTitle>
        <S.DocumentRowAlignRight>
          {!editingDocumentTypes && (
            <div style={{
              display: state !== States.SELECTED_WITH_DOC ? 'none' : 'grid',
              gridTemplateColumns: '8.5rem 9rem 4.5rem',
              columnGap: 'var(--small)',
            }}>
              {data.document?.uploadedBy && state !== States.DRAGGING && (
                <S.DocumentRowLabel 
                  style={{ textAlign: 'right', gridColumn: '1 / 2'}}>
                  {data.document?.uploadedBy}
                </S.DocumentRowLabel>
              )}
              {data.document?.uploadedAt && state !== States.DRAGGING && (
                <S.DocumentRowLabel 
                style={{ textAlign: 'left', gridColumn: '2 / 3'}}>
                  Uploaded on {new Date(data.document?.uploadedAt).toLocaleDateString()}
                </S.DocumentRowLabel>
              )}
              {data.document?.investorApproved && state !== States.DRAGGING && (
                <S.DocumentRowLabel 
                  color="green" 
                  style={{ margin: 0, textAlign: 'center', gridColumn: '3 / 4' }}>
                  Approved
                </S.DocumentRowLabel> 
              )}
            </div>
          )}
          {!editingDocumentTypes && (
            <div>
              {state === States.SELECTED_NO_DOC && (
                <S.UploadIcon name="upload" style={{ color: 'var(--color-charcoal)' }} /> 
              )}
              {state === States.SELECTED_WITH_DOC && (
                <S.DocumentRowDropdown ref={dropdownRef}>
                  <Dropdown.Menu style={{ transform: 'translateX(1rem)'}}>
                    {permissions.canApproveDataroomDocuments && dealState >= DealStates.AWAITING_DATAROOM && !data.document?.investorApproved && (
                      <Dropdown.Item disabled={state === States.CHANGING_APPROVAL_STATUS} onClick={() => setDocumentApproval(true)}>
                        Approve
                      </Dropdown.Item>
                    )}
                    {permissions.canApproveDataroomDocuments && dealState >= DealStates.AWAITING_DATAROOM && data.document?.investorApproved && (
                      <Dropdown.Item disabled={state === States.CHANGING_APPROVAL_STATUS} onClick={() => setDocumentApproval(false)}>
                        Revoke Approval
                      </Dropdown.Item>
                    )}
                    {permissions.canUpdateDataroom && (
                      <Dropdown.Item onClick={openFileDialog}>
                        Replace
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={() => downloadFile({ id: dataroom.id, docId: data.document?.id })}>
                      Download
                    </Dropdown.Item>
                    {permissions.canUpdateDataroom && (
                      <Dropdown.Item onClick={() => setDeleteModalOpen(true)}>
                        Delete {data.document?.investorApproved ? 'approved' : 'uploaded'} document
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </S.DocumentRowDropdown>
              )}
              {(state === States.UPLOADING_NEW || state === States.REPLACING || state === States.DOWNLOADING || state === States.CHANGING_APPROVAL_STATUS) && (
                <S.DocumentRowLoaderContainer>
                  <Loader size="mini" active />
                </S.DocumentRowLoaderContainer>
              )}
             </div>
            )}
          {editingDocumentTypes && <EditCustomDocuments documentType={data} />}
        </S.DocumentRowAlignRight>
      </S.DocumentRow>

      {deleteModalOpen && (
        <Modal allowClose={deleteStatus !== 'loading'} closeModal={() => setDeleteModalOpen(false)}>
          <Modal.Title>
            Delete file?
          </Modal.Title>
          <Modal.Content>
            <p>
              This will delete the file for all users who have access to view it. Are you sure you want to delete the file?
            </p>
          </Modal.Content>
          <Modal.Footer>
            <Button
              primary
              onClick={() => deleteFile().then(() => setDeleteModalOpen(false))}
              loading={deleteStatus === 'loading'}
              disabled={deleteStatus === 'loading'}
            >
              Confirm
            </Button>
            <Button
              outlined
              primary
              onClick={() => setDeleteModalOpen(false)}
              disabled={deleteStatus === 'loading'}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </S.DocumentRowContainer>
  );
}