import { useCallback } from 'react';
import { usePortfolioContext } from '~/hooks';
import { DealStates } from '~/constants';
import InvestorInvitations from '~/components/investorInvitations';
import { assignPortfolioToInvestor, unassignPortfolioFromInvestor, updateInvestorInPortfolioQueue } from '~/api/adminInvestorInvitations';
import { Project } from '~/types/project';

export default function AdminPortfolioInvestorInvites() {
  const { portfolio, id, updatePortfolioValue, portfolioState } = usePortfolioContext();

  const assignToInvestor = useCallback(
    async (formState: any) => {
      const projects = await assignPortfolioToInvestor(id, formState);
      updatePortfolioValue('projects', projects, {doSave: false});
    },
    [id]
  );

  const updateInvitation = useCallback(
    async (formState: any) => {
      const projects = await updateInvestorInPortfolioQueue(id, formState);
      updatePortfolioValue('projects', projects, {doSave: false});
    },
    [id]
  );

  const unassignInvestor = useCallback(
    async (data: any) => {
      const projects = await unassignPortfolioFromInvestor(id, data);
      updatePortfolioValue('projects', projects, {doSave: false});
    },
    [id]
  );

  const parentProject = portfolio?.projects?.find((project: Project) => !project.parentId);

  return (
    <>
      <div style={{ position: 'relative' }}>
        {portfolio?.projects?.length && portfolioState.latestState >= DealStates.IN_REVIEW && ( 
          <InvestorInvitations
            {...{assignToInvestor, updateInvitation, unassignInvestor}}
            label='portfolio'
            investorQueue={parentProject?.investorQueue}
            preferredInvestors={portfolio.preferredInvestors}
            preferredInvestorDetails={portfolio.preferredInvestorDetails}
            dealState={portfolioState.earliestState}
          />
        )}
      </div>

    </>
  );
}

