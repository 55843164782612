import { ReactNode } from 'react';
import { useState } from 'react';
import { Button } from '~/components';
import { useCompleteTutorialMutation } from '~/mutations/tutorials/useCompleteTutorialMutation';
import Modal from '~/components/Modal';
import { useModalContext } from '~/hooks/providers/ModalProvider';

interface TutorialModalProps { 
    title: string;
    content: ReactNode;
    submitButtonText?: string; 
    hasCancelButton?: boolean;
    cancelText?: string;
    onSubmit?: () => void; 
    tutorialCode: string; 
};

export default function TutorialModal({
    tutorialCode,
    title,
    content,
    submitButtonText, 
    hasCancelButton,
    cancelText,
    onSubmit, 
}: TutorialModalProps) {

    const { closeModal } = useModalContext();
    const { mutateAsync: completeTutorial, status } = useCompleteTutorialMutation();

    const [submitLoading, setSubmitLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);

    const handleSubmit = async () => {
        try {
          setSubmitLoading(true);
          await completeTutorial({ code: tutorialCode });
          closeModal();
          if (onSubmit) {
            onSubmit();
          }
        } catch (err: any) {
          setSubmitLoading(false);
        }
      };

    const handleClose =  async () => {
        try {
          setCancelLoading(true);
          await completeTutorial({ code: tutorialCode });
          closeModal();
        } catch (err: any) {
          setCancelLoading(false);
        }
    };

    return (
        <Modal
          shouldCloseOnEsc={false} 
          shouldCloseOnOverlayClick={false} 
          closeModal={handleClose}>
        <Modal.Title>
                {title}
            </Modal.Title>
            <Modal.Content>
                {content}
            </Modal.Content>
            <Modal.Footer>
            {hasCancelButton && (
              <Button
                  primary
                  outlined
                  onClick={handleClose}
                  disabled={cancelLoading || status === 'loading'}
                  loading={cancelLoading}
                  >
                  {cancelText}
              </Button>
            )}
            <Button
                primary
                onClick={handleSubmit}
                disabled={submitLoading || status === 'loading'}
                loading={submitLoading}
                >
                {submitButtonText}
            </Button>
            </Modal.Footer>
        </Modal>
    ); 
};