import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getNotificationResolverRoute } from '~/api/notification';
import toast from 'react-hot-toast';

const NotificationResolver = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    getNotificationResolverRoute(id).then((path: string) => {
      // Redirect to the path returned from the server
      history.replace(path);
    }).catch((err: any) => {
      // Log the error and show a toast
      console.error(err);
      toast.error('An error occurred while trying to open your notification.', { duration: 4000 });
      
      // Redirect to the home page if there is an error
      history.replace('/');
    });
  }, []);

  return null;
};

export default NotificationResolver;