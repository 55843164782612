import { Conversations } from '~/types/conversations/Conversation';
import { Message } from '~/types/conversations/Message';
import axios from '~/utils/axiosInstance';

export const getProjectConversations = async (projectId: string): Promise<Conversations> => {
  const response = await axios.get(`/projects/${projectId}/conversations`);
  return response.data ?? [];
};

export const getPortfolioConversations = async (portfolioId: string): Promise<Conversations> => {
  const response = await axios.get(`/portfolios/${portfolioId}/conversations`);
  return response.data ?? [];
};

export const fetchConversation = async (conversationId: string) => {
  const response = await axios.get(`/conversations/${conversationId}`);
  return response.data;
};

export const addCommentMessage = async (conversationId: string, comment: string, replyThreadId?: string) => {
  const response = await axios.post(`/conversations/${conversationId}/comments`, { comment, replyThreadId });
  return response.data as Message;
};

export const deleteCommentMessage = async (conversationId: string, messageId: string) => {
  const response = await axios.delete(`/conversations/${conversationId}/comments/${messageId}`);
  return response.data as Message;
};

export const editCommentMessage = async (conversationId: string, messageId: string, text: string) => {
  const response = await axios.patch(`/conversations/${conversationId}/comments/${messageId}`, { text });
  return response.data as Message;
};

export const updateResponseRequiredFlag = (conversationId: string, messageId: string, flag: boolean) => {
  return axios.put(`/conversations/${conversationId}/comments/${messageId}/requires-response`, { flag })
    .then(res => res.data);
};

export const addFileMessage = async (conversationId: string, fileName: string) => {
  const response = await axios.post(`/conversations/${conversationId}/files`, { fileName });
  return response.data as { presignedUrl: string, undoKey: string, message: Message };
};

export const getFileMessagePresignedUrl = async (conversationId: string, messageId: string) => {
  const response = await axios.get(`/conversations/${conversationId}/files/${messageId}/presigned-url`);
  return response.data?.presignedUrl as string;
};

export const replaceMessageFile = async (conversationId: string, messageId: string, fileName: string) => {
  const response = await axios.put(`/conversations/${conversationId}/files/${messageId}`, { fileName });
  return response.data;
};