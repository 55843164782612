import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { updateAdminNotes } from '~/api/admin';

const defaults = {
  onError() {
    toast.error('Error occurred updating admin notes. Please try again.', { duration: 3000 });
  },
  onSuccess() {
    toast.success('Successfully updated admin notes.', { duration: 3000 });
  }
};

export const useUpdateAdminNotes = (params = {}) => {
  return useMutation({
    mutationFn: async ({ projectId, notes }: any) => {
      try {
        await updateAdminNotes(projectId, notes);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    ...defaults,
    ...params
  });
};