import { useCallback, useState } from 'react';
import { withPromiseToaster } from '~/utils';

// Hook
const usePromiseWatcher = (asyncFunction?: any, toastMessages?: any, dependencies?: any, {defaultValue = null, toastOpts = null} = {}) => {
  const [status, setStatus] = useState('idle');
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(null);

  // The execute function wraps asyncFunction and handles setting state for pending, value, and error; it also handles toast messaging.
  const execute = useCallback(
    (...args: any) => {
      setStatus('pending');
      setValue(defaultValue);
      setError(null);

      let promise = asyncFunction(...args);
      if (toastMessages) {
        promise = withPromiseToaster(promise, toastMessages, {catchErrors: false, args, toastOpts});
      }
      return promise
        .then((response: any) => {
          setValue(response);
          setStatus('success');
        })
        .catch((error: any) => {
          console.error('Error caught in usePromiseWatcher - args:\n', args, '\n', error);
          setError(error);
          setStatus('error');
        });
    },
    dependencies
  );

  return {execute, status, value, error, setValue};
};

export default usePromiseWatcher;
