import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateUserCompanySettingsParams, updateUserCompanySettings } from '~/api/team';
import { getListTeamMembersKey, useTeamMembersQuery } from '~/requests/team';

export const useUpdateUserCompanySettings = () => {
  const queryClient = useQueryClient();
  const { data: teamMembers } = useTeamMembersQuery();

  return useMutation((params: UpdateUserCompanySettingsParams) => {
    return updateUserCompanySettings(params)
      .then((newMember: any) => {
        // Update useTeamMembersQuery data. This allows us to not need to refetch the data
        const newTeamMembers = teamMembers?.map((member: any) => member.id === newMember.id ? newMember : member);
        queryClient.setQueryData(getListTeamMembersKey(), newTeamMembers);
      })
      .catch(e => {
        const errorCode = e?.response?.data?.message as string;
        let toastMessage: string;
        switch (errorCode) {
          case 'AT_LEAST_ONE_PROJECT_LEAD_REQUIRED':
            toastMessage = 'At least one active user must be a project lead.';
            break;
          case 'CANNOT_REMOVE_LAST_ADMIN':
            toastMessage = 'You cannot remove your Admin privileges when there are no other Admins in your organization. Please set another user as an Admin and try again.';
            break;
          default:
            toastMessage = 'Failed to update user settings. Please try again.';
        }
        toast.error(toastMessage);
        throw e;
      });
  }
);};